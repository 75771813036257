import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { useNavigate } from "react-router-dom";
import { TfiBrushAlt } from "react-icons/tfi";

import { useGetAllSuggestion } from "../../../../hooks/useGetAllSuggestion";

import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import TableComponent from "../../../reusables/TableComponent/TableComponent";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import { handleStateChange, searchData } from "../../../../utils/helpers";
import {
  countries,
  qualificationListTable,
  qualificationTableFieldstoShow,
} from "../../../../utils/constant";

const SuperAdminQualificationList = () => {
  const [suggestion, isLoading, error] = useGetAllSuggestion();

  const [filterData, setFilterData] = useState(suggestion);

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [filterContent, setFilterContent] = useState({
    startDate: "",
    endDate: "",
    studentCountry: "",
    examination: "",
  });

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const clearSearch = () => {
    setFilterContent({
      startDate: "",
      endDate: "",
      studentCountry: "",
      examination: "",
    });
    setFilterData(suggestion);
  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (ranges) => {
    setFilterContent((prev) => ({
      ...prev,
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    }));
    onToggle();
  };

  useEffect(() => {
    setFilterData(searchData(filterContent, suggestion));
  }, [filterContent, suggestion]);

  useEffect(() => {
    setFilterData(suggestion);
  }, [suggestion]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;
  return (
    <div>
      <UserNavbar>
        <PageTitle title={"QUALIFICATION"} />
        <br />

        <div className="university_list_table_holder">
          <div className="action_button_holder">
            <div className="date_range_keeper">
              <input
                className="applciation_input_field_style date_show_input"
                value={
                  filterContent.endDate &&
                  filterContent.startDate &&
                  `${new Date(
                    filterContent.startDate
                  ).toLocaleDateString()} - ${new Date(
                    filterContent.endDate
                  ).toLocaleDateString()}`
                }
                placeholder="Select dates"
                onClick={onToggle}
              />
              {isOpen && (
                <div className="date_picker_holder">
                  <DateRange
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    showSelectionPreview={true}
                  />
                </div>
              )}
            </div>
            <select
              className="applciation_input_field_style"
              name="studentCountry"
              onChange={(e) => handleStateChange(e, setFilterContent)}
              value={filterContent?.studentCountry}
            >
              {countries.map((country) => (
                <option>{country.name}</option>
              ))}
            </select>
            <input
              type="text"
              className="applciation_input_field_style"
              placeholder="Search Universities"
              name="examination"
              onChange={(e) => handleStateChange(e, setFilterContent)}
              value={filterContent.examination}
            />

            <button
              className="add_university_navigation_btn"
              onClick={clearSearch}
            >
              <TfiBrushAlt className="clear_icon_style" />
            </button>
            <button
              className="add_university_navigation_btn"
              onClick={() =>
                navigate("/superAdmin/qualification/addQualification")
              }
            >
              Add new Qualification
            </button>
          </div>

          <TableComponent
            tableColumns={qualificationListTable}
            fieldsToShowList={qualificationTableFieldstoShow}
            data={filterData}
            navigateTo="/superAdmin/qualification/view"
            emptyFor="superAdminQualificationList"
          />
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminQualificationList;
