import React from "react";
import { Navigate } from "react-router-dom";

const SuperAdminPrivateRoute = ({ children }) => {
  const currentLoginUser = JSON.parse(localStorage.getItem("currentLoginUser"));
  const allowedRoles = ["SuperAdmin", "SubSuperAdmin", "SubAdmin"];

  if (currentLoginUser && allowedRoles.includes(currentLoginUser.role)) {
    return <div>{children}</div>;
  } else {
    return <Navigate to={"/"} />;
  }
};

export default SuperAdminPrivateRoute;
