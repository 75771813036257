import React, { useEffect, useState } from "react";
import "./SuperAdminStudentView.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { MultiSelect } from "react-multi-select-component";

import { useGetStudentById } from "../../../../hooks/useGetStudentById";
import { useGetAllCourse } from "../../../../hooks/useGetAllCourse";

import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import CustomVericalInputField from "../../../reusables/FromComponent/CustomVericalInputField";
import CustomVerticalSelectField from "../../../reusables/FromComponent/CustomVerticalSelectField";

import Notification, {
  generateEducationRequirementScoreSelect,
  generateRandomString,
  handleFileInputInState,
  handleStateChange,
  removeElementFromArray,
  removeEmptyProperties,
  replaceLocalhostUrl,
} from "../../../../utils/helpers";
import {
  API_HEADERS,
  courseLevelSelect,
  educationalRequirementSelect,
  englishExamtest,
  genderSelect,
  languages,
  newCountryList,
  newCourseSubjectListForStudent,
  newMaritalStatus,
  postGraduationWorkPermit,
  visaPermission,
  workDuringStydentSelect,
} from "../../../../utils/constant";

const SuperAdminStudentView = () => {
  const { studentId } = useParams();

  const [studentData, isLoading, error] = useGetStudentById(studentId);
  const course = useGetAllCourse();

  const navigate = useNavigate();

  const [studentToupdate, setStudentToupdate] = useState({});

  useEffect(() => {
    setStudentToupdate({
      studentProfileImage: "",
      firstName: studentData?.firstName || "",
      lastName: studentData?.lastName || "",
      email: studentData?.email || "",
      visaPermitStatus: studentData?.visaPermitStatus || "",
      placeOfBirth: studentData?.placeOfBirth || "",
      countryOfBirth: studentData?.countryOfBirth || "",
      citizenship: studentData?.citizenship || "",
      secondCitizenship: studentData?.secondCitizenship || "",
      countryOfResidence: studentData?.countryOfResidence || "",
      firstlanguage: studentData?.firstlanguage || "",
      maritalStatus: studentData?.maritalStatus || "",
      cityOfResidence: studentData?.cityOfResidence || "",
      gender: studentData?.gender || "",
      cv: "",
      address: studentData?.address || "",
      passportNumber: studentData?.passportNumber || "",
      dateOfBirth: studentData?.dateOfBirth || "",
      postcode: studentData?.postcode || "",
      passportExpiry: studentData?.passportExpiry || "",
      passportCopy: "",
      username: studentData?.username || "",
      highestEducation: studentData?.highestEducation || "",
      studentAcademicInstitution: studentData?.studentAcademicInstitution || "",
      languageOfInstruction: studentData?.languageOfInstruction || "",
      countryOfEducation: studentData?.countryOfEducation || "",
      averageGrade: studentData?.averageGrade || "",
      dateAttendedFrom: studentData?.dateAttendedFrom || "",
      dateAttendedUntil: studentData?.dateAttendedUntil || "",
      nameOfDegree: studentData?.nameOfDegree || "",
      englishLanguageTest: studentData?.englishLanguageTest || "",
      languageTestScore: studentData?.languageTestScore || "",
      proofOfAcadamicQualification: "",
      desiredCountry: studentData?.desiredCountry || [],
      workDuringStudy: studentData?.workDuringStudy || "",
      postGraduateWorkPermit: studentData?.postGraduateWorkPermit || "",
      desiredlevelOfStudy: studentData?.desiredlevelOfStudy || "",
      desiredCourseDiscipline: studentData?.desiredCourseDiscipline || "",
      courseID: studentData?.courseID || "",
      preferedStartMonth: studentData?.preferedStartMonth || "",
      englishTestRequirement: studentData?.englishTestRequirement || [],
    });

    setEnglishProfData(studentData?.englishTestRequirement || []);
  }, [studentId, studentData]);

  const handleUpdateStudent = () => {
    const dataToUpdate = removeEmptyProperties({
      ...studentToupdate,
      studentId: studentId,
    });

    const updateStudentData = new FormData();
    Object.keys(dataToUpdate).forEach((key) => {
      if (
        Array.isArray(dataToUpdate[key]) &&
        (key === "desiredCountry" || key === "englishTestRequirement")
      ) {
        updateStudentData.append(key, JSON.stringify(dataToUpdate[key]));
      } else {
        updateStudentData.append(key, dataToUpdate[key]);
      }
    });

    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/student/updateStudentById`,
        updateStudentData,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Student Updated Successfully");
        navigate("/superAdmin/student");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  const [englishProfData, setEnglishProfData] = useState(
    studentData?.englishTestRequirement || []
  );

  const handleValueEnteringEnglishTest = (
    id,
    englishTest,
    reading,
    writing,
    listning,
    speaking,
    total
  ) => {
    const updatedTestInput = englishProfData.map((item) => {
      if (item.id === id) {
        if (englishTest !== undefined) return { ...item, englishTest };
        if (reading !== undefined) return { ...item, reading: reading };
        if (writing !== undefined) return { ...item, writing: writing };
        if (listning !== undefined) return { ...item, listning: listning };
        if (speaking !== undefined) return { ...item, speaking: speaking };
        if (total !== undefined) return { ...item, total: total };
        else return { ...item };
      }
      return item;
    });

    setEnglishProfData(updatedTestInput);
  };

  useEffect(() => {
    setStudentToupdate((prev) => ({
      ...prev,
      englishTestRequirement: englishProfData,
    }));
  }, [englishProfData]);

  const handleDeleteStudent = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/student/deleteStudentById/${studentId}`,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Student Deleted Successfully");
        navigate("/superAdmin/student");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  const [showVisaResidenceField, setshowVisaResidenceField] = useState(true);

  useEffect(() => {
    const newCitizenshipArray = [
      studentToupdate?.citizenship,
      studentToupdate?.secondCitizenship,
    ];
    if (
      newCitizenshipArray.includes(studentToupdate?.countryOfResidence) &&
      studentToupdate?.citizenship &&
      studentToupdate?.secondCitizenship &&
      studentToupdate?.countryOfResidence
    )
      setshowVisaResidenceField(false);
    else setshowVisaResidenceField(true);
  }, [
    studentToupdate?.citizenship,
    studentToupdate?.secondCitizenship,
    studentToupdate?.countryOfResidence,
  ]);

  if (isLoading || course[1]) return <Loader />;
  if (error || course[2]) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"VIEW STUDENT"} />
        <div className="add_student_form">
          <div className="student_input_container">
            <div className="profile_photo_info_holder">
              <div className="student_profile_img_container">
                <label
                  htmlFor="studen_profile_image"
                  className="student_profile_img_label_style"
                >
                  {studentToupdate && studentToupdate?.studentProfileImage ? (
                    <img
                      src={URL.createObjectURL(
                        studentToupdate.studentProfileImage
                      )}
                      className="user_uploaded_image_style"
                      alt="user_profile_image"
                    />
                  ) : studentData && studentData?.studentProfileImage ? (
                    <img
                      src={replaceLocalhostUrl(
                        studentData?.studentProfileImage
                      )}
                      className="user_uploaded_image_style"
                      alt="user_profile_image"
                    />
                  ) : (
                    <img
                      src="/images/superAdmin/upload_placeholder.png"
                      className="upload_place_holder_style"
                      alt="user_profile_image"
                    />
                  )}
                </label>
                <input
                  type="file"
                  className="actual_file_uopload_input_field"
                  id="studen_profile_image"
                  name="studentProfileImage"
                  onChange={(e) =>
                    handleFileInputInState(e, setStudentToupdate)
                  }
                />
              </div>
              <div className="general_details_holder">
                <div className="container">
                  <div className="row">
                    <CustomVericalInputField
                      name="firstName"
                      label="Applicant First Name"
                      value={studentToupdate?.firstName}
                      onChange={(e) => handleStateChange(e, setStudentToupdate)}
                      colClass="col-12 col-lg-6"
                    />

                    <CustomVericalInputField
                      name="lastName"
                      label="Applicant Last Name"
                      value={studentToupdate?.lastName}
                      onChange={(e) => handleStateChange(e, setStudentToupdate)}
                      colClass="col-12 col-lg-6"
                    />

                    <CustomVericalInputField
                      name="email"
                      label="Email Address"
                      type="email"
                      value={studentToupdate?.email}
                      colClass="col-12 col-lg-6"
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="student_input_container">
            <div className="container">
              <div className="row">
                <CustomVericalInputField
                  name="placeOfBirth"
                  label="Place of Birth"
                  value={studentToupdate?.placeOfBirth}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="countryOfBirth"
                  label="Country of Birth"
                  value={studentToupdate?.countryOfBirth}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  options={newCountryList}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  label="Citizenship"
                  name="citizenship"
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  value={studentToupdate?.citizenship}
                  options={newCountryList}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  label="Second Citizenship"
                  name="secondCitizenship"
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  value={studentToupdate?.secondCitizenship}
                  options={newCountryList}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  label="Country of Residence"
                  name="countryOfResidence"
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  value={studentToupdate?.countryOfResidence}
                  options={newCountryList}
                  colClass="col-12 col-lg-4"
                />

                {showVisaResidenceField && (
                  <CustomVerticalSelectField
                    name="visaPermitStatus"
                    label="Visa / Resident Permit Held"
                    value={studentToupdate?.visaPermitStatus}
                    onChange={(e) => handleStateChange(e, setStudentToupdate)}
                    options={visaPermission}
                    colClass="col-12 col-lg-4"
                  />
                )}

                <CustomVerticalSelectField
                  name="firstlanguage"
                  label="First Language"
                  value={studentToupdate?.firstlanguage}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  options={languages}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="maritalStatus"
                  label="Marital Status"
                  value={studentToupdate?.maritalStatus}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  options={newMaritalStatus}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="cityOfResidence"
                  label="City of Residence"
                  value={studentToupdate?.cityOfResidence}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="gender"
                  label="Gender"
                  value={studentToupdate?.gender}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  options={genderSelect}
                  colClass="col-12 col-lg-4"
                />

                <div className="col-12 col-lg-4">
                  <div className="student_label_field_holder_flex_style">
                    <label className="student_label_style">CV</label>
                    <label htmlFor="cv" style={{ width: "90%" }}>
                      <div className="cv_file_holder_div_style">
                        {studentToupdate && studentToupdate.cv !== ""
                          ? `${studentToupdate?.cv?.name}`
                          : ""}
                      </div>
                    </label>
                    {studentData?.cv && (
                      <a
                        href={replaceLocalhostUrl(studentData?.cv)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Old Doc
                      </a>
                    )}

                    <input
                      type="file"
                      className="actual_file_uopload_input_field"
                      id="cv"
                      name="cv"
                      onChange={(e) =>
                        handleFileInputInState(e, setStudentToupdate)
                      }
                    />
                  </div>
                </div>

                <CustomVericalInputField
                  name="address"
                  label="Address"
                  value={studentToupdate?.address}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="passportNumber"
                  label=" Passport Number"
                  value={studentToupdate?.passportNumber}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="dateOfBirth"
                  label="Date of Birth"
                  type="date"
                  value={studentToupdate?.dateOfBirth}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="postcode"
                  label="Postcode"
                  value={studentToupdate?.postcode}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="passportExpiry"
                  label="Passport Expiry"
                  type="month"
                  value={studentToupdate?.passportExpiry}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  colClass="col-12 col-lg-4"
                />

                <div className="col-12 col-lg-4">
                  <div className="student_label_field_holder_flex_style">
                    <label className="student_label_style">Passport Copy</label>
                    <label htmlFor="passportCopy" style={{ width: "90%" }}>
                      <div className="cv_file_holder_div_style">
                        {studentToupdate && studentToupdate.passportCopy !== ""
                          ? `${studentToupdate?.passportCopy?.name}`
                          : ""}
                      </div>
                    </label>
                    {studentData?.passportCopy && (
                      <a
                        href={replaceLocalhostUrl(studentData?.passportCopy)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Old Doc
                      </a>
                    )}
                    <input
                      type="file"
                      className="actual_file_uopload_input_field"
                      id="passportCopy"
                      name="passportCopy"
                      onChange={(e) =>
                        handleFileInputInState(e, setStudentToupdate)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="student_input_container">
            <div className="container">
              <div className="row">
                <CustomVerticalSelectField
                  name="highestEducation"
                  label="Highest Education Level Attained"
                  value={studentToupdate?.highestEducation}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  options={educationalRequirementSelect}
                  colClass="col-12 col-lg-4"
                  toolTip="We use this information to match you to the most suitable courses"
                />

                <CustomVericalInputField
                  name="studentAcademicInstitution"
                  label="Name of Academic Institution"
                  value={studentToupdate?.studentAcademicInstitution}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="languageOfInstruction"
                  label="Language of Instruction"
                  value={studentToupdate?.languageOfInstruction}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  options={languages}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="countryOfEducation"
                  label="Country of Education"
                  value={studentToupdate?.countryOfEducation}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  options={newCountryList}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="averageGrade"
                  label="Average grade (%)"
                  informativeLink={`/blog/view/650ceecf2cf8f95dc218238f`}
                  value={studentToupdate?.averageGrade}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  colClass="col-12 col-lg-4"
                  options={generateEducationRequirementScoreSelect(100)}
                />

                <CustomVericalInputField
                  name="dateAttendedFrom"
                  label="Date Attended From"
                  type="date"
                  value={studentToupdate?.dateAttendedFrom}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="dateAttendedUntil"
                  label="Date Attended Until"
                  type="date"
                  value={studentToupdate?.dateAttendedUntil}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="nameOfDegree"
                  label="Name of Course"
                  value={studentToupdate?.nameOfDegree}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  colClass="col-12 col-lg-4"
                />

                <div className="col-12 col-lg-4">
                  <div className="student_label_field_holder_flex_style">
                    <label className="student_label_style">
                      Proof of Academic Qualification
                    </label>
                    <label
                      htmlFor="proofOfAcadamicQualification"
                      style={{ width: "90%" }}
                    >
                      <div className="cv_file_holder_div_style">
                        {studentToupdate &&
                        studentToupdate.proofOfAcadamicQualification !== ""
                          ? `${studentToupdate?.proofOfAcadamicQualification?.name}`
                          : ""}
                      </div>
                    </label>
                    {studentData?.proofOfAcadamicQualification && (
                      <a
                        href={replaceLocalhostUrl(
                          studentData?.proofOfAcadamicQualification
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Old Doc
                      </a>
                    )}
                    <input
                      type="file"
                      className="actual_file_uopload_input_field"
                      id="proofOfAcadamicQualification"
                      name="proofOfAcadamicQualification"
                      onChange={(e) =>
                        handleFileInputInState(e, setStudentToupdate)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="student_input_container">
            <div className="english_prof_container">
              <div className="label_and_add_btn_holder">
                <label className="course_label_style_multiple">
                  Given English test
                </label>
                <AiOutlinePlus
                  className="add_work_exp_icon_style"
                  onClick={() =>
                    setEnglishProfData([
                      ...englishProfData,
                      {
                        id: generateRandomString(5),
                        englishTest: "",
                        requiredScore: "",
                      },
                    ])
                  }
                />
              </div>
              {englishProfData &&
                englishProfData.map((field) => (
                  <div className="work_option_holder">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-lg-12">
                          <CustomVerticalSelectField
                            className="course_input_field_style_with_img"
                            value={field.englishTest}
                            placeholder={"Please enter service"}
                            id={field.id}
                            onChange={(e) =>
                              handleValueEnteringEnglishTest(
                                field.id,
                                e.target.value,
                                ...[,]
                              )
                            }
                            options={englishExamtest}
                          />
                        </div>
                        <div
                          className="col-12 col-lg-12"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <CustomVericalInputField
                            className="course_input_field_style_with_img"
                            value={field.reading}
                            label={"Reading"}
                            placeholder={"Reading "}
                            id={field.id}
                            onChange={(e) =>
                              handleValueEnteringEnglishTest(
                                field.id,
                                ...[,],
                                e.target.value
                              )
                            }
                          />
                          <CustomVericalInputField
                            className="course_input_field_style_with_img"
                            value={field.writing}
                            label={"Writing"}
                            placeholder={"Writing "}
                            id={field.id}
                            onChange={(e) =>
                              handleValueEnteringEnglishTest(
                                field.id,
                                ...[,],
                                ...[,],
                                e.target.value
                              )
                            }
                          />
                          <CustomVericalInputField
                            className="course_input_field_style_with_img"
                            value={field.listning}
                            label={"Listening"}
                            placeholder={"Listening"}
                            id={field.id}
                            onChange={(e) =>
                              handleValueEnteringEnglishTest(
                                field.id,
                                ...[,],
                                ...[,],
                                ...[,],

                                e.target.value
                              )
                            }
                          />
                          <CustomVericalInputField
                            className="course_input_field_style_with_img"
                            value={field.speaking}
                            label={"Speaking"}
                            placeholder={"Speaking"}
                            id={field.id}
                            onChange={(e) =>
                              handleValueEnteringEnglishTest(
                                field.id,
                                ...[,],
                                ...[,],
                                ...[,],
                                ...[,],
                                e.target.value
                              )
                            }
                          />
                          <CustomVericalInputField
                            className="course_input_field_style_with_img"
                            value={field.total}
                            label={"Total"}
                            placeholder={"Total"}
                            id={field.id}
                            onChange={(e) =>
                              handleValueEnteringEnglishTest(
                                field.id,
                                ...[,],
                                ...[,],
                                ...[,],
                                ...[,],
                                ...[,],
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="minus_btn_holder">
                      <AiOutlineMinus
                        className="minus_icon_style"
                        onClick={() => {
                          setEnglishProfData(
                            removeElementFromArray(englishProfData, field.id)
                          );
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="student_input_container">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <label className="agency_label_style">Desired Country</label>
                  <MultiSelect
                    options={newCountryList}
                    value={studentToupdate?.desiredCountry || []}
                    onChange={(values) => {
                      setStudentToupdate((prev) => ({
                        ...prev,
                        desiredCountry: [...values],
                      }));
                    }}
                    className="multipleInputStyle"
                    isCreatable={true}
                  />
                </div>

                <CustomVerticalSelectField
                  name="workDuringStudy"
                  label="Work During Your Studies"
                  value={studentToupdate?.workDuringStudy}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  options={workDuringStydentSelect}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="postGraduateWorkPermit"
                  label="Post-Graduation work Permit"
                  value={studentToupdate?.postGraduateWorkPermit}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  options={postGraduationWorkPermit}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="desiredlevelOfStudy"
                  label="Desired Level of Study"
                  value={studentToupdate?.desiredlevelOfStudy}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  options={courseLevelSelect}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="desiredCourseDiscipline"
                  label="Desired Course Discipline"
                  value={studentToupdate?.desiredCourseDiscipline}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  options={newCourseSubjectListForStudent}
                  colClass="col-12 col-lg-4"
                />

                <div className="col-12 col-lg-4">
                  <div className="student_label_field_holder_flex_style">
                    <label className="student_label_style">Course</label>
                    <select
                      className="student_input_field_style"
                      name="courseID"
                      defaultValue={course ? studentData?.courseID : ""}
                      onChange={(e) => handleStateChange(e, setStudentToupdate)}
                    >
                      <option>---- Select Option ----</option>
                      {course[0] &&
                        course[0].map((item) => (
                          <option value={item?._id}>{item.courseName}</option>
                        ))}
                    </select>
                  </div>
                </div>

                <CustomVericalInputField
                  name="preferedStartMonth"
                  label="Preferred Start Month"
                  type="month"
                  value={studentToupdate?.preferedStartMonth}
                  onChange={(e) => handleStateChange(e, setStudentToupdate)}
                  colClass="col-12 col-lg-4"
                />
              </div>
            </div>
          </div>

          <div className="student_btn_holder">
            <button
              className="delete_student_btn_style"
              onClick={handleDeleteStudent}
            >
              Delete
            </button>
            <button
              className="update_student_btn_style"
              onClick={handleUpdateStudent}
            >
              Update Student
            </button>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminStudentView;
