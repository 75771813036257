import React, { useState } from "react";
import "./GeneralBlogList.css";

import { useGetPublishedBlog } from "../../../hooks/useGetPublishedBlog";

import UserNavbar from "../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../reusables/PageTitle/PageTitle";
import Loader from "../../reusables/Loader/Loader";
import BlogBox from "../../reusables/BlogBox/BlogBox";
import Pagination from "../../reusables/Pagination/Pagination";
import ErrorPage from "../../reusables/ErrorPage/ErrorPage";

const GeneralBlogList = () => {
  const [blogData, isLoading, error] = useGetPublishedBlog();
  const [paginatedData, setpaginatedData] = useState([]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;
  return (
    <div>
      <UserNavbar>
        <PageTitle title={"BLOGS"} />
        <div className="recruitment_agent_form" style={{ marginTop: "50px" }}>
          {paginatedData?.length &&
            paginatedData.map((blog) => (
              <BlogBox
                title={blog?.title}
                author={blog?.author?.username}
                description={blog?.description}
                publishDate={blog?.publishDate}
                createdAt={blog?.createdAt}
                blogImage={blog?.blogImage}
                blogId={blog?._id}
              />
            ))}
          <div className="general_blog_list_pagination_holder">
            <Pagination setpaginatedData={setpaginatedData} data={blogData} />
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default GeneralBlogList;
