import React from "react";
import MainNavbar from "../../reusables/Navbar/MainNavbar/MainNavbar";
import WhyJoinUs from "./UniversityHomeElement/WhyJoinUs";
import WhoWeAre from "./UniversityHomeElement/WhoWeAre";
import HowDoesItWork from "./UniversityHomeElement/HowDoesItWork";
import {
  howDoesItWorkUniversity,
  universityWhyJoinUs,
  whoWeAreUniversity,
} from "../../../utils/constant";

const UniversityHomePage = () => {
  return (
    <div>
      <MainNavbar>
        <div className="agency_home_hape_content_holder">
          <WhyJoinUs whyJoinUs={universityWhyJoinUs} />
          <WhoWeAre whoWeAre={whoWeAreUniversity} />
          <HowDoesItWork
            howDoesItWork={howDoesItWorkUniversity}
            userType={"university"}
          />
        </div>
      </MainNavbar>
    </div>
  );
};

export default UniversityHomePage;
