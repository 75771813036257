import { useEffect, useState } from "react";
import axios from "axios";
import { API_HEADERS } from "../utils/constant";

export const useGetSuperAdminByRole = (role, populate, name) => {
  const [isLoading, setIsLoading] = useState(false);
  const [superAdmin, setsuperAdmin] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/subscriber/getSuperAdmin?superAdmin=${role}&populate=${populate}&name=${name}`,
        API_HEADERS()
      )
      .then((response) => {
        setsuperAdmin(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, [role, populate]);

  return [superAdmin, isLoading, error];
};
