import React, { useState } from "react";
import PageTitle from "../../reusables/PageTitle/PageTitle";
import UserNavbar from "../../reusables/Navbar/UserNavbar/UserNavbar";
import { useGetSuperAdminByRole } from "../../../hooks/useGetSuperAdminByRole";
import { MailManagementTabs } from "../../../utils/constant";
import UniversityMail from "./UsersMailContoller/UniversityMail";
import { classNames } from "../../../utils/helpers";
import "./MailManagementStyle.css";
import AgencyMail from "./UsersMailContoller/AgencyMail";
import StudentMail from "./UsersMailContoller/StudentMail";

const MailManagement = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleChangeTab = async (data) => {
    setActiveTab(data);
  };

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"MAIL MANAGEMENT"} />
        <div className="content_update_tab_holder">
          {MailManagementTabs.map((tab) => (
            <button
              className={classNames(
                tab.tab === activeTab && "selected_content_update_btn_style",
                "content_update_button_style"
              )}
              onClick={() => handleChangeTab(tab.tab)}
            >
              {tab.name}
            </button>
          ))}
        </div>
        <br />
        <br />

        {activeTab === "tab1" ? (
          <UniversityMail />
        ) : activeTab == "tab2" ? (
          <AgencyMail />
        ) : activeTab == "tab3" ? (
          <StudentMail />
        ) : null}
      </UserNavbar>
    </div>
  );
};

export default MailManagement;
