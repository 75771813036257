import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";

import CustomInputField from "../../../reusables/FromComponent/CustomInputField";
import CustomSelectField from "../../../reusables/FromComponent/CustomSelectField";

import Notification, { handleStateChange } from "../../../../utils/helpers";
import {
  API_HEADERS,
  defaultValueOfQulificationState,
  newCountryList,
  newhighestEducationList,
} from "../../../../utils/constant";

const SuperAdminAddQualification = () => {
  const [qualificationData, setQualificationData] = useState({
    ...defaultValueOfQulificationState,
  });

  const navigate = useNavigate();

  const handleSaveQualification = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/suggestion/addSuggestion`,
        qualificationData,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Qualification added Successfully");
        navigate("/superAdmin/qualification");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"ADD QUALIFICATION "} />
        <div className="add_user_form_holder">
          <CustomSelectField
            name="studentCountry"
            label="Student Country"
            value={qualificationData?.studentCountry}
            onChange={(e) => handleStateChange(e, setQualificationData)}
            options={newCountryList}
          />

          <CustomSelectField
            name="courseCountry"
            label="Course Country"
            value={qualificationData?.courseCountry}
            onChange={(e) => handleStateChange(e, setQualificationData)}
            options={newCountryList}
          />

          <CustomSelectField
            name="educationLevel"
            label="Education Level"
            value={qualificationData?.educationLevel}
            onChange={(e) => handleStateChange(e, setQualificationData)}
            options={newhighestEducationList}
          />

          <CustomInputField
            name="examination"
            label="Examination"
            value={qualificationData?.examination}
            onChange={(e) => handleStateChange(e, setQualificationData)}
          />

          <CustomInputField
            name="gradingSystem"
            label="Grading System"
            value={qualificationData?.gradingSystem}
            onChange={(e) => handleStateChange(e, setQualificationData)}
          />

          <CustomInputField
            name="passingGrade"
            label="Passing Grade"
            value={qualificationData?.passingGrade}
            onChange={(e) => handleStateChange(e, setQualificationData)}
          />

          <div className="admin_action_btn_container">
            <div className="admin_action_btn_holder">
              <button
                className="action_admin_btn_style"
                onClick={handleSaveQualification}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminAddQualification;
