import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AiFillCamera,
  AiOutlineCheckCircle,
  AiOutlineFolderAdd,
} from "react-icons/ai";

import { useGetAllUniversities } from "../../../../hooks/useGetAllUniversities";
import { useGetAllCourse } from "../../../../hooks/useGetAllCourse";
import { useGetStudentById } from "../../../../hooks/useGetStudentById";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import CustomVericalInputField from "../../../reusables/FromComponent/CustomVericalInputField";
import CustomVerticalSelectField from "../../../reusables/FromComponent/CustomVerticalSelectField";

import Notification, {
  findEmptyFields,
  generateEducationRequirementScoreSelect,
  getEducationLevelBylevelNumber,
  handleFileInputInState,
  handleStateChange,
  handleUserAddedDoumentRequirements,
  manageUniversityAndCourseSelection,
  randomNumberString,
  replaceLocalhostUrl,
} from "../../../../utils/helpers";
import {
  API_HEADERS,
  addApplicationDefaultStateObject,
  courseLevelSelect,
  educationalRequirementSelect,
  genderSelect,
  languages,
  newCountryList,
  newhighestEducationList,
} from "../../../../utils/constant";

const StudentAddApplication = () => {
  const location = useLocation();
  // Parse the query parameters from the location object
  const queryParams = new URLSearchParams(location.search);

  const selectedCourseID = queryParams.get("courseId");
  const selectedUniversityID = queryParams.get("universityId");

  const { user } = useContext(AuthContext);

  const [applicationData, setApplicationData] = useState(
    addApplicationDefaultStateObject
  );

  const student = useGetStudentById(
    user?.studentRef ||
      JSON.parse(localStorage.getItem("currentLoginUser")).studentRef
  );

  const navigate = useNavigate();

  const [universities, isLoading, error] = useGetAllUniversities();
  const course = useGetAllCourse();

  const [handleCourseSelection, setHandleCourseSelection] = useState(course[0]);
  const [handleUniversitySelection, setHandleUniversitySelection] = useState(
    course[0]
  );
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [documentList, setDocumentList] = useState(null);

  const [userProfileImageNewlyUploaded, setUserProfileImageNewlyUploaded] =
    useState(null);

  const manageAutoFill = (courseId) => {
    course[0].map((course) => {
      if (course._id === courseId) {
        setSelectedCourse(course);
        setApplicationData((prev) => ({
          ...prev,
          courseFee: course.courseFee,
          courseLevel: course.courseLevel,
          universityId: course?.courseUniversityId?._id,
          applicationDate: new Date().toISOString().slice(0, 10),
        }));
      }
    });
  };

  useEffect(() => {
    setApplicationData((prev) => ({ ...prev, courseId: selectedCourseID }));
    if (selectedCourseID) manageAutoFill(selectedCourseID);
  }, [selectedCourseID, course[0]]);

  const handleSubmitApplication = () => {
    setApplicationData((prev) => ({
      ...prev,
      ...(userProfileImageNewlyUploaded && {
        userProfilePicture: userProfileImageNewlyUploaded,
      }),
    }));
    let updatedUserdata = applicationData;
    if (userProfileImageNewlyUploaded) {
      updatedUserdata.userProfilePicture = userProfileImageNewlyUploaded;
    }

    if (findEmptyFields(updatedUserdata).length > 0) {
      Notification.errorNotification(
        `Please add ${findEmptyFields(updatedUserdata)[0]}`
      );
    } else {
      const applicationFormData = new FormData();

      Object.keys(updatedUserdata).forEach((key) => {
        if (key === "other" && updatedUserdata["other"].length > 0) {
          updatedUserdata.other.map((doc) => {
            applicationFormData.append("other", doc);
          });
        }
        applicationFormData.append(key, updatedUserdata[key]);
      });
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/application/addApplication`,
          applicationFormData,
          API_HEADERS()
        )
        .then(() => {
          Notification.successNotification(
            "New Application added Successfully"
          );
          navigate("/student/application");
        })
        .catch((err) => {
          Notification.errorNotification(err.response.data.message);
        });
    }
  };

  useEffect(() => {
    setApplicationData({
      ...applicationData,
      addedBy: user?._id,
      creatorRole: user?.role,
    });
  }, [user]);

  useEffect(() => {
    setHandleCourseSelection(course[0]);
    setHandleUniversitySelection(universities);
  }, [universities, course]);

  useEffect(() => {
    async function process() {
      const universityAndCourse = manageUniversityAndCourseSelection(
        applicationData?.universityId,
        applicationData?.courseId,
        universities,
        course[0]
      );

      setHandleCourseSelection(universityAndCourse?.courses);
      setHandleUniversitySelection(universityAndCourse?.universities);
    }

    process();
  }, [
    applicationData?.universityId,
    applicationData?.courseId,
    course[0],
    universities,
  ]);

  useEffect(() => {
    async function manage() {
      if (selectedCourse) {
        const newDocsData = handleUserAddedDoumentRequirements(
          selectedCourse?.basicDoumentRequirement
        );
        setDocumentList(newDocsData);
      }
    }

    manage();
  }, [selectedCourse]);

  useEffect(() => {
    setApplicationData((prev) => ({
      ...prev,
      applicationFormStatus: "open",
      firstName: student[0]?.firstName || "",
      lastName: student[0]?.lastName || "",
      gender: student[0]?.gender || "",
      address: student[0]?.address || "",
      postcode: student[0]?.postcode || "",
      averageGrade: student[0]?.averageGrade || "",
      countryOfEducation: student[0]?.countryOfEducation || "",
      secondCitizenship: student[0]?.secondCitizenship || "",
      applicationNo: randomNumberString(6),
      firstLanguage: student[0]?.firstlanguage || "",
      languageOfInstruction: student[0]?.languageOfInstruction || "",
      cityOfResidence: student[0]?.cityOfResidence || "",
      highestEducationLevel: student[0]?.highestEducation || "",
      academicInstitution: student[0]?.studentAcademicInstitution || "",
      countryOfResidence: student[0]?.countryOfResidence || "",
      countryOfBirth: student[0]?.countryOfBirth || "",
      placeOfBirth: student[0]?.placeOfBirth || "",
      nationality: student[0]?.citizenship || "",
      userProfilePicture: student[0]?.studentProfileImage || "",
    }));
  }, [user, student[0]]);

  if (isLoading || course[1] || student[1]) return <Loader />;
  if (error || course[2] || student[2]) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"ADD APPLICATIONS"} />
        <div className="create_application_component_container">
          <div className="application_detail_component">
            <div className="profile_photo_holder">
              <label htmlFor="profile_photo_upload">
                {userProfileImageNewlyUploaded ? (
                  <img
                    src={URL.createObjectURL(userProfileImageNewlyUploaded)}
                    className="profile_image_style"
                    alt="profile_image"
                  />
                ) : student[0] && student[0]?.studentProfileImage ? (
                  <img
                    src={replaceLocalhostUrl(student[0]?.studentProfileImage)}
                    className="profile_image_style"
                    alt="profile_image"
                  />
                ) : (
                  <img
                    src="/images/superAdmin/upload_placeholder.png"
                    className="profile_image_style"
                    alt="profile_image"
                  />
                )}
                <AiFillCamera className="upload_profile_photo_btn" />
              </label>
              <input
                name="userProfilePicture"
                type="file"
                className="upload_document_actual_field"
                id="profile_photo_upload"
                onChange={(e) =>
                  // handleFileInputInState(e, setUserProfileImageNewlyUploaded)
                  setUserProfileImageNewlyUploaded(e.target.files[0])
                }
              />
            </div>
            <div className="filed_container">
              <div className="field_set_1">
                <CustomVericalInputField
                  name="firstName"
                  label="Application First Name"
                  value={applicationData?.firstName}
                  onChange={(e) => handleStateChange(e, setApplicationData)}
                />

                <CustomVericalInputField
                  name="applicationNo"
                  label="Application No"
                  type="number"
                  value={applicationData?.applicationNo}
                  readOnly
                />
              </div>
              <div className="field_set_2">
                <CustomVericalInputField
                  name="lastName"
                  label="Application Last Name"
                  value={applicationData?.lastName}
                  onChange={(e) => handleStateChange(e, setApplicationData)}
                />

                <CustomVericalInputField
                  name="applicationFormStatus"
                  label="Application Status"
                  value={applicationData?.applicationFormStatus}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="agency_detail_container_add_application row">
            {/* <div className="agency_field_holder col-12 col-md-4 ">
              <label className="agency_label_style">University Name</label>
              <select
                type="text"
                className="agency_input_field_style"
                name="universityId"
                onChange={(e) => handleStateChange(e, setApplicationData)}
              >
                <option value={"all"}>---- Select University ----</option>
                {handleUniversitySelection &&
                  handleUniversitySelection?.map((university) => (
                    <option value={university?._id}>
                      {university?.universityName}
                    </option>
                  ))}
              </select>
            </div> */}

            <CustomVericalInputField
              name="universityId"
              label="University Name"
              value={selectedCourse?.courseUniversityId?.universityName}
              colClass="col-12 col-md-4"
              readOnly
            />

            <CustomVericalInputField
              name="applicationDate"
              label="Application Date"
              type="date"
              value={applicationData?.applicationDate}
              readOnly
              colClass="col-12 col-md-4"
            />

            <CustomVericalInputField
              name="courseId"
              label="Course Name"
              value={selectedCourse?.courseName}
              colClass="col-12 col-md-4"
              readOnly
            />

            {/* <div className="agency_field_holder col-12 col-md-4 ">
              <label className="agency_label_style">Course Name</label>
              <select
                type="text"
                className="agency_input_field_style"
                name="courseId"
                onChange={(e) => {
                  handleStateChange(e, setApplicationData);
                  manageAutoFill(e.target.value);
                }}
              >
                <option value={"all"}>---- Select Course ----</option>
                <option value={"all"}>View ALL</option>

                {handleCourseSelection &&
                  handleCourseSelection?.map((course) => (
                    <option value={course?._id}>{course?.courseName}</option>
                  ))}
              </select>
            </div> */}
            {/* <div className="agency_field_holder col-12 col-md-4 ">
              <label className="agency_label_style">Course Level</label>
              <select
                className="agency_input_field_style"
                name="courseLevel"
                value={selectedCourse.courseLevel}
                onChange={(e) => handleStateChange(e, setApplicationData)}
              >
                {courseLevelSelect.map((level) => (
                  <option value={level.value}>{level.label}</option>
                ))}
              </select>
            </div> */}

            <CustomVericalInputField
              name="courseLevel"
              label="Course Level"
              value={selectedCourse?.courseLevel}
              colClass="col-12 col-md-4"
              readOnly
            />

            <CustomVericalInputField
              name="courseFee"
              label="Course Fee"
              type="number"
              value={selectedCourse ? selectedCourse?.courseFee : ""}
              readOnly
              onChange={(e) => handleStateChange(e, setApplicationData)}
              colClass="col-12 col-md-4"
            />

            <CustomVericalInputField
              name="placeOfBirth"
              label="Place of Birth"
              value={applicationData?.placeOfBirth}
              onChange={(e) => handleStateChange(e, setApplicationData)}
              colClass="col-12 col-md-4"
            />

            <CustomVerticalSelectField
              name="countryOfBirth"
              label="Country of Birth"
              value={applicationData?.countryOfBirth}
              onChange={(e) => handleStateChange(e, setApplicationData)}
              options={newCountryList}
              colClass="col-12 col-md-4"
            />

            {/* <CustomVericalInputField
              name="nationality"
              label="Nationality"
              value={applicationData?.nationality}
              onChange={(e) => handleStateChange(e, setApplicationData)}
              colClass="col-12 col-md-4"
            /> */}

            <CustomVerticalSelectField
              label="Nationality"
              name="nationality"
              onChange={(e) => handleStateChange(e, setApplicationData)}
              value={applicationData?.nationality}
              options={newCountryList}
              colClass="col-12 col-md-4"
            />

            {/* <CustomVericalInputField
              name="secondCitizenship"
              label="Second Citizenship"
              value={applicationData?.secondCitizenship}
              onChange={(e) => handleStateChange(e, setApplicationData)}
              colClass="col-12 col-md-4"
            /> */}

            <CustomVerticalSelectField
              label="Second Citizenship"
              name="secondCitizenship"
              onChange={(e) => handleStateChange(e, setApplicationData)}
              value={applicationData?.secondCitizenship}
              options={newCountryList}
              colClass="col-12 col-md-4"
            />

            <CustomVerticalSelectField
              name="countryOfResidence"
              label="Country of Residence"
              value={applicationData?.countryOfResidence}
              onChange={(e) => handleStateChange(e, setApplicationData)}
              options={newCountryList}
              colClass="col-12 col-md-4"
            />

            <CustomVericalInputField
              name="address"
              label="Address"
              value={applicationData?.address}
              onChange={(e) => handleStateChange(e, setApplicationData)}
              colClass="col-12 col-md-4"
            />

            <CustomVericalInputField
              name="postcode"
              label="Postcode"
              value={applicationData?.postcode}
              onChange={(e) => handleStateChange(e, setApplicationData)}
              colClass="col-12 col-md-4"
            />

            <CustomVericalInputField
              name="cityOfResidence"
              label="City of Residence"
              value={applicationData?.cityOfResidence}
              onChange={(e) => handleStateChange(e, setApplicationData)}
              colClass="col-12 col-md-4"
            />

            <CustomVerticalSelectField
              name="gender"
              label="Gender"
              value={applicationData?.gender}
              onChange={(e) => handleStateChange(e, setApplicationData)}
              options={genderSelect}
              colClass="col-12 col-md-4"
            />

            <CustomVerticalSelectField
              name="firstLanguage"
              label="First Language"
              value={applicationData?.firstLanguage}
              onChange={(e) => handleStateChange(e, setApplicationData)}
              options={languages}
              colClass="col-12 col-md-4"
            />
          </div>
          <div className="education_detail_container row">
            <CustomVerticalSelectField
              label="Highest Education Level"
              name="highestEducationLevel"
              value={applicationData?.highestEducationLevel}
              onChange={(e) => handleStateChange(e, setApplicationData)}
              options={educationalRequirementSelect}
              colClass="col-12 col-md-4"
              toolTip="We use this information to match you to the most suitable courses"
            />

            <CustomVericalInputField
              name="academicInstitution"
              label="Name of Academic Institution"
              value={applicationData?.academicInstitution}
              onChange={(e) => handleStateChange(e, setApplicationData)}
              colClass="col-12 col-md-4"
            />

            <CustomVerticalSelectField
              label="Language of Instruction"
              name="languageOfInstruction"
              value={applicationData?.languageOfInstruction}
              onChange={(e) => handleStateChange(e, setApplicationData)}
              options={languages}
              colClass="col-12 col-md-4"
            />

            <CustomVerticalSelectField
              label="Country of Education"
              name="countryOfEducation"
              value={applicationData?.countryOfEducation}
              onChange={(e) => handleStateChange(e, setApplicationData)}
              options={newCountryList}
              colClass="col-12 col-md-4"
            />

            {/* <CustomVericalInputField
              name="averageGrade"
              label="Average Grade"
              value={applicationData?.averageGrade}
              onChange={(e) => handleStateChange(e, setApplicationData)}
              colClass="col-12 col-md-4"
            /> */}

            <CustomVerticalSelectField
              name="averageGrade"
              label="Average grade (%)"
              informativeLink={`/blog/view/650ceecf2cf8f95dc218238f`}
              value={applicationData?.averageGrade}
              onChange={(e) => handleStateChange(e, setApplicationData)}
              colClass="col-12 col-md-4"
              options={generateEducationRequirementScoreSelect(100)}
            />
          </div>

          {selectedCourse && (
            <div className="work_english_requirement">
              <p className="Document_heading">
                Work experience and English proficiency Requirements
              </p>
              <p className="document_info_text_style">
                (Minimum Requirement must be satisfied.)
              </p>
              <div
                className="course_input_container"
                style={{ textAlign: "left" }}
              >
                <div className="">
                  <div className="work_requirement">
                    <p className="requirement_heading">
                      Work Experience Requirement
                    </p>
                    {selectedCourse &&
                      selectedCourse?.courseWorkRequirement.map(
                        (requirement) => (
                          <div>
                            <p className="requirement_text_style">
                              {requirement?.work} : {requirement?.experience}
                            </p>
                          </div>
                        )
                      )}
                  </div>
                  <div
                    className="english_prof_requiremen"
                    style={{ marginTop: "60px" }}
                  >
                    <p className="requirement_heading">
                      English Proficiency Requirement
                    </p>
                    <div className="row">
                      {selectedCourse &&
                        selectedCourse?.englishTestRequirement.map(
                          (requirement) => (
                            <div className="col-md-6 col-12">
                              <p className="requirement_text_style">
                                {requirement?.englishTest} (Total:{" "}
                                {requirement.total})
                              </p>
                              <div className="english_test_score_container">
                                <p className="score_text_style requirement_text_style">
                                  Reading: {requirement?.reading}
                                </p>
                                <p className="score_text_style requirement_text_style">
                                  Writing: {requirement?.writing}
                                </p>
                                <p className="score_text_style requirement_text_style">
                                  Listening: {requirement?.listning}
                                </p>
                                <p className="score_text_style requirement_text_style">
                                  Speaking: {requirement?.speaking}
                                </p>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </div>

                  <div
                    className="work_requirement"
                    style={{ marginTop: "60px" }}
                  >
                    <p className="requirement_heading">
                      Educational Requirement
                    </p>
                    <p className="requirement_text_style">
                      Minimum Qualification:{" "}
                      {getEducationLevelBylevelNumber(
                        selectedCourse?.educationalRequirement
                          ?.educationIQFLevel
                      )}
                    </p>
                    <p className="requirement_text_style">
                      Minimum Score:{" "}
                      {`${selectedCourse?.educationalRequirement?.educationScore}%`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="education_detail_container row remove_justify_center gy-4">
            {documentList &&
              documentList.map((doc) => (
                <>
                  <div className="col-12 col-lg-4 col-md-6 col-sm-6">
                    <label htmlFor={doc.id}>
                      <div className="upload_doc_holder">
                        <div
                          className={
                            (applicationData[doc.id] && doc.id !== "other") ||
                            (applicationData["other"]?.length > 0 &&
                              doc.id === "other")
                              ? `upload_other_docs_container_done`
                              : `upload_other_docs_container`
                          }
                        >
                          {(applicationData[doc.id] && doc.id !== "other") ||
                          (applicationData["other"]?.length > 0 &&
                            doc.id === "other") ? (
                            <AiOutlineCheckCircle className="check_btn_upload_style" />
                          ) : null}
                          <AiOutlineFolderAdd className="file_input_icon" />
                          <p className="doc_name_text_style">{`Upload ${doc.documentName} here`}</p>
                        </div>
                        <p className="uploaded_file_name_text_style">
                          {/* {applicationData[doc.id]?.name} */}
                        </p>
                      </div>
                    </label>
                    <input
                      name={doc.id}
                      type="file"
                      className="upload_document_actual_field"
                      id={doc.id}
                      onChange={(e) => {
                        if (doc.id === "other") {
                          setApplicationData((prev) => ({
                            ...prev,
                            other: [
                              ...applicationData.other,
                              e.target.files[0],
                            ],
                          }));
                        } else {
                          handleFileInputInState(e, setApplicationData);
                        }
                      }}
                    />
                  </div>
                </>
              ))}
          </div>

          <div className="agency_application_submit_btn_holder">
            <button
              className="application_submit_btn_style"
              onClick={handleSubmitApplication}
            >
              Submit Application
            </button>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default StudentAddApplication;
