import React from "react";

const CustomSelectField = ({ name, options, onChange, label, value = "" }) => {
  return (
    <div className="password_label_field_holder">
      <label className="password_label_style">{label}</label>
      <select
        className="password_input_field_style"
        name={name}
        onChange={onChange}
        value={value}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CustomSelectField;
