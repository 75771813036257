import React, { useRef, useState } from "react";
import axios from "axios";
import "./HomePage.css";

import { useGetHomeInfo } from "../../../hooks/useGetHomeInfo";
import { useGetNewlyPublishedBlogs } from "../../../hooks/useGetNewlyPublishedBlogs";

import MainNavbar from "../../reusables/Navbar/MainNavbar/MainNavbar";
import PageTitle from "../../reusables/PageTitle/PageTitle";
import VideoPlayer from "../../reusables/VideoPlayer/VideoPlayer";
import AbstractBlogBox from "../../reusables/AbstractBlogBox/AbstractBlogBox";
import Loader from "../../reusables/Loader/Loader";
import ErrorPage from "../../reusables/ErrorPage/ErrorPage";

import CustomButton from "../../reusables/FromComponent/CustomButton";

import Notification, { replaceLocalhostUrl } from "../../../utils/helpers";
import { Link } from "react-router-dom";
import { PiStudentFill } from "react-icons/pi";

const HomePage = () => {
  const [homeInfo, isLoading, error] = useGetHomeInfo();
  const blogData = useGetNewlyPublishedBlogs();
  const [email, setEmail] = useState("");

  const handleAddSubscription = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/subscriber/addSubscriber`, {
        email,
      })
      .then(() => {
        Notification.successNotification("Done Subscription");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;
  if (blogData[1]) return <Loader />;
  if (blogData[2]) return <ErrorPage />;
  return (
    <div>
      <MainNavbar>
        <div>
          <div className="mb-5 home_who_are_you_title">
            <h4>Who are you ?</h4>
            <div className="d-block  d-md-flex justify-content-between">
              <Link to="/publicCourseList" className="w-25  ">
                <CustomButton
                  fullwidth
                  className="my-2"
                  buttonLabel={"I am a Student"}
                />
              </Link>
              <Link to="/university/home" className="w-25  ">
                <CustomButton
                  fullwidth
                  className="my-2"
                  buttonLabel={"I am an University"}
                />
              </Link>
              <Link to="/agency/home" className="w-25  ">
                <CustomButton
                  fullwidth
                  className="my-2"
                  buttonLabel={"I am an Agency"}
                />
              </Link>
            </div>
          </div>
          <div>
            <br />
            <PageTitle title="WHY APPLY" />
            <br />
            <div className="home_page_why_apply_container">
              {homeInfo?.whyApply?.map((card, index) => (
                <div className="home_page_why_apply_card" key={index}>
                  <img
                    src={replaceLocalhostUrl(card.whyApplyCardCoverImage)}
                    className="home_page_why_apply_card_img"
                    alt="how_to_apply_image"
                  />
                  <p className="home_page_why_apply_card_title">
                    {card?.title.length < 30
                      ? card.title
                      : ` ${card.title.slice(0, 30)}...`}
                  </p>
                  <p className="home_page_why_apply_card_desc">
                    {card?.description.length < 80
                      ? card.description
                      : ` ${card.description.slice(0, 80)}...`}
                  </p>
                </div>
              ))}
            </div>
            <br />
          </div>
          <div className="home_title_video_container">
            <VideoPlayer
              videoSource={homeInfo.titleVideo}
              classname={"title_video_style"}
              // text={{
              //   title: "MBBS",
              //   desc: "At Shantou University Medical Collage",
              // }}
            />
          </div>
          <br />
          <div className="home_page_how_to_apply_container">
            <div className="home_page_staper_container">
              <h4>HOW TO APPLY?</h4>
              <p>
                Zoomtocollege is always available to help you - feel free to
                contact us at any time. Here’s how you can apply:
              </p>
              <div>
                <div className="agency_field_holder">
                  {homeInfo.applysteps &&
                    homeInfo.applysteps.map((step, index) => (
                      <div
                        className="how_to_apply_step_li_container"
                        key={index}
                      >
                        <h1>•</h1>
                        <p>{step.step}</p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="home_apply_step_video_container">
              <VideoPlayer
                videoSource={homeInfo.applyStepVideo}
                classname={"apply_step_video_style"}
              />
            </div>
          </div>
          <br />
          <br />
          <PageTitle title={"RECENT BLOG ARTICLE"} />
          <div className="blog_abstract_list_view">
            <div className="blog_items">
              <div className="row" style={{ width: "100%", margin: "auto" }}>
                {blogData[0].length &&
                  blogData[0].map((blog) => (
                    <div
                      className="col-12 col-lg-4 col-md-4 h-auto mb-4 mb-md-0"
                      key={blog?._id}
                    >
                      <AbstractBlogBox
                        blogImage={blog?.blogImage}
                        title={blog?.title}
                        description={blog?.description}
                        blogId={blog?._id}
                        link={`/blog/view/${blog?._id}`}
                        type="blog"
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <br />
          {/* Subscription Part */}
          <div className="subscription_container">
            <p className="subscriotion_heading_text">
              Join Numerous international students and get monthly updates
            </p>
            <div className="input_and_btn_holder">
              <input
                type="email"
                placeholder="Enter Email Here"
                className="subscriotion_input_style"
                onChange={(e) => setEmail(e.target.value)}
              />

              <CustomButton
                buttonLabel="Subscribe"
                onClick={handleAddSubscription}
              />
            </div>
          </div>
          <div className="subscription_part"></div>
          {/* End */}
        </div>
      </MainNavbar>
    </div>
  );
};

export default HomePage;
