import { useEffect, useState } from "react";
import axios from "axios";
import { API_HEADERS } from "../utils/constant";

export const useGetFavoriteCourseByStudentId = (studentId) => {
  const [isLoading, setIsLoading] = useState(false);
  const [FavoriteCourses, setFavoriteCourses] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    if (studentId) {
      setIsLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/student/getFavoriteCourseList/${studentId}`,
          API_HEADERS()
        )
        .then((response) => {
          setFavoriteCourses(response.data.data[0].favoriteCourses);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, [studentId]);

  return [FavoriteCourses, isLoading, error];
};
