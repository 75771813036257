import React, { useContext, useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { GiHamburgerMenu } from "react-icons/gi";
import "./UserNavbar.css";

import { AuthContext } from "../../AuthProvider/AuthProvider";
import Footer from "../../Footer/Footer";

import CustomButton from "../../FromComponent/CustomButton";

import {
  agency_usernav_links,
  main_nav_link,
  student_usernav_links,
  super_admin_usernav_links,
  university_usernav_links,
} from "../../../../utils/constant";

const UserNavbar = ({ children }) => {
  const { user, setUserDetails } = useContext(AuthContext);
  const [userNavList, setuserNavList] = useState([]);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (user?.role === "Student") {
      setuserNavList(student_usernav_links);
    } else if (
      user?.role === "SuperAdmin" ||
      user?.role === "SubSuperAdmin" ||
      user?.role === "SubAdmin"
    ) {
      setuserNavList(super_admin_usernav_links);
    } else if (
      user?.role === "AgencySuperAdmin" ||
      user?.role === "AgencySubSuperAdmin" ||
      user?.role === "AgencySubAdmin"
    ) {
      setuserNavList(agency_usernav_links);
    } else if (
      user?.role === "UniversitySuperAdmin" ||
      user?.role === "UniversitySubSuperAdmin" ||
      user?.role === "UniversitySubAdmin"
    ) {
      setuserNavList(university_usernav_links);
    } else {
      setuserNavList(main_nav_link);
    }
  }, [user]);

  const handleLogOut = () => {
    localStorage.removeItem("currentLoginUser");
    setUserDetails(null);
    navigate("/");
  };

  return (
    <div className="user_navbar_component_holder">
      <div className="user_navbar">
        <img
          src="/images/navbar/NewLogo.svg"
          className="user_navbar_logo_style"
          alt="website_logo"
          onClick={() => navigate("/")}
        />
        <div className="user_nav_link_container">
          {userNavList.map((link) => (
            <div key={link.id}>
              {link?.submenu?.length > 0 ? (
                <>
                  <select
                    onChange={(event) => navigate(event.target.value)}
                    className="select_navbar_style"
                  >
                    <option>More</option>
                    {link?.submenu?.map((link) => (
                      <option
                        key={link.id}
                        value={link.link}
                        className="nav_option_style"
                      >
                        {link.name}
                      </option>
                    ))}
                  </select>
                </>
              ) : (
                <>
                  (
                  <Link
                    to={link.link}
                    key={link.id}
                    className="user_link_text_style"
                  >
                    {link.name}
                  </Link>
                  )
                </>
              )}
            </div>
          ))}
        </div>
        <div className="user_profile_logout_btn_container">
          <p className="username_text_style">{user?.username}</p>
          {user?.role ? (
            <FiLogOut className="logout_icon_style" onClick={handleLogOut} />
          ) : (
            <CustomButton
              secondary
              buttonLabel={"Login"}
              onClick={() => {
                navigate("/login");
              }}
            />
          )}
        </div>
        <div className="user_mobile_nav_manu">
          <GiHamburgerMenu
            className="user_mobile_nav_manu_icon"
            onClick={handleShow}
          />
        </div>
      </div>
      <div className="children_component_holder">{children}</div>
      <Footer />
      <div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          className="user_navbar_drawer"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {userNavList.map((link) => (
              <div
                className="user_mobile_link_text_style_container"
                key={link.id}
              >
                {link?.submenu?.length ? (
                  link?.submenu?.map((link) => (
                    <div
                      className="user_mobile_link_text_style_container"
                      key={link.id}
                    >
                      <Link
                        to={link.link}
                        key={link.id}
                        className="user_mobile_link_text_style"
                      >
                        {link.name}
                      </Link>
                    </div>
                  ))
                ) : (
                  <Link
                    to={link.link}
                    key={link.id}
                    className="user_mobile_link_text_style"
                  >
                    {link.name}
                  </Link>
                )}
              </div>
            ))}
            <CustomButton
              buttonLabel={user?.role ? "Logout" : "Login"}
              onClick={() => {
                user?.role ? handleLogOut() : navigate("/login");
              }}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
};

export default UserNavbar;
