import React from "react";
import { useNavigate } from "react-router-dom";
import { TfiPencilAlt } from "react-icons/tfi";
import { BsCalendar } from "react-icons/bs";
import "./BlogBox.css";

import CustomButton from "../FromComponent/CustomButton";

import { removeSubstring, replaceLocalhostUrl } from "../../../utils/helpers";

const BlogBox = ({
  title,
  author,
  blogImage,
  description,
  publishDate,
  blogId,
}) => {
  const navigate = useNavigate();

  return (
    <div className="blog_box_content_holder">
      {blogImage && (
        <img
          src={replaceLocalhostUrl(blogImage)}
          className="blog_image_class"
          alt="blog_image"
        />
      )}
      <div className="blog_information">
        <div className="blog_info_holder">
          <div className="auther_info">
            <TfiPencilAlt className="box_author_icon_style" />
            <p className="blog_box_author_text_style">{author}</p>
          </div>
          <div className="auther_info margin_left_style">
            <BsCalendar className="box_author_icon_style" />
            <p className="blog_box_author_text_style">
              {publishDate
                ? new Date(publishDate).toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                : "-"}
            </p>
          </div>
        </div>
        <div className="blog_box_heading_holder">
          <p className="blog_box_title_text_style">{title}</p>
        </div>
        <div className="blog_abstract_view">
          <div
            dangerouslySetInnerHTML={{
              __html: removeSubstring(description, "<p><br></p>"),
            }}
          ></div>
          <div className="gradient"></div>
        </div>
        <div className="box_blog_button_holder">
          <CustomButton
            buttonLabel="Read More"
            onClick={() => navigate(`/blog/view/${blogId}`)}
          />
        </div>
      </div>
    </div>
  );
};

export default BlogBox;
