import React, { useEffect, useState } from "react";
import "./SuperAdminAgentView.css";
import axios from "axios";

import { useNavigate, useParams } from "react-router-dom";
import { useGetAgencyById } from "../../../../hooks/useGetAgencyById";
import { useGetAgentsByAgencyId } from "../../../../hooks/useGetAgentsByAgencyId";
import { useGetStudentForAgencyByAgencyRef } from "../../../../hooks/useGetStudentForAgencyByAgencyRef";

import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import TableComponent from "../../../reusables/TableComponent/TableComponent";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import CustomVericalInputField from "../../../reusables/FromComponent/CustomVericalInputField";
import CustomVerticalSelectField from "../../../reusables/FromComponent/CustomVerticalSelectField";

import Notification, { handleStateChange } from "../../../../utils/helpers";
import {
  API_HEADERS,
  agentListtableCols,
  agenttableFields,
  newCountryList,
  studentFieldToShow,
  studentListCols,
} from "../../../../utils/constant";

const SuperAdminAgentView = () => {
  const { agencyId } = useParams();
  const [agencyData, isLoading, error] = useGetAgencyById(agencyId);
  const agencyStudent = useGetStudentForAgencyByAgencyRef(agencyId);

  const agentList = useGetAgentsByAgencyId(agencyId);
  const navigate = useNavigate();

  const [agencyDataToUpdate, setAgencyDataToUpdate] = useState({
    agencyName: agencyData?.agencyName || "",
    agencyRegistrationNumber: agencyData?.agencyRegistrationNumber || "",
    agencyTaxNumber: agencyData?.agencyTaxNumber || "",
    agencyCommission: agencyData?.agencyCommission || "",
    agencyCountry: agencyData?.agencyCountry || "",
    agencyCity: agencyData?.agencyCity || "",
    agencyAddress: agencyData?.agencyAddress || "",
    postcode: agencyData?.postcode || "",
  });

  useEffect(() => {
    setAgencyDataToUpdate({
      agencyName: agencyData?.agencyName || "",
      agencyRegistrationNumber: agencyData?.agencyRegistrationNumber || "",
      agencyTaxNumber: agencyData?.agencyTaxNumber || "",
      agencyCommission: agencyData?.agencyCommission || "",
      agencyCountry: agencyData?.agencyCountry || "",
      agencyCity: agencyData?.agencyCity || "",
      agencyAddress: agencyData?.agencyAddress || "",
      postcode: agencyData?.postcode || "",
    });
  }, [agencyId, agencyData]);

  const handleDelete = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/agency/deleteAgencyById/${agencyId}`,
        API_HEADERS()
      )
      .then((response) => {
        Notification.successNotification("Agency Deleted Successfully");
        navigate("/superAdmin/agent");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  const handleUpdate = () => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/agency/updateAgencyById`,
        { ...agencyDataToUpdate, agencyId },
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Agency Updated Successfully");
        navigate("/superAdmin/agent");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  const verifyAgency = () => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/agency/verifyAgencyById`,
        {
          agencyId,
          addedBy: JSON.parse(localStorage.getItem("currentLoginUser"))._id,
        },
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Agency Verified.");
        navigate("/superAdmin/agent");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  if (isLoading || agentList[1] || agencyStudent[1]) return <Loader />;
  if (error || agentList[2] || agencyStudent[2]) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"AGENCY"} />
        <div className="recruitment_agent_form">
          <div className="recruitment_agent_input_container">
            <div className="container">
              <div className="row">
                <CustomVericalInputField
                  name="agencyName"
                  label="R.Agency Name"
                  value={agencyDataToUpdate?.agencyName}
                  onChange={(e) => handleStateChange(e, setAgencyDataToUpdate)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="agencyRegistrationNumber"
                  label="Registration Number"
                  value={agencyDataToUpdate?.agencyRegistrationNumber}
                  onChange={(e) => handleStateChange(e, setAgencyDataToUpdate)}
                  colClass="col-12 col-lg-6"
                  readOnly
                />

                <CustomVericalInputField
                  name="agencyTaxNumber"
                  label="Tax Number"
                  value={agencyDataToUpdate?.agencyTaxNumber}
                  onChange={(e) => handleStateChange(e, setAgencyDataToUpdate)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="agencyCommission"
                  label="Agreed Commission (%)"
                  type="number"
                  value={agencyDataToUpdate?.agencyCommission}
                  onChange={(e) => handleStateChange(e, setAgencyDataToUpdate)}
                  colClass="col-12 col-lg-6"
                />
              </div>
            </div>
          </div>
          <div className="recruitment_agent_input_container">
            <div className="container">
              <div className="row">
                <CustomVerticalSelectField
                  label="R.Agency Country"
                  name="agencyCountry"
                  value={agencyDataToUpdate?.agencyCountry}
                  onChange={(e) => handleStateChange(e, setAgencyDataToUpdate)}
                  options={newCountryList}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="agencyCity"
                  label="R.Agency City"
                  value={agencyDataToUpdate?.agencyCity}
                  onChange={(e) => handleStateChange(e, setAgencyDataToUpdate)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="agencyAddress"
                  label="R.Agency Address"
                  value={agencyDataToUpdate?.agencyAddress}
                  onChange={(e) => handleStateChange(e, setAgencyDataToUpdate)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="postcode"
                  label="Postcode"
                  value={agencyDataToUpdate?.postcode}
                  onChange={(e) => handleStateChange(e, setAgencyDataToUpdate)}
                  colClass="col-12 col-lg-6"
                />
              </div>
            </div>
          </div>
          <div className="update_delete_btn_holder">
            <button className="delete_btn_style" onClick={handleDelete}>
              Delete Agency
            </button>
            <button className="update_btn_style" onClick={handleUpdate}>
              Update Agency
            </button>
            {agencyData?.isVerified === false && (
              <button className="accept_btn_style" onClick={verifyAgency}>
                Verify Agency
              </button>
            )}
          </div>

          <div className="agent_list_holder">
            <PageTitle title={"AGENT LIST"} />
            {agentList[0] && (
              <TableComponent
                tableColumns={agentListtableCols}
                fieldsToShowList={agenttableFields}
                data={agentList[0]}
              />
            )}
          </div>
          <div className="agent_list_holder">
            <PageTitle title={"STUDENT BELONG TO AGENCY"} />
            {agencyStudent[0] && (
              <TableComponent
                tableColumns={studentListCols}
                fieldsToShowList={studentFieldToShow}
                data={agencyStudent[0]}
              />
            )}
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminAgentView;
