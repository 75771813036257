import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsCheck2Circle } from "react-icons/bs";
import "./TableComponent.css";

import { Badge } from "react-bootstrap";
import { MdEdit, MdLockReset, MdOutlineDelete } from "react-icons/md";

import Pagination from "../Pagination/Pagination";
import EmptyComponent from "../EmptryComponent/EmptyComponent";

import {
  applicationStatusSortNamesForTable,
  emptyComponentTitleAndSubTitle,
} from "../../../utils/constant";

const TableComponent = ({
  tableColumns,
  data,
  fieldsToShowList,
  navigateTo = "",
  emptyFor = "common",
}) => {
  const [paginatedData, setpaginatedData] = useState([]);
  const navigate = useNavigate();

  const handleNavigate = (id) => {
    if (navigateTo !== "") {
      navigate(`${navigateTo}/${id}`);
    }
  };

  return (
    <>
      {data?.length > 0 ? (
        <div className="application_table_container">
          <div className="table_sub_container">
            <table className="table table-striped table_shadow">
              <thead className="green_table_head">
                <tr className="table_header_row">
                  {tableColumns.map((col) => (
                    <th scope="col">{col.name}</th>
                  ))}
                </tr>
              </thead>
              {paginatedData.length &&
                paginatedData.map((row, index) => (
                  <tbody>
                    <tr
                      key={row._id}
                      onClick={() => {
                        handleNavigate(row._id);
                      }}
                    >
                      <td>
                        {index + 1}
                        {row["showGreenDot"] && (
                          <Badge bg="success">Updated</Badge>
                        )}
                        {row["isApplicationClosed"] && (
                          <div>
                            <BsCheck2Circle className="closed_icon" />
                          </div>
                        )}
                      </td>
                      {fieldsToShowList &&
                        fieldsToShowList.map((field) =>
                          field === "resetIcon" ? (
                            <td>
                              <MdLockReset className="table_icon_style" />
                            </td>
                          ) : field === "editIcon" ? (
                            <td>
                              <MdEdit className="table_icon_style" />
                            </td>
                          ) : field === "deleteIcon" ? (
                            <td>
                              <MdOutlineDelete className="table_icon_style" />
                            </td>
                          ) : field === "createdAt" ||
                            field === "publishDate" ? (
                            <td>
                              {row[field]
                                ? new Date(row[field]).toLocaleDateString(
                                    "en-US",
                                    {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    }
                                  )
                                : "-"}
                            </td>
                          ) : field === "addedBy" ? (
                            <td>
                              {row[field]
                                ? `${row[field]?.firstName} - ${row[field]?.role}`
                                : "Self"}
                            </td>
                          ) : field === "courseUniversityId" ? (
                            <td>{row[field]?.universityName}</td>
                          ) : field === "courseList" ? (
                            <td>{row[field]?.length || 0}</td>
                          ) : field.includes("/") ? (
                            <td>
                              {row[field.split("/")[0]][field.split("/")[1]]}
                            </td>
                          ) : field === "superAdminStatus" ? (
                            <td>
                              <Badge
                                bg={
                                  row[field] === "acceptedByUniversity"
                                    ? "success"
                                    : row[field] === "rejectedByUniversity"
                                    ? "danger"
                                    : "secondary"
                                }
                              >
                                {applicationStatusSortNamesForTable[
                                  row[field]
                                ].toUpperCase()}
                              </Badge>
                            </td>
                          ) : field === "applicationFormStatus" ? (
                            <td>
                              <Badge
                                bg={
                                  row[field] === "open"
                                    ? "secondary"
                                    : row[field] === "accepted"
                                    ? "success"
                                    : "danger"
                                }
                              >
                                {row[field].toUpperCase()}
                              </Badge>
                            </td>
                          ) : (
                            <td>{row[field]}</td>
                          )
                        )}
                    </tr>
                  </tbody>
                ))}
            </table>
          </div>
          <Pagination setpaginatedData={setpaginatedData} data={data} />
        </div>
      ) : (
        <EmptyComponent
          title={emptyComponentTitleAndSubTitle[emptyFor].title}
          subTitle={emptyComponentTitleAndSubTitle[emptyFor].subTitle}
        />
      )}
    </>
  );
};
export default TableComponent;
