import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AiFillHeart, AiOutlineClockCircle } from "react-icons/ai";
import { RiMoneyEuroCircleLine } from "react-icons/ri";
import { FiSend } from "react-icons/fi";
import { LuCalendarDays } from "react-icons/lu";
import { MdLocationPin } from "react-icons/md";

import "./CourseDetailView.css";

import { useGetCourseById } from "../../../../hooks/useGetCourseById";
import { useGetFavoriteCourseByStudentId } from "../../../../hooks/useGetFavoriteCourseByStudentId";
import { useGetStudentById } from "../../../../hooks/useGetStudentById";
import { useGetSuggestionCourses } from "../../../../hooks/useGetSuggestionCourses";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import SuggestionModal from "../../../reusables/SuggestionModal/SuggestionModal";
import Loader from "../../../reusables/Loader/Loader";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";
import AbstractBlogBox from "../../../reusables/AbstractBlogBox/AbstractBlogBox";

import CustomButton from "../../../reusables/FromComponent/CustomButton";

import Notification, {
  calculateTheMatchPersentage,
  replaceLocalhostUrl,
} from "../../../../utils/helpers";
import {
  API_HEADERS,
  coursePublicOtherDetails,
} from "../../../../utils/constant";

const CourseDetailView = () => {
  const { courseId } = useParams();
  const [courseData, isLoading, error] = useGetCourseById(courseId);
  const [countryData, setCountryData] = useState(null);
  const [detailTypeSelect, setDetailTypeSelect] = useState("courseDetails");

  const [suggestion, setSuggestion] = useState([]);
  const [matchProfile, setMatchProfile] = useState(
    "Check you match after Login"
  );
  const { user } = useContext(AuthContext);
  const suggestionCourses = useGetSuggestionCourses(
    courseData?.courseSubjectType,
    courseId,
    user?.studentRef ||
      JSON.parse(localStorage.getItem("currentLoginUser"))?.studentRef
  );

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [suggestionData, setSuggestionData] = useState(null);

  const FavoriteCourses = useGetFavoriteCourseByStudentId(
    user?.studentRef ||
      JSON.parse(localStorage.getItem("currentLoginUser"))?.studentRef
  );

  const student = useGetStudentById(
    user?.studentRef ||
      JSON.parse(localStorage.getItem("currentLoginUser"))?.studentRef
  );

  const [like, setFavourite] = useState(false);
  const likeCourse = () => {
    handleLikeCourse("like");
    setFavourite(true);
  };
  const unlikeCourse = () => {
    handleLikeCourse("unlike");
    setFavourite(false);
  };

  useEffect(() => {
    FavoriteCourses[0].map((course) => {
      if (course?._id === courseId) {
        setFavourite(true);
      }
    });
  }, [FavoriteCourses[0], courseId]);

  useEffect(() => {
    async function check() {
      await calculateTheMatchPersentage(student[0], courseData)
        .then((response) => {
          setMatchProfile(response.matchPersentage.toFixed(2));
          setSuggestion(response.suggestions);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function getCountryDataByName() {
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/homeInfo/getCountryByCountryName/${courseData?.courseCountry}`
        )
        .then((response) => {
          setCountryData(response.data.data);
        })
        .catch((err) => {
          console.log("Err: ", err);
        });
    }

    if (user) check();
    getCountryDataByName();
  }, [courseData, student[0]]);

  const handleLikeCourse = (status) => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/student/likeOrUnlikeCourseByStudentId`,
        {
          courseId,
          studentId:
            user?.studentRef ||
            JSON.parse(localStorage.getItem("currentLoginUser"))?.studentRef,
          status,
        },
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification(
          status === "like"
            ? "Course added to favorite"
            : "Course removed from favorite"
        );
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  const getSuggestion = () => {
    if (user) {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/suggestion/getSuggestionByCountry/${courseData?.courseCountry}/${student[0]?.countryOfEducation}`,
          API_HEADERS()
        )
        .then((response) => {
          setSuggestionData(response.data.data);
          setShow(true);
        })
        .catch((err) => {
          Notification.errorNotification(err.response.data.message);
        });
    }
  };

  if (isLoading || FavoriteCourses[1] || student[1] || suggestionCourses[1])
    return <Loader />;

  if (FavoriteCourses[2] || error || student[2] || suggestionCourses[2])
    return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <div>
          <div className="course_detail_thumbnail_container">
            <img
              src={replaceLocalhostUrl(courseData?.coursethumbnail)}
              className="course_detail_thumbnail_img"
              alt="course_thumnnail"
            />
            <div className="course_main_details_card">
              <div className="course_main_details_type_container">
                <div className="course_main_details_type_sub_container">
                  <p>{courseData?.courseSubjectType}</p>
                  <p>{courseData?.courseStudyMode}</p>
                </div>
                <AiFillHeart
                  className={
                    like
                      ? "university_card_heart_icon_red"
                      : "university_card_heart_icon_gray"
                  }
                  onClick={() => {
                    like ? unlikeCourse() : likeCourse();
                  }}
                />
              </div>
              <div className="crs_name_container">
                <p className="course_main_details_card_crs_name">
                  {courseData?.courseName}
                </p>
                <p className="university_card_uni_city">
                  {courseData?.courseUniversityId?.universityName}
                </p>
              </div>
              <div className="course_main_details_card_btn_container">
                {/* <button onClick={getSuggestion}>Check Our suggestion</button> */}
                <CustomButton
                  buttonLabel="Check Our suggestion"
                  onClick={getSuggestion}
                />
                {/* <button>
                  {user
                    ? `Profile ${matchProfile}% match`
                    : "Login to Check match"}
                </button> */}
                <CustomButton
                  buttonLabel={
                    user
                      ? `Profile ${matchProfile}% match`
                      : "Login to Check match"
                  }
                />
                <CustomButton
                  buttonLabel={user ? "Apply" : "Login to Apply"}
                  onClick={() => {
                    user !== null
                      ? ["SuperAdmin", "SubSuperAdmin", "SubAdmin"].includes(
                          user.role
                        )
                        ? navigate("/superAdmin/application/addApplication")
                        : [
                            "AgencySuperAdmin",
                            "AgencySubSuperAdmin",
                            "AgencySubAdmin",
                          ].includes(user.role)
                        ? navigate("/agency/application/addApplication")
                        : ["Student"].includes(user.role)
                        ? navigate(
                            `/student/application/addApplication?universityId=${courseData?.courseUniversityId?._id}&courseId=${courseData?._id}`
                          )
                        : console.log("Other")
                      : navigate("/login");
                  }}
                />
              </div>
            </div>
          </div>
          <div className="course_highlight_container">
            <div>
              <AiOutlineClockCircle className="course_highlight_container_icons" />
              <h5>{courseData?.courseDuration} Months</h5>
              <p>Duration</p>
            </div>
            <div>
              <RiMoneyEuroCircleLine className="course_highlight_container_icons" />
              <h5>{courseData?.courseFee} EUR / Year</h5>
              <p> Tuition fee</p>
            </div>
            <div>
              <FiSend className="course_highlight_container_icons" />
              <h5>{courseData?.courseApplicationEndDate}</h5>
              <p> Apply date</p>
            </div>
            <div>
              <LuCalendarDays className="course_highlight_container_icons" />
              <h5>{courseData?.courseApplicationStartDate}</h5>
              <p> Start Date</p>
            </div>
          </div>
          {detailTypeSelect === "courseDetails" ? (
            <>
              <div className="course_details_sub_container">
                <div>
                  <br />
                  <PageTitle title={"DETAILS"} />
                  <br />
                  <p className="course_desc">{courseData?.courseDescription}</p>
                </div>
              </div>
              <div className="course_other_details">
                <div className="container">
                  <div className="row">
                    {coursePublicOtherDetails.map((field) => (
                      <div
                        className="col-12 col-lg-3 col-md-6 other_detail_box_style"
                        key={field.label}
                      >
                        <div className="detail_holder">
                          {field.icon}
                          <p className="other_field_heading">{field.label}</p>
                          <p className="other_field_value">
                            {courseData?.[field.fieldName]}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <br />
                <CustomButton
                  onClick={() => setDetailTypeSelect("countryDetails")}
                  buttonLabel="Find out more about country"
                />
              </div>
            </>
          ) : (
            <div className="course_details_sub_container">
              <div
                style={{
                  backgroundImage: `linear-gradient(to right top, rgb(0 0 0 / 65%), rgb(0 0 0 / 65%), rgb(0 0 0 / 65%), rgb(0 0 0 / 65%), rgb(0 0 0 / 65%)), url(${replaceLocalhostUrl(
                    countryData?.countryCoverImage
                  )}`,
                  width: "80%",
                  margin: "auto",
                  padding: "15px",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <p className="country_name_style">{countryData?.countryName}</p>
                <p className="city_name_style">{courseData?.courseCity}</p>
                <br />
                <br />
                <div className="country_detail_holder">
                  <div className="country_info_box">
                    <p className="city_name_style">{`Currency: ${countryData?.officialCurrency}`}</p>
                    <p className="city_name_style">{`Language: ${countryData?.officialLanguage}`}</p>
                  </div>
                  <div className="country_info_box">
                    <p className="city_name_style">{`${
                      countryData?.workPermit
                        ? "Post study work permit provided after completion of studies"
                        : "No post study work permit provided after completion of studies"
                    }`}</p>
                    <p className="city_name_style">{`${
                      countryData?.workPermitDuringStudies
                        ? "Work permit during studies provided"
                        : "No work permit during studies provided"
                    }`}</p>
                  </div>
                </div>
                <br />
                <br />
                <p className="city_name_style">
                  {`Average Cost of living per year: ${
                    countryData?.averageLivingCostPerYear
                      ? countryData.averageLivingCostPerYear
                      : "Will specify soon."
                  }`}
                </p>

                <br />
                <br />

                <p className="city_name_style">
                  {countryData.details.split("\r").map((paragraph, index) => (
                    <p
                      className="desc_para"
                      style={{
                        ...(index === 0 && { textIndent: "100px" }),
                      }}
                    >
                      {paragraph}
                    </p>
                  ))}
                  <span>
                    <Link
                      to={countryData?.referenceLink}
                      className="city_name_style"
                    >
                      <b>More details</b>
                    </Link>
                  </span>
                </p>
                <br />
                <p
                  className="link_to_courseDetails"
                  onClick={() => setDetailTypeSelect("courseDetails")}
                >
                  {"< Course Details"}
                </p>
              </div>
            </div>
          )}

          <div className="public_university_detail_holder">
            <PageTitle title={"UNIVERSITY"} />
          </div>

          <div className="university_cover">
            <img
              src={courseData?.courseUniversityId?.universityCoverImage}
              className="public_university_cover_img"
              alt="university_cover_image"
            />
          </div>

          <div className="university_other_detail_container">
            <p className="university_name_style">
              {courseData?.courseUniversityId?.universityName}
            </p>

            <p className="university_description">
              {courseData?.courseUniversityId?.universityDescription}
            </p>

            <div className="university_other_img_container">
              <div className="container">
                <div className="row">
                  {courseData?.courseUniversityId?.subImages?.map(
                    (other, index) => (
                      <div
                        className="col-12 col-lg-4 col-md-4 other_image_holder"
                        key={index}
                      >
                        <img
                          src={replaceLocalhostUrl(other)}
                          className="other_image_style"
                          alt="other_image"
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="university_location_container">
                <MdLocationPin className="other_icon_style" />
                <p style={{ marginBottom: "5px" }}>
                  {courseData?.courseUniversityId?.universityAddress}
                </p>
              </div>
            </div>
          </div>
          <br />
          <PageTitle title={"MORE TO DISCOVER"} />
          <div className="blog_abstract_list_view">
            <div className="blog_items">
              <div className="row" style={{ width: "100%", margin: "auto" }}>
                {suggestionCourses[0].length &&
                  suggestionCourses[0].map((course) => (
                    <div
                      className="col-12 col-lg-4 col-md-4 h-auto mb-4 mb-md-0"
                      key={course?._id}
                    >
                      <AbstractBlogBox
                        blogImage={course?.coursethumbnail}
                        title={course?.courseName}
                        description={course?.courseDescription}
                        blogId={course?._id}
                        link={`/student/courseDetails/${course?._id}`}
                        buttonText="View Course"
                        type={"course"}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <br />
        </div>

        <SuggestionModal
          handleClose={handleClose}
          suggestionData={suggestionData}
          suggestion={suggestion}
          show={show}
          courseImage={courseData?.coursethumbnail}
          match={matchProfile}
          courseName={courseData?.courseName}
          courseDecepline={courseData?.courseSubjectType}
        />
      </UserNavbar>
    </div>
  );
};

export default CourseDetailView;
