import React from "react";
import { useParams } from "react-router-dom";
import { BsCheckSquareFill, BsXSquareFill } from "react-icons/bs";

import { useGetCourseById } from "../../../../../hooks/useGetCourseById";
import { useGetApplicationByCourseId } from "../../../../../hooks/useGetApplicationByCourseId";

import UserNavbar from "../../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../../reusables/PageTitle/PageTitle";
import TableComponent from "../../../../reusables/TableComponent/TableComponent";
import Loader from "../../../../reusables/Loader/Loader";
import ErrorPage from "../../../../reusables/ErrorPage/ErrorPage";

import { replaceLocalhostUrl } from "../../../../../utils/helpers";
import {
  applicationTableCols,
  applicationTableFields,
} from "../../../../../utils/constant";
import CustomVericalInputField from "../../../../reusables/FromComponent/CustomVericalInputField";

const UniversityCourseDetailView = () => {
  const { courseId } = useParams();
  const [courseData, isLoading, error] = useGetCourseById(courseId);

  const applications = useGetApplicationByCourseId(courseId);

  if (isLoading || applications[1]) return <Loader />;
  if (error || applications[2]) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"COURSE VIEW"} />
        <div className="new_course_detail_container">
          <div className="course_input_container">
            <div className="container">
              <div className="row">
                <CustomVericalInputField
                  name="courseName"
                  label="Course Name"
                  readOnly
                  value={courseData?.courseName}
                  colClass="col-12 col-md-6 col-lg-4"
                />
                <CustomVericalInputField
                  value={courseData?.courseSubjectType}
                  name="courseSubjectType"
                  label="Course Subject"
                  readOnly
                  colClass="col-12 col-md-6 col-lg-4"
                />
                <CustomVericalInputField
                  name="courseApplicationFee"
                  value={courseData?.courseApplicationFee}
                  label="Application Fee"
                  readOnly
                  colClass="col-12 col-md-6 col-lg-4"
                />
                <CustomVericalInputField
                  name="courseStudyMode"
                  value={courseData?.courseStudyMode}
                  label="Study Mode"
                  readOnly
                  colClass="col-12 col-md-6 col-lg-4"
                />
                <CustomVericalInputField
                  name="courseFee"
                  value={courseData?.courseFee}
                  label="Course fee/year"
                  readOnly
                  colClass="col-12 col-md-6 col-lg-4"
                />
                <CustomVericalInputField
                  name="courseUniversityName"
                  value={courseData?.courseUniversityId?.universityName}
                  label="University Name"
                  readOnly
                  colClass="col-12 col-md-6 col-lg-4"
                />

                <CustomVericalInputField
                  name="courseLanguage"
                  value={courseData?.courseLanguage}
                  label={"Study Language"}
                  readOnly
                  colClass="col-12 col-md-6 col-lg-4"
                />
                <CustomVericalInputField
                  name={"courseLevel"}
                  value={courseData?.courseLevel}
                  label={"Course Level"}
                  readOnly
                  colClass="col-12 col-md-6 col-lg-4"
                />
                <CustomVericalInputField
                  name="courseMaxSeat"
                  value={courseData?.courseMaxSeat}
                  label={"Available Seats"}
                  readOnly
                  colClass="col-12 col-md-6 col-lg-4"
                />
              </div>
            </div>
          </div>
          <PageTitle title={"APPLICATIONS"} />

          <TableComponent
            tableColumns={applicationTableCols}
            fieldsToShowList={applicationTableFields}
            data={applications[0]}
            navigateTo="/university/application/view"
            emptyFor="universityApplicationsOnCourse"
          />

          <div className="course_desc_container">
            <PageTitle title={"COURSE OVERVIEW AND DESCRIPTION"} />

            <div className="course_description_holder">
              <p className="course_desc_text_style">
                {courseData?.courseDescription}
              </p>
            </div>

            <div>
              <div className="who_we_are_conatiner">
                <div className="who_we_are_content_holder">
                  <div className="img_border">
                    <div className="img_container">
                      <img
                        src={replaceLocalhostUrl(courseData?.coursethumbnail)}
                        className="who_we_are_img_style"
                        alt="who_we_are_image"
                      ></img>
                    </div>
                  </div>
                  <div className="desc_title_holder">
                    <PageTitle title={"SPECIAL FEATURES"} />
                    <div className="who_we_are_desc">
                      {courseData?.courseSpecialFeature}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="document_required_section">
              <p className="Document_heading">Admission Requirements</p>
              <p className="document_info_text_style">
                (all these options will appear as a dropdown and will determine
                what documents an applicant needs and also what courses an
                applicant is suitable for)
              </p>
              <div className="course_input_container">
                <div className="container">
                  <div className="row">
                    {courseData &&
                      courseData?.basicDoumentRequirement?.map((document) => (
                        <div className="col-12 col-lg-4 col-md-4 col-sm-6 single_document_check_holder">
                          {document["required"] !== "" &&
                          document["required"] !== "false" ? (
                            <BsCheckSquareFill className="document_icon_style_check" />
                          ) : (
                            <BsXSquareFill className="document_icon_style_uncheck" />
                          )}

                          <p className="document_heading_txt_style">
                            {document.documentName}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="work_english_requirement">
              <p className="Document_heading">
                Work experience and English proficiency Requirements
              </p>
              <p className="document_info_text_style">
                (All this data will directly shown in text to the applicant.)
              </p>
              <div className="course_input_container">
                <div className="data_holder">
                  <div className="work_requirement">
                    <p className="requirement_heading">
                      Work Experience Requirement
                    </p>
                    {courseData &&
                      courseData?.courseWorkRequirement.map((requirement) => (
                        <div>
                          <p className="requirement_text_style">
                            {requirement?.work} : {requirement?.experience}
                          </p>
                        </div>
                      ))}
                  </div>
                  <div className="vertical_line"></div>
                  <div className="english_prof_requiremen">
                    <p className="requirement_heading">
                      English Proficiency Requirement
                    </p>

                    {courseData &&
                      courseData?.englishTestRequirement.map((requirement) => (
                        <div>
                          <p className="requirement_text_style">
                            {requirement?.englishTest} :{" "}
                            {requirement?.requiredScore}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default UniversityCourseDetailView;
