import React from "react";
import { useContext } from "react";

import { useGetStudentDashboardStatById } from "../../../hooks/useGetStudentDashboardStatById";

import { AuthContext } from "../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../reusables/PageTitle/PageTitle";
import StatisticBlock from "../../reusables/StatisticBlock/StatisticBlock";
import Loader from "../../reusables/Loader/Loader";
import ErrorPage from "../../reusables/ErrorPage/ErrorPage";

import { StudentDashboardStatisticInfo } from "../../../utils/constant";

const StudentDashboard = () => {
  const { user } = useContext(AuthContext);
  const [statistics, isLoading, error] = useGetStudentDashboardStatById(
    user?._id || JSON.parse(localStorage.getItem("currentLoginUser"))._id,
    user?.studentRef ||
      JSON.parse(localStorage.getItem("currentLoginUser"))?.studentRef
  );

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"DASHBOARD"} />
        <br />
        <br />
        <div className="container">
          <div className="row">
            {Object.keys(statistics).length &&
              Object.keys(statistics).map((statKey) => (
                <StatisticBlock
                  statTitle={StudentDashboardStatisticInfo[statKey].statTitle}
                  description={
                    StudentDashboardStatisticInfo[statKey].description
                  }
                  count={statistics[statKey]}
                  link={StudentDashboardStatisticInfo[statKey].link}
                />
              ))}
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default StudentDashboard;
