import { useEffect, useState } from "react";
import axios from "axios";
import { API_HEADERS } from "../utils/constant";

export const useGetCountryById = (countryId) => {
  const [countryListLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState([]);
  const [countryListError, setError] = useState();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/homeInfo/getCountryById/${countryId}`,
        API_HEADERS()
      )
      .then((response) => {
        setCountry(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, [countryId]);

  return [country, countryListLoading, countryListError];
};
