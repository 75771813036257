import React from "react";
import UserNavbar from "../Navbar/UserNavbar/UserNavbar";
import PageTitle from "../PageTitle/PageTitle";

const PrivacyPolicy = () => {
  return (
    <UserNavbar>
      <PageTitle title="PRIVACY POLICY" />
      <div className="recruitment_agent_form" style={{ textAlign: "left" }}>
        <p>Last updated: Aug 16, 2023</p>
        <p>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>
        <p>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>
        <h1>Interpretation and Definitions</h1>
        <h2>Interpretation</h2>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <h2>Definitions</h2>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
          <li>
            <p>
              <strong>Account</strong> means a unique account created for You to
              access our Service or parts of our Service.
            </p>
          </li>
          <li>
            <p>
              <strong>Affiliate</strong> means an entity that controls, is
              controlled by or is under common control with a party, where
              &quot;control&quot; means ownership of 50% or more of the shares,
              equity interest or other securities entitled to vote for election
              of directors or other managing authority.
            </p>
          </li>
          <li>
            <p>
              <strong>Company</strong> (referred to as either &quot;the
              Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
              in this Agreement) refers to University Management.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies</strong> are small files that are placed on Your
              computer, mobile device or any other device by a website,
              containing the details of Your browsing history on that website
              among its many uses.
            </p>
          </li>
          <li>
            <p>
              <strong>Country</strong> refers to: United Kingdom
            </p>
          </li>
          <li>
            <p>
              <strong>Device</strong> means any device that can access the
              Service such as a computer, a cellphone or a digital tablet.
            </p>
          </li>
          <li>
            <p>
              <strong>Personal Data</strong> is any information that relates to
              an identified or identifiable individual.
            </p>
          </li>
          <li>
            <p>
              <strong>Service</strong> refers to the Website.
            </p>
          </li>
          <li>
            <p>
              <strong>Service Provider</strong> means any natural or legal
              person who processes the data on behalf of the Company. It refers
              to third-party companies or individuals employed by the Company to
              facilitate the Service, to provide the Service on behalf of the
              Company, to perform services related to the Service or to assist
              the Company in analyzing how the Service is used.
            </p>
          </li>
          <li>
            <p>
              <strong>Third-party Social Media Service</strong> refers to any
              website or any social network website through which a User can log
              in or create an account to use the Service.
            </p>
          </li>
          <li>
            <p>
              <strong>Usage Data</strong> refers to data collected
              automatically, either generated by the use of the Service or from
              the Service infrastructure itself (for example, the duration of a
              page visit).
            </p>
          </li>
          <li>
            <p>
              <strong>Website</strong> refers to University Management,
              accessible from{" "}
              <a
                href="https://www.zoomtocollege.com"
                rel="external nofollow noopener"
                target="_blank"
              >
                www.zoomtocollege.com
              </a>
            </p>
          </li>
          <li>
            <p>
              <strong>You</strong> means the individual accessing or using the
              Service, or the company, or other legal entity on behalf of which
              such individual is accessing or using the Service, as applicable.
            </p>
          </li>
        </ul>
        <p>
          This Privacy Policy applies to all of your activities on our websites,
          services, web and mobile applications (www.zoomtocollege.com),
          (collectively, the “Service”). This Privacy Policy describes and
          governs our practices regarding the collection, processing, and usage
          of information we collect from Users (“Personal Data”). Data we
          collect from Users (“Personal Data”), is used, disclosed, and
          protected according to this Privacy Policy.
        </p>
        <p>
          Zoomtocollege may change this policy from time to time by updating
          this page. You should check this page from time to time to ensure that
          you are happy with the changes.
        </p>
        <h1>What we collect</h1>
        <ul>
          <li>
            Information which you provide to us from any of the input areas
            within the website.
          </li>
          <li>
            Information which you provide to us by any other means including
            (without limitation) telephone, fax or post.
          </li>
          <li>
            Information which you provide to us in connection with your
            application, which will be handled in accordance with this privacy
            policy and our data protection statement, which forms part of the
            declaration.
          </li>
          <li>
            If you contact us, we may keep a record of that correspondence.
          </li>
        </ul>
        <p>
          By using or accessing the Service in any manner, you acknowledge that
          you accept the practices and policies outlined in this Privacy Policy,
          and you hereby agree that we may collect, use, and share your
          information as set forth below. For purposes of this Privacy Policy,
          the term “includes” or “including” mean including without limitation.
        </p>
        <p>
          If you do not agree with any of the terms in this Privacy Policy, you
          should not use the Service. If you have any questions about this
          Privacy Policy, please contact us by email or mail using the contact
          information provided.
        </p>
        <h2>What we do with the information</h2>
        <p>We use personal information held about you in the following ways:</p>
        <ul>
          <li>to process your application.</li>
          <li>to notify you about changes to our service.</li>
          <li>
            to ensure that content from our website is presented in the most
            effective manner for you and for your computer.
          </li>
          <li>
            to keep you informed (with your consent) by electronic means, or
            other means, of information about specific funding, sponsorship
            opportunities or services and products that we think may be of
            interest to you.
          </li>
          <li>
            to provide you (unless you object) with general information relevant
            to students.
          </li>
        </ul>
        <p>
          We may share personal information included in your application, or
          which you subsequently supply to us, with the staff of the
          institutions which (i) you have previously or currently attend as a
          student or (ii) you are making application to attend for further or
          higher education as indicated in your application (either directly or
          through a third party on your behalf). This may include cross-border
          transfer depending on your location and the university you are
          applying to.
        </p>
        <p>
          Once we have provided your data to the institutions you are applying
          to, we no longer have control over the information and recommend that
          you contact them directly if you have questions about how it is
          handled.
        </p>
        <p>
          We may share your information with our service providers including
          internet service providers, data and banks, financial institutions,
          service providers that help us verify your identity and help us comply
          with our legal and regulatory obligations, and our business, legal,
          tax, financial and other advisors, on a confidential basis. Your
          personal data may be maintained and processed by third party service
          providers. We do not authorise them to use or disclose personal data
          for their own marketing or other purposes.
        </p>
        <p>
          We do not sell, trade, or otherwise transfer to outside parties your
          personally identifiable information. This does not include trusted
          third parties who assist us in operating our website and database, or
          conducting our business as those parties agree to keep this
          information confidential.
        </p>
        <p>
          As part of your application, you may nominate a representative who may
          speak to <strong>Zoomtocollege</strong> on your behalf. You do not
          have to nominate a representative but where you do so you must have
          their consent that they will act as your representative and that we
          may hold their personal details including their name and contact
          details. We will only discuss your application, give information, or
          take directions from your nominated representative if you make us
          aware that you have appointed one.
        </p>
        <p>
          We may, on occasion, be required to undertake a check of your
          identity. This will be conducted in confidence. We will normally
          discuss this with you prior to carrying out any identity checks except
          as set out in our data protection statement.
        </p>
        <p>
          Zoomtocollege will cooperate, where it is required to do so, with
          enquiries from authorities which demonstrate a legal requirement for
          access to information about you. All personal data that has been
          collected from you will only be stored for the duration that is
          relevant to the purpose for which it was processed and for as long as
          required by applicable law (usually 6 months).
        </p>
        <h3>Communication</h3>
        <p>
          The information we collect from you in connection with your
          application will include (without limitation) your name; postal and
          email address; and telephone numbers, both home and mobile. If any of
          these details change, it is important that you tell us straight away
          so that we can communicate with you about your application.
        </p>
        <p>
          We will normally communicate with you by electronic means in respect
          of any application made by you.
        </p>
        <p>
          You may contact us by any of the methods set out on our contact
          us page of the website. If you have any questions about our use of
          your personal data, contact us here.
        </p>
        <p>
          If we intend to use any Personal Data in any manner that is not
          consistent with this Privacy Policy, you will be informed of such
          anticipated use prior to or at the time the Personal Data is
          collected, and we will obtain your consent for any such use. You can
          also disable the consent by emailing service@zoomtocollege.com or from
          your settings.
        </p>
        <h3>Promotional Information and Suggestions</h3>
        <p>
          In addition, from time to time, we would like to make use of your
          personal information to send you promotional information about
          (without limitation) products and services which may be of interest to
          you. Commercial mailings may include information sent by the
          Zoomtocollege on behalf of carefully selected third parties; these can
          include information on accommodation, discounts, tickets, travel,
          banking, and professional associations. Please note that except as set
          out in this privacy policy or data protection statement, your details
          will not be released to any third party.
        </p>
        <p>
          We may collect information about your computer, including where
          available your IP address, operating system, and browser type, for
          system administration and to report aggregate information to our
          advertisers. This is statistical data about our users’ browsing
          actions and patterns and does not identify any individual.
        </p>
        <p>
          You may, at any time, withdraw your permission for Zoomtocollege to
          use your personal information for the purposes of sending mailings by
          contacting us.
        </p>
        <h3>Transfer</h3>
        <p>
          Except for the purposes described above, we will not transfer your
          Personal Data to any company, organization or individual, except in
          the following circumstances:
        </p>
        <p>
          we may transfer your Personal Data to other parties with your explicit
          consent; or in case of any acquisition, merger, insolvency or
          liquidation of Zoomtocollege, if the transfer of Personal Data is
          involved, we will require the new company, organization or individual
          in possession of your Personal Data to continue to be bound by this
          Privacy Policy, or we will require such company, organization or
          individual to obtain your authorization and consent for any use of
          your Personal Data.
        </p>
        <h3>The Location Of Your Data</h3>
        <p>
          These facilities are either operated by Zoomtocollege or our
          third-party providers. By using the Service, you are consenting to
          have this data stored, transferred, and processed according to this
          Privacy Policy. Personal Data collected will be primarily stored at
          hosting facilities located in the EU, and in the USA.
        </p>
        <p>
          Zoomtocollege is a global company. Subject to applicable laws, we may
          transfer your Personal Data to any Zoomtocollege affiliate worldwide
          when processing that data for the purposes described in this Privacy
          Policy. We may also transfer your Personal Data to our third-party
          service providers, who may be located in a country or area outside the
          European Economic Area (“EEA”).
        </p>
        <h3>EEA, SWITZERLAND AND UK ONLY</h3>
        <p>
          The EU General Data Protection Regulation (“GDPR”): In May 2018, a new
          data privacy law known as the EU General Data Protection Regulation
          became effective. The GDPR requires us to tell you about the legal
          grounds we are relying on to process any Personal Data about you. The
          legal grounds for us processing your Personal Data in conjunction with
          the Service will be that: (i) you provided your consent; (ii) the
          processing is necessary for our contractual relationship; (iii) the
          processing is necessary for us to comply with our legal or regulatory
          obligations; or (iv) the processing is in our legitimate interest in
          providing you with the Service. Transfers of Personal Data:
          Zoomtocollege is a global company; thus, we will need to transfer your
          Personal Data outside of the country from which it was originally
          collected. This may be intra-group or to third parties that we work
          with who may be in jurisdictions outside the EEA, Switzerland, and the
          UK, that have no data protection laws or laws that are less strict
          than those in the EEA, Switzerland, and the UK. Whenever we transfer
          Personal Data outside of the EEA, Switzerland, or the UK, we take all
          legally required steps to make sure that appropriate safeguards are in
          place to protect your Personal Data.
        </p>
        <p>
          Personal Data Retention/Rights: We retain your Personal Data for as
          long as necessary to provide you with the Service, comply with
          applicable laws, resolve disputes, and enforce our agreements. If you
          have an account with us, we will typically retain your Personal Data
          for a period of 90 days after you have requested that your account is
          closed, or for up to seven years after your account becomes inactive
          but is not closed.
        </p>
        <p>
          Data protection laws provide you with rights in respect to Personal
          Data that we hold about you, including the right to request a copy of
          the Personal Data, the right to request that we rectify, restrict, or
          delete your Personal Data, and the right to unsubscribe from marketing
          communications.
        </p>
        <p>
          You can exercise these rights by contacting us using the contact
          information set out in this policy.
        </p>
        <p>
          If you have a complaint about how we handle your Personal Data, please
          contact us as set forth in this policy If you are not happy with how
          we have attempted to resolve your complaint, you may contact the
          relevant data protection authority.
        </p>
        <p>
          <strong>
            Zoomtocollege as a Data Controller and a Data Processor:
          </strong>
        </p>
        <p>
          EU data protection law distinguishes between organizations that
          process Personal Data for their own purposes (known as “data
          controllers”) and organizations that process personal data on behalf
          of other organizations or individuals (known as “data processors”). If
          you have any questions or complaints about how your Personal Data is
          handled, these should always be directed to the relevant data
          controller since they are the ones with primary responsibility for
          your Personal Data.
        </p>
        <p>
          Zoomtocollege may act as either a data controller or a data processor
          with respect to your Personal Data, depending on the circumstances. We
          will be a data controller of any information you submit to us to
          create a user account or an Organizer account. However, once a User
          submits Personal Data to an Organizer in conjunction with use of the
          Services, Zoomtocollege becomes a data processor with respect to the
          Organizer Collected Personal Data.
        </p>
        <h3>Public Disclosure</h3>
        <p>
          We will disclose your Personal Data to the public only under the
          following circumstances:
        </p>
        <p>You have explicitly authorized us to disclose your Personal Data;</p>
        <p>
          Disclosing your Personal Data is in the interest of national security;
          public health, or another major public interest;
        </p>
        <p>
          Your Personal Data is relevant to any criminal investigation,
          prosecution, judgment, or enforcement;
        </p>
        <p>
          Disclosing your Personal Data is necessary to protect yours or another
          individual’s life, property or other major lawful right;
        </p>
        <p>Personal Data is disclosed voluntarily by you to the public;</p>
        <p>
          Personal Data is collected from public channels, such as news reports
          or from public government documents.
        </p>
        <p>
          Pursuant to applicable laws, sharing and transfer of aggregated data
          which cannot be recovered by the recipient to re-identify individual
          Personal Data does not constitute sharing, transfer and disclosure of
          Personal Data, and therefore it may be stored and processed without
          notice to you and without your consent.
        </p>
        <h3>Cookies</h3>
        <p>
          We may collect information about your computer, including where
          available your IP address, operating system, and browser type. This is
          statistical data about our users’ browsing actions and patterns and
          does not identify you as an individual.
        </p>
        <p>
          Zoomtocollege may use cookies. A cookie is a small amount of data
          which is stored on your computer, and which is revealed to
          Zoomtocollege when you use this network. Zoomtocollege uses cookies
          only to make it easier to identify you when using the network.
          Zoomtocollege does not use cookies to collect additional information
          about you. You can configure your web browser not to accept cookies.
        </p>
        <p>
          We use Google Analytics tracking code that supports Display
          Advertising in order to use Google Analytics Demographics and Interest
          Reporting so that we can purpose content on our website according to
          the demographics (such as age, gender and interests) of the visitors
          to particular pages. You may opt-out of Google Analytics for Display
          Advertising and customise Google Display Network ads by using the Ad
          Settings on Google’s website.
        </p>
        <h3>Other Websites</h3>
        <p>
          The website may contain links that make it easy for you to visit other
          websites. Zoomtocollege does not have any control over these websites.
          Therefore, if you use these links to leave Zoomtocollege and visit
          websites operated by third parties, Zoomtocollege cannot be
          responsible for the protection and privacy of any information that you
          provide whilst visiting these websites.
        </p>
        <h2>Changes to this Policy</h2>
        <p>
          This Privacy Policy was last updated on the date set forth at the top
          of this Privacy Policy. We will alert you to any future updates to
          this Privacy Policy by placing a notice on the website, by sending you
          an email, by notifying you when you logon to the Service, and/or by
          some other means. We will also edit the date at the top of this policy
          to reflect the date of the changes. Please note that if you’ve opted
          not to receive legal notice emails from us (or you haven’t provided us
          with your email address), our legal notices (including this Privacy
          Policy) will still govern your use of the Service, and you are still
          responsible for reading and understanding them. Use of Personal Data
          we collect is subject to the Privacy Policy in effect at the time such
          Personal Data is collected. If you use the Service after any updated
          Privacy Policy has been posted, that means that you agree to all the
          changes made with respect to all Personal Data, whether collected
          previously or in the future.
        </p>
        <h2>Minors</h2>
        <p>
          We do not knowingly collect or solicit personal information from
          anyone under the age of 14. If you are under 14, please do not attempt
          to register for the Service or send any personal information about
          yourself to us. If we learn that we have collected personal
          information from a child under 14, we will delete that information as
          quickly as possible. If you believe that a child under 14 may have
          provided us with personal information, please contact us at
          service@zoomtocollege.com.
        </p>
        <h2>Requesting or Amending Information</h2>
        <p>
          You may request for us to provide you with details of personal
          information that we hold about you by contacting us.
        </p>
        www.zoomtocollege.com
        <p>
          To the extent you have authorized us to collect and process your
          Personal Data, you may withdraw that authorization by deleting
          information, turning off device functions, or changing privacy
          settings on our website or in our software. You may also stop using
          the Service so that we cannot continue collecting your Personal Data.
          Please note that some parts of the Service may not function properly
          if you remove our authorization to collect your Personal Data. For any
          Personal Data that you have authorized the Organizers directly, you
          should contact the Organizers to withdraw such authorization.
        </p>
        <p>
          If you request us to do so we will make changes or remove the
          information that we hold about you. We will change certain limited
          information that we hold about you where you tell us it is incorrect.
        </p>
        <h1>Contacting Us</h1>
        <p>
          If you have any questions or concerns regarding our privacy policies,
          please send us a detailed message by email to{" "}
          <strong>service@zoomtocollege.com</strong>
        </p>
      </div>
    </UserNavbar>
  );
};

export default PrivacyPolicy;
