import React, { useState } from "react";
import MainNavbar from "../../reusables/Navbar/MainNavbar/MainNavbar";
import PageTitle from "../../reusables/PageTitle/PageTitle";
import CustomVericalInputField from "../../reusables/FromComponent/CustomVericalInputField";
import CustomButton from "../../reusables/FromComponent/CustomButton";
import Notification, { handleStateChange } from "../../../utils/helpers";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const Resetpassword = () => {
  const [userData, setUserData] = useState({});
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (userData.password !== userData.confPassword) {
      Notification.errorNotification(
        "Please enter a valid password, the provided passwords do not match."
      );
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/resetPassword/${token}`,
        userData
      )
      .then((response) => {
        Notification.successNotification(response.data.message);
        navigate("/login");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };
  return (
    <MainNavbar>
      <>
        <PageTitle title={"Reset password"} />
        <div className="login_form_holder">
          <form className="login_form_style">
            <CustomVericalInputField
              name="password"
              label="Enter Password"
              type="password"
              value={userData?.password}
              onChange={(e) => handleStateChange(e, setUserData)}
            />
            <CustomVericalInputField
              name="confPassword"
              label="Enter confirm password"
              type="password"
              value={userData?.confPassword}
              onChange={(e) => handleStateChange(e, setUserData)}
            />

            <div className="login_field_holder">
              <CustomButton
                buttonLabel="Send mail"
                onClick={handleSubmit}
                fullwidth
              />
            </div>
          </form>
        </div>
      </>
    </MainNavbar>
  );
};

export default Resetpassword;
