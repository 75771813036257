import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./UniversitySignUp.css";

import MainNavbar from "../../reusables/Navbar/MainNavbar/MainNavbar";
import PageTitle from "../../reusables/PageTitle/PageTitle";
import UniversitySignUpStep1 from "./UniversitySignUpSteps/UniversitySignUpStep1";
import UniversitySignUpStep2 from "./UniversitySignUpSteps/UniversitySignUpStep2";

import { UniversitySignUpTabs } from "../../../utils/constant";
import Notification, {
  classNames,
  findEmptyFields,
} from "../../../utils/helpers";

const UniversitySignUp = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("tab1");

  const [universityInqury, setUniversityInqury] = useState({
    isVerified: false,
  });

  const updateUniversityDataState = (values) => {
    const newFields = { ...universityInqury, ...values };
    setUniversityInqury(newFields);
  };

  const handleChangeTab = async (data) => {
    setActiveTab(data === "tab1" ? "tab1" : "tab2");
  };

  const onSubmit = (data) => {
    if (findEmptyFields(data).length <= 0) {
      const UniversitySignUpData = { ...universityInqury, ...data };

      const formData = new FormData();

      Object.keys(UniversitySignUpData).forEach((item) => {
        if (Array.isArray(UniversitySignUpData[item])) {
          UniversitySignUpData[item].forEach((obj) => {
            formData.append(item, JSON.stringify(obj));
          });
        } else {
          formData.append(item, UniversitySignUpData[item]);
        }
      });

      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/auth/signup`, formData)
        .then(() => {
          Notification.successNotification(
            "Your university added Successfully"
          );
          navigate("/login");
        })
        .catch((err) => {
          Notification.errorNotification(err.response.data.message);
        });
    } else {
      Notification.errorNotification("Please fill all the required field");
    }
  };
  return (
    <div>
      <MainNavbar>
        <PageTitle title={"UNIVERSITY INQUIRY"} />
        <div className="signup_main_conatainer">
          <div className="university_sign_up_tab_holder">
            {UniversitySignUpTabs.map((tab) => (
              <div className="university_tab_btn_holder_cont">
                <button
                  className={classNames(
                    tab.tab === activeTab && "selected_tab_btn-style",
                    "tab_btn_style"
                  )}
                  onClick={() => handleChangeTab(tab.tab)}
                >
                  {tab.name}
                </button>
              </div>
            ))}
          </div>
          <div>
            {activeTab === "tab1" ? (
              <UniversitySignUpStep1
                handleChangeTab={handleChangeTab}
                handleSaveFields={updateUniversityDataState}
              />
            ) : activeTab === "tab2" ? (
              <UniversitySignUpStep2
                handleRoleSubmit={(data) => onSubmit(data)}
              />
            ) : null}
          </div>
        </div>
      </MainNavbar>
    </div>
  );
};

export default UniversitySignUp;
