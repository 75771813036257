import React from "react";
import { useParams } from "react-router-dom";
import { TfiPencilAlt } from "react-icons/tfi";
import { BsCalendar } from "react-icons/bs";

import { useGetBlogById } from "../../../hooks/useGetBlogById";

import UserNavbar from "../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../reusables/PageTitle/PageTitle";
import Loader from "../../reusables/Loader/Loader";
import ErrorPage from "../../reusables/ErrorPage/ErrorPage";

import { replaceLocalhostUrl } from "../../../utils/helpers";

const BlogDetailView = () => {
  const { blogId } = useParams();
  const [blogData, isLoading, error] = useGetBlogById(blogId);

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;
  return (
    <div>
      <UserNavbar>
        <PageTitle title={"BLOG PREVIEW"} />
        <div className="main_blog_container">
          <div className="blogTitleHolder">
            <p className="blog_title_text_style">{blogData?.title}</p>
          </div>
          <div className="blog_info_holder">
            <div className="auther_info">
              <TfiPencilAlt className="author_icon_style" />
              <p>{blogData?.author?.username}</p>
            </div>
            <div className="auther_info margin_left_style">
              <BsCalendar className="author_icon_style" />
              <p>
                {blogData?.publishDate
                  ? new Date(blogData?.publishDate).toLocaleDateString(
                      "en-US",
                      {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      }
                    )
                  : "-"}
              </p>
            </div>
          </div>

          {blogData?.blogImage && (
            <div className="image_contaienr">
              <img
                src={replaceLocalhostUrl(blogData?.blogImage)}
                className="image_style"
                alt="blog_cover_image"
              />
            </div>
          )}
          <div
            className="blog_content_holder"
            dangerouslySetInnerHTML={{ __html: blogData?.description }}
          />
        </div>
      </UserNavbar>
    </div>
  );
};

export default BlogDetailView;
