import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import axios from "axios";
import "./SuperAdminViewUniversity.css";

import { useGetUniversityById } from "../../../../hooks/useGetUniversityById";
import { useGetUsersOfUniversityById } from "../../../../hooks/useGetUsersOfUniversityById";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import CustomVericalInputField from "../../../reusables/FromComponent/CustomVericalInputField";
import CustomVerticalSelectField from "../../../reusables/FromComponent/CustomVerticalSelectField";
import TableComponent from "../../../reusables/TableComponent/TableComponent";

import Notification, {
  handleFileInputInState,
  handleStateChange,
  removeEmptyProperties,
  replaceLocalhostUrl,
} from "../../../../utils/helpers";
import {
  API_HEADERS,
  agentListtableCols,
  agenttableFields,
  newCountryList,
  universityAddDefaultStateObj,
} from "../../../../utils/constant";

const SuperAdminViewUniversity = () => {
  const { universityId } = useParams();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [universityData, isLoading, error] = useGetUniversityById(universityId);
  const userList = useGetUsersOfUniversityById(universityId);

  const [newUniversityData, setNewuniversityData] = useState({
    ...universityAddDefaultStateObj,
  });

  useEffect(() => {
    setNewuniversityData({
      updatedBy:
        user?._id || JSON.parse(localStorage.getItem("currentLoginUser"))._id,
      universityName: universityData?.universityName || "",
      universityRegistrationNumber:
        universityData?.universityRegistrationNumber || "",
      universityTaxNumber: universityData?.universityTaxNumber || "",
      typeOfInstitution: universityData?.typeOfInstitution || "",
      universityCoverImage: "",
      subImage1: "",
      subImage2: "",
      subImage3: "",
      universityCountry: universityData?.universityCountry || "",
      universityCity: universityData?.universityCity || "",
      universityAddress: universityData?.universityAddress || "",
      universityPostalcode: universityData?.universityPostalcode || "",
      universityDescription: universityData?.universityDescription || "",
    });
  }, [universityId, universityData, user?._id]);

  const handleUpdateUniversity = () => {
    const dataToUpdate = removeEmptyProperties({
      ...newUniversityData,
      universityId,
      updatedBy:
        user?._id || JSON.parse(localStorage.getItem("currentLoginUser"))?._id,
    });
    const universityData = new FormData();

    Object.keys(dataToUpdate).forEach((key) => {
      if (["subImage1", "subImage2", "subImage3"].includes(key)) {
        universityData.append("subImages", dataToUpdate[key]);
      } else {
        universityData.append(key, dataToUpdate[key]);
      }
    });

    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/university/updateUniversityById`,
        universityData,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("University Updated Successfuly");
        navigate("/superAdmin/university");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  const handleDeleteUniversity = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/university/deleteUniversityById/${universityId}`,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("University Deleted Successful");
        navigate("/superAdmin/university");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  const verifyUniversity = () => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/university/verifyUniversityById`,
        {
          universityId,
          addedBy: JSON.parse(localStorage.getItem("currentLoginUser"))._id,
        },
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("University Verified.");
        navigate("/superAdmin/university");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  if (isLoading || userList[1]) return <Loader />;
  if (error || userList[2]) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"VIEW UNIVERSITY"} />
        <div className="add_university_holder">
          <div className="input_container">
            <div className="bacsic_detail_holder">
              <div className="basic_university_detail_holder">
                <CustomVericalInputField
                  name="universityName"
                  label="University Name"
                  value={newUniversityData?.universityName}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                />

                <CustomVericalInputField
                  name="universityRegistrationNumber"
                  label="Registration Number"
                  value={newUniversityData?.universityRegistrationNumber}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                />

                <CustomVericalInputField
                  name="universityTaxNumber"
                  label="Tax Number"
                  value={newUniversityData?.universityTaxNumber}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                />

                <CustomVericalInputField
                  name="typeOfInstitution"
                  label="Type of Institution"
                  value={newUniversityData?.typeOfInstitution}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                />
              </div>
              <div className="university_image_container">
                <label
                  className="university_cover_image"
                  htmlFor="universityCoverImage"
                >
                  {newUniversityData?.universityCoverImage !== "" ? (
                    <img
                      src={URL.createObjectURL(
                        newUniversityData?.universityCoverImage
                      )}
                      className="cover_img_style"
                      alt="university_cover_image"
                    />
                  ) : universityData?.universityCoverImage ? (
                    <img
                      src={replaceLocalhostUrl(
                        universityData?.universityCoverImage
                      )}
                      className="cover_img_style"
                      alt="university_cover_image"
                    />
                  ) : (
                    <img
                      src="/images/superAdmin/upload_placeholder.png"
                      className="upload_placeholder_img_style"
                      alt="university_cover_image"
                    />
                  )}
                </label>
                <input
                  type="file"
                  className="actual_documetn_upload_input_field"
                  id="universityCoverImage"
                  name="universityCoverImage"
                  onChange={(e) =>
                    handleFileInputInState(e, setNewuniversityData)
                  }
                />

                <div className="sub_images_container">
                  <label className="sub_images_class" htmlFor="subImage1">
                    {newUniversityData?.subImage1 !== "" ? (
                      <img
                        src={URL.createObjectURL(newUniversityData?.subImage1)}
                        className="side_image_style"
                        alt="sub_image"
                      />
                    ) : universityData?.subImages[0] ? (
                      <img
                        src={replaceLocalhostUrl(universityData?.subImages[0])}
                        className="side_image_style"
                        alt="sub_image"
                      />
                    ) : (
                      <img
                        src="/images/superAdmin/upload_placeholder.png"
                        className="subimage_upload_placeholder_img_style"
                        alt="sub_image"
                      />
                    )}
                  </label>
                  <input
                    type="file"
                    className="actual_documetn_upload_input_field"
                    id="subImage1"
                    name="subImage1"
                    onChange={(e) =>
                      handleFileInputInState(e, setNewuniversityData)
                    }
                  />
                  <label className="sub_images_class" htmlFor="subImage2">
                    {newUniversityData?.subImage2 !== "" ? (
                      <img
                        src={URL.createObjectURL(newUniversityData?.subImage2)}
                        className="side_image_style"
                        alt="sub_image"
                      />
                    ) : universityData?.subImages[1] ? (
                      <img
                        src={replaceLocalhostUrl(universityData?.subImages[1])}
                        className="side_image_style"
                        alt="sub_image"
                      />
                    ) : (
                      <img
                        src="/images/superAdmin/upload_placeholder.png"
                        className="subimage_upload_placeholder_img_style"
                        alt="sub_image"
                      />
                    )}
                  </label>
                  <input
                    type="file"
                    className="actual_documetn_upload_input_field"
                    id="subImage2"
                    name="subImage2"
                    onChange={(e) =>
                      handleFileInputInState(e, setNewuniversityData)
                    }
                  />
                  <label className="sub_images_class" htmlFor="subImage3">
                    {newUniversityData?.subImage3 ? (
                      <img
                        src={URL.createObjectURL(newUniversityData?.subImage3)}
                        className="side_image_style"
                        alt="sub_image"
                      />
                    ) : universityData?.subImages[2] ? (
                      <img
                        src={replaceLocalhostUrl(universityData?.subImages[2])}
                        className="side_image_style"
                        alt="sub_image"
                      />
                    ) : (
                      <img
                        src="/images/superAdmin/upload_placeholder.png"
                        className="subimage_upload_placeholder_img_style"
                        alt="sub_image"
                      />
                    )}
                  </label>
                  <input
                    type="file"
                    className="actual_documetn_upload_input_field"
                    id="subImage3"
                    name="subImage3"
                    onChange={(e) =>
                      handleFileInputInState(e, setNewuniversityData)
                    }
                  />
                </div>
                <p>Change All three images togather</p>
              </div>
            </div>
            <div className="university_label_field_holder">
              <label className="university_label_style">About University</label>
              <textarea
                type="text"
                className="full_university_input_field_style"
                name="universityDescription"
                value={newUniversityData?.universityDescription}
                onChange={(e) => handleStateChange(e, setNewuniversityData)}
              />
            </div>
          </div>
          <div className="input_container">
            <div className="container">
              <div className="row">
                <CustomVerticalSelectField
                  name="universityCountry"
                  label="University Country"
                  value={newUniversityData?.universityCountry}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                  options={newCountryList}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="universityCity"
                  label="University City"
                  value={newUniversityData?.universityCity}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="universityAddress"
                  label="University Address"
                  value={newUniversityData?.universityAddress}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="universityPostalcode"
                  label="Postalcode"
                  value={newUniversityData?.universityPostalcode}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                  colClass="col-12 col-lg-6"
                />
              </div>
            </div>
          </div>

          <div className="submit_button_holder">
            <button
              className="add_new_univercity_btn"
              style={{ marginRight: "10px" }}
              onClick={handleDeleteUniversity}
            >
              Delete University
            </button>
            <button
              className="add_new_univercity_btn"
              onClick={handleUpdateUniversity}
            >
              Update University
            </button>
            {universityData?.isVerified === false && (
              <button
                className="verify_univercity_btn"
                onClick={verifyUniversity}
              >
                Verify University
              </button>
            )}
          </div>
          <div className="agent_list_holder">
            <PageTitle title={"UNIVERISTY USERS"} />
            {userList[0] && (
              <TableComponent
                tableColumns={agentListtableCols}
                fieldsToShowList={agenttableFields}
                data={userList[0]}
              />
            )}
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminViewUniversity;
