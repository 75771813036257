import axios from "axios";
import React, { useContext, useState } from "react";
import Notification, { handleStateChange } from "../../../utils/helpers";
import MainNavbar from "../../reusables/Navbar/MainNavbar/MainNavbar";
import PageTitle from "../../reusables/PageTitle/PageTitle";
import CustomVericalInputField from "../../reusables/FromComponent/CustomVericalInputField";
import CustomButton from "../../reusables/FromComponent/CustomButton";

const ForgotPassword = () => {
  const [userData, setUserData] = useState({});
  // const navigate = useNavigate();

  const handleSubmit = async () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/forgetPassword`,
        userData
      )
      .then((response) => {
        Notification.successNotification(response.data.message);
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };
  return (
    <MainNavbar>
      <>
        <PageTitle title={"Forgot password"} />
        <div className="login_form_holder">
          <form className="login_form_style">
            <CustomVericalInputField
              name="email"
              label="Enter Email"
              type="email"
              value={userData?.email}
              onChange={(e) => handleStateChange(e, setUserData)}
            />

            <div className="login_field_holder">
              <CustomButton
                buttonLabel="Send mail"
                onClick={handleSubmit}
                fullwidth
              />
            </div>
          </form>
        </div>
      </>
    </MainNavbar>
  );
};

export default ForgotPassword;
