import React from "react";
import { replaceLocalhostUrl } from "../../../../utils/helpers";

const WhoWeAreBlock = ({ whoWeAreContent }) => {
  return (
    <div className="who_we_are_main row gx-4 gy-4">
      <p className="curious_heading">ABOUT US</p>
      <div className="row">
        {whoWeAreContent?.map((content) => (
          <div className="col-12 col-lg-4 col-md-6">
            <div className="who_we_container">
              <div className="who_we_icon_holder">
                <img
                  src={replaceLocalhostUrl(content.iconImage)}
                  className="icon_image_style"
                />
              </div>
              <div className="who_we_content_holder">
                <p className="who_we_are_headings">{content.heading}</p>
                <p className="who_we_are_description">{content.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhoWeAreBlock;
