import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./StudentSignUp.css";

import MainNavbar from "../../reusables/Navbar/MainNavbar/MainNavbar";
import PageTitle from "../../reusables/PageTitle/PageTitle";
import CustomVericalInputField from "../../reusables/FromComponent/CustomVericalInputField";
import CustomButton from "../../reusables/FromComponent/CustomButton";

import Notification, {
  findEmptyFields,
  handleStateChange,
} from "../../../utils/helpers";
import { studentSignUpStateDefaultObject } from "../../../utils/constant";

const StudentSignUp = () => {
  const navigate = useNavigate();

  const [signUpstudentData, setSignUpStudentData] = useState({
    ...studentSignUpStateDefaultObject,
    role: "Student",
  });

  const handleStudetnSignUp = () => {
    if (signUpstudentData?.password !== signUpstudentData?.confirmPassword) {
      Notification.errorNotification("Password not match. Please try again!");
      return;
    }

    if (findEmptyFields(signUpstudentData).length <= 0) {
      const formData = new FormData();

      Object.keys(signUpstudentData).forEach((item) => {
        formData.append(item, signUpstudentData[item]);
      });

      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/auth/signup`, formData)
        .then(() => {
          Notification.successNotification("Sign Up Successful. Please login.");
          navigate("/login");
        })
        .catch((err) => {
          console.log("error", err);
          Notification.errorNotification(err?.response?.data?.message);
        });
    } else {
      Notification.errorNotification("Please fill all the required field");
    }
  };

  return (
    <div>
      <MainNavbar>
        <PageTitle title={"Student SignUp"} />
        <div className="student_sign_up_container">
          <CustomVericalInputField
            label={"Enter First Name"}
            name="firstName"
            value={signUpstudentData?.firstName}
            onChange={(e) => handleStateChange(e, setSignUpStudentData)}
          />
          <CustomVericalInputField
            label={"Enter Last Name"}
            name="lastName"
            value={signUpstudentData?.lastName}
            onChange={(e) => handleStateChange(e, setSignUpStudentData)}
          />
          <CustomVericalInputField
            label={"Enter Preferred Username"}
            name="username"
            value={signUpstudentData?.username}
            onChange={(e) => handleStateChange(e, setSignUpStudentData)}
          />
          <CustomVericalInputField
            label={"Enter Email"}
            type="email"
            name="email"
            value={signUpstudentData?.email}
            onChange={(e) => handleStateChange(e, setSignUpStudentData)}
          />
          <CustomVericalInputField
            label={"Enter Password"}
            type="password"
            name="password"
            value={signUpstudentData?.password}
            onChange={(e) => handleStateChange(e, setSignUpStudentData)}
          />
          <CustomVericalInputField
            label={"Confirm Password"}
            type="password"
            name="confirmPassword"
            value={signUpstudentData?.confirmPassword}
            onChange={(e) => handleStateChange(e, setSignUpStudentData)}
          />
          <CustomButton
            buttonLabel={"Sign Up"}
            fullwidth
            onClick={handleStudetnSignUp}
          />
        </div>
      </MainNavbar>
    </div>
  );
};

export default StudentSignUp;
