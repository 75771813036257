import React, { useEffect, useState } from "react";
import axios from "axios";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";

import Notification, {
  generateRandomString,
  removeEmptyProperties,
  replaceLocalhostUrl,
} from "../../../../../utils/helpers";
import { API_HEADERS } from "../../../../../utils/constant";

const OurTeam = ({ ourTeam }) => {
  const [ourTeamData, setOurTeamData] = useState([
    {
      id: generateRandomString(5),
      name: "",
      positin: "",
      memberImage: "",
    },
  ]);

  const handleImageChange = (index, newImage) => {
    const updatedData = [...ourTeamData];
    updatedData[index].memberImage = newImage;
    setOurTeamData(updatedData);
  };

  const addTeamMember = () => {
    const newTeamMember = {
      id: generateRandomString(5),
      name: "",
      position: "",
      memberImage: "",
    };
    setOurTeamData([...ourTeamData, newTeamMember]);
  };
  const handleInputChange = (index, field, newValue) => {
    const updatedData = [...ourTeamData];
    updatedData[index][field] = newValue;
    setOurTeamData(updatedData);
  };

  const deleteTeamMember = (index) => {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/aboutUsInfo/removeOurTeamMember/${ourTeamData[index].id}`,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification(
          "Member removed from the team successfully"
        );
      })
      .catch((err) => {
        Notification.errorNotification(err?.response?.data?.message);
      });
    const updatedData = ourTeamData.filter((_, i) => i !== index);
    setOurTeamData(updatedData);
  };

  const handleUpdateMember = (value) => {
    const newData = removeEmptyProperties(value);
    const ourCultureDataToSend = new FormData();

    Object.keys(newData).forEach((key) => {
      ourCultureDataToSend.append(key, newData[key]);
    });

    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/aboutUsInfo/updateOurTeam`,
        ourCultureDataToSend,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification(
          "About Us Content Updated Successfully"
        );
      })
      .catch((err) => {
        Notification.errorNotification(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    setOurTeamData(ourTeam);
  }, [ourTeam]);
  return (
    <div className="our_story_main">
      <p className="curious_heading">OUR TEAM</p>
      <div className="row">
        {ourTeamData.map((item, index) => (
          <div key={item.id} className="col-6 col-lg-3 col-md-4 col-sm-6 my-2">
            <div className="member_details_holder text-center">
              <label htmlFor={`team_member_image_input_${item.id}`}>
                {typeof item.memberImage === "string" ? (
                  <img
                    src={
                      replaceLocalhostUrl(item.memberImage) ||
                      "/images/aboutUs/our-team/CEO.png"
                    }
                    className="member_image_style"
                  />
                ) : (
                  <img
                    src={URL.createObjectURL(item.memberImage)}
                    className="member_image_style"
                  />
                )}
              </label>
              <input
                id={`team_member_image_input_${item.id}`}
                type="file"
                className="image_input_hiddden_style"
                name="ourCultureImage"
                onChange={(e) => handleImageChange(index, e.target.files[0])}
              />
              <div className="member_detail_holder">
                <input
                  type="text"
                  value={item.name}
                  onChange={(e) =>
                    handleInputChange(index, "name", e.target.value)
                  }
                  placeholder="Enter Name"
                  className="team_member_name"
                />
                <input
                  type="text"
                  value={item.position}
                  onChange={(e) =>
                    handleInputChange(index, "position", e.target.value)
                  }
                  placeholder="Enter Position"
                  className="team_member_position_style"
                />
              </div>
              <div className="my-3">
                <button
                  className="update_team_member_btn"
                  onClick={() => handleUpdateMember(item)}
                >
                  Update
                </button>
                <AiOutlineDelete
                  className="team_member_delete_btn"
                  onClick={() => deleteTeamMember(index)}
                />
              </div>
            </div>
          </div>
        ))}
        <div className="col-6 col-lg-3 col-md-4 col-sm-6">
          <div className="add_team_member_container">
            <AiOutlinePlus
              onClick={addTeamMember}
              className="team_member_add_icon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
