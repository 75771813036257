import React, { useState } from "react";

import Notification, {
  findEmptyFields,
  handleStateChange,
} from "../../../../utils/helpers";
import { agencySignUpStep4DefaultStateObj } from "../../../../utils/constant";

const SignUpStep4 = ({ handleRoleSubmit }) => {
  const [step4Data, setStep4Data] = useState(agencySignUpStep4DefaultStateObj);

  const handleSubmit = () => {
    if (findEmptyFields(step4Data).length) {
      Notification.errorNotification(
        `Please Enter following Field:  ${findEmptyFields(step4Data)[0]}`
      );
    } else {
      handleRoleSubmit(step4Data);
    }
  };

  return (
    <div className="sign_in_step_1_form_holder">
      <form className="step-1_form">
        <div className="field_holder">
          <input
            type="text"
            placeholder="Agent First Name"
            className="input_field_style"
            name="firstName"
            onChange={(e) => handleStateChange(e, setStep4Data)}
          />
        </div>
        <div className="field_holder">
          <input
            type="text"
            placeholder="Agent Last Name"
            className="input_field_style"
            name="lastName"
            onChange={(e) => handleStateChange(e, setStep4Data)}
          />
        </div>
        <div className="field_holder">
          <input
            type="text"
            placeholder="Agent Preferred Username"
            className="input_field_style"
            name="username"
            onChange={(e) => handleStateChange(e, setStep4Data)}
          />
        </div>
        <div className="field_holder">
          <select
            type="text"
            placeholder="Agency tax Number"
            className="input_field_style"
            name="role"
            onChange={(e) => handleStateChange(e, setStep4Data)}
          >
            <option>---- Select User Type ----</option>
            <option value={"AgencySuperAdmin"}>Agency Super Admin</option>
            <option value={"AgencySubSuperAdmin"}>
              Agency Sub Super Admin
            </option>
            <option value={"AgencySubAdmin"}>Agency Sub Admin</option>
          </select>
        </div>
        <div className="field_holder">
          <input
            type="email"
            placeholder="Agent Email"
            className="input_field_style"
            name="email"
            onChange={(e) => handleStateChange(e, setStep4Data)}
          />
        </div>
        <div className="field_holder">
          <input
            type="password"
            placeholder="Enter Password"
            className="input_field_style"
            name="password"
            onChange={(e) => handleStateChange(e, setStep4Data)}
          />
        </div>
        <div className="field_holder">
          <input
            type="password"
            placeholder="Confirm Password"
            className="input_field_style"
            name="confirmPassword"
            onChange={(e) => handleStateChange(e, setStep4Data)}
          />
        </div>

        <div className="next_button_holder" type="button">
          <button type="button" className="next_button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default SignUpStep4;
