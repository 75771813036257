import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Notification, {
  handleFileInputInState,
  handleStateChange,
  replaceLocalhostUrl,
} from "../../../../../utils/helpers";
import axios from "axios";
import { API_HEADERS } from "../../../../../utils/constant";

const WhyApplyForm = ({ selectedData, handleClose }) => {
  const [whyApplyCardData, setwhyApplyCardData] = useState({});
  const handleSave = () => {
    const whyApplyCardFormData = new FormData();

    Object.keys(whyApplyCardData).forEach((key) => {
      whyApplyCardFormData.append(key, whyApplyCardData[key]);
    });
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/homeInfo/updateWhyApplyCard`,
        whyApplyCardFormData,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("New Application added Successfully");
        setTimeout(() => handleClose(), 2000);
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };
  useEffect(() => {
    setwhyApplyCardData({
      ...whyApplyCardData,
      cardId: selectedData._id,
      title: selectedData.title,
      description: selectedData.description,
    });
  }, [selectedData]);
  return (
    <div>
      <Modal.Body>
        <div>
          <label
            className="university_cover_image"
            htmlFor="whyApplyCardCoverImage"
          >
            {whyApplyCardData?.whyApplyCardCoverImage ? (
              <img
                src={URL.createObjectURL(
                  whyApplyCardData?.whyApplyCardCoverImage
                )}
                className="cover_img_style"
                alt="why_apply_card"
              />
            ) : (
              <img
                src={
                  selectedData.whyApplyCardCoverImage
                    ? replaceLocalhostUrl(selectedData.whyApplyCardCoverImage)
                    : "/images/superAdmin/upload_placeholder.png"
                }
                className="upload_placeholder_img_style"
                alt="why_apply_card"
              />
            )}
          </label>
          <input
            type="file"
            className="actual_documetn_upload_input_field"
            id="whyApplyCardCoverImage"
            name="whyApplyCardCoverImage"
            onChange={(e) => handleFileInputInState(e, setwhyApplyCardData)}
          />
        </div>
        <div className="university_label_field_holder">
          <label className="university_label_style">Title</label>
          <input
            type="text"
            className="university_input_field_style"
            name="title"
            defaultValue={selectedData?.title}
            onChange={(e) => handleStateChange(e, setwhyApplyCardData)}
          />
        </div>
        <div className="university_label_field_holder">
          <label className="university_label_style">Description</label>
          <textarea
            type="text"
            className="university_input_field_style"
            name="description"
            defaultValue={selectedData.description}
            onChange={(e) => handleStateChange(e, setwhyApplyCardData)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default WhyApplyForm;
