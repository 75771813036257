import React from "react";
import "./EmptyComponent.css";
import { Container, Row, Col } from "react-bootstrap";

const EmptyComponent = ({ title, subTitle }) => {
  return (
    <Container className="mt-5 text-center" style={{ width: "75%" }}>
      <Row>
        <Col xs={12} className="  text-center">
          <svg
            className="No_content_icon"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
        </Col>
        <Col xs={12}>
          <h3 className="mt-2 text-sm  text-gray-900">{title}</h3>
          <p className="mt-1 text-sm text-gray-500">{subTitle}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default EmptyComponent;
