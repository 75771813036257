import React, { useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

import Notification, {
  generateRandomString,
  getStringListFromObjectList,
  removeElementFromArray,
  removeEmptyStrings,
} from "../../../../utils/helpers";

const SignUpStep2 = ({ handleChangeTab, handleSaveFields }) => {
  const [serviceInput, setServiceInput] = useState([
    {
      id: generateRandomString(5),
      service: "",
    },
  ]);

  const handleNext = () => {
    const serviceList = getStringListFromObjectList(serviceInput);
    if (removeEmptyStrings(serviceList).length <= 0) {
      Notification.errorNotification("Please enter at least one service");
    } else {
      handleSaveFields({ services: serviceInput });
      handleChangeTab("tab3");
    }
  };

  const handleValueEntering = (id, service) => {
    const updatedServiceInput = serviceInput.map((item) => {
      if (item.id === id) {
        return { ...item, service };
      }
      return item;
    });
    setServiceInput(updatedServiceInput);
  };

  return (
    <div className="sign_in_step_2_form_holder">
      <div className="step_2_holder">
        <div className="data_container">
          <div className="content_style add_button_style">
            What services do you provide?{" "}
            <AiOutlinePlus
              className="icon_style"
              onClick={() =>
                setServiceInput([
                  ...serviceInput,
                  { id: generateRandomString(5), service: "" },
                ])
              }
            />
          </div>
        </div>

        <div className="option_holder_container">
          {serviceInput &&
            serviceInput.map((field) => (
              <div className="option_holder">
                <div className="option_text_holder">
                  <input
                    className="option_text_style"
                    value={field.service}
                    placeholder={"Please enter service"}
                    id={field.id}
                    onChange={(e) =>
                      handleValueEntering(field.id, e.target.value)
                    }
                  />
                  <div className="minus_btn_holder">
                    <AiOutlineMinus
                      className="icon_style"
                      onClick={() => {
                        setServiceInput(
                          removeElementFromArray(serviceInput, field.id)
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="next_button_holder" type="button">
          <button className="next_button" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUpStep2;
