import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DateRange } from "react-date-range";
import { TfiBrushAlt } from "react-icons/tfi";

import { useGetApplicationByStudentId } from "../../../../hooks/useGetApplicationByStudentId";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import TableComponent from "../../../reusables/TableComponent/TableComponent";
import EmptyComponent from "../../../reusables/EmptryComponent/EmptyComponent";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import {
  filterApplicationByStatusForAgencyAndStudents,
  filterApplicationsByStatus,
  handleStateChange,
  searchData,
} from "../../../../utils/helpers";
import {
  applicationTableCols,
  applicationTableFields,
} from "../../../../utils/constant";

const StudentApplicationList = () => {
  const { user } = useContext(AuthContext);

  const [applications, isLoading, error] = useGetApplicationByStudentId(
    user?._id || JSON.parse(localStorage.getItem("currentLoginUser"))?._id
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigationFilter = queryParams.get("filter") || "all";

  const [filterData, setFilterData] = useState(
    filterApplicationByStatusForAgencyAndStudents(
      applications,
      navigationFilter
    )
  );

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [filterContent, setFilterContent] = useState({
    startDate: "",
    endDate: "",
    firstName: "",
  });

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const clearSearch = () => {
    setFilterContent({
      startDate: "",
      endDate: "",
      firstName: "",
    });
    setFilterData(
      filterApplicationByStatusForAgencyAndStudents(
        applications,
        navigationFilter
      )
    );
  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (ranges) => {
    setFilterContent((prev) => ({
      ...prev,
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    }));
    onToggle();
  };

  useEffect(() => {
    setFilterData(
      searchData(
        filterContent,
        filterApplicationByStatusForAgencyAndStudents(
          applications,
          navigationFilter
        )
      )
    );
  }, [filterContent]);

  useEffect(() => {
    if (applications !== null) {
      setFilterData(
        filterApplicationByStatusForAgencyAndStudents(
          applications,
          navigationFilter
        )
      );
    }
  }, [applications]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;
  return (
    <div>
      <UserNavbar>
        <PageTitle title={"APPLICATIONS"} />
        <div className="university_list_table_holder">
          <div className="action_button_holder">
            <div className="date_range_keeper">
              <input
                className="applciation_input_field_style date_show_input"
                value={
                  filterContent.endDate &&
                  filterContent.startDate &&
                  `${new Date(
                    filterContent.startDate
                  ).toLocaleDateString()} - ${new Date(
                    filterContent.endDate
                  ).toLocaleDateString()}`
                }
                placeholder="Select dates"
                onClick={onToggle}
              />
              {isOpen && (
                <div className="date_picker_holder">
                  <DateRange
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    showSelectionPreview={true}
                  />
                </div>
              )}
            </div>
            <input
              type="text"
              className="applciation_input_field_style"
              placeholder="Search by first name"
              name="firstName"
              onChange={(e) => handleStateChange(e, setFilterContent)}
              value={filterContent.firstName}
            />
            <button
              className="add_university_navigation_btn"
              onClick={clearSearch}
            >
              <TfiBrushAlt className="clear_icon_style" />
            </button>
            <button
              className="add_university_navigation_btn"
              onClick={() => navigate("/student/application/addApplication")}
            >
              Add New Application
            </button>
          </div>

          <TableComponent
            tableColumns={applicationTableCols}
            fieldsToShowList={applicationTableFields}
            data={filterData}
            navigateTo="/student/application/view"
            emptyFor="studentApplicationList"
          />
        </div>
      </UserNavbar>
    </div>
  );
};

export default StudentApplicationList;
