import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./AgencyAgentList.css";

import { useGetAgentsByAgencyId } from "../../../../hooks/useGetAgentsByAgencyId";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import TableComponent from "../../../reusables/TableComponent/TableComponent";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import {
  agentListtableCols,
  agenttableFields,
} from "../../../../utils/constant";

const AgencyAgentList = () => {
  const { user } = useContext(AuthContext);

  const [agentList, isLoading, error] = useGetAgentsByAgencyId(
    user?.agencyRef ||
      JSON.parse(localStorage.getItem("currentLoginUser"))?.agencyRef
  );

  const navigate = useNavigate();

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"AGENTS"} />

        <div className="agency_agent_list_holder">
          <div className="add_agent_btn_holder">
            <button
              className="add_agent_btn_style"
              onClick={() => navigate(`/agency/agent/addAgent`)}
            >
              Add Agent
            </button>
          </div>

          <TableComponent
            tableColumns={agentListtableCols}
            fieldsToShowList={agenttableFields}
            data={agentList}
            navigateTo="/agency/agent/view"
            emptyFor="agencyAgentList"
          />
        </div>
      </UserNavbar>
    </div>
  );
};

export default AgencyAgentList;
