import { useEffect, useState } from "react";
import axios from "axios";
import { API_HEADERS } from "../utils/constant";

export const useGetBlog = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/blog/getBlog`, API_HEADERS())
      .then((response) => {
        setBlogData(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, []);

  return [blogData, isLoading, error];
};
