import { useEffect, useState } from "react";
import axios from "axios";

export const useGetSuggestionCourses = (
  courseDecepline,
  courseId,
  studentId = ""
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [suggestionCourses, setSuggestionCourses] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    if (courseDecepline && courseId) {
      setIsLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/course/getSuggestionCourses/${courseDecepline}/${courseId}?studentId=${studentId}`
        )
        .then((response) => {
          setSuggestionCourses(response.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, [courseDecepline, courseId]);

  return [suggestionCourses, isLoading, error];
};
