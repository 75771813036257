import React from "react";
import { useNavigate } from "react-router-dom";

import { useGetBlog } from "../../../../hooks/usegetBlog";

import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import TableComponent from "../../../reusables/TableComponent/TableComponent";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import {
  BlogfiledToShow,
  blogListTableColumn,
} from "../../../../utils/constant";

const SuperAdminBlogList = () => {
  const [blogData, isLoading, error] = useGetBlog();
  const navigate = useNavigate();

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"BLOGS"} />
        <div className="student_list_container">
          <div className="add_new_blog_button">
            <button
              type="button"
              className="agency_agent_btn_style"
              onClick={() => {
                navigate("/superAdmin/blog/addBlog");
              }}
            >
              Add blog
            </button>
          </div>
          <TableComponent
            tableColumns={blogListTableColumn}
            data={blogData}
            fieldsToShowList={BlogfiledToShow}
            navigateTo="/superAdmin/blog/view"
            emptyFor="superAdminBlogList"
          />
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminBlogList;
