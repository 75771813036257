import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoInformationCircleSharp } from "react-icons/io5";
import { Link } from "react-router-dom";

const CustomVerticalSelectField = ({
  name,
  options,
  onChange,
  label,
  value = "",
  colClass = "",
  toolTip = "",
  informativeLink = "",
}) => {
  const tooltip = (
    <Tooltip id="tooltip">
      <strong>{toolTip}</strong>
    </Tooltip>
  );

  return (
    <div className={colClass}>
      <div className="agency_field_holder">
        <label className="agency_label_style">
          {label}
          {toolTip !== "" && (
            <OverlayTrigger placement="top" overlay={tooltip}>
              <span style={{ marginLeft: "10px" }}>
                <IoInformationCircleSharp />
              </span>
            </OverlayTrigger>
          )}
          {informativeLink !== "" ? (
            <Link
              to={informativeLink}
              style={{ marginLeft: "10px", textDecoration: "none" }}
              target="_blank"
            >
              {" "}
              <IoInformationCircleSharp />
            </Link>
          ) : null}
        </label>
        <select
          className="agency_input_field_style"
          name={name}
          onChange={onChange}
          value={value}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default CustomVerticalSelectField;
