import React from "react";
import UserNavbar from "../Navbar/UserNavbar/UserNavbar";
import PageTitle from "../PageTitle/PageTitle";

const TermsAndconditions = () => {
  return (
    <div>
      <UserNavbar>
        <PageTitle title="TERMS AND CONDITIONS" />
        <br />
        <div className="recruitment_agent_form" style={{ textAlign: "left" }}>
          <h3>
            <strong>Welcome to Zoomtocollege!</strong>
          </h3>

          <p>
            These terms and conditions shall govern your use of our website,
            http://www.zoomtocollege.com (the “Website”). The owner and operator
            of the website is <strong>Zoomtocollege Limited </strong>, (also
            referred to in these Terms as “we”, “us”, “our” or “Zoomtocollege”)
            whose registered office is at 128 City Road, London, EC1V 2NX,
            United Kingdom and whose company registration number is 15203411.
          </p>

          <p>
            By accessing this website, we assume you accept these terms and
            conditions. Do not continue to use Zoomtocollege if you do not agree
            to all the terms and conditions stated on this page.
          </p>

          <p>
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice and all Agreements:
            "Client", "You" and "Your" refers to you, the person log on this
            website and compliant to the Company's terms and conditions. "The
            Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
            "Party", "Parties", or "Us", refers to both the Client and
            ourselves. All terms refer to the offer, acceptance, and
            consideration of payment necessary to undertake the process of our
            assistance to the Client in the most appropriate manner for the
            express purpose of meeting the Client's needs in respect of
            provision of the Company's stated services, in accordance with and
            subject to, prevailing law of England. Any use of the above
            terminology or other words in the singular, plural, capitalization
            and/or he/she or they, are taken as interchangeable and therefore as
            referring to same.
          </p>

          <h3>
            <strong>Cookies</strong>
          </h3>

          <p>
            We employ the use of cookies. By accessing Zoomtocollege, you agreed
            to use cookies in agreement with the Zoomtocollege's Privacy Policy.
            Most interactive websites use cookies to let us retrieve the user's
            details for each visit. Cookies are used by our website to enable
            the functionality of certain areas to make it easier for people
            visiting our website. Some of our affiliate/advertising partners may
            also use cookies.
          </p>

          <h3>
            <strong>License</strong>
          </h3>

          <p>
            Unless otherwise stated, Zoomtocollege and/or its licensors own the
            intellectual property rights for all material on Zoomtocollege. All
            intellectual property rights are reserved. You may access this from
            Zoomtocollege for your own personal use subjected to restrictions
            set in these terms and conditions.
          </p>

          <p>You must not:</p>
          <ul>
            <li>Republish material from Zoomtocollege</li>
            <li>Sell, rent or sub-license material from Zoomtocollege</li>
            <li>Reproduce, duplicate or copy material from Zoomtocollege</li>
            <li>Redistribute content from Zoomtocollege</li>
          </ul>

          <p>
            Parts of this website offer an opportunity for users to post and
            exchange opinions and information in certain areas of the website.
            Zoomtocollege does not filter, edit, publish or review Comments
            prior to their presence on the website. Comments do not reflect the
            views and opinions of Zoomtocollege, its agents and/or affiliates.
            Comments reflect the views and opinions of the person who post their
            views and opinions. To the extent permitted by applicable laws,
            Zoomtocollege shall not be liable for the Comments or for any
            liability, damages or expenses caused and/or suffered as a result of
            any use of and/or posting of and/or appearance of the Comments on
            this website.
          </p>

          <p>
            Zoomtocollege reserves the right to monitor all Comments and to
            remove any Comments which can be considered inappropriate, offensive
            or causes breach of these Terms and Conditions.
          </p>

          <p>You warrant and represent that:</p>

          <ul>
            <li>
              You are entitled to post the Comments on our website and have all
              necessary licenses and consents to do so;
            </li>
            <li>
              The Comments do not invade any intellectual property right,
              including without limitation copyright, patent or trademark of any
              third party;
            </li>
            <li>
              The Comments do not contain any defamatory, libelous, offensive,
              indecent or otherwise unlawful material which is an invasion of
              privacy
            </li>
            <li>
              The Comments will not be used to solicit or promote business or
              custom or present commercial activities or unlawful activity.
            </li>
          </ul>

          <p>
            You hereby grant Zoomtocollege a non-exclusive license to use,
            reproduce, edit, and authorize others to use, reproduce and edit any
            of your Comments in any and all forms, formats or media.
          </p>

          <h3>
            <strong>Hyperlinking to our Content</strong>
          </h3>

          <p>
            The following organizations may link to our Website without prior
            written approval:
          </p>

          <ul>
            <li>Government agencies;</li>
            <li>Search engines;</li>
            <li>News organizations;</li>
            <li>
              Online directory distributors may link to our Website in the same
              manner as they hyperlink to the Websites of other listed
              businesses; and
            </li>
            <li>
              System wide Accredited Businesses except soliciting non-profit
              organizations, charity shopping malls, and charity fundraising
              groups which may not hyperlink to our Web site.
            </li>
          </ul>

          <p>
            These organizations may link to our home page, to publications or to
            other Website information so long as the link: (a) is not in any way
            deceptive; (b) does not falsely imply sponsorship, endorsement or
            approval of the linking party and its products and/or services; and
            (c) fits within the context of the linking party's site.
          </p>

          <p>
            We may consider and approve other link requests from the following
            types of organizations:
          </p>

          <ul>
            <li>
              commonly-known consumer and/or business information sources;
            </li>
            <li>dot.com community sites;</li>
            <li>associations or other groups representing charities;</li>
            <li>online directory distributors;</li>
            <li>internet portals;</li>
            <li>accounting, law and consulting firms; and</li>
            <li>educational institutions and trade associations.</li>
          </ul>

          <p>
            We will approve link requests from these organizations if we decide
            that: (a) the link would not make us look unfavourably to ourselves
            or to our accredited businesses; (b) the organization does not have
            any negative records with us; (c) the benefit to us from the
            visibility of the hyperlink compensates the absence of Zoomtocollege
            ; and (d) the link is in the context of general resource
            information.
          </p>

          <p>
            These organizations may link to our home page so long as the link:
            (a) is not in any way deceptive; (b) does not falsely imply
            sponsorship, endorsement or approval of the linking party and its
            products or services; and (c) fits within the context of the linking
            party's site.
          </p>

          <p>
            If you are one of the organizations listed in paragraph 2 above and
            are interested in linking to our website, you must inform us by
            sending an e-mail to Zoomtocollege . Please include your name, your
            organization name, contact information as well as the URL of your
            site, a list of any URLs from which you intend to link to our
            website, and a list of the URLs on our site to which you would like
            to link. Wait 2-3 weeks for a response.
          </p>

          <p>Approved organizations may hyperlink to our Website as follows:</p>

          <ul>
            <li>By use of our corporate name; or</li>
            <li>By use of the uniform resource locator being linked to; or</li>
            <li>
              By use of any other description of our Website being linked to
              that makes sense within the context and format of content on the
              linking party's site.
            </li>
          </ul>

          <p>
            No use of Zoomtocollege 's logo or other artwork will be allowed for
            linking absent a trademark license agreement.
          </p>

          <h3>
            <strong>iFrames</strong>
          </h3>

          <p>
            Without prior approval and written permission, you may not create
            frames around our Webpages that alter in any way the visual
            presentation or appearance of our website.
          </p>

          <h3>
            <strong>Content Liability</strong>
          </h3>

          <p>
            We shall not be hold responsible for any content that appears on
            your Website. You agree to protect and defend us against all claims
            that is rising on your Website. No link(s) should appear on any
            Website that may be interpreted as libellous, obscene or criminal,
            or which infringes, otherwise violates, or advocates the
            infringement or other violation of, any third-party rights.
          </p>

          <h3>
            <strong>Reservation of Rights</strong>
          </h3>

          <p>
            We reserve the right to request that you remove all links or any
            link to our website. You approve to immediately remove all links to
            our Website upon request. We also reserve the right to amen these
            terms and conditions and it's linking policy at any time. By
            continuously linking to our website, you agree to be bound to and
            follow these linking terms and conditions.
          </p>

          <h3>
            <strong>Removal of links from our website</strong>
          </h3>

          <p>
            If you find any link on our Website that is offensive for any
            reason, you are free to contact and inform us any moment. We will
            consider requests to remove links but we are not obligated to or so
            or to respond to you directly.
          </p>

          <p>
            We do not ensure that the information on this website is correct, we
            do not warrant its completeness or accuracy; nor do we promise to
            ensure that the website remains available or that the material on
            the website is kept up to date.
          </p>

          <p>
            Any rights not expressly granted in these terms, or any document
            referenced in them, are reserved to Zoomtocollege.
          </p>

          <h3>
            <strong>Disclaimer</strong>
          </h3>

          <p>
            Zoomtocollege has taken care to ensure that the information on the
            website is correct. However, no warranty, express or implied, is
            given as to its accuracy and Zoomtocollege does not accept any
            liability for error or omission. Zoomtocollege is not responsible
            for how the information is used, how it is interpreted or what
            reliance is placed on it. We do not guarantee that the information
            on the website is fit for any particular purpose. You should also be
            aware that the availability of courses of study is subject to
            conditions applied by individual institutions at their sole
            discretion.
          </p>

          <p>
            The content of the website is for general information only and does
            not constitute any form of advice or recommendation upon which a
            specific decision should be made. Zoomtocollege has taken steps to
            ensure the materials contained on its website and any
            recommendations are current and accurate, but to the fullest extent
            permitted by law Zoomtocollege hereby excludes any warranty, whether
            express or implied, by statute at common law or otherwise, relating
            to the website and the material contained on the website and
            recommendations. In particular (but without limitation of the
            above), Zoomtocollege gives no warranties as to quality, accuracy,
            timeliness, completeness, or fitness for a particular purpose of the
            website or of the material contained in it.
          </p>

          <p>
            To the fullest extent permitted by law, Zoomtocollege shall not be
            liable for any claims, costs, penalties, loss (whether direct,
            indirect, or consequential and whether economic or special loss of
            any nature), damages or expenses arising from the use or from the
            inability to use the website. Zoomtocollege does not accept
            liability from reliance on information or recommendations or from
            any unauthorised access or alteration to the website by a third
            party.
          </p>

          <p>
            Zoomtocollege shall not be liable for the behaviour and actions or
            any claims, costs, penalties, loss (whether direct, indirect or
            consequential and whether economic or special loss of any nature),
            damages or expenses arising from the institutions, universities or
            third parties.
          </p>

          <p>
            The website includes links to external websites. These links are
            provided to help you find additional information quickly and easily.
            Zoomtocollege accepts no responsibility for the content of these
            websites and specifically excludes (to the fullest extent permitted
            by law) all liability that may arise with respect to or as a result
            of such material causing damage, costs, injury or financial loss of
            any kind. Zoomtocollege cannot be responsible for the protection and
            privacy of any information that you provide whilst visiting these
            websites.
          </p>

          <p>
            Zoomtocollege does not endorse or accept any liability for any of
            the products or services so advertised, or for any error or
            inaccuracy in the advertisements. Advertisers and sponsors are
            responsible for ensuring that material submitted for inclusion on
            the website complies with all relevant laws.
          </p>

          <h3>
            <strong>General</strong>
          </h3>

          <p>
            These terms shall be construed and interpreted in accordance with
            English law. The courts of England shall have exclusive jurisdiction
            in relation to any claim, dispute or other matters arising there
            from. For the exclusive benefit of Zoomtocollege, we reserve the
            right to bring proceedings in the Courts of the country of residence
            of any user of the website.
          </p>

          <p>
            If any provision of these terms and conditions is found to be
            invalid by any Court having competent jurisdiction, the invalidity
            of that provision will not affect the validity of the remaining
            provisions of these terms and conditions which shall continue to
            have full force and effect. The failure by us to exercise any right
            or remedy under these terms and conditions shall not constitute a
            waiver of that right or remedy.
          </p>
        </div>
      </UserNavbar>
    </div>
  );
};

export default TermsAndconditions;
