import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import Masonry from "react-masonry-css";
import { Card } from "react-bootstrap";
import "./HomePageInfoForm.css";

import { useGetCountries } from "../../../hooks/useGetCountries";
import { useGetHomeInfo } from "../../../hooks/useGetHomeInfo";

import UserNavbar from "../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../reusables/PageTitle/PageTitle";
import HomeInfoFormModal from "./HomePageInfoModal/HomeInfoFormModal";
import Loader from "../../reusables/Loader/Loader";
import ErrorPage from "../../reusables/ErrorPage/ErrorPage";

import Notification, {
  generateRandomString,
  removeElementFromArray,
  replaceLocalhostUrl,
} from "../../../utils/helpers";
import { API_HEADERS } from "../../../utils/constant";

const HomePageInfoForm = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [formType, setFormType] = useState("");
  const [selectedData, setselectedData] = useState();
  const [titleVideo, setTitleVideo] = useState();
  const [applyStepVideo, setapplyStepVideo] = useState();
  const [webCover, setWebCover] = useState(null);

  const [homeInfo, isLoading, error] = useGetHomeInfo(show);
  const [countryList, countryListLoading, countryListError] = useGetCountries();

  const [applysteps, setapplysteps] = useState([
    {
      id: generateRandomString(5),
      step: "",
    },
  ]);

  const handleClose = () => setShow(false);
  const handleShow = (type, data) => {
    setselectedData(data);
    setFormType(type);
    setShow(true);
  };

  const handleStepValueEntering = (id, step) => {
    const updatedapplystepsInput = applysteps.map((item) => {
      if (item.id === id) {
        return { ...item, step };
      }
      return item;
    });
    setapplysteps(updatedapplystepsInput);
  };

  const handleSaveTitleVideo = () => {
    const titleVideoFormData = new FormData();
    titleVideoFormData.append("_id", homeInfo._id);
    titleVideoFormData.append("titleVideo", titleVideo);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/homeInfo/UpdateTitleVideo`,
        titleVideoFormData,
        API_HEADERS()
      )
      .then((response) => {
        Notification.successNotification("Title video updated Successfully");
      })
      .catch((err) => console.log(err));
  };

  const handleSaveApplySteps = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/homeInfo/updateApplyStep`,
        {
          _id: homeInfo._id,
          applysteps,
        },
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Apply steps updated Successfully");
      })
      .catch((err) => console.log(err));
  };

  const handleWebCoverPhoto = () => {
    const webCoverFormData = new FormData();
    webCoverFormData.append("infoId", homeInfo._id);
    webCoverFormData.append("websiteCover", webCover);

    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/homeInfo/updateWebCover`,
        webCoverFormData,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification(
          "Website Cover Photo Updated Successfully"
        );
      })
      .catch((err) => console.log(err));
  };

  const handleSaveApplyStepVideo = () => {
    const applyStepVideoFormData = new FormData();
    applyStepVideoFormData.append("_id", homeInfo._id);
    applyStepVideoFormData.append("applyStepVideo", applyStepVideo);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/homeInfo/uapdateApplyStepVideo`,
        applyStepVideoFormData,
        API_HEADERS()
      )
      .then((response) => {
        Notification.successNotification(
          "Apply Step Video updated Successfully"
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setapplysteps(homeInfo.applysteps);
  }, [homeInfo]);

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;
  if (countryListLoading) return <Loader />;
  if (countryListError) return <ErrorPage />;
  return (
    <div>
      <PageTitle title="COUNTRIES" />
      <div className="home_info_country_list_container">
        <br />
        <div className="submit_button_holder">
          <button
            className="add_new_univercity_btn"
            onClick={() => navigate("/superAdmin/addCountryForm")}
          >
            Add Country
          </button>
        </div>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {countryList.map((country, index) => (
            <div
              key={index}
              className="my-masonry-grid_item"
              onClick={() =>
                navigate(`/superAdmin/updateCountry/${country._id}`)
              }
            >
              <Card>
                <Card.Img
                  src={`${replaceLocalhostUrl(
                    country.countryCoverImage
                  )}?w=248&fit=crop&auto=format`}
                  srcSet={`${replaceLocalhostUrl(
                    country.countryCoverImage
                  )}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={country.countryName}
                  loading="lazy"
                  className="masonry-grid-image"
                />
              </Card>
              <p className="masory_country_name"> {country?.countryName}</p>
            </div>
          ))}
        </Masonry>
      </div>
      <br />
      <PageTitle title={"WEBSITE COVER IMAGE"} />
      <div className="webcover_holder">
        <label htmlFor="webCover" className="webcover_holder">
          {webCover !== null ? (
            <img src={URL.createObjectURL(webCover)} className="web_cover" />
          ) : (
            <img
              src={replaceLocalhostUrl(homeInfo?.websiteCover)}
              className="web_cover"
            />
          )}
        </label>
        <input
          id="webCover"
          className="actual_documetn_upload_input_field"
          type="file"
          onChange={(e) => setWebCover(e.target.files[0])}
        />
        <br />
        <button
          onClick={handleWebCoverPhoto}
          className="home_info_title_video_btn"
        >
          Save Cover Image
        </button>
      </div>
      <br />
      <br />
      <div>
        <div>
          <br />
          <PageTitle title="WHY APPLY" />
          <br />
          <div className="home_page_why_apply_container">
            {homeInfo?.whyApply?.map((card) => (
              <div
                className="home_page_why_apply_card"
                onClick={() => handleShow("whyApply", card)}
              >
                <img
                  src={replaceLocalhostUrl(card.whyApplyCardCoverImage)}
                  className="home_page_why_apply_card_img"
                  alt="why_apply_card"
                />
                <p className="home_page_why_apply_card_title">
                  {card?.title.length < 30
                    ? card.title
                    : ` ${card.title.slice(0, 30)}...`}
                </p>
                <p className="home_page_why_apply_card_desc">
                  {card?.description.length < 80
                    ? card.description
                    : ` ${card.description.slice(0, 80)}...`}
                </p>
              </div>
            ))}
          </div>
          <br />
          <div className="home_info_title_video_container">
            <br />
            <PageTitle title="TITLE VIDEO" />
            <br />
            <div>
              <label className="home_info_title_video_label">
                {titleVideo ? (
                  <video
                    // src={titleVideo}
                    src={titleVideo && URL.createObjectURL(titleVideo)}
                    className="title_video_style"
                    controls
                  />
                ) : (
                  <video
                    src={
                      homeInfo.titleVideo
                        ? replaceLocalhostUrl(homeInfo.titleVideo)
                        : "/images/superAdmin/upload_placeholder.png"
                    }
                    className="title_video_style"
                    controls
                  />
                )}
              </label>
              <input
                type="file"
                className="actual_documetn_upload_input_field"
                id="titleVideo"
                name="titleVideo"
                onChange={(e) => setTitleVideo(e.target.files[0])}
              />
              <div className="home_info_title_video_btn_container">
                <label
                  htmlFor="titleVideo"
                  className="home_info_title_video_btn"
                >
                  Change Video
                </label>
                <button
                  className="home_info_title_video_btn"
                  onClick={handleSaveTitleVideo}
                >
                  Save Video
                </button>
              </div>
            </div>
          </div>
          <br />
          <PageTitle title="HOW TO APPLY" />
          <br />
          <div className="home_info_how_to_apply_container">
            <div className="home_info_staper_container">
              <h5>HOW TO APPLY?</h5>
              <p>
                Zoomtocollege is always available to help you - feel free to
                contact us at any time. Here’s how you can apply:
              </p>
              <div>
                <div className="agency_field_holder">
                  <label className="agency_label_style">
                    Steps to apply
                    <AiOutlinePlus
                      className="add_service_icon_style"
                      onClick={() =>
                        setapplysteps([
                          ...applysteps,
                          {
                            id: generateRandomString(5),
                            step: "",
                          },
                        ])
                      }
                    />
                  </label>
                  {applysteps &&
                    applysteps.map((step) => (
                      <div className="multiple_field_holder">
                        <textarea
                          type="text"
                          className="agency_input_field_style_multiple_input"
                          name="step"
                          value={step.step}
                          onChange={(e) =>
                            handleStepValueEntering(step.id, e.target.value)
                          }
                        />
                        <AiOutlineMinus
                          className="minus_icon_style"
                          onClick={() => {
                            setapplysteps(
                              removeElementFromArray(applysteps, step.id)
                            );
                          }}
                        />
                      </div>
                    ))}
                </div>
              </div>
              <button
                className="home_info_title_video_btn"
                onClick={handleSaveApplySteps}
              >
                Save Changes
              </button>
            </div>
            <div className="home_info_video_container">
              <div>
                <label className="home_info_title_video_label">
                  {applyStepVideo ? (
                    <video
                      // src={applyStepVideo}
                      src={
                        applyStepVideo && URL.createObjectURL(applyStepVideo)
                      }
                      className="title_video_style"
                      controls
                    />
                  ) : (
                    <video
                      src={
                        homeInfo.applyStepVideo
                          ? replaceLocalhostUrl(homeInfo.applyStepVideo)
                          : "/images/superAdmin/upload_placeholder.png"
                      }
                      className="title_video_style"
                      controls
                    />
                  )}
                </label>
                <input
                  type="file"
                  className="actual_documetn_upload_input_field"
                  id="applyStepVideo"
                  name="applyStepVideo"
                  onChange={(e) => setapplyStepVideo(e.target.files[0])}
                />
                <div className="home_info_title_video_btn_container">
                  <label
                    htmlFor="applyStepVideo"
                    className="home_info_title_video_btn"
                  >
                    Change Video
                  </label>
                  <button
                    className="home_info_title_video_btn"
                    onClick={handleSaveApplyStepVideo}
                  >
                    Save Video
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {show && (
        <HomeInfoFormModal
          show={show}
          handleClose={handleClose}
          formType={formType}
          selectedData={selectedData}
        />
      )}
    </div>
  );
};

export default HomePageInfoForm;
