import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TfiBrushAlt } from "react-icons/tfi";
import { DateRange } from "react-date-range";
import "./SuperAdminCourseList.css";

import { useGetAllCourse } from "../../../../hooks/useGetAllCourse";

import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import TableComponent from "../../../reusables/TableComponent/TableComponent";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import { handleStateChange, searchData } from "../../../../utils/helpers";
import {
  countries,
  courseListTableCols,
  courseTableFields,
} from "../../../../utils/constant";

const SuperAdminCourseList = () => {
  const [course, isLoading, error] = useGetAllCourse();

  const [filterData, setFilterData] = useState(course);

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [filterContent, setFilterContent] = useState({
    startDate: "",
    endDate: "",
    courseName: "",
    courseCountry: "",
  });

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const clearSearch = () => {
    setFilterContent({
      startDate: "",
      endDate: "",
      courseName: "",
      courseCountry: "",
    });
    setFilterData(course);
  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (ranges) => {
    setFilterContent((prev) => ({
      ...prev,
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    }));
    onToggle();
  };

  useEffect(() => {
    setFilterData(searchData(filterContent, course));
  }, [filterContent]);

  useEffect(() => {
    setFilterData(course);
  }, [course]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;
  return (
    <div>
      <UserNavbar>
        <PageTitle title={"COURSES"} />
        <div className="course_list_holder_style">
          <div className="action_button_holder">
            <div className="date_range_keeper">
              <input
                className="applciation_input_field_style date_show_input"
                value={
                  filterContent.endDate &&
                  filterContent.startDate &&
                  `${new Date(
                    filterContent.startDate
                  ).toLocaleDateString()} - ${new Date(
                    filterContent.endDate
                  ).toLocaleDateString()}`
                }
                placeholder="Select dates"
                onClick={onToggle}
              />
              {isOpen && (
                <div className="date_picker_holder">
                  <DateRange
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    showSelectionPreview={true}
                  />
                </div>
              )}
            </div>
            <select
              className="applciation_input_field_style"
              name="courseCountry"
              onChange={(e) => handleStateChange(e, setFilterContent)}
            >
              {countries.map((country) => (
                <option>{country.name}</option>
              ))}
            </select>
            <input
              type="text"
              className="applciation_input_field_style"
              placeholder="Search Courses by name"
              name="courseName"
              onChange={(e) => handleStateChange(e, setFilterContent)}
              value={filterContent.universityName}
            />

            <button
              className="add_university_navigation_btn"
              onClick={clearSearch}
            >
              <TfiBrushAlt className="clear_icon_style" />
            </button>
            <button
              className="add_university_navigation_btn"
              onClick={() => navigate("/superAdmin/course/addCourse")}
            >
              Add New Course
            </button>
          </div>
          <TableComponent
            tableColumns={courseListTableCols}
            data={filterData}
            fieldsToShowList={courseTableFields}
            navigateTo="/superAdmin/course/view"
            emptyFor="superAdminCourseList"
          />
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminCourseList;
