import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { ToastContainer } from "react-toastify";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage from "./component/GeneralAuth/HomePage/HomePage";
import PageNotFound from "./component/reusables/PageNotFound/PageNotFound";

import SuperAdminRoutes from "./component/router/SuperAdminRoutes";
import AgencyRoutes from "./component/router/AgencyRoutes";
import UniversityRoutes from "./component/router/UniversityRoutes";
import { StudentRoutes } from "./component/router/StudentRoutes";

import GeneralLogin from "./component/GeneralAuth/GeneralLogin/GeneralLogin";
import AuthProvider from "./component/reusables/AuthProvider/AuthProvider";

import Course from "./component/Student/CourseView/Course";

import GeneralBlogList from "./component/GeneralAuth/GeneralBlogList/GeneralBlogList";
import BlogDetailView from "./component/GeneralAuth/BlogDetailView/BlogDetailView";

import PrivacyPolicy from "./component/reusables/PrivacyPolicy/PrivacyPolicy";
import TermsAndconditions from "./component/reusables/TermsAndConditions/TermsAndconditions";
import AboutUs from "./component/GeneralAuth/AboutUsArea";
import ContactUsContainer from "./component/GeneralAuth/ContactUsArea/ContactUsContainer";
import ForgotPassword from "./component/GeneralAuth/ForgotPassword/ForgotPassword";
import Resetpassword from "./component/GeneralAuth/ResetPassword/Resetpassword";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<PageNotFound />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route
              path="/termsAndConditions"
              element={<TermsAndconditions />}
            />
            <Route path="/blog" element={<GeneralBlogList />} />
            <Route path="/blog/view/:blogId" element={<BlogDetailView />} />
            <Route path="/login" element={<GeneralLogin />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/resetpassword/:token" element={<Resetpassword />} />
            <Route path="/publicCourseList" element={<Course />} />
            <Route path="/superAdmin/*" element={<SuperAdminRoutes />} />
            <Route path="/student/*" element={<StudentRoutes />} />
            <Route path="/agency/*" element={<AgencyRoutes />} />
            <Route path="/university/*" element={<UniversityRoutes />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/contactUs" element={<ContactUsContainer />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default App;
