import React, { useState } from "react";
import axios from "axios";
import "./ComposeEmail.css";

import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";

import Notification, { handleStateChange } from "../../../../utils/helpers";
import { API_HEADERS } from "../../../../utils/constant";

const ComposeEmail = () => {
  const [emailContent, setEmailContent] = useState({
    subject: "",
    content: "",
  });

  const handleSentEmailToAll = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/subscriber/sendMailToallSubscribers`,
        emailContent,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Mail sent to all subscriber.");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"COMPOSE EMAIL"} />
        <br />
        <br />
        <div className="compose_email_section">
          <div className="university_label_field_holder">
            <label className="university_label_style">Email Subject</label>
            <input
              type="text"
              className="full_university_input_field_style"
              name="subject"
              onChange={(e) => handleStateChange(e, setEmailContent)}
            />
          </div>
          <div className="university_label_field_holder">
            <label className="university_label_style">Email Content</label>
            <textarea
              type="text"
              className="full_university_input_field_style"
              name="content"
              onChange={(e) => handleStateChange(e, setEmailContent)}
              rows={5}
            />
          </div>
          <div className="new_email_btn_holder">
            <button
              className="add_agent_btn_style"
              onClick={handleSentEmailToAll}
            >
              Send mail to all Subscribers
            </button>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default ComposeEmail;
