import React, { useContext, useRef, useState } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toPng } from "html-to-image";
import jsPDF from "jspdf";
import { AiFillCamera } from "react-icons/ai";
import { BsCheckSquareFill, BsXSquareFill } from "react-icons/bs";

import { useGetApplicationById } from "../../../../hooks/useGetApplicationById";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import CustomVericalInputField from "../../../reusables/FromComponent/CustomVericalInputField";
import CustomButton from "../../../reusables/FromComponent/CustomButton";

import Notification, { replaceLocalhostUrl } from "../../../../utils/helpers";
import { API_HEADERS } from "../../../../utils/constant";

const ApplicationDetailView = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const { applicationId } = useParams();
  const [application, isLoading, error] = useGetApplicationById(applicationId);

  const targetRef = useRef();

  const [comment, setComment] = useState("");
  const [show, setShow] = useState(false);
  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  const downloadImageAsPdf = async () => {
    const divElement = document.getElementById("capture_area");

    toPng(divElement, { backgroundColor: "#ffffff" })
      .then((dataUrl) => {
        const pdf = new jsPDF();
        const img = new Image();

        img.onload = () => {
          const imgWidth = pdf.internal.pageSize.getWidth() - 30;
          const imgHeight = (img.height * imgWidth) / img.width - 40;

          const x = (pdf.internal.pageSize.getWidth() - imgWidth) / 2;
          const y = (pdf.internal.pageSize.getHeight() - imgHeight) / 2;

          pdf.addImage(img, "PNG", x, y, imgWidth, imgHeight);
          pdf.save(`${applicationId} - Application`);
        };

        img.src = dataUrl;
      })
      .catch((error) => {
        console.error("Error capturing div as PDF:", error);
      });
  };

  const handleAcceptApplication = () => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/application/reviewApplication`,
        {
          applicationId: applicationId,
          reviewedBy:
            user?._id ||
            JSON.parse(localStorage.getItem("currentLoginuser"))?._id,
          applicationFormStatus: "accepted",
          reviewedAt: new Date(),
        },
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Application Accepted");
        setTimeout(() => navigate("/university/application"), 2000);
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  const handleRejectApplication = () => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/application/reviewApplication`,
        {
          applicationId: applicationId,
          reviewedBy:
            user?._id ||
            JSON.parse(localStorage.getItem("currentLoginuser"))?._id,
          applicationFormStatus: "rejected",
          reviewedAt: new Date(),
          comment: comment,
        },
        API_HEADERS()
      )
      .then(() => {
        Notification.warningNotification("Application Rejected");
        setTimeout(() => navigate("/university/application"), 2000);
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;
  return (
    <div>
      <UserNavbar>
        <PageTitle title="APPLICATION VIEW" />
        <div className="create_application_component_container" ref={targetRef}>
          <div className="add_agent_btn_holder">
            {application?.applicationFormStatus === "accepted" ? (
              <p className="accepted_status_text">Accepted</p>
            ) : application?.applicationFormStatus === "rejected" ? (
              <p className="rejected_status_text">
                Rejected :{" "}
                <span className="comment_text_style">
                  {application?.comment}
                </span>
              </p>
            ) : (
              <div>
                {application?.superAdminStatus === "submittedToUniveristy" ? (
                  <div className="decesion_making_btn_holder">
                    <CustomButton
                      buttonLabel="Accept Application"
                      onClick={handleAcceptApplication}
                    />

                    <CustomButton
                      buttonLabel="Reject Application"
                      onClick={handleOpen}
                      secondary
                    />
                  </div>
                ) : (
                  <p className="pending_status_text">
                    Super admin have not Approved this Application Yet.
                  </p>
                )}
              </div>
            )}
          </div>

          <div id="capture_area" style={{ width: "100%", height: "100%" }}>
            <div className="application_detail_component">
              <div className="profile_photo_holder">
                <label htmlFor="profile_photo_upload">
                  {application?.userProfilePicture ? (
                    <img
                      src={replaceLocalhostUrl(application?.userProfilePicture)}
                      className="profile_image_style"
                      alt="profile_image"
                    />
                  ) : (
                    <img
                      src="/images/superAdmin/upload_placeholder.png"
                      className="profile_image_style"
                      alt="profile_image"
                    />
                  )}
                  <AiFillCamera className="upload_profile_photo_btn" />
                </label>
                <input
                  name="userProfilePicture"
                  type="text"
                  className="upload_document_actual_field"
                  id="profile_photo_upload"
                />
              </div>
              <div className="filed_container">
                <div className="field_set_1">
                  <CustomVericalInputField
                    name="firstName"
                    label="Application First Name"
                    value={application?.firstName}
                    readOnly
                  />

                  <CustomVericalInputField
                    name="applicationNo"
                    label="Application No"
                    value={application?.applicationNo}
                    readOnly
                  />
                </div>
                <div className="field_set_2">
                  <CustomVericalInputField
                    name="lastName"
                    label="Application Last Name"
                    value={application?.lastName}
                    readOnly
                  />

                  <CustomVericalInputField
                    name="applicationFormStatus"
                    label="Application Status"
                    value={application?.applicationFormStatus}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="agency_detail_container_add_application row">
              <CustomVericalInputField
                name="universityId"
                label="University Name"
                value={application?.universityId?.universityName}
                readOnly
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="applicationDate"
                label="Application Date"
                type="date"
                value={application?.applicationDate}
                readOnly
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="courseId"
                label="Course Name"
                value={application?.courseId?.courseName}
                readOnly
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="courseLevel"
                label="Course Level"
                value={
                  application?.courseId
                    ? `${application.courseId.courseLevel
                        .charAt(0)
                        .toUpperCase()}${application.courseId.courseLevel.slice(
                        1
                      )}`
                    : null
                }
                readOnly
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="courseFee"
                label="Course Fee"
                type="number"
                value={application?.courseId?.courseFee}
                readOnly
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="placeOfBirth"
                label="Place of Birth"
                value={application?.placeOfBirth}
                readOnly
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="countryOfBirth"
                label="Country of Birth"
                value={application?.countryOfBirth}
                readOnly
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="nationality"
                label="Nationality"
                value={application?.nationality}
                readOnly
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="secondCitizenship"
                label="Second Citizenship"
                value={application?.secondCitizenship}
                readOnly
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="countryOfResidence"
                label="Country of Residence"
                value={application?.countryOfResidence}
                readOnly
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="address"
                label="Address"
                value={application?.address}
                readOnly
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="postcode"
                label="Postcode"
                value={application?.postcode}
                readOnly
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="cityOfResidence"
                label="City of Residence"
                value={application?.cityOfResidence}
                readOnly
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="gender"
                label="Gender"
                value={application?.gender}
                readOnly
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="firstLanguage"
                label="First Language"
                value={application?.firstLanguage}
                readOnly
                colClass="col-12 col-md-4"
              />
            </div>

            <div className="education_detail_container row">
              <CustomVericalInputField
                name="highestEducationLevel"
                label="Highest Education Level"
                value={application?.highestEducationLevel}
                readOnly
                colClass="col-12 col-md-4"
                toolTip="We use this information to match you to the most suitable courses"
              />

              <CustomVericalInputField
                name="academicInstitution"
                label="Name of Academic Institution"
                value={application?.academicInstitution}
                readOnly
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="languageOfInstruction"
                label="Language of Instruction"
                value={application?.languageOfInstruction}
                readOnly
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="countryOfEducation"
                label="Country of Education"
                value={application?.countryOfEducation}
                readOnly
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="averageGrade"
                label="Average Grade"
                value={application?.averageGrade}
                readOnly
                colClass="col-12 col-md-4"
              />
            </div>
            <div className="application_detail_component">
              <div className="row">
                {application?.courseId?.basicDoumentRequirement?.map(
                  (document) =>
                    document.type !== "userAdded" && (
                      <div className="col-12 col-lg-4 col-md-4 col-sm-6 single_document_check_holder">
                        {application[document.id] !== "" &&
                        application[document.id] !== undefined ? (
                          <BsCheckSquareFill className="document_icon_style_check" />
                        ) : (
                          <BsXSquareFill className="document_icon_style_uncheck" />
                        )}
                        <a
                          className="document_heading_txt_style"
                          href={replaceLocalhostUrl(application[document.id])}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {document?.documentName}
                        </a>
                      </div>
                    )
                )}
                {application?.other.map(
                  (docLink, index) =>
                    docLink !== "" && (
                      <div className="col-12 col-lg-4 col-md-4 col-sm-6 single_document_check_holder">
                        <BsCheckSquareFill className="document_icon_style_check" />
                        <a
                          href={replaceLocalhostUrl(docLink)}
                          target="_blank"
                          className="document_heading_txt_style"
                        >
                          Document - {index + 1}
                        </a>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
          <div className="download_pdf_button_holder">
            <CustomButton
              buttonLabel="Download As PDF"
              onClick={downloadImageAsPdf}
            />
          </div>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Application Rejection</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>Please submit reason for rejection</h5>
              <textarea
                className="rejection_textarea_style"
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="danger" onClick={handleRejectApplication}>
                Reject Application
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </UserNavbar>
    </div>
  );
};

export default ApplicationDetailView;
