import React from "react";
import { MdOutlineMailOutline } from "react-icons/md";

const EmailInfoBlock = () => {
  return (
    <div className="email_data_holder">
      <p className="email_reacg_out_heading">REACH OUT</p>
      <p className="email_heading_style">Email us</p>
      <p className="email_desc_style">
        Reach out by email and we will get back to you as soon as possible
      </p>
      <div className="icon_email_holder">
        <MdOutlineMailOutline className="contact_us_icons_style" />{" "}
        <p className="email_text_style">support@zoomtocollege.com</p>
      </div>
      <div className="email_format_holder">
        <p className="email_desc_style">
          Please use following format for email:
        </p>
        <p className="email_format_style">Name: Alex Carry</p>
        <p className="email_format_style">Phone Number: XX: 0000000</p>
        <p className="email_format_style">Message: Write your query here...</p>
      </div>
    </div>
  );
};

export default EmailInfoBlock;
