import React, { useState } from "react";
import "./ContentUpdateController.css";

import UserNavbar from "../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../reusables/PageTitle/PageTitle";

import AboutUsPageInfoControl from "./AboutUsPageInfoControl/AboutUsPageInfoControl";
import HomePageInfoForm from "../HomePageInfoControl/HomePageInfoForm";

import { classNames } from "../../../utils/helpers";
import { ContentUpdateTabs } from "../../../utils/constant";

const ContentUpdateController = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const handleChangeTab = async (data) => {
    setActiveTab(data);
  };

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"WEBSITE CONTENT UPDATE"} />
        <div className="content_update_tab_holder">
          {ContentUpdateTabs.map((tab) => (
            <button
              className={classNames(
                tab.tab === activeTab && "selected_content_update_btn_style",
                "content_update_button_style"
              )}
              onClick={() => handleChangeTab(tab.tab)}
            >
              {tab.name}
            </button>
          ))}
        </div>
        <br />
        <br />

        {activeTab === "tab1" ? (
          <HomePageInfoForm />
        ) : activeTab == "tab2" ? (
          <AboutUsPageInfoControl />
        ) : null}
      </UserNavbar>
    </div>
  );
};

export default ContentUpdateController;
