import React, { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./SuperAdminAddUniversity.css";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";

import CustomVericalInputField from "../../../reusables/FromComponent/CustomVericalInputField";
import CustomVerticalSelectField from "../../../reusables/FromComponent/CustomVerticalSelectField";

import Notification, {
  findEmptyFields,
  handleFileInputInState,
  handleStateChange,
} from "../../../../utils/helpers";
import {
  API_HEADERS,
  UniversityUserRolesSelect,
  newCountryList,
  universityAddDefaultStateObj,
} from "../../../../utils/constant";

const SuperAdminAddUniversity = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [newUniversityData, setNewuniversityData] = useState({
    addedBy:
      user?._id || JSON.parse(localStorage.getItem("currentLoginUser"))._id,
    isVerified: true,
    ...universityAddDefaultStateObj,
  });

  const handleAddNewUniversity = async () => {
    if (findEmptyFields(newUniversityData).length > 0) {
      Notification.errorNotification(
        `Please add ${findEmptyFields(newUniversityData)[0]}`
      );
    } else if (
      newUniversityData.password !== newUniversityData.confirmPassword
    ) {
      Notification.errorNotification(
        "Please confirm your password correctly..!"
      );
    } else {
      const universityData = new FormData();

      Object.keys(newUniversityData).forEach((key) => {
        if (["subImage1", "subImage2", "subImage3"].includes(key)) {
          universityData.append("subImages", newUniversityData[key]);
        } else {
          universityData.append(key, newUniversityData[key]);
        }
      });

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/auth/signup`,
          universityData,
          API_HEADERS()
        )
        .then(() => {
          Notification.successNotification("New admin added Successfully");
          navigate("/superAdmin/university");
        })
        .catch((err) => {
          Notification.errorNotification(err.response.data.message);
        });
    }
  };

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"ADD UNIVERSITY "} />
        <div className="add_university_holder">
          <div className="input_container">
            <div className="bacsic_detail_holder">
              <div className="basic_university_detail_holder">
                <CustomVericalInputField
                  name="universityName"
                  label="University Name"
                  value={newUniversityData?.universityName}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                />

                <CustomVericalInputField
                  name="universityRegistrationNumber"
                  label="Registration Number"
                  value={newUniversityData?.universityRegistrationNumber}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                />

                <CustomVericalInputField
                  name="universityTaxNumber"
                  label="Tax Number"
                  value={newUniversityData?.universityTaxNumber}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                />

                <CustomVericalInputField
                  name="typeOfInstitution"
                  label="Type of Institution"
                  value={newUniversityData?.typeOfInstitution}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                />
              </div>
              <div className="university_image_container">
                <label
                  className="university_cover_image"
                  htmlFor="universityCoverImage"
                >
                  {newUniversityData?.universityCoverImage ? (
                    <img
                      src={URL.createObjectURL(
                        newUniversityData?.universityCoverImage
                      )}
                      className="cover_img_style"
                      alt="university_cover_image"
                    />
                  ) : (
                    <img
                      src="/images/superAdmin/upload_placeholder.png"
                      className="upload_placeholder_img_style"
                      alt="university_cover_image"
                    />
                  )}
                </label>
                <input
                  type="file"
                  className="actual_documetn_upload_input_field"
                  id="universityCoverImage"
                  name="universityCoverImage"
                  onChange={(e) =>
                    handleFileInputInState(e, setNewuniversityData)
                  }
                />

                <div className="sub_images_container">
                  <label className="sub_images_class" htmlFor="subImage1">
                    {newUniversityData?.subImage1 ? (
                      <img
                        src={URL.createObjectURL(newUniversityData?.subImage1)}
                        className="side_image_style"
                        alt="sub_image"
                      />
                    ) : (
                      <img
                        src="/images/superAdmin/upload_placeholder.png"
                        className="subimage_upload_placeholder_img_style"
                        alt="sub_image"
                      />
                    )}
                  </label>
                  <input
                    type="file"
                    className="actual_documetn_upload_input_field"
                    id="subImage1"
                    name="subImage1"
                    onChange={(e) =>
                      handleFileInputInState(e, setNewuniversityData)
                    }
                  />
                  <label className="sub_images_class" htmlFor="subImage2">
                    {newUniversityData?.subImage2 ? (
                      <img
                        src={URL.createObjectURL(newUniversityData?.subImage2)}
                        className="side_image_style"
                        alt="sub_image"
                      />
                    ) : (
                      <img
                        src="/images/superAdmin/upload_placeholder.png"
                        className="subimage_upload_placeholder_img_style"
                        alt="sub_image"
                      />
                    )}
                  </label>
                  <input
                    type="file"
                    className="actual_documetn_upload_input_field"
                    id="subImage2"
                    name="subImage2"
                    onChange={(e) =>
                      handleFileInputInState(e, setNewuniversityData)
                    }
                  />
                  <label className="sub_images_class" htmlFor="subImage3">
                    {newUniversityData?.subImage3 ? (
                      <img
                        src={URL.createObjectURL(newUniversityData?.subImage3)}
                        className="side_image_style"
                        alt="sub_image"
                      />
                    ) : (
                      <img
                        src="/images/superAdmin/upload_placeholder.png"
                        className="subimage_upload_placeholder_img_style"
                        alt="sub_image"
                      />
                    )}
                  </label>
                  <input
                    type="file"
                    className="actual_documetn_upload_input_field"
                    id="subImage3"
                    name="subImage3"
                    onChange={(e) =>
                      handleFileInputInState(e, setNewuniversityData)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="university_label_field_holder">
              <label className="university_label_style">About University</label>
              <textarea
                type="text"
                className="full_university_input_field_style"
                name="universityDescription"
                onChange={(e) => handleStateChange(e, setNewuniversityData)}
              />
            </div>
          </div>
          <div className="input_container">
            <div className="container">
              <div className="row">
                <CustomVerticalSelectField
                  name="universityCountry"
                  label="University Country"
                  value={newUniversityData?.universityCountry}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                  options={newCountryList}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="universityCity"
                  label="University City"
                  value={newUniversityData?.universityCity}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="universityAddress"
                  label="University Address"
                  value={newUniversityData?.universityAddress}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="universityPostalcode"
                  label="Postalcode"
                  value={newUniversityData?.universityPostalcode}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                  colClass="col-12 col-lg-6"
                />
              </div>
            </div>
          </div>
          <div className="input_container">
            <div className="container">
              <div className="row">
                <CustomVericalInputField
                  name="firstName"
                  label="First Name"
                  value={newUniversityData?.firstName}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="lastName"
                  label="Last Name"
                  value={newUniversityData?.lastName}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="username"
                  label="User Name"
                  value={newUniversityData?.username}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVerticalSelectField
                  name="role"
                  label="User Role"
                  value={newUniversityData?.role}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                  options={UniversityUserRolesSelect}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="email"
                  label="User Email"
                  type="email"
                  value={newUniversityData?.email}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="password"
                  label="Password"
                  type="password"
                  value={newUniversityData?.password}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  value={newUniversityData?.confirmPassword}
                  onChange={(e) => handleStateChange(e, setNewuniversityData)}
                  colClass="col-12 col-lg-6"
                />
              </div>
            </div>
          </div>
          <div className="submit_button_holder">
            <button
              className="add_new_univercity_btn"
              onClick={handleAddNewUniversity}
            >
              Save University
            </button>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminAddUniversity;
