import React from "react";
import { replaceLocalhostUrl } from "../../../../utils/helpers";

const OurTeam = ({ ourTeam }) => {
  return (
    <div className="our_team_main">
      <p className="curious_heading">OUR TEAM</p>
      <br />
      <div className="row">
        {ourTeam?.map((member) => (
          <div className="col-6 col-lg-3 col-md-4 col-sm-6">
            <div className="member_details_holder">
              <img
                src={replaceLocalhostUrl(member.memberImage)}
                className="member_image_style"
              />
              <div className="member_detail_holder">
                <p className="mamber_name_style">{member.name}</p>
                <p className="member_designation_style">{member.position}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurTeam;
