import React from "react";
import { useNavigate } from "react-router-dom";
import "./SuperAdminProfile.css";
import { FaUserPlus } from "react-icons/fa";
import { IoKeySharp } from "react-icons/io5";

import { useGetAllAdmin } from "../../../hooks/useGetAllAdmin";

import UserNavbar from "../../reusables/Navbar/UserNavbar/UserNavbar";
import TableComponent from "../../reusables/TableComponent/TableComponent";
import PageTitle from "../../reusables/PageTitle/PageTitle";
import Loader from "../../reusables/Loader/Loader";
import ErrorPage from "../../reusables/ErrorPage/ErrorPage";

import CustomButton from "../../reusables/FromComponent/CustomButton";

import {
  adminListFields,
  superAdminProfileTableCols,
} from "../../../utils/constant";

const SuperAdminProfile = () => {
  const [admins, isLoading, error] = useGetAllAdmin();
  const navigate = useNavigate();

  if (error) return <ErrorPage />;
  if (isLoading) return <Loader />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"PROFILE"} />
        <div className="create_new_user_btn_holder">
          <CustomButton
            buttonLabel="Change Password"
            onClick={() => navigate("/superAdmin/profile/changePassword")}
            icon={<IoKeySharp className="add_user_icon_style" />}
          />

          <CustomButton
            buttonLabel="Create new user"
            onClick={() => navigate("/superAdmin/profile/addSubAdmin")}
            icon={<FaUserPlus className="add_user_icon_style" />}
          />
        </div>
        <div className="admin_list_table_holder">
          <TableComponent
            tableColumns={superAdminProfileTableCols}
            data={admins}
            fieldsToShowList={adminListFields}
            navigateTo="/superAdmin/profile/view"
            emptyFor="superAdminUserList"
          />
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminProfile;
