import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { useGetAgencyById } from "../../../../hooks/useGetAgencyById";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";
import Loader from "../../../reusables/Loader/Loader";

import CustomInputField from "../../../reusables/FromComponent/CustomInputField";
import CustomSelectField from "../../../reusables/FromComponent/CustomSelectField";

import Notification, {
  findEmptyFields,
  handleStateChange,
} from "../../../../utils/helpers";
import {
  API_HEADERS,
  addgeneralUserDefaultStateObject,
  agencyRoleSelectOption,
} from "../../../../utils/constant";

const AgencyAddNewAgent = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [newAdminData, setNewAdmindata] = useState({
    ...addgeneralUserDefaultStateObject,
    addedBy:
      user?._id || JSON.parse(localStorage.getItem("currentLoginUser"))?._id,
  });

  const [agencyData, isLoading, error] = useGetAgencyById(
    user?.agencyRef ||
      JSON.parse(localStorage.getItem("currentLoginUser"))?.agencyRef
  );

  const handleAddNewAdmin = () => {
    if (findEmptyFields(newAdminData).length > 0) {
      Notification.errorNotification(
        `Please add ${findEmptyFields(newAdminData)[0]}`
      );
    } else if (newAdminData.password !== newAdminData.confirmPassword) {
      Notification.errorNotification(
        "Please confirm your password correctly..!"
      );
    } else if (!agencyData?.isVerified) {
      Notification.errorNotification("Agency not verified! Access Denied.");
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/agency/addAgentToAgency`,
          {
            ...newAdminData,
            agencyRef: JSON.parse(localStorage.getItem("currentLoginUser"))
              ?.agencyRef,
          },
          API_HEADERS()
        )
        .then(() => {
          Notification.successNotification("New admin added Successfully");
          navigate("/superAdmin/profile");
        })
        .catch((err) => {
          Notification.errorNotification(err.response.data.message);
        });
    }
  };

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"ADD AGENT"} />
        <div className="agent_view_form_holder">
          <CustomInputField
            label="User Name"
            name="username"
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />

          <CustomInputField
            label="Email"
            name="email"
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />

          <CustomInputField
            label="First Name"
            name="firstName"
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />

          <CustomInputField
            label="Last Name"
            name="lastName"
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />

          <CustomSelectField
            name="role"
            label="Role"
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
            options={agencyRoleSelectOption}
          />

          <CustomInputField
            label="Password"
            name="password"
            type="password"
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />

          <CustomInputField
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />

          <div className="agent_label_field_holder">
            <label className="agent_label_style"> </label>
            <div className="agency_agent_action_btn_holder">
              <button
                className="agency_agent_btn_style"
                onClick={handleAddNewAdmin}
              >
                Add Agent
              </button>
            </div>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default AgencyAddNewAgent;
