import { useEffect, useState } from "react";
import axios from "axios";

export const useGetHomeInfo = (show = false) => {
  const [isLoading, setIsLoading] = useState(false);
  const [homeInfo, setHomeInfo] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    if (!show) {
      setIsLoading(true);
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/homeInfo/getHomeInfo`)
        .then((response) => {
          setHomeInfo(response.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, [show]);

  return [homeInfo, isLoading, error];
};
