import React from "react";
import "./StatisticBlock.css";
import { useNavigate } from "react-router-dom";

const StatisticBlock = ({ statTitle, description, count, link }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (link !== "other") {
      navigate(link);
    }
  };

  return (
    <div
      className="col-12 col-xl-3 col-lg-4 col-md-6"
      onClick={handleNavigation}
    >
      <div className="statistic_block">
        <div className="heading_holder">
          <p className="heading_text_style">{statTitle}</p>
        </div>
        <div className="sub_heading_holder">
          <p className="sub_heading_text_style">{description}</p>
        </div>
        <div className="counting_holder">
          <p className="counting_text_style">{count}</p>
        </div>
      </div>
    </div>
  );
};

export default StatisticBlock;
