import React from "react";

const WhoWeAre = ({ whoWeAre }) => {
  return (
    <div className="who_we_are_conatiner">
      <div className="who_we_are_content_holder">
        <div className="img_border">
          <div className="img_container">
            <img
              src="/images/HomePage/whoWeAre.png"
              className="who_we_are_img_style"
              alt="who we are"
            />
          </div>
        </div>
        <div className="desc_title_holder">
          <p className="title_text_style">WHO WE ARE</p>
          <div className="short_border_who_we_are"></div>
          <div className="who_we_are_desc">{whoWeAre}</div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
