import React from "react";
import { replaceLocalhostUrl } from "../../../../utils/helpers";

const OurStory = ({ ourStoryText, ourStoryImage }) => {
  return (
    <div className="our_story_main">
      <p className="curious_heading">OUR STORY</p>
      <div className="story_holder">
        <div className="story_text_holder">
          {ourStoryText?.split("/n")?.map((para) => (
            <>
              <p className="story_text_style">{para}</p>
              <br />
            </>
          ))}
        </div>
        <div className="story_image_holder">
          <img
            src={replaceLocalhostUrl(ourStoryImage)}
            className="image_style"
          />
        </div>
      </div>
    </div>
  );
};

export default OurStory;
