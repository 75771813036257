import React from "react";

import { useGetAboutUsData } from "../../../../hooks/useGetAboutUsData";

import Curious from "./AboutUsSections/Curious";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";
import OurStory from "./AboutUsSections/OurStory";
import OurCulture from "./AboutUsSections/OurCulture";
import OurTeam from "./AboutUsSections/OurTeam";
import WhoWeAreBlock from "./AboutUsSections/WhoWeAreBlock";
import GoalStatementUpdate from "./AboutUsSections/GoalStatementUpdate";

const AboutUsPageInfoControl = () => {
  const [aboutUsData, isLoading, error] = useGetAboutUsData();

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;

  return (
    <div>
      <Curious
        heading={aboutUsData?.curiousHeading}
        subText={aboutUsData?.curiousSubtext}
        imageURL={aboutUsData?.curiousImage}
      />
      <GoalStatementUpdate
        valuesAndGoalsArray={aboutUsData?.valuesAndGoalsArray}
        ownerNameData={aboutUsData?.ownerName}
        wordFromOwnerData={aboutUsData?.wordFromOwner}
      />
      <WhoWeAreBlock aboutUsCards={aboutUsData?.aboutUsCards} />
      <OurStory
        ourStoryText={aboutUsData?.ourStoryText}
        ourStoryImage={aboutUsData?.ourStoryImage}
      />
      <OurCulture
        ourCultureText={aboutUsData?.ourCultureText}
        ourCultureImage={aboutUsData?.ourCultureImage}
      />
      <OurTeam ourTeam={aboutUsData?.ourTeam} />
    </div>
  );
};

export default AboutUsPageInfoControl;
