import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { useGetCountryById } from "../../../../../hooks/useGetCountryById";

import UserNavbar from "../../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../../reusables/PageTitle/PageTitle";
import Loader from "../../../../reusables/Loader/Loader";

import CustomVerticalSelectField from "../../../../reusables/FromComponent/CustomVerticalSelectField";
import CustomVericalInputField from "../../../../reusables/FromComponent/CustomVericalInputField";
import CustomButton from "../../../../reusables/FromComponent/CustomButton";

import Notification, {
  handleFileInputInState,
  handleStateChange,
  removeEmptyProperties,
  replaceLocalhostUrl,
} from "../../../../../utils/helpers";
import {
  API_HEADERS,
  countryCurrency,
  languages,
  newCountryList,
  postGraduationWorkPermit,
  workDuringStydentSelect,
} from "../../../../../utils/constant";

const UpdateCountryForm = () => {
  const [countryData, setCountryData] = useState({});
  const { countryId } = useParams();
  const [country, countryListLoading, countryListError] =
    useGetCountryById(countryId);
  const navigate = useNavigate();

  const handleSaveCountry = () => {
    const clearedCountryData = removeEmptyProperties(countryData);

    const countryDataFormData = new FormData();
    Object.keys(clearedCountryData).forEach((key) => {
      countryDataFormData.append(key, clearedCountryData[key]);
    });
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/homeInfo//updateCountry/${countryId}`,
        countryDataFormData,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Country update successfully");
        navigate(`/superAdmin/homePageInfoForm`);
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  useEffect(() => {
    setCountryData((prev) => ({
      ...prev,
      countryName: country?.countryName || "",
      officialCurrency: country?.officialCurrency || "",
      officialLanguage: country?.officialLanguage || "",
      countryCoverImage: "",
      workPermit: country?.workPermit || "",
      details: country?.details || "",
      referenceLink: country?.referenceLink || "",
      workPermitDuringStudies: country?.workPermitDuringStudies || "",
      averageLivingCostPerYear: country?.averageLivingCostPerYear || "",
    }));
  }, [country]);

  if (countryListLoading) return <Loader />;
  if (countryListError) return "Please try again later...";
  return (
    <div>
      <UserNavbar>
        <PageTitle title={"ADD COUNTRY"} />
        <div className="add_university_holder">
          <div className="input_container">
            <div className="bacsic_detail_holder">
              <div className="basic_university_detail_holder">
                <CustomVerticalSelectField
                  name="countryName"
                  label="Country Name"
                  value={countryData?.countryName}
                  onChange={(e) => handleStateChange(e, setCountryData)}
                  options={newCountryList}
                />

                <CustomVerticalSelectField
                  name="officialCurrency"
                  label="Official Currency"
                  value={countryData?.officialCurrency}
                  onChange={(e) => handleStateChange(e, setCountryData)}
                  options={countryCurrency}
                />

                <CustomVerticalSelectField
                  name="officialLanguage"
                  label="Official Language"
                  value={countryData?.officialLanguage}
                  onChange={(e) => handleStateChange(e, setCountryData)}
                  options={languages}
                />
              </div>
              <div className="university_image_container">
                <label
                  className="country_cover_image"
                  htmlFor="countryCoverImage"
                >
                  {countryData?.countryCoverImage ? (
                    <img
                      src={URL.createObjectURL(countryData?.countryCoverImage)}
                      className="country_cover_img_style"
                      alt="country_cover_image"
                    />
                  ) : (
                    <img
                      src={
                        country.countryCoverImage
                          ? replaceLocalhostUrl(country?.countryCoverImage)
                          : "/images/superAdmin/upload_placeholder.png"
                      }
                      className={
                        country.countryCoverImage
                          ? "country_cover_img_style"
                          : "country_upload_placeholder_img_style"
                      }
                      alt="country_cover_image"
                    />
                  )}
                </label>
                <input
                  type="file"
                  className="actual_documetn_upload_input_field"
                  id="countryCoverImage"
                  name="countryCoverImage"
                  onChange={(e) => handleFileInputInState(e, setCountryData)}
                />
              </div>
            </div>
          </div>
          <div className="input_container">
            <CustomVericalInputField
              name="averageLivingCostPerYear"
              label="Average Cost of living per year"
              value={countryData?.averageLivingCostPerYear}
              onChange={(e) => handleStateChange(e, setCountryData)}
            />

            {/* <CustomVericalInputField
              name="workPermit"
              label="Post study work permit Y/N"
              value={countryData?.workPermit}
              onChange={(e) => handleStateChange(e, setCountryData)}
            /> */}

            <CustomVerticalSelectField
              name="workPermit"
              label="Post study work permit Y/N"
              value={countryData?.workPermit}
              onChange={(e) => handleStateChange(e, setCountryData)}
              options={postGraduationWorkPermit}
            />

            {/* <CustomVericalInputField
              name="workPermitDuringStudies"
              label="Work Permit During Studies Y/N"
              value={countryData?.workPermitDuringStudies}
              onChange={(e) => handleStateChange(e, setCountryData)}
            /> */}

            <CustomVerticalSelectField
              name="workPermitDuringStudies"
              label="Work Permit During Studies Y/N"
              value={countryData?.workPermitDuringStudies}
              onChange={(e) => handleStateChange(e, setCountryData)}
              options={workDuringStydentSelect}
            />

            <div className="university_label_field_holder">
              <label className="university_label_style">Details</label>
              <textarea
                type="text"
                className="university_input_field_style"
                name="details"
                defaultValue={country.details}
                onChange={(e) => handleStateChange(e, setCountryData)}
              />
            </div>

            <CustomVericalInputField
              name="referenceLink"
              label="Official Reference link"
              value={countryData?.referenceLink}
              onChange={(e) => handleStateChange(e, setCountryData)}
            />
          </div>
          <div className="submit_button_holder">
            <CustomButton
              buttonLabel="Update Country"
              onClick={handleSaveCountry}
            />
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default UpdateCountryForm;
