import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// import { ToastContainer } from "react-toastify";
import "./SuperAdminProfileView.css";

import { useGetAdminById } from "../../../../hooks/useGetAdminById";

import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import CustomInputField from "../../../reusables/FromComponent/CustomInputField";
import CustomSelectField from "../../../reusables/FromComponent/CustomSelectField";
import CustomButton from "../../../reusables/FromComponent/CustomButton";

import Notification, { handleStateChange } from "../../../../utils/helpers";
import { API_HEADERS, superAdminSelect } from "../../../../utils/constant";

const SuperAdminProfileView = () => {
  const { adminId } = useParams();
  const navigate = useNavigate();

  const [adminData, isLoading, error] = useGetAdminById(adminId);

  const [newAdminData, setNewAdmindata] = useState({
    username: adminData?.username || "",
    email: adminData?.email || "",
    firstName: adminData?.firstName || "",
    lastName: adminData?.lastName || "",
    role: adminData?.role || "",
  });

  const handleUpdateAdmin = () => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/auth/updateAdminById`,
        { ...newAdminData, adminId },
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Admin Details Updated Successfully");
        setTimeout(() => navigate("/superAdmin/profile"), 2000);
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  const handleDeleteAdmin = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/auth/deleteAdminById/${adminId}`,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Admin Deleted Successfully");
        navigate("/superAdmin/profile");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  useEffect(() => {
    setNewAdmindata({
      username: adminData?.username || "",
      email: adminData?.email || "",
      firstName: adminData?.firstName || "",
      lastName: adminData?.lastName || "",
      role: adminData?.role || "",
    });
  }, [adminId, adminData]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"View Profile"} />

        <div className="add_user_form_holder">
          <CustomInputField
            name="username"
            label="User Name"
            value={newAdminData?.username}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />

          <CustomInputField
            name="email"
            label="Email"
            type="email"
            value={newAdminData?.email}
            readOnly
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />

          <CustomInputField
            name="firstName"
            label="First Name"
            value={newAdminData?.firstName}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />

          <CustomInputField
            name="lastName"
            label="Last Name"
            value={newAdminData?.lastName}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />

          <CustomSelectField
            name="role"
            label="Role"
            value={newAdminData?.role}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
            options={superAdminSelect}
          />

          <div className="admin_action_btn_container">
            <div className="admin_action_btn_holder">
              <CustomButton
                buttonLabel="Delete"
                onClick={handleDeleteAdmin}
                secondary
              />
              <CustomButton buttonLabel="Update" onClick={handleUpdateAdmin} />
            </div>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminProfileView;
