import React, { useState } from "react";
import "./Sidebar.css";

import { useGetAllUniversities } from "../../../../hooks/useGetAllUniversities";

import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import CustomButton from "../../../reusables/FromComponent/CustomButton";

import {
  handleStateChange,
  removeEmptyProperties,
} from "../../../../utils/helpers";
import {
  courseLevelSelect,
  languages,
  newCountryList,
  newCourseSubjectListForStudent,
} from "../../../../utils/constant";

const SideManu = ({ setSearchObjData, clearSearch }) => {
  const [universities, isLoading, error] = useGetAllUniversities();

  const [searchObject, setSearchobject] = useState({
    universityName: "",
    courseSubjectType: "",
    courseLevel: "",
    courseLanguage: "",
    courseStudyMode: "",
    courseDuration: "",
    courseCountry: "",
  });

  const handleFilter = () => {
    const newfilterObject = removeEmptyProperties(searchObject);
    if (Object.keys(newfilterObject).length) setSearchObjData(newfilterObject);
  };

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;
  return (
    <>
      <div className="sidebar">
        <div className="filter_fields_holder">
          <p className="search_filter_heading_style">
            Course Search and Filter
          </p>
          <select
            className="filter_input_style"
            name="universityName"
            onChange={(e) => handleStateChange(e, setSearchobject)}
          >
            <option value="">Select University</option>
            {universities.map((university) => (
              <option key={university.universityName}>
                {university.universityName}
              </option>
            ))}
          </select>

          <select
            name="courseSubjectType"
            className="filter_input_style"
            onChange={(e) => handleStateChange(e, setSearchobject)}
          >
            <option value="">Select Course Discipline</option>
            {newCourseSubjectListForStudent.map((decepline) => (
              <option key={decepline.label}>{decepline.label}</option>
            ))}
          </select>

          <select
            name="courseLevel"
            className="filter_input_style"
            onChange={(e) => handleStateChange(e, setSearchobject)}
          >
            <option value="">Select Course level</option>
            {courseLevelSelect.map(
              (level) =>
                level.label !== "---- Select Level ----" && (
                  <option value={level.value} key={level.value}>
                    {level.label}
                  </option>
                )
            )}
          </select>

          {/* <select
            name="courseLanguage"
            className="filter_input_style"
            onChange={(e) => handleStateChange(e, setSearchobject)}
          >
            <option>Select Course Language</option>
            {languages.map((language) => (
              <option>{language.label}</option>
            ))}
          </select> */}

          <select
            name="courseStudyMode"
            className="filter_input_style"
            onChange={(e) => handleStateChange(e, setSearchobject)}
          >
            <option value="">Study Mode</option>
            <option value={"on-campus"}>On Campus</option>
            <option value={"online"}>Online</option>
          </select>

          <select
            name="courseDuration"
            className="filter_input_style"
            onChange={(e) => handleStateChange(e, setSearchobject)}
          >
            <option value="">Select Course Duration </option>
            <option value={6}>6 or less Months</option>
            <option value={12}>12 or less Months</option>
            <option value={18}>18 or less Months</option>
            <option value={24}>24 or less Months</option>
            <option value={100}>Other</option>
          </select>

          <select
            name="courseCountry"
            className="filter_input_style"
            onChange={(e) => handleStateChange(e, setSearchobject)}
          >
            {newCountryList.map((country) => (
              <option value={country.value} key={country.value}>
                {country.label}
              </option>
            ))}
          </select>
        </div>

        <div className="ApplyFilterPerent">
          <CustomButton buttonLabel="Apply Filter" onClick={handleFilter} />
          <CustomButton buttonLabel="Clear" onClick={clearSearch} />
        </div>
      </div>
    </>
  );
};

export default SideManu;
