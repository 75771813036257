import React from "react";
import UserNavbar from "../../reusables/Navbar/UserNavbar/UserNavbar";
import "./AboutUs.css";

import { useGetAboutUsData } from "../../../hooks/useGetAboutUsData";

import CuriousBlock from "./AboutUsContent/CuriousBlock";
import GoalStatement from "./AboutUsContent/GoalStatement";
import WhoWeAreBlock from "./AboutUsContent/WhoWeAreBlock";
import Statistics from "./AboutUsContent/Statistics";
import OurStory from "./AboutUsContent/OurStory";
import OurCulture from "./AboutUsContent/OurCulture";
import OurTeam from "./AboutUsContent/OurTeam";
import JoinUs from "./AboutUsContent/JoinUs";

import Loader from "../../reusables/Loader/Loader";
import ErrorPage from "../../reusables/ErrorPage/ErrorPage";

const AboutUs = () => {
  const [aboutUsData, isLoading, error] = useGetAboutUsData();

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <div>
          <CuriousBlock
            heading={aboutUsData?.curiousHeading}
            subText={aboutUsData?.curiousSubtext}
            imageURL={aboutUsData?.curiousImage}
          />
          <GoalStatement
            valuesAndGoalsArray={aboutUsData?.valuesAndGoalsArray}
            ownerName={aboutUsData?.ownerName}
            wordFromOwner={aboutUsData?.wordFromOwner}
          />
          <WhoWeAreBlock whoWeAreContent={aboutUsData?.aboutUsCards} />
          <OurStory
            ourStoryText={aboutUsData?.ourStoryText}
            ourStoryImage={aboutUsData?.ourStoryImage}
          />
          <Statistics />
          <OurCulture
            ourCultureText={aboutUsData?.ourCultureText}
            ourCultureImage={aboutUsData?.ourCultureImage}
          />
          <OurTeam ourTeam={aboutUsData?.ourTeam} />
          {/* <JoinUs /> */}
        </div>
      </UserNavbar>
    </div>
  );
};

export default AboutUs;
