import React from "react";

import { useGetAdminDashboardStatistics } from "../../../hooks/useGetAdminDashboardStatistics";

import UserNavbar from "../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../reusables/PageTitle/PageTitle";
import StatisticBlock from "../../reusables/StatisticBlock/StatisticBlock";
import Loader from "../../reusables/Loader/Loader";
import ErrorPage from "../../reusables/ErrorPage/ErrorPage";

import { dashboardStatisticsInfo } from "../../../utils/constant";

const SuperAdminDashboard = () => {
  const [statistics, isLoading, error] = useGetAdminDashboardStatistics();

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;
  return (
    <div>
      <UserNavbar>
        <PageTitle title={"DASHBOARD"} />
        <br />
        <br />
        <div className="container">
          <div className="row">
            {Object.keys(statistics).length &&
              Object.keys(statistics).map((statKey) => (
                <StatisticBlock
                  statTitle={dashboardStatisticsInfo[statKey].statTitle}
                  description={dashboardStatisticsInfo[statKey].description}
                  count={statistics[statKey]}
                  link={dashboardStatisticsInfo[statKey].link}
                />
              ))}
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminDashboard;
