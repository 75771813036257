import { BsSpeedometer } from "react-icons/bs";
import {
  FaGripfire,
  FaUsers,
  FaBalanceScale,
  FaHandshake,
  FaBookOpen,
  FaHouseUser,
  FaToolbox,
  FaUsersCog,
  FaUniversity,
} from "react-icons/fa";
import { LuLanguages } from "react-icons/lu";
import { TbMoneybag } from "react-icons/tb";
import { PiStudentFill } from "react-icons/pi";
import { HiBuildingOffice2 } from "react-icons/hi2";
import { randomNumberString } from "./helpers";

export function API_HEADERS() {
  return {
    headers: {
      authorization: `Bearer ${
        localStorage.getItem("currentLoginUser")
          ? JSON.parse(localStorage.getItem("currentLoginUser")).token
          : ""
      }`,
    },
  };
}

export const main_nav_link = [
  {
    id: 1,
    name: "Programs",
    link: "/publicCourseList",
    afterLoginLink: "/publicCourseList",
  },
  {
    id: 2,
    name: "Institutions",
    link: "/university/home",
    afterLoginLink: "/university/dashboard",
  },
  {
    id: 3,
    name: "Recruitment Agent",
    link: "/agency/home",
    afterLoginLink: "/agency/dashboard",
  },
  {
    id: 4,
    name: "Student",
    link: "/student/signup",
    afterLoginLink: "/student/profile",
  },
  {
    id: 5,
    name: "Blog",
    link: "/blog",
    afterLoginLink: "/blog",
  },
  {
    id: 6,
    name: "About us",
    link: "/aboutUs",
    afterLoginLink: "/aboutUs",
  },
];

export const super_admin_usernav_links = [
  {
    id: 1,
    name: "Dashboard",
    link: "/superAdmin/dashboard",
  },
  {
    id: 2,
    name: "Profile",
    link: "/superAdmin/Profile",
  },
  {
    id: 3,
    name: "Applications",
    link: "/superAdmin/application",
  },
  {
    id: 4,
    name: "Universities",
    link: "/superAdmin/university",
  },
  {
    id: 5,
    name: "R.Agents",
    link: "/superAdmin/agent",
  },
  {
    id: 6,
    name: "Students",
    link: "/superAdmin/student",
  },
  {
    id: 7,
    name: "Courses",
    link: "/superAdmin/course",
  },
  {
    id: 8,
    name: "other",
    submenu: [
      {
        id: 9,
        name: "Blogs",
        link: "/superAdmin/blog",
      },
      {
        id: 10,
        name: "Content Update",
        link: "/superAdmin/homePageInfoForm",
      },
      {
        id: 11,
        name: "Subscriber",
        link: "/superAdmin/subscriber",
      },
      {
        id: 12,
        name: "Mailing",
        link: "/superAdmin/mailManagement",
      },
      // {
      //   id: 12,
      //   name: "Qualifications",
      //   link: "/superAdmin/qualification",
      // },
    ],
  },
];

export const student_usernav_links = [
  {
    id: 1,
    name: "Dashboard",
    link: "/student/dashboard",
  },
  {
    id: 2,
    name: "Profile",
    link: "/student/Profile",
  },
  {
    id: 3,
    name: "Applications",
    link: "/student/application",
  },
  {
    id: 4,
    name: "Course Search",
    link: "/student/course",
  },
];

export const agency_usernav_links = [
  {
    id: 1,
    name: "Dashboard",
    link: "/agency/dashboard",
  },
  {
    id: 2,
    name: "Profile",
    link: "/agency/profile",
  },
  {
    id: 3,
    name: "Agents",
    link: "/agency/agentList",
  },
  {
    id: 4,
    name: "Applications",
    link: "/agency/application",
  },
  {
    id: 5,
    name: "Students",
    link: "/agency/student",
  },
];

export const university_usernav_links = [
  {
    id: 1,
    name: "Dashboard",
    link: "/university/dashboard",
  },
  {
    id: 2,
    name: "Profile",
    link: "/university/profile",
  },
  {
    id: 3,
    name: "Users",
    link: "/university/userList",
  },
  {
    id: 4,
    name: "Courses",
    link: "/university/courseList",
  },
  {
    id: 5,
    name: "Applications",
    link: "/university/application",
  },
];

export const footer_item_list_with_title = [
  {
    id: 1,
    title: "ABOUT US",
    links: [
      { id: 2, name: "Our Story", link: "/aboutUs" },
      { id: 3, name: "Our Services", link: "/aboutUs" },
      { id: 4, name: "Testimonials", link: "/aboutUs" },
    ],
  },
  {
    id: 6,
    title: "STUDENTS",
    links: [
      { id: 5, name: "Search Programs", link: "/publicCourseList" },

      { id: 6, name: "Blog", link: "/blog" },
      { id: 7, name: "Contact us", link: "/contactUs" },
    ],
  },
  {
    id: 11,
    title: "RECRUITMENT AGENT",
    links: [
      { id: 8, name: "Join us", link: "/recruitmentAgent/joinUs" },

      { id: 9, name: "Blog", link: "/blog" },
      { id: 10, name: "Contact us", link: "/contactUs" },
    ],
  },
];

export const superAdminProfileTableCols = [
  {
    id: 1,
    name: "ID",
  },
  {
    id: 2,
    name: "User Name",
  },
  {
    id: 3,
    name: "Email",
  },
  {
    id: 4,
    name: "Role",
  },
  {
    id: 5,
    name: "Reset",
  },
  {
    id: 6,
    name: "Edit",
  },
  {
    id: 7,
    name: "Delete",
  },
];

export const adminListFields = [
  "username",
  "email",
  "role",
  "resetIcon",
  "editIcon",
  "deleteIcon",
];

export const qualificationListTable = [
  {
    id: 0,
    name: "id",
  },
  {
    id: 1,
    name: "Student Country",
  },
  {
    id: 2,
    name: "Course Country",
  },
  {
    id: 3,
    name: "Education Level",
  },
  {
    id: 4,
    name: "Examination",
  },
  {
    id: 5,
    name: "Grading System",
  },
  {
    id: 6,
    name: "Minimum Passing Grade",
  },
];

export const qualificationTableFieldstoShow = [
  "studentCountry",
  "courseCountry",
  "educationLevel",
  "examination",
  "gradingSystem",
  "passingGrade",
];

export const universityListtableCols = [
  {
    id: 0,
    name: "id",
  },
  {
    id: 1,
    name: "University Name",
  },
  {
    id: 2,
    name: "Onboard Date",
  },
  {
    id: 3,
    name: "Country",
  },
  {
    id: 4,
    name: "Total No of Courses",
  },
  {
    id: 5,
    name: "Type of Institution",
  },
  {
    id: 6,
    name: "City",
  },
];

export const universitytableFields = [
  "universityName",
  "createdAt",
  "universityCountry",
  "courseList",
  "typeOfInstitution",
  "universityCity",
];

export const courseListTableCols = [
  {
    id: 0,
    name: "id",
  },
  {
    id: 1,
    name: "Course Name",
  },
  {
    id: 2,
    name: "University",
  },
  {
    id: 3,
    name: "Course Subject",
  },
  {
    id: 4,
    name: "Course Start Date",
  },
  {
    id: 5,
    name: "Application Start Date",
  },
  {
    id: 6,
    name: "Application End Date",
  },
  {
    id: 7,
    name: "Course level",
  },
  {
    id: 8,
    name: "Course Fee",
  },
  {
    id: 9,
    name: "No of Available Places",
  },
];

export const courseTableFields = [
  "courseName",
  "courseUniversityId",
  "courseSubjectType",
  "courseStartDate",
  "courseApplicationStartDate",
  "courseApplicationEndDate",
  "courseLevel",
  "courseFee",
  "courseMaxSeat",
];

export const applicationTableFields = [
  "firstName",
  "lastName",
  "nationality",
  "createdAt",
  "applicationNo",
  // "applicationFormStatus",
  "superAdminStatus",
  "courseId/courseName",
  "courseLevel",
];

export const applicationTableCols = [
  {
    id: 0,
    name: "Id",
  },
  {
    id: 1,
    name: "Applicant F. Name",
  },
  {
    id: 2,
    name: "Applicant L. Name",
  },
  {
    id: 3,
    name: "Applicant Nationality",
  },
  {
    id: 4,
    name: "Application Date",
  },
  {
    id: 5,
    name: "Application Number",
  },
  {
    id: 6,
    name: "Application Status",
  },
  {
    id: 7,
    name: "Course Name",
  },
  {
    id: 8,
    name: "Course Level",
  },
];

export const SuperAdminapplicationListFields = [
  "firstName",
  "lastName",
  "nationality",
  "createdAt",
  "applicationNo",
  "applicationFormStatus",
  "superAdminStatus",
  "courseId/courseName",
  "courseLevel",
];

export const SuperAdminapplicationTableCols = [
  {
    id: 0,
    name: "Id",
  },
  {
    id: 1,
    name: "Applicant F. Name",
  },
  {
    id: 2,
    name: "Applicant L. Name",
  },
  {
    id: 3,
    name: "Applicant Nationality",
  },
  {
    id: 4,
    name: "Application Date",
  },
  {
    id: 5,
    name: "Application Number",
  },
  {
    id: 6,
    name: "Application Status (By Uni.)",
  },
  {
    id: 7,
    name: "Application Status (By Admin)",
  },
  {
    id: 8,
    name: "Course Name",
  },
  {
    id: 9,
    name: "Course Level",
  },
];

export const countries = [
  { name: "----Select Country----", code: "NA" },
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },

  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },

  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const newCountryList = [
  { label: "---- Select Country ----", value: "", disabled: true },
  { label: "Afghanistan", value: "Afghanistan" },
  { label: "Åland Islands", value: "Åland Islands" },
  { label: "Albania", value: "Albania" },
  { label: "Algeria", value: "Algeria" },
  { label: "American Samoa", value: "American Samoa" },
  { label: "Andorra", value: "Andorra" },
  { label: "Angola", value: "Angola" },
  { label: "Anguilla", value: "Anguilla" },
  { label: "Antarctica", value: "Antarctica" },
  { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
  { label: "Argentina", value: "Argentina" },
  { label: "Armenia", value: "Armenia" },
  { label: "Aruba", value: "Aruba" },
  { label: "Australia", value: "Australia" },
  { label: "Austria", value: "Austria" },
  { label: "Azerbaijan", value: "Azerbaijan" },
  { label: "Bahamas", value: "Bahamas" },
  { label: "Bahrain", value: "Bahrain" },
  { label: "Bangladesh", value: "Bangladesh" },
  { label: "Barbados", value: "Barbados" },
  { label: "Belarus", value: "Belarus" },
  { label: "Belgium", value: "Belgium" },
  { label: "Belize", value: "Belize" },
  { label: "Benin", value: "Benin" },
  { label: "Bermuda", value: "Bermuda" },
  { label: "Bhutan", value: "Bhutan" },
  { label: "Bolivia", value: "Bolivia" },
  { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
  { label: "Botswana", value: "Botswana" },
  { label: "Bouvet Island", value: "Bouvet Island" },
  { label: "Brazil", value: "Brazil" },
  {
    label: "British Indian Ocean Territory",
    value: "British Indian Ocean Territory",
  },
  { label: "Brunei Darussalam", value: "Brunei Darussalam" },
  { label: "Bulgaria", value: "Bulgaria" },
  { label: "Burkina Faso", value: "Burkina Faso" },
  { label: "Burundi", value: "Burundi" },
  { label: "Cambodia", value: "Cambodia" },
  { label: "Cameroon", value: "Cameroon" },
  { label: "Canada", value: "Canada" },
  { label: "Cape Verde", value: "Cape Verde" },
  { label: "Cayman Islands", value: "Cayman Islands" },
  { label: "Central African Republic", value: "Central African Republic" },
  { label: "Chad", value: "Chad" },
  { label: "Chile", value: "Chile" },
  { label: "China", value: "China" },
  { label: "Christmas Island", value: "Christmas Island" },
  { label: "Cocos (Keeling) Islands", value: "Cocos (Keeling) Islands" },
  { label: "Colombia", value: "Colombia" },
  { label: "Comoros", value: "Comoros" },
  { label: "Congo", value: "Congo" },
  {
    label: "Congo, The Democratic Republic of the",
    value: "Congo, The Democratic Republic of the",
  },
  { label: "Cook Islands", value: "Cook Islands" },
  { label: "Costa Rica", value: "Costa Rica" },
  { label: "Cote D'Ivoire", value: "Cote D'Ivoire" },
  { label: "Croatia", value: "Croatia" },
  { label: "Cuba", value: "Cuba" },
  { label: "Cyprus", value: "Cyprus" },
  { label: "Czech Republic", value: "Czech Republic" },
  { label: "Denmark", value: "Denmark" },
  { label: "Djibouti", value: "Djibouti" },
  { label: "Dominica", value: "Dominica" },
  { label: "Dominican Republic", value: "Dominican Republic" },
  { label: "Ecuador", value: "Ecuador" },
  { label: "Egypt", value: "Egypt" },
  { label: "El Salvador", value: "El Salvador" },
  { label: "Equatorial Guinea", value: "Equatorial Guinea" },
  { label: "Eritrea", value: "Eritrea" },
  { label: "Estonia", value: "Estonia" },
  { label: "Ethiopia", value: "Ethiopia" },
  {
    label: "Falkland Islands (Malvinas)",
    value: "Falkland Islands (Malvinas)",
  },
  { label: "Faroe Islands", value: "Faroe Islands" },
  { label: "Fiji", value: "Fiji" },
  { label: "Finland", value: "Finland" },
  { label: "France", value: "France" },
  { label: "French Guiana", value: "French Guiana" },
  { label: "French Polynesia", value: "French Polynesia" },
  {
    label: "French Southern Territories",
    value: "French Southern Territories",
  },
  { label: "Gabon", value: "Gabon" },
  { label: "Gambia", value: "Gambia" },
  { label: "Georgia", value: "Georgia" },
  { label: "Germany", value: "Germany" },
  { label: "Ghana", value: "Ghana" },
  { label: "Gibraltar", value: "Gibraltar" },
  { label: "Greece", value: "Greece" },
  { label: "Greenland", value: "Greenland" },
  { label: "Grenada", value: "Grenada" },
  { label: "Guadeloupe", value: "Guadeloupe" },
  { label: "Guam", value: "Guam" },
  { label: "Guatemala", value: "Guatemala" },
  { label: "Guernsey", value: "Guernsey" },
  { label: "Guinea", value: "Guinea" },
  { label: "Guinea-Bissau", value: "Guinea-Bissau" },
  { label: "Guyana", value: "Guyana" },
  { label: "Haiti", value: "Haiti" },
  {
    label: "Heard Island and Mcdonald Islands",
    value: "Heard Island and Mcdonald Islands",
  },
  {
    label: "Holy See (Vatican City State)",
    value: "Holy See (Vatican City State)",
  },
  { label: "Honduras", value: "Honduras" },
  { label: "Hong Kong", value: "Hong Kong" },
  { label: "Hungary", value: "Hungary" },
  { label: "Iceland", value: "Iceland" },
  { label: "India", value: "India" },
  { label: "Indonesia", value: "Indonesia" },
  { label: "Iran, Islamic Republic Of", value: "Iran, Islamic Republic Of" },
  { label: "Iraq", value: "Iraq" },
  { label: "Ireland", value: "Ireland" },
  { label: "Isle of Man", value: "Isle of Man" },
  { label: "Israel", value: "Israel" },
  { label: "Italy", value: "Italy" },
  { label: "Jamaica", value: "Jamaica" },
  { label: "Japan", value: "Japan" },
  { label: "Jersey", value: "Jersey" },
  { label: "Jordan", value: "Jordan" },
  { label: "Kazakhstan", value: "Kazakhstan" },
  { label: "Kenya", value: "Kenya" },
  { label: "Kiribati", value: "Kiribati" },
  {
    label: "Korea, Democratic People'S Republic of",
    value: "Korea, Democratic People'S Republic of",
  },
  { label: "Korea, Republic of", value: "Korea, Republic of" },
  { label: "Kuwait", value: "Kuwait" },
  { label: "Kyrgyzstan", value: "Kyrgyzstan" },
  {
    label: "Lao People'S Democratic Republic",
    value: "Lao People'S Democratic Republic",
  },
  { label: "Latvia", value: "Latvia" },
  { label: "Lebanon", value: "Lebanon" },
  { label: "Lesotho", value: "Lesotho" },
  { label: "Liberia", value: "Liberia" },
  { label: "Libyan Arab Jamahiriya", value: "Libyan Arab Jamahiriya" },
  { label: "Liechtenstein", value: "Liechtenstein" },
  { label: "Lithuania", value: "Lithuania" },
  { label: "Luxembourg", value: "Luxembourg" },
  { label: "Macao", value: "Macao" },
  {
    label: "Macedonia, The Former Yugoslav Republic of",
    value: "Macedonia, The Former Yugoslav Republic of",
  },
  { label: "Madagascar", value: "Madagascar" },
  { label: "Malawi", value: "Malawi" },
  { label: "Malaysia", value: "Malaysia" },
  { label: "Maldives", value: "Maldives" },
  { label: "Mali", value: "Mali" },
  { label: "Malta", value: "Malta" },
  { label: "Marshall Islands", value: "Marshall Islands" },
  { label: "Martinique", value: "Martinique" },
  { label: "Mauritania", value: "Mauritania" },
  { label: "Mauritius", value: "Mauritius" },
  { label: "Mayotte", value: "Mayotte" },
  { label: "Mexico", value: "Mexico" },
  {
    label: "Micronesia, Federated States of",
    value: "Micronesia, Federated States of",
  },
  { label: "Moldova, Republic of", value: "Moldova, Republic of" },
  { label: "Monaco", value: "Monaco" },
  { label: "Mongolia", value: "Mongolia" },
  { label: "Montserrat", value: "Montserrat" },
  { label: "Morocco", value: "Morocco" },
  { label: "Mozambique", value: "Mozambique" },
  { label: "Myanmar", value: "Myanmar" },
  { label: "Namibia", value: "Namibia" },
  { label: "Nauru", value: "Nauru" },
  { label: "Nepal", value: "Nepal" },
  { label: "Netherlands", value: "Netherlands" },
  { label: "Netherlands Antilles", value: "Netherlands Antilles" },
  { label: "New Caledonia", value: "New Caledonia" },
  { label: "New Zealand", value: "New Zealand" },
  { label: "Nicaragua", value: "Nicaragua" },
  { label: "Niger", value: "Niger" },
  { label: "Nigeria", value: "Nigeria" },
  { label: "Niue", value: "Niue" },
  { label: "Norfolk Island", value: "Norfolk Island" },
  { label: "Northern Mariana Islands", value: "Northern Mariana Islands" },
  { label: "Norway", value: "Norway" },
  { label: "Oman", value: "Oman" },
  { label: "Pakistan", value: "Pakistan" },
  { label: "Palau", value: "Palau" },
  {
    label: "Palestinian Territory, Occupied",
    value: "Palestinian Territory, Occupied",
  },
  { label: "Panama", value: "Panama" },
  { label: "Papua New Guinea", value: "Papua New Guinea" },
  { label: "Paraguay", value: "Paraguay" },
  { label: "Peru", value: "Peru" },
  { label: "Philippines", value: "Philippines" },
  { label: "Pitcairn", value: "Pitcairn" },
  { label: "Poland", value: "Poland" },
  { label: "Portugal", value: "Portugal" },
  { label: "Puerto Rico", value: "Puerto Rico" },
  { label: "Qatar", value: "Qatar" },
  { label: "Reunion", value: "Reunion" },
  { label: "Romania", value: "Romania" },
  { label: "Russian Federation", value: "Russian Federation" },
  { label: "RWANDA", value: "RWANDA" },
  { label: "Saint Helena", value: "Saint Helena" },
  { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
  { label: "Saint Lucia", value: "Saint Lucia" },
  { label: "Saint Pierre and Miquelon", value: "Saint Pierre and Miquelon" },
  {
    label: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
  },
  { label: "Samoa", value: "Samoa" },
  { label: "San Marino", value: "San Marino" },
  { label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
  { label: "Saudi Arabia", value: "Saudi Arabia" },
  { label: "Senegal", value: "Senegal" },
  { label: "Serbia and Montenegro", value: "Serbia and Montenegro" },
  { label: "Seychelles", value: "Seychelles" },
  { label: "Sierra Leone", value: "Sierra Leone" },
  { label: "Singapore", value: "Singapore" },
  { label: "Slovakia", value: "Slovakia" },
  { label: "Slovenia", value: "Slovenia" },
  { label: "Solomon Islands", value: "Solomon Islands" },
  { label: "Somalia", value: "Somalia" },
  { label: "South Africa", value: "South Africa" },
  {
    label: "South Georgia and the South Sandwich Islands",
    value: "South Georgia and the South Sandwich Islands",
  },
  { label: "Spain", value: "Spain" },
  { label: "Sri Lanka", value: "Sri Lanka" },
  { label: "Sudan", value: "Sudan" },
  { label: "Surilabel", value: "Surilabel" },
  { label: "Svalbard and Jan Mayen", value: "Svalbard and Jan Mayen" },
  { label: "Swaziland", value: "Swaziland" },
  { label: "Sweden", value: "Sweden" },
  { label: "Switzerland", value: "Switzerland" },
  { label: "Syrian Arab Republic", value: "Syrian Arab Republic" },
  { label: "Taiwan, Province of China", value: "Taiwan, Province of China" },
  { label: "Tajikistan", value: "Tajikistan" },
  {
    label: "Tanzania, United Republic of",
    value: "Tanzania, United Republic of",
  },
  { label: "Thailand", value: "Thailand" },
  { label: "Timor-Leste", value: "Timor-Leste" },
  { label: "Togo", value: "Togo" },
  { label: "Tokelau", value: "Tokelau" },
  { label: "Tonga", value: "Tonga" },
  { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
  { label: "Tunisia", value: "Tunisia" },
  { label: "Turkey", value: "Turkey" },
  { label: "Turkmenistan", value: "Turkmenistan" },
  { label: "Turks and Caicos Islands", value: "Turks and Caicos Islands" },
  { label: "Tuvalu", value: "Tuvalu" },
  { label: "Uganda", value: "Uganda" },
  { label: "Ukraine", value: "Ukraine" },
  { label: "United Arab Emirates", value: "United Arab Emirates" },
  { label: "United Kingdom", value: "United Kingdom" },
  { label: "United States", value: "United States" },
  {
    label: "United States Minor Outlying Islands",
    value: "United States Minor Outlying Islands",
  },
  { label: "Uruguay", value: "Uruguay" },
  { label: "Uzbekistan", value: "Uzbekistan" },
  { label: "Vanuatu", value: "Vanuatu" },
  { label: "Venezuela", value: "Venezuela" },
  { label: "Viet Nam", value: "Viet Nam" },
  { label: "Virgin Islands, British", value: "Virgin Islands, British" },
  { label: "Virgin Islands, U.S.", value: "Virgin Islands, U.S." },
  { label: "Wallis and Futuna", value: "Wallis and Futuna" },
  { label: "Western Sahara", value: "Western Sahara" },
  { label: "Yemen", value: "Yemen" },
  { label: "Zambia", value: "Zambia" },
  { label: "Zimbabwe", value: "Zimbabwe" },
];

export const countryCurrency = [
  {
    label: "---- Select Currency ----",
    value: "",
  },
  {
    label: "Afghanistan",
    value: "AFN",
  },
  {
    label: "Albania",
    value: "ALL",
  },
  {
    label: "Algeria",
    value: "DZD",
  },
  {
    label: "American Samoa",
    value: "USD-Samoa",
  },
  {
    label: "Andorra",
    value: "EUR - Andorra",
  },
  {
    label: "Angola",
    value: "AOA",
  },
  {
    label: "Anguilla",
    value: "XCD",
  },
  {
    label: "Antarctica",
    value: "XCD",
  },
  {
    label: "Antigua and Barbuda",
    value: "XCD",
  },
  {
    label: "Argentina",
    value: "ARS",
  },
  {
    label: "Armenia",
    value: "AMD",
  },
  {
    label: "Aruba",
    value: "AWG",
  },
  {
    label: "Australia",
    value: "AUD",
  },
  {
    label: "Austria",
    value: "EUR - Austria",
  },
  {
    label: "Azerbaijan",
    value: "AZN",
  },
  {
    label: "Bahamas",
    value: "BSD",
  },
  {
    label: "Bahrain",
    value: "BHD",
  },
  {
    label: "Bangladesh",
    value: "BDT",
  },
  {
    label: "Barbados",
    value: "BBD",
  },
  {
    label: "Belarus",
    value: "BYR",
  },
  {
    label: "Belgium",
    value: "EUR - Belgium",
  },
  {
    label: "Belize",
    value: "BZD",
  },
  {
    label: "Benin",
    value: "XOF",
  },
  {
    label: "Bermuda",
    value: "BMD",
  },
  {
    label: "Bhutan",
    value: "BTN",
  },
  {
    label: "Bolivia",
    value: "BOB",
  },
  {
    label: "Bosnia and Herzegovina",
    value: "BAM",
  },
  {
    label: "Botswana",
    value: "BWP",
  },
  {
    label: "Bouvet Island",
    value: "NOK",
  },
  {
    label: "Brazil",
    value: "BRL",
  },
  {
    label: "British Indian Ocean Territory",
    value: "USD-Indian Ocen Territory",
  },
  {
    label: "Brunei",
    value: "BND",
  },
  {
    label: "Bulgaria",
    value: "BGN",
  },
  {
    label: "Burkina Faso",
    value: "XOF",
  },
  {
    label: "Burundi",
    value: "BIF",
  },
  {
    label: "Cambodia",
    value: "KHR",
  },
  {
    label: "Cameroon",
    value: "XAF",
  },
  {
    label: "Canada",
    value: "CAD",
  },
  {
    label: "Cape Verde",
    value: "CVE",
  },
  {
    label: "Cayman Islands",
    value: "KYD",
  },
  {
    label: "Central African Republic",
    value: "XAF",
  },
  {
    label: "Chad",
    value: "XAF",
  },
  {
    label: "Chile",
    value: "CLP",
  },
  {
    label: "China",
    value: "CNY",
  },
  {
    label: "Christmas Island",
    value: "AUD",
  },
  {
    label: "Cocos (Keeling) Islands",
    value: "AUD",
  },
  {
    label: "Colombia",
    value: "COP",
  },
  {
    label: "Comoros",
    value: "KMF",
  },
  {
    label: "Congo",
    value: "XAF",
  },
  {
    label: "Cook Islands",
    value: "NZD",
  },
  {
    label: "Costa Rica",
    value: "CRC",
  },
  {
    label: "Croatia",
    value: "HRK",
  },
  {
    label: "Cuba",
    value: "CUP",
  },
  {
    label: "Cyprus",
    value: "EUR - Cyprus",
  },
  {
    label: "Czech Republic",
    value: "CZK",
  },
  {
    label: "Denmark",
    value: "DKK",
  },
  {
    label: "Djibouti",
    value: "DJF",
  },
  {
    label: "Dominica",
    value: "XCD",
  },
  {
    label: "Dominican Republic",
    value: "DOP",
  },
  {
    label: "East Timor",
    value: "USD - East Timor",
  },
  {
    label: "Ecuador",
    value: "ECS",
  },
  {
    label: "Egypt",
    value: "EGP",
  },
  {
    label: "El Salvador",
    value: "SVC",
  },
  {
    label: "England",
    value: "GBP",
  },
  {
    label: "Equatorial Guinea",
    value: "XAF",
  },
  {
    label: "Eritrea",
    value: "ERN",
  },
  {
    label: "Estonia",
    value: "EUR - Estonia",
  },
  {
    label: "Ethiopia",
    value: "ETB",
  },
  {
    label: "Falkland Islands",
    value: "FKP",
  },
  {
    label: "Faroe Islands",
    value: "DKK",
  },
  {
    label: "Fiji Islands",
    value: "FJD",
  },
  {
    label: "Finland",
    value: "EUR - Finland",
  },
  {
    label: "France",
    value: "EUR - France",
  },
  {
    label: "French Guiana",
    value: "EUR - French Guiana",
  },
  {
    label: "French Polynesia",
    value: "XPF",
  },
  {
    label: "French Southern territories",
    value: "EUR - French Southern territories",
  },
  {
    label: "Gabon",
    value: "XAF",
  },
  {
    label: "Gambia",
    value: "GMD",
  },
  {
    label: "Georgia",
    value: "GEL",
  },
  {
    label: "Germany",
    value: "EUR - Germany",
  },
  {
    label: "Ghana",
    value: "GHS",
  },
  {
    label: "Gibraltar",
    value: "GIP",
  },
  {
    label: "Greece",
    value: "EUR - Greece",
  },
  {
    label: "Greenland",
    value: "DKK",
  },
  {
    label: "Grenada",
    value: "XCD",
  },
  {
    label: "Guadeloupe",
    value: "EUR - Guadeloupe",
  },
  {
    label: "Guam",
    value: "USD - Guam",
  },
  {
    label: "Guatemala",
    value: "QTQ",
  },
  {
    label: "Guinea",
    value: "GNF",
  },
  {
    label: "Guinea-Bissau",
    value: "CFA",
  },
  {
    label: "Guyana",
    value: "GYD",
  },
  {
    label: "Haiti",
    value: "HTG",
  },
  {
    label: "Heard Island and McDonald Islands",
    value: "AUD",
  },
  {
    label: "Holy See (Vatican City State)",
    value: "EUR - Holy See (Vatican City State)",
  },
  {
    label: "Honduras",
    value: "HNL",
  },
  {
    label: "Hong Kong",
    value: "HKD",
  },
  {
    label: "Hungary",
    value: "HUF",
  },
  {
    label: "Iceland",
    value: "ISK",
  },
  {
    label: "India",
    value: "INR",
  },
  {
    label: "Indonesia",
    value: "IDR",
  },
  {
    label: "Iran",
    value: "IRR",
  },
  {
    label: "Iraq",
    value: "IQD",
  },
  {
    label: "Ireland",
    value: "EUR - Ireland",
  },
  {
    label: "Israel",
    value: "ILS",
  },
  {
    label: "Italy",
    value: "EUR - Italy",
  },
  {
    label: "Ivory Coast",
    value: "XOF",
  },
  {
    label: "Jamaica",
    value: "JMD",
  },
  {
    label: "Japan",
    value: "JPY",
  },
  {
    label: "Jordan",
    value: "JOD",
  },
  {
    label: "Kazakhstan",
    value: "KZT",
  },
  {
    label: "Kenya",
    value: "KES",
  },
  {
    label: "Kiribati",
    value: "AUD",
  },
  {
    label: "Kuwait",
    value: "KWD",
  },
  {
    label: "Kyrgyzstan",
    value: "KGS",
  },
  {
    label: "Laos",
    value: "LAK",
  },
  {
    label: "Latvia",
    value: "LVL",
  },
  {
    label: "Lebanon",
    value: "LBP",
  },
  {
    label: "Lesotho",
    value: "LSL",
  },
  {
    label: "Liberia",
    value: "LRD",
  },
  {
    label: "Libyan Arab Jamahiriya",
    value: "LYD",
  },
  {
    label: "Liechtenstein",
    value: "CHF",
  },
  {
    label: "Lithuania",
    value: "LTL",
  },
  {
    label: "Luxembourg",
    value: "EUR - Luxembourg",
  },
  {
    label: "Macau",
    value: "MOP",
  },
  {
    label: "North Macedonia",
    value: "MKD",
  },
  {
    label: "Madagascar",
    value: "MGF",
  },
  {
    label: "Malawi",
    value: "MWK",
  },
  {
    label: "Malaysia",
    value: "MYR",
  },
  {
    label: "Maldives",
    value: "MVR",
  },
  {
    label: "Mali",
    value: "XOF",
  },
  {
    label: "Malta",
    value: "EUR - Malta",
  },
  {
    label: "Marshall Islands",
    value: "USD - Marshall Islands",
  },
  {
    label: "Martinique",
    value: "EUR - Martinique",
  },
  {
    label: "Mauritania",
    value: "MRO",
  },
  {
    label: "Mauritius",
    value: "MUR",
  },
  {
    label: "Mayotte",
    value: "EUR - Mayotte",
  },
  {
    label: "Mexico",
    value: "MXN",
  },
  {
    label: "Micronesia, Federated States of",
    value: "USD - Micronesia, Federated States of",
  },
  {
    label: "Moldova",
    value: "MDL",
  },
  {
    label: "Monaco",
    value: "EUR - Monaco",
  },
  {
    label: "Mongolia",
    value: "MNT",
  },
  {
    label: "Montserrat",
    value: "XCD",
  },
  {
    label: "Morocco",
    value: "MAD",
  },
  {
    label: "Mozambique",
    value: "MZN",
  },
  {
    label: "Myanmar",
    value: "MMR",
  },
  {
    label: "Namibia",
    value: "NAD",
  },
  {
    label: "Nauru",
    value: "AUD",
  },
  {
    label: "Nepal",
    value: "NPR",
  },
  {
    label: "Netherlands",
    value: "EUR - Netherlands",
  },
  {
    label: "Netherlands Antilles",
    value: "ANG",
  },
  {
    label: "New Caledonia",
    value: "XPF",
  },
  {
    label: "New Zealand",
    value: "NZD",
  },
  {
    label: "Nicaragua",
    value: "NIO",
  },
  {
    label: "Niger",
    value: "XOF",
  },
  {
    label: "Nigeria",
    value: "NGN",
  },
  {
    label: "Niue",
    value: "NZD",
  },
  {
    label: "Norfolk Island",
    value: "AUD",
  },
  {
    label: "North Korea",
    value: "KPW",
  },
  {
    label: "Northern Ireland",
    value: "GBP",
  },
  {
    label: "Northern Mariana Islands",
    value: "USD - Northern Mariana Islands",
  },
  {
    label: "Norway",
    value: "NOK",
  },
  {
    label: "Oman",
    value: "OMR",
  },
  {
    label: "Pakistan",
    value: "PKR",
  },
  {
    label: "Palau",
    value: "USD - Palau",
  },
  {
    value: "Palestine",
    currency_code: null,
  },
  {
    label: "Panama",
    value: "PAB",
  },
  {
    label: "Papua New Guinea",
    value: "PGK",
  },
  {
    label: "Paraguay",
    value: "PYG",
  },
  {
    label: "Peru",
    value: "PEN",
  },
  {
    label: "Philippines",
    value: "PHP",
  },
  {
    label: "Pitcairn Islands",
    value: "NZD",
  },
  {
    label: "Poland",
    value: "PLN",
  },
  {
    label: "Portugal",
    value: "EUR - Portugal",
  },
  {
    label: "Puerto Rico",
    value: "USD - Puerto Rico",
  },
  {
    label: "Qatar",
    value: "QAR",
  },
  {
    label: "Reunion",
    value: "EUR - Reunion",
  },
  {
    label: "Romania",
    value: "RON",
  },
  {
    label: "Russian Federation",
    value: "RUB",
  },
  {
    label: "Rwanda",
    value: "RWF",
  },
  {
    label: "Saint Helena",
    value: "SHP",
  },
  {
    label: "Saint Kitts and Nevis",
    value: "XCD",
  },
  {
    label: "Saint Lucia",
    value: "XCD",
  },
  {
    label: "Saint Pierre and Miquelon",
    value: "EUR - Saint Pierre and Miquelon",
  },
  {
    label: "Saint Vincent and the Grenadines",
    value: "XCD",
  },
  {
    label: "Samoa",
    value: "WST",
  },
  {
    label: "San Marino",
    value: "EUR - San Marino",
  },
  {
    label: "Sao Tome and Principe",
    value: "STD",
  },
  {
    label: "Saudi Arabia",
    value: "SAR",
  },
  {
    label: "Scotland",
    value: "GBP",
  },
  {
    label: "Senegal",
    value: "XOF",
  },
  {
    label: "Serbia",
    value: "RSD",
  },
  {
    label: "Seychelles",
    value: "SCR",
  },
  {
    label: "Sierra Leone",
    value: "SLL",
  },
  {
    label: "Singapore",
    value: "SGD",
  },
  {
    label: "Slovakia",
    value: "EUR - Slovakia",
  },
  {
    label: "Slovenia",
    value: "EUR - Slovenia",
  },
  {
    label: "Solomon Islands",
    value: "SBD",
  },
  {
    label: "Somalia",
    value: "SOS",
  },
  {
    label: "South Africa",
    value: "ZAR",
  },
  {
    label: "South Georgia and the South Sandwich Islands",
    value: "GBP",
  },
  {
    label: "South Korea",
    value: "KRW",
  },
  {
    label: "South Sudan",
    value: "SSP",
  },
  {
    label: "Spain",
    value: "EUR - Spain",
  },
  {
    label: "Sri Lanka",
    value: "LKR",
  },
  {
    label: "Sudan",
    value: "SDG",
  },
  {
    label: "Suriname",
    value: "SRD",
  },
  {
    label: "Svalbard and Jan Mayen",
    value: "NOK",
  },
  {
    label: "Swaziland",
    value: "SZL",
  },
  {
    label: "Sweden",
    value: "SEK",
  },
  {
    label: "Switzerland",
    value: "CHF",
  },
  {
    label: "Syria",
    value: "SYP",
  },
  {
    label: "Tajikistan",
    value: "TJS",
  },
  {
    label: "Tanzania",
    value: "TZS",
  },
  {
    label: "Thailand",
    value: "THB",
  },
  {
    label: "The Democratic Republic of Congo",
    value: "CDF",
  },
  {
    label: "Togo",
    value: "XOF",
  },
  {
    label: "Tokelau",
    value: "NZD",
  },
  {
    label: "Tonga",
    value: "TOP",
  },
  {
    label: "Trinidad and Tobago",
    value: "TTD",
  },
  {
    label: "Tunisia",
    value: "TND",
  },
  {
    label: "Turkey",
    value: "TRY",
  },
  {
    label: "Turkmenistan",
    value: "TMT",
  },
  {
    label: "Turks and Caicos Islands",
    value: "USD - Turks and Caicos Islands",
  },
  {
    label: "Tuvalu",
    value: "AUD",
  },
  {
    label: "Uganda",
    value: "UGX",
  },
  {
    label: "Ukraine",
    value: "UAH",
  },
  {
    label: "United Arab Emirates",
    value: "AED",
  },
  {
    label: "United Kingdom",
    value: "GBP",
  },
  {
    label: "United States",
    value: "USD",
  },
  {
    label: "United States Minor Outlying Islands",
    value: "USD - Island",
  },
  {
    label: "Uruguay",
    value: "UYU",
  },
  {
    label: "Uzbekistan",
    value: "UZS",
  },
  {
    label: "Vanuatu",
    value: "VUV",
  },
  {
    label: "Venezuela",
    value: "VEF",
  },
  {
    label: "Vietnam",
    value: "VND",
  },
  {
    label: "Virgin Islands, British",
    value: "USD - Virgin Islands, British",
  },
  {
    label: "Virgin Islands, U.S.",
    value: "USD - Virgin Islands, U.S.",
  },
  {
    label: "Wales",
    value: "GBP",
  },
  {
    label: "Wallis and Futuna",
    value: "XPF",
  },
  {
    label: "Western Sahara",
    value: "MAD",
  },
  {
    label: "Yemen",
    value: "YER",
  },
  {
    label: "Zambia",
    value: "ZMW",
  },
  {
    label: "Zimbabwe",
    value: "ZWD",
  },
];

export const languages = [
  { value: "", label: "---- Select language ----" },
  { value: "Afar", label: "Afar" },
  { value: "Abkhazian", label: "Abkhazian" },
  { value: "Avestan", label: "Avestan" },
  { value: "Afrikaans", label: "Afrikaans" },
  { value: "Akan", label: "Akan" },
  { value: "Amharic", label: "Amharic" },
  { value: "Aragonese", label: "Aragonese" },
  { value: "Arabic", label: "Arabic" },
  { value: "Assamese", label: "Assamese" },
  { value: "Avaric", label: "Avaric" },
  { value: "Aymara", label: "Aymara" },
  { value: "Azerbaijani", label: "Azerbaijani" },
  { value: "Bashkir", label: "Bashkir" },
  { value: "Belarusian", label: "Belarusian" },
  { value: "Bulgarian", label: "Bulgarian" },
  { value: "Bihari languages", label: "Bihari languages" },
  { value: "Bislama", label: "Bislama" },
  { value: "Bambara", label: "Bambara" },
  { value: "Bengali", label: "Bengali" },
  { value: "Tibetan", label: "Tibetan" },
  { value: "Breton", label: "Breton" },
  { value: "Bosnian", label: "Bosnian" },
  { value: "Catalan; Valencian", label: "Catalan; Valencian" },
  { value: "Chechen", label: "Chechen" },
  { value: "Chamorro", label: "Chamorro" },
  { value: "Corsican", label: "Corsican" },
  { value: "Cree", label: "Cree" },
  { value: "Czech", label: "Czech" },
  {
    value:
      "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic",
    label:
      "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic",
  },
  { value: "Chuvash", label: "Chuvash" },
  { value: "Welsh", label: "Welsh" },
  { value: "Danish", label: "Danish" },
  { value: "German", label: "German" },
  { value: "Divehi; Dhivehi; Maldivian", label: "Divehi; Dhivehi; Maldivian" },
  { value: "Dzongkha", label: "Dzongkha" },
  { value: "Ewe", label: "Ewe" },
  { value: "Greek, Modern (1453-)", label: "Greek, Modern (1453-)" },
  { value: "English", label: "English" },
  { value: "Esperanto", label: "Esperanto" },
  { value: "Spanish; Castilian", label: "Spanish; Castilian" },
  { value: "Estonian", label: "Estonian" },
  { value: "Basque", label: "Basque" },
  { value: "Persian", label: "Persian" },
  { value: "Fulah", label: "Fulah" },
  { value: "Finnish", label: "Finnish" },
  { value: "Fijian", label: "Fijian" },
  { value: "Faroese", label: "Faroese" },
  { value: "French", label: "French" },
  { value: "Western Frisian", label: "Western Frisian" },
  { value: "Irish", label: "Irish" },
  { value: "Gaelic; Scomttish Gaelic", label: "Gaelic; Scomttish Gaelic" },
  { value: "Galician", label: "Galician" },
  { value: "Guarani", label: "Guarani" },
  { value: "Gujarati", label: "Gujarati" },
  { value: "Manx", label: "Manx" },
  { value: "Hausa", label: "Hausa" },
  { value: "Hebrew", label: "Hebrew" },
  { value: "Hindi", label: "Hindi" },
  { value: "Hiri Motu", label: "Hiri Motu" },
  { value: "Croatian", label: "Croatian" },
  { value: "Haitian; Haitian Creole", label: "Haitian; Haitian Creole" },
  { value: "Hungarian", label: "Hungarian" },
  { value: "Armenian", label: "Armenian" },
  { value: "Herero", label: "Herero" },
  {
    value: "Interlingua (International Auxiliary Language Association)",
    label: "Interlingua (International Auxiliary Language Association)",
  },
  { value: "Indonesian", label: "Indonesian" },
  { value: "Interlingue; Occidental", label: "Interlingue; Occidental" },
  { value: "Igbo", label: "Igbo" },
  { value: "Sichuan Yi; Nuosu", label: "Sichuan Yi; Nuosu" },
  { value: "Inupiaq", label: "Inupiaq" },
  { value: "Ido", label: "Ido" },
  { value: "Icelandic", label: "Icelandic" },
  { value: "Italian", label: "Italian" },
  { value: "Inuktitut", label: "Inuktitut" },
  { value: "Japanese", label: "Japanese" },
  { value: "Javanese", label: "Javanese" },
  { value: "Georgian", label: "Georgian" },
  { value: "Kongo", label: "Kongo" },
  { value: "Kikuyu; Gikuyu", label: "Kikuyu; Gikuyu" },
  { value: "Kuanyama; Kwanyama", label: "Kuanyama; Kwanyama" },
  { value: "Kazakh", label: "Kazakh" },
  { value: "Kalaallisut; Greenlandic", label: "Kalaallisut; Greenlandic" },
  { value: "Central Khmer", label: "Central Khmer" },
  { value: "Kannada", label: "Kannada" },
  { value: "Korean", label: "Korean" },
  { value: "Kanuri", label: "Kanuri" },
  { value: "Kashmiri", label: "Kashmiri" },
  { value: "Kurdish", label: "Kurdish" },
  { value: "Komi", label: "Komi" },
  { value: "Cornish", label: "Cornish" },
  { value: "Kirghiz; Kyrgyz", label: "Kirghiz; Kyrgyz" },
  { value: "Latin", label: "Latin" },
  {
    value: "Luxembourgish; Letzeburgesch",
    label: "Luxembourgish; Letzeburgesch",
  },
  { value: "Ganda", label: "Ganda" },
  {
    value: "Limburgan; Limburger; Limburgish",
    label: "Limburgan; Limburger; Limburgish",
  },
  { value: "Lingala", label: "Lingala" },
  { value: "Lao", label: "Lao" },
  { value: "Lithuanian", label: "Lithuanian" },
  { value: "Luba-Katanga", label: "Luba-Katanga" },
  { value: "Latvian", label: "Latvian" },
  { value: "Malagasy", label: "Malagasy" },
  { value: "Marshallese", label: "Marshallese" },
  { value: "Maori", label: "Maori" },
  { value: "Macedonian", label: "Macedonian" },
  { value: "Malayalam", label: "Malayalam" },
  { value: "Mongolian", label: "Mongolian" },
  { value: "Marathi", label: "Marathi" },
  { value: "Malay", label: "Malay" },
  { value: "Maltese", label: "Maltese" },
  { value: "Burmese", label: "Burmese" },
  { value: "Nauru", label: "Nauru" },
  {
    value: "Bokmål, Norwegian; Norwegian Bokmål",
    label: "Bokmål, Norwegian; Norwegian Bokmål",
  },
  {
    value: "Ndebele, North; North Ndebele",
    label: "Ndebele, North; North Ndebele",
  },
  { value: "Nepali", label: "Nepali" },
  { value: "Ndonga", label: "Ndonga" },
  { value: "Dutch; Flemish", label: "Dutch; Flemish" },
  {
    value: "Norwegian Nynorsk; Nynorsk, Norwegian",
    label: "Norwegian Nynorsk; Nynorsk, Norwegian",
  },
  { value: "Norwegian", label: "Norwegian" },
  {
    value: "Ndebele, South; South Ndebele",
    label: "Ndebele, South; South Ndebele",
  },
  { value: "Navajo; Navaho", label: "Navajo; Navaho" },
  { value: "Chichewa; Chewa; Nyanja", label: "Chichewa; Chewa; Nyanja" },
  { value: "Occitan (post 1500)", label: "Occitan (post 1500)" },
  { value: "Ojibwa", label: "Ojibwa" },
  { value: "Oromo", label: "Oromo" },
  { value: "Oriya", label: "Oriya" },
  { value: "Ossetian; Ossetic", label: "Ossetian; Ossetic" },
  { value: "Panjabi; Punjabi", label: "Panjabi; Punjabi" },
  { value: "Pali", label: "Pali" },
  { value: "Polish", label: "Polish" },
  { value: "Pushto; Pashto", label: "Pushto; Pashto" },
  { value: "Portuguese", label: "Portuguese" },
  { value: "Quechua", label: "Quechua" },
  { value: "Romansh", label: "Romansh" },
  { value: "Rundi", label: "Rundi" },
  {
    value: "Romanian; Moldavian; Moldovan",
    label: "Romanian; Moldavian; Moldovan",
  },
  { value: "Russian", label: "Russian" },
  { value: "Kinyarwanda", label: "Kinyarwanda" },
  { value: "Sanskrit", label: "Sanskrit" },
  { value: "Sardinian", label: "Sardinian" },
  { value: "Sindhi", label: "Sindhi" },
  { value: "Northern Sami", label: "Northern Sami" },
  { value: "Sango", label: "Sango" },
  { value: "Sinhala; Sinhalese", label: "Sinhala; Sinhalese" },
  { value: "Slovak", label: "Slovak" },
  { value: "Slovenian", label: "Slovenian" },
  { value: "Samoan", label: "Samoan" },
  { value: "Shona", label: "Shona" },
  { value: "Somali", label: "Somali" },
  { value: "Albanian", label: "Albanian" },
  { value: "Serbian", label: "Serbian" },
  { value: "Swati", label: "Swati" },
  { value: "Sotho, Southern", label: "Sotho, Southern" },
  { value: "Sundanese", label: "Sundanese" },
  { value: "Swedish", label: "Swedish" },
  { value: "Swahili", label: "Swahili" },
  { value: "Tamil", label: "Tamil" },
  { value: "Telugu", label: "Telugu" },
  { value: "Tajik", label: "Tajik" },
  { value: "Thai", label: "Thai" },
  { value: "Tigrinya", label: "Tigrinya" },
  { value: "Turkmen", label: "Turkmen" },
  { value: "Tagalog", label: "Tagalog" },
  { value: "Tswana", label: "Tswana" },
  { value: "Tonga (Tonga Islands)", label: "Tonga (Tonga Islands)" },
  { value: "Turkish", label: "Turkish" },
  { value: "Tsonga", label: "Tsonga" },
  { value: "Tatar", label: "Tatar" },
  { value: "Twi", label: "Twi" },
  { value: "Tahitian", label: "Tahitian" },
  { value: "Uighur; Uyghur", label: "Uighur; Uyghur" },
  { value: "Ukrainian", label: "Ukrainian" },
  { value: "Urdu", label: "Urdu" },
  { value: "Uzbek", label: "Uzbek" },
  { value: "Venda", label: "Venda" },
  { value: "Vietnamese", label: "Vietnamese" },
  { value: "Volapük", label: "Volapük" },
  { value: "Walloon", label: "Walloon" },
  { value: "Wolof", label: "Wolof" },
  { value: "Xhosa", label: "Xhosa" },
  { value: "Yiddish", label: "Yiddish" },
  { value: "Yoruba", label: "Yoruba" },
  { value: "Zhuang; Chuang", label: "Zhuang; Chuang" },
  { value: "Chinese", label: "Chinese" },
  { value: "Zulu", label: "Zulu" },
];

export const courseUploadField = [
  {
    id: "languageTestDocument",
    documentName: "Language Test",
    required: false,
    type: "basic",
  },
  {
    id: "applicationFeeDocument",
    documentName: "proof of payment of application fee",
    required: false,
    type: "basic",
  },
  {
    id: "admissionTestDocument",
    documentName: "Admission Test",
    required: false,
    type: "basic",
  },
  {
    id: "cvDocument",
    documentName: "CV",
    required: true,
    type: "basic",
  },
  {
    id: "letterOfMotivationDocument",
    documentName: "Letter Of motivation",
    required: false,
    type: "basic",
  },
  {
    id: "proofOfWorkExperienceDocument",
    documentName: "Proof of work experience",
    required: false,
    type: "basic",
  },
  {
    id: "OnlinerPitchVideoDocument",
    documentName: "Online pitch video",
    required: false,
    type: "basic",
  },
  {
    id: "academicQualificationDocument",
    documentName: "Academic qualification",
    required: false,
    type: "basic",
  },
  {
    id: "IDCopy",
    documentName: "Passport/Id Copy",
    required: true,
    type: "basic",
  },
];

export const courseSubjectList = [
  {
    id: "1",
    name: "Mathematics",
    value: "Mathematics",
  },
  {
    id: "2",
    name: "Physics",
    value: "Physics",
  },
  {
    id: "3",
    name: "History",
    value: "History",
  },
  {
    id: "4",
    name: "Philosophy",
    value: "Philosophy",
  },
  {
    id: "5",
    name: "Political science",
    value: "Political science",
  },
  {
    id: "6",
    name: "Aerospace engineering",
    value: "Aerospace engineering",
  },
  {
    id: "7",
    name: "Home Science",
    value: "Home Science",
  },
  {
    id: "8",
    name: "Agriculture",
    value: "Agriculture",
  },
  {
    id: "9",
    name: "Geography",
    value: "Geography",
  },
  {
    id: "10",
    name: "Computer Science",
    value: "Computer Science",
  },
  {
    id: "11",
    name: "Sociology",
    value: "Sociology",
  },
  {
    id: "12",
    name: "Humanities",
    value: "Humanities",
  },
  {
    id: "13",
    name: "Social science",
    value: "Social science",
  },
  {
    id: "14",
    name: "Anthropology",
    value: "Anthropology",
  },
  {
    id: "15",
    name: "Biotechnology",
    value: "Biotechnology",
  },
  {
    id: "16",
    name: "Electrical engineering",
    value: "Electrical engineering",
  },
  {
    id: "17",
    name: "Chemistry",
    value: "Chemistry",
  },
  {
    id: "18",
    name: "Psychology",
    value: "Psychology",
  },
  {
    id: "19",
    name: "Law",
    value: "Law",
  },
  {
    id: "20",
    name: "Environmental science",
    value: "Environmental science",
  },
  {
    id: "21",
    name: "Accounting",
    value: "Accounting",
  },
  {
    id: "22",
    name: "Chemical Engineering",
    value: "Chemical Engineering",
  },
  {
    id: "23",
    name: "Journalism",
    value: "Journalism",
  },
];

export const newCourseSubjectList = [
  {
    id: "0",
    label: "---- Select Subject ----",
    value: "",
  },
  {
    id: "1",
    label: "Mathematics",
    value: "Mathematics",
  },
  {
    id: "2",
    label: "Physics",
    value: "Physics",
  },
  {
    id: "3",
    label: "History",
    value: "History",
  },
  {
    id: "4",
    label: "Philosophy",
    value: "Philosophy",
  },
  {
    id: "5",
    label: "Political science",
    value: "Political science",
  },
  {
    id: "6",
    label: "Aerospace engineering",
    value: "Aerospace engineering",
  },
  {
    id: "7",
    label: "Home Science",
    value: "Home Science",
  },
  {
    id: "8",
    label: "Agriculture",
    value: "Agriculture",
  },
  {
    id: "9",
    label: "Geography",
    value: "Geography",
  },
  {
    id: "10",
    label: "Computer Science",
    value: "Computer Science",
  },
  {
    id: "11",
    label: "Sociology",
    value: "Sociology",
  },
  {
    id: "12",
    label: "Humanities",
    value: "Humanities",
  },
  {
    id: "13",
    label: "Social science",
    value: "Social science",
  },
  {
    id: "14",
    label: "Anthropology",
    value: "Anthropology",
  },
  {
    id: "15",
    label: "Biotechnology",
    value: "Biotechnology",
  },
  {
    id: "16",
    label: "Electrical engineering",
    value: "Electrical engineering",
  },
  {
    id: "17",
    label: "Chemistry",
    value: "Chemistry",
  },
  {
    id: "18",
    label: "Psychology",
    value: "Psychology",
  },
  {
    id: "19",
    label: "Law",
    value: "Law",
  },
  {
    id: "20",
    label: "Environmental science",
    value: "Environmental science",
  },
  {
    id: "21",
    label: "Accounting",
    value: "Accounting",
  },
  {
    id: "22",
    label: "Chemical Engineering",
    value: "Chemical Engineering",
  },
  {
    id: "23",
    label: "Journalism",
    value: "Journalism",
  },
];

export const newCourseSubjectListForStudent = [
  {
    id: "0",
    label: "---- Select Subject ----",
    value: "",
  },
  {
    id: "1",
    label: "Arts and humanities",
    value: "Arts and humanities",
  },
  {
    id: "2",
    label: "Architecture",
    value: "Architecture",
  },
  {
    id: "3",
    label: "Business",
    value: "Business",
  },
  {
    id: "4",
    label: "Education",
    value: "Education",
  },
  {
    id: "5",
    label: "Engineering",
    value: "Engineering",
  },
  {
    id: "6",
    label: "Health sciences",
    value: "Health sciences",
  },
  {
    id: "7",
    label: "Law",
    value: "Law",
  },
  {
    id: "8",
    label: "Natural sciences",
    value: "Natural sciences",
  },
  {
    id: "9",
    label: "Political science",
    value: "Political science",
  },
  {
    id: "10",
    label: "Social sciences",
    value: "Social sciences",
  },
  {
    id: "11",
    label: "Technology",
    value: "Technology",
  },
];

export const studentSignUpStateDefaultObject = {
  firstName: "",
  lastName: "",
  username: "",
  email: "",
  password: "",
  confirmPassword: "",
};

export const universitySignInStep1DefaultStateObj = {
  universityName: "",
  universityRegistrationNumber: "",
  universityTaxNumber: "",
  typeOfInstitution: "",
  universityCountry: "",
};

export const universityAddDefaultStateObj = {
  universityName: "",
  universityRegistrationNumber: "",
  universityTaxNumber: "",
  typeOfInstitution: "",
  universityCoverImage: "",
  subImage1: "",
  subImage2: "",
  subImage3: "",
  universityCountry: "",
  universityCity: "",
  universityAddress: "",
  universityPostalcode: "",
  firstName: "",
  lastName: "",
  username: "",
  role: "",
  email: "",
  password: "",
  confirmPassword: "",
  universityDescription: "",
};

export const agencySigninStep1DefaultStateObj = {
  agencyName: "",
  agencyRegistrationNumber: "",
  agencyTaxNumber: "",
  // agencyCommission: "",
  agencyAddress: "",
  agencyMainPhoneNumber: "",
};

export const agencySignUpStep4DefaultStateObj = {
  firstName: "",
  lastName: "",
  username: "",
  role: "",
  email: "",
  password: "",
  confirmPassword: "",
};

export const addApplicationDefaultStateObject = {
  academicInstitution: "",
  addedBy: "",
  address: "",
  applicationDate: new Date().toISOString().substring(0, 10),
  applicationFormStatus: "",
  applicationNo: randomNumberString(6),
  averageGrade: "",
  cityOfResidence: "",
  countryOfBirth: "",
  universityId: "",
  courseId: "",
  countryOfEducation: "",
  countryOfResidence: "",
  courseFee: "",
  courseLevel: "",
  creatorRole: "",
  firstLanguage: "",
  firstName: "",
  gender: "",
  highestEducationLevel: "",
  languageOfInstruction: "",
  lastName: "",
  nationality: "",
  placeOfBirth: "",
  postcode: "",
  secondCitizenship: "",
  userProfilePicture: "",
  other: [],
};

export const addCourseStateDefaultStateObject = {
  coursethumbnail: "",
  courseName: "",
  courseUniversityId: "",
  courseDuration: "",
  courseSubjectType: "",
  courseStartDate: "",
  courseApplicationStartDate: "",
  courseApplicationEndDate: "",
  possibelCourseEndDate: "",
  courseLevel: "",
  courseMaxSeat: "",
  courseApplicationFee: "",
  courseFee: "",
  courseStudyMode: "",
  courseLanguage: "",
  courseCountry: "",
  courseCity: "",
  courseDescription: "",
  courseSpecialFeature: "",
  basicDoumentRequirement: [],
  courseWorkRequirement: [],
  englishTestRequirement: [],
};

export const addAgentStateDefaultStateObject = {
  agencyName: "",
  agencyRegistrationNumber: "",
  agencyTaxNumber: "",
  agencyCommission: "",
  agencyCountry: "",
  agencyCity: "",
  agencyAddress: "",
  postcode: "",
  firstName: "",
  lastName: "",
  username: "",
  role: "",
  email: "",
  password: "",
};

export const addgeneralUserDefaultStateObject = {
  username: "",
  email: "",
  firstName: "",
  lastName: "",
  role: "",
  password: "",
  confirmPassword: "",
};

export const agencyListTableCols = [
  {
    id: 0,
    name: "id",
  },
  {
    id: 1,
    name: "Agency Name",
  },
  {
    id: 2,
    name: "Agent Name",
  },
  {
    id: 3,
    name: "Onboarded Date",
  },
  {
    id: 4,
    name: "Total Number of Student",
  },
  {
    id: 5,
    name: "Open Applications",
  },
  {
    id: 6,
    name: "Accepted Applications",
  },
  {
    id: 7,
    name: "Rejected Applications",
  },
  {
    id: 8,
    name: "City",
  },
  {
    id: 9,
    name: "Country",
  },
];

export const agencyFieldstoShow = [
  "agencyName",
  "agentName",
  "createdAt",
  "studentCount",
  "openApplicationCount",
  "acceptedApplication",
  "rejectedApplication",
  "agencyCity",
  "agencyCountry",
];

export const universityCourseListTableCols = [
  {
    id: 0,
    name: "id",
  },
  {
    id: 1,
    name: "Course Name",
  },
  {
    id: 2,
    name: "Course Fee",
  },
  {
    id: 3,
    name: "course Level",
  },
  {
    id: 4,
    name: "Course Mode",
  },
  {
    id: 5,
    name: "Course Max Seat",
  },
  {
    id: 6,
    name: "Applicaions",
  },
  {
    id: 7,
    name: "Start date",
  },
];

export const universityCourseViewFileds = [
  "courseName",
  "courseFee",
  "courseLevel",
  "courseStudyMode",
  "courseMaxSeat",
  "applicationsCount",
  "courseStartDate",
];

export const maritalStatus = [
  "Single",
  "Married",
  "Widowed",
  "Divorced",
  "Separated",
  "Registered Partnership",
];

export const newMaritalStatus = [
  { label: "---- Select Status ----", value: "" },
  { label: "Single", value: "Single" },
  { label: "Married", value: "Married" },
  { label: "Widowed", value: "Widowed" },
  { label: "Divorced", value: "Divorced" },
  { label: "Separated", value: "Separated" },
  { label: "Registered Partnership", value: "Registered Partnership" },
];

export const highestEducationList = [
  "----Select graduation----",
  "Grade1",
  "Grade2",
  "Grade3",
  "Grade4",
  "Grade5",
  "Grade6",
  "Grade7",
  "Grade8",
  "Grade9",
  "Grade10",
  "Grade11",
  "Grade12",
  "Graduate",
  "Post Graduate",
];

export const newhighestEducationList = [
  { label: "----Select graduation----", value: "" },
  { label: "Grade1", value: "Grade1" },
  { label: "Grade2", value: "Grade2" },
  { label: "Grade3", value: "Grade3" },
  { label: "Grade4", value: "Grade4" },
  { label: "Grade5", value: "Grade5" },
  { label: "Grade6", value: "Grade6" },
  { label: "Grade7", value: "Grade7" },
  { label: "Grade8", value: "Grade8" },
  { label: "Grade9", value: "Grade9" },
  { label: "Grade10", value: "Grade10" },
  { label: "Grade11", value: "Grade11" },
  { label: "Grade12", value: "Grade12" },
  { label: "Graduate", value: "Graduate" },
  { label: "Post Graduate", value: "Post Graduate" },
];

export const addStudentStateInitialStateObject = {
  studentProfileImage: "",
  firstName: "",
  lastName: "",
  email: "",
  // visaPermitStatus: "",
  placeOfBirth: "",
  countryOfBirth: "",
  citizenship: "",
  secondCitizenship: "",
  countryOfResidence: "",
  firstlanguage: "",
  maritalStatus: "",
  cityOfResidence: "",
  gender: "",
  cv: "",
  address: "",
  passportNumber: "",
  dateOfBirth: "",
  postcode: "",
  passportExpiry: "",
  passportCopy: "",
  username: "",
  role: "",
  highestEducation: "",
  studentAcademicInstitution: "",
  languageOfInstruction: "",
  countryOfEducation: "",
  // gradingSystem: "",
  averageGrade: "",
  dateAttendedFrom: "",
  dateAttendedUntil: "",
  nameOfDegree: "",
  // englishLanguageTest: "",
  // languageTestScore: "",
  proofOfAcadamicQualification: "",
  // internationalExamination: "",
  // internationalExamScore: "",
  desiredCountry: [],
  workDuringStudy: "",
  postGraduateWorkPermit: "",
  desiredlevelOfStudy: "",
  desiredCourseDiscipline: "",
  courseID: "",
  preferedStartMonth: "",
  password: "",
};

export const studentListCols = [
  {
    id: 0,
    name: "id",
  },
  {
    id: 1,
    name: "First Name",
  },
  {
    id: 2,
    name: "Last Name",
  },
  {
    id: 3,
    name: "Country Of Recidence",
  },
  {
    id: 4,
    name: "Highested level of Education",
  },
  {
    id: 5,
    name: "Date Joined",
  },
];

export const studentFieldToShow = [
  "firstName",
  "lastName",
  "countryOfResidence",
  "highestEducation",
  "createdAt",
];

export const blogListTableColumn = [
  {
    id: 0,
    name: "id",
  },
  {
    id: 1,
    name: "Title",
  },
  {
    id: 2,
    name: "Author",
  },
  {
    id: 3,
    name: "Status",
  },
  {
    id: 4,
    name: "Created at",
  },
  {
    id: 5,
    name: "Published at",
  },
];

export const BlogfiledToShow = [
  "title",
  "author/username",
  "status",
  "createdAt",
  "publishDate",
];

export const agentListtableCols = [
  {
    id: 0,
    name: "id",
  },
  {
    id: 1,
    name: "First name",
  },
  {
    id: 2,
    name: "Last name",
  },
  {
    id: 3,
    name: "Username",
  },
  {
    id: 4,
    name: "Email",
  },
  {
    id: 5,
    name: "Role",
  },
  {
    id: 6,
    name: "Added By",
  },
];

export const agenttableFields = [
  "firstName",
  "lastName",
  "username",
  "email",
  "role",
  "addedBy",
];

export const whyJoinUs = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`;

export const universityWhyJoinUs = `We are a fast-growing start-up that is revolutionizing the way students apply to schools. We want educational institutions that are excited to partner with us and to help us make a difference in the lives of students around the world.`;
export const agencyWhyJoinUs = `We are a fast-growing start-up that is revolutionizing the way students apply to schools. We want educational institutions that are excited to partner with us and to help us make a difference in the lives of students around the world.`;

export const whyJoinUspoints = [
  {
    id: 0,
    line: `Connect with a Larger International Student Base. Imagine spreading your university's name across the globe. Zoomtocollege serves as that critical bridge connecting you to international students from every corner of the world.`,
  },
  {
    id: 1,
    line: `Simplify Your University Application Procedure. Zoomtocollege takes all the application stress off students' shoulders. They can conveniently send out their applications to diverse universities using just one form. What a time-saver! Plus, universities benefit too—it takes a load off their admin work when it comes to sieving through and handling student applications.`,
  },
  {
    id: 2,
    line: `Tap into Crucial Data and Insights on International Students.By giving you access to priceless insights about your prospective international student base, Zoomtotcolleg helps to better understand them.`,
  },
  {
    id: 4,
    line: `Boost the Overall Student Experience. In today’s competitive academic landscape, delivering an unforgettable student experience is paramount—and Zoomtocollege makes sure this happens effortlessly. Our platform offers prospective students loads of handy tools and resources they'll need when applying. Educational institutions on board will find themselves enhancing their student journey, thereby helping even more scholars secure their educational dreams.`,
  },
];

export const agencyWhyJoinUsPoints = [
  {
    id: 0,
    line: `Access an Extensive Network of Universities: Zoomtocollege boasts a growing network of universities and colleges around the globe. This opens you to a plethora of programs and institutions, giving their students lot more options.`,
  },
  {
    id: 1,
    line: `Simplified Application Procedure: With Zoomtocollege offering an effortless and streamlined application procedure for international students, it empowers you to assist your students apply to multiple universities and programs efficiently.`,
  },
  {
    id: 2,
    line: `A Team of Experts Always at Your Service: Zoomtocollege’s knowledgeable team is always on hand ready to offer end-to-end support to both you and your students.`,
  },
  {
    id: 3,
    line: `Data-Driven Technology Solutions: To make life easier for you, Zoomtocollege offers a range of savvy tech solutions. These tools aid you in managing your businesses proficiently and effectively.`,
  },
];

export const whoWeAre = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem 
Ipsum has been the industry's standard dummy text ever since the 1500s, when an
unknown printer took a galley of type and scrambled it to make a type specimen
book. It has survived not only five centuries, but also the leap into electronic
typesetting, remaining essentially unchanged.It was popularised in the 1960s with the
release of Letraset sheets containing Lorem Ipsum passages, and more recently with
desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
Ipsum has been the industry's standard dummy text ever since the 1500s, when an
unknown printer took a galley of type and scrambled it to make a type specimen
book. It has survived not only five centuries, but also the leap into electronic
typesetting, remaining essentially unchanged.It was popularised in the 1960s.`;

export const whoWeAreUniversity = `We are a technology company that is revolutionizing the way students apply to schools. We are passionate about education and believe that everyone deserves the opportunity to pursue their educational dreams, regardless of their background.
Our platform connects students with schools all over the world, making it easier for them to find the right school for them and to apply for admission. We also provide schools with the tools they need to streamline their application process and to make informed decisions about admissions.
We are committed to working with educational institutions to help them achieve their goals. We believe that by partnering with us, schools can reach a wider audience of students, improve their admissions outcomes, and build their brand.`;

export const whoWeAreAgency = `We are a technology company that is revolutionizing the way students apply to schools. We are passionate about education and believe that everyone deserves the opportunity to pursue their educational dreams, regardless of their background.
Our platform connects students with schools all over the world, making it easier for them to find the right school for them and to apply for admission. We also provide schools with the tools they need to streamline their application process and to make informed decisions about admissions.
We are committed to working with educational institutions to help them achieve their goals. We believe that by partnering with us, schools can reach a wider audience of students, improve their admissions outcomes, and build their brand.`;

export const howDoesItWork = [
  {
    step: `STEP 1`,
    description: `Lorem Ipsum is simply dummy text of the printing
        Ipsum has been the industry's`,
  },
  {
    step: `STEP 2`,
    description: `Lorem Ipsum is simply dummy text of the printing
        Ipsum has been the industry's`,
  },
  {
    step: `STEP 3`,
    description: `Lorem Ipsum is simply dummy text of the printing
        Ipsum has been the industry's`,
  },
  {
    step: `STEP 4`,
    description: `Lorem Ipsum is simply dummy text of the printing
        Ipsum has been the industry's`,
  },
  {
    step: `STEP 5`,
    description: `Lorem Ipsum is simply dummy text of the printing
        Ipsum has been the industry's`,
  },
  {
    step: `STEP 6`,
    description: `Lorem Ipsum is simply dummy text of the printing
        Ipsum has been the industry's`,
  },
];

export const howDoesItWorkUniversity = [
  {
    step: `STEP 1`,
    description: `Complete university inquiry form`,
  },
  {
    step: `STEP 2`,
    description: `Your details will be reviewed by `,
  },
  {
    step: `STEP 3`,
    description: `A team member will contact you`,
  },
  {
    step: `STEP 4`,
    description: `Receive official partnership agreement`,
  },
  {
    step: `STEP 5`,
    description: `Send University brochure and course catalogue`,
  },
  {
    step: `STEP 6`,
    description: `Publish on zoomtocollege and begin recruiting students`,
  },
];

export const howDoesItWorkAgency = [
  {
    step: `STEP 1`,
    description: `Complete Agency inquiry form`,
  },
  {
    step: `STEP 2`,
    description: `Your details will be reviewed by`,
  },
  {
    step: `STEP 3`,
    description: `A team member will contact you`,
  },
  {
    step: `STEP 4`,
    description: `Receive official partnership agreement`,
  },
  {
    step: `STEP 5`,
    description: `Your account created`,
  },
  {
    step: `STEP 6`,
    description: `Begin recruiting students`,
  },
];

export const AgencySignUpTabs = [
  { tab: "tab1", name: "Business Details", href: "#", current: true },
  { tab: "tab2", name: "Business Services", href: "#", current: false },
  { tab: "tab3", name: "Recruitment Details", href: "#", current: false },
  { tab: "tab4", name: "Lead User", href: "#", current: false },
];

export const ContentUpdateTabs = [
  { tab: "tab1", name: "Home Page Content", href: "#", current: false },
  { tab: "tab2", name: "About Us Content", href: "#", current: false },
];

export const MailManagementTabs = [
  { tab: "tab1", name: "Institutions", href: "#", current: true },
  { tab: "tab2", name: "Agencies", href: "#", current: false },
  { tab: "tab3", name: "Students", href: "#", current: false },
];

export const UniversitySignUpTabs = [
  { tab: "tab1", name: "University Details", href: "#", current: true },
  { tab: "tab2", name: "User Information", href: "#", current: false },
];

export const agencyStatisticsUInfo = {
  totalAcceptedApplicationForAgency: {
    statTitle: "Total Accepted Applications",
    description:
      "All applications made by your agency that have been accepted by the respective education institution(s)",
    link: "/agency/application?filter=acceptedByUniversity",
  },
  totalEstimatedAgencyRevenue: {
    statTitle: "Total Estimate Agency Revenue (€)",
    description:
      "the total sum of commission you will earn from applications you made that have either been accepted or are still under review by the respective education institution(s)",
    link: "other",
  },

  totalOpenApplicationForAgency: {
    statTitle: "Total Open Applications",
    description: "All applications still under review with a final decision",
    link: "/agency/application?filter=underReview",
  },
  totalPotentialAgencyRevenue: {
    statTitle: "Total Potential Agency Revenue (€)",
    description:
      "the total sum of commission you will earn from applications you made that have been accepted by the respective education institution(s)",
    link: "",
  },
  totalRecruitedStudent: {
    statTitle: "Total Student Recruited",
    description:
      "All students recruited by your agency (including Open, Closed and Rejected)",
    link: "/agency/student",
  },
  totalRejectedApplicationForAgency: {
    statTitle: "Total Rejected Applications",
    description:
      "All applications made by your agency that have been rejected by the respective education institution(s)",
    link: "/agency/application?filter=rejectedByUniversity",
  },
};

export const universityStatisticInfo = {
  totalCourseBelongToUniversity: {
    statTitle: "Total Number of Course",
    description: "All courses offered by your institutions",
    link: "/university/courseList",
  },
  totalApplicationReceivedToUniversity: {
    statTitle: "Total Number of Application",
    description:
      "All applications to your institution (including Open, Closed and Rejected)",
    link: "/university/application?filter=all",
  },
  totalOpenApplicationToUniversity: {
    statTitle: "Total number of Open Applications",
    description: "All applications you’ve still under review",
    link: "/university/application?filter=open",
  },
  totalAcceptedApplicationToUniversity: {
    statTitle: "Total number of Accepted Applications",
    description: "All applications you’ve accepted",
    link: "/university/application?filter=accepted",
  },
  totalRejectedApplicationToUniversity: {
    statTitle: "Total number of Rejected Applications",
    description: "All applications you’ve rejected",
    link: "/university/application?filter=rejected",
  },
  totalPotentialUniversityRevenue: {
    statTitle: "Potential University Revenue (€)",
    description: "Total course fees expected from accepted applications",
    link: "other",
  },
  totalEstimatedUniversityRevenue: {
    statTitle: "Estimated University Revenue (€)",
    description:
      "Total course fees expected from accepted and open applications (excluding rejected applications)",
    link: "other",
  },
};

export const dashboardStatisticsInfo = {
  totalCourse: {
    statTitle: "Total Number of Course",
    description: "All courses upload across all institutions",
    link: "/superAdmin/course",
  },
  totalApplications: {
    statTitle: "Total Number of Application",
    description: "All applications made (including Open, Closed and Rejected)",
    link: "/superAdmin/application?filter=all",
  },
  totalOpenApplications: {
    statTitle: "Total number of Open Applications",
    description: "All applications you’ve still under review",
    link: "/superAdmin/application?filter=underReview",
  },
  totalAcceptedApplications: {
    statTitle: "Total Accepted Applications",
    description: "All applications accepted by any institutions",
    link: "/superAdmin/application?filter=acceptedByUniversity",
  },
  totalRejectedApplications: {
    statTitle: "Total Rejected Applications",
    description: "All applications Rejected by any institutions",
    link: "/superAdmin/application?filter=rejectedByUniversity",
  },
  totalUniversities: {
    statTitle: "Total Number of Universities",
    description: "All institutions (including verified and unverified)",
    link: "/superAdmin/university",
  },
  totalAgencies: {
    statTitle: "Total Number of Agencies",
    description: "All agents (including verified and unverified)",
    link: "/superAdmin/agent",
  },
  totalStudent: {
    statTitle: "Total Number of Students",
    description: "All students enrolled on the platform",
    link: "/superAdmin/student",
  },
  totalPlatformUser: {
    statTitle: "Total Number of Users",
    description: "All types of users on the platform including super admins",
    link: "other",
  },
};

export const StudentDashboardStatisticInfo = {
  studentTotalOpenApplication: {
    statTitle: "Total number of Open Applications",
    description: "All your applications still under review",
    link: "/student/application?filter=underReview",
  },
  studentTotalAcceptedApplication: {
    statTitle: "Total number of Accepted Applications",
    description:
      "All your applications that have been accepted by your selected institution(s).",
    link: "/student/application?filter=acceptedByUniversity",
  },
  studentTotalRejectedApplication: {
    statTitle: "Total number of Rejected Applications",
    description:
      "All your applications that have been rejected by your selected institution(s).",
    link: "/student/application?filter=rejectedByUniversity",
  },
  studentTotalFavoriteCourse: {
    statTitle: "Number of courses in Favourites",
    description: "All courses you have shortlisted",
    link: "/student/course",
  },
};

export const coursePublicOtherDetails = [
  {
    label: "Course Level",
    fieldName: "courseLevel",
    icon: <BsSpeedometer className="other_icon_style" />,
  },
  {
    label: "Course Max Seat",
    fieldName: "courseMaxSeat",
    icon: <FaUsers className="other_icon_style" />,
  },
  {
    label: "Application Fee ($)",
    fieldName: "courseApplicationFee",
    icon: <TbMoneybag className="other_icon_style" />,
  },
  {
    label: "Course Language",
    fieldName: "courseLanguage",
    icon: <LuLanguages className="other_icon_style" />,
  },
];

export const defaultValueOfQulificationState = {
  studentCountry: "",
  courseCountry: "",
  educationLevel: "",
  examination: "",
  gradingSystem: "",
  passingGrade: "",
};

export const goalValuesForAboutUs = [
  {
    id: 1,
    name: "Integrity",
    description:
      "Our first principal is honesty, which helps us meet the expectations we create.",
    icon: <FaBalanceScale className="value_icon_style" />,
  },
  {
    id: 2,
    name: "Commitment",
    description:
      "Our commitment to our work and our clients is an unbreakable bond.",
    icon: <FaHandshake className="value_icon_style" />,
  },
  {
    id: 3,
    name: "Passion",
    description:
      "Passion drives us to perform better, progress continually, and deliver timely.",
    icon: <FaGripfire className="value_icon_style" />,
  },
  {
    id: 4,
    name: "Learning",
    description:
      "Our team is always learning something new to get better at what they do.",
    icon: <FaBookOpen className="value_icon_style" />,
  },
  {
    id: 5,
    name: "Team-work",
    description: "Our team members are all about helping each other.",
    icon: <FaUsers className="value_icon_style" />,
  },
];

export const whoWeAreContent = [
  {
    id: 1,
    icon: <FaHouseUser className="what_we_are_icon_style" />,
    heading: "Who are we?",
    description:
      "We connect universities, students, and agents through our user-friendly platform, offering personalized support and diverse opportunities.",
  },
  {
    id: 2,
    icon: <FaUsersCog className="what_we_are_icon_style" />,
    heading: "What we do?",
    description:
      "We connect universities, students, and agents for seamless admissions, providing efficiency and personalized experiences.",
  },
  {
    id: 3,
    icon: <FaToolbox className="what_we_are_icon_style" />,
    heading: "How we do?",
    description:
      "We provide a user-friendly platform for universities, students, and agents to efficiently connect and simplify the admission process.",
  },
];

export const aboutUsStatistics = {
  studentCount: {
    label: "Active Students",
    icon: <PiStudentFill className="statistics_icon_style" />,
  },
  universityCount: {
    label: "Active Universities",
    icon: <FaUniversity className="statistics_icon_style" />,
  },
  courseCount: {
    label: "Available Courses",
    icon: <FaBookOpen className="statistics_icon_style" />,
  },
  agencyCount: {
    label: "Active Agencies",
    icon: <HiBuildingOffice2 className="statistics_icon_style" />,
  },
};

export const ourTeamData = [
  {
    id: 1,
    profileImage: "/images/aboutUs/our-team/CEO.png",
    name: "Otti Otusajo",
    designation: "CEO & CO-FOUNDER",
  },
  {
    id: 2,
    profileImage: "/images/aboutUs/our-team/CFO.png",
    name: "Ryan King",
    designation: "CFO & CO-FOUNDER",
  },
  {
    id: 3,
    profileImage: "/images/aboutUs/our-team/COO.png",
    name: "Mark Troughton",
    designation: "COO",
  },
  {
    id: 4,
    profileImage: "/images/aboutUs/our-team/CPO.png",
    name: "Beth Steinberg",
    designation: "CHEIF PEOPLE OFFICER",
  },
  {
    id: 5,
    profileImage: "/images/aboutUs/our-team/GC.png",
    name: "Kate Karas",
    designation: "GENERAL COUNSEL",
  },
  {
    id: 6,
    profileImage: "/images/aboutUs/our-team/MD.png",
    name: "Matt Newcomb",
    designation: "MD",
  },
];

export const emptyComponentTitleAndSubTitle = {
  common: {
    title: "Data not found",
    subTitle: "No suitable content found here. May you haven't add anything.",
  },

  agencyStudentList: {
    title: "No Students found",
    subTitle: "When you have new student belog to Agency will be listed here.",
  },

  agencyAgentList: {
    title: "No agent found",
    subTitle:
      "This is not possible. Every agency must have atleast one Super Admin. Just in case contact to support.",
  },

  agencyApplicationList: {
    title: "No Application found",
    subTitle: "Once we receive any application will be listed here.",
  },

  studentApplicationList: {
    title: "No Application found",
    subTitle: "When you make new applications will be listed here.",
  },

  universityUserList: {
    title: "No user found",
    subTitle:
      "This is not possible. Every University must have atleast one Super Admin. Just in case contact to support.",
  },

  universityCourseList: {
    title: "No Courses found",
    subTitle:
      "When new courses will added on behalf of university will be displayed here",
  },

  universityApplicationsOnCourse: {
    title: "No Application Found",
    subTitle:
      "No application on course yet. if received any will be listed here",
  },

  universityApplicationList: {
    title: "No Application Found",
    subTitle: "When new application arrieved will be display in list here",
  },

  superAdminUserList: {
    title: "No user Found",
    subTitle: "This is not possible. Please contect to Developer ASAP.",
  },

  superAdminApplicationList: {
    title: "No Application found",
    subTitle: "No one has made application yet. When we receiced any a[",
  },

  superAdminverifiedUniversityList: {
    title: "No University found",
    subTitle:
      "Platform still dose't registered any university. When we have one will we displayed here.",
  },

  superAdminUnverifiedUniversityList: {
    title: "Hurrey..! No Unverified Universities",
    subTitle:
      "No unverified Universities. When new university arrived will be shown in list here.",
  },

  superAdminVerifiedAgencyList: {
    title: "No Agencies found",
    subTitle:
      "Platform still doesn't registered any Agency. When we have one will we displayed here.",
  },

  superAdminUnverifiedAgencyList: {
    title: "Hurrey..! No Unverified Agencies",
    subTitle:
      "No unverified agencies. When new agency arrived will be shown in list here.",
  },

  superAdminStudentList: {
    title: "No Student Found",
    subTitle:
      "Platform still  doesn't registered any Students. When we have one will we displayed here.",
  },

  superAdminCourseList: {
    title: "No Courses Found",
    subTitle: "Admin haven't add any courses on behalf of any University.",
  },

  superAdminBlogList: {
    title: "No Blogs Found",
    subTitle:
      "Admin havent't add or Publish any blogs on platform. When we have one will be displayed here.",
  },

  superAdminQualificationList: {
    title: "No Qualification Found",
    subTitle:
      "Admin haven't add any qualification data for the Platform. Whenn we have one will be displayed here.",
  },
};

export const agencyRoleSelectOption = [
  {
    label: "---- Select Role ----",
    value: "",
  },
  {
    label: "Agency Super Admin",
    value: "AgencySuperAdmin",
  },
  {
    label: "Agency Sub Super Admin",
    value: "AgencySubSuperAdmin",
  },
  {
    label: "Agency Sub Admin",
    value: "AgencySubAdmin",
  },
];

export const applicationStatusSelect = [
  { label: "---- Select Status ----", value: "" },
  { label: "Open", value: "open" },
];

export const courseLevelSelect = [
  { label: "---- Select Course Level ----", value: "" },
  { label: "Pre-bachelors Course", value: "Pre-bachelors Course" },
  { label: "Bachelor’s degree", value: "Bachelor’s degree" },
  { label: "Post Graduate Diploma ", value: "Post Graduate Diploma " },
  { label: "Master’s Degree", value: "Master’s Degree" },
  { label: "PHD", value: "PHD" },
];

export const genderSelect = [
  { label: "---- Select Gender ----", value: "" },
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Other", value: "Other" },
];

export const visaPermission = [
  { label: "---- Select Status ----", value: "" },
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const workDuringStydentSelect = [
  { label: "---- Select Status ----", value: "" },
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const postGraduationWorkPermit = [
  { label: "---- Select Status ----", value: "" },
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const courseModeSelect = [
  { label: "---- Select Mode ----", value: "" },
  { label: "On Campus", value: "on-campus" },
  { label: "Online", value: "online" },
];

export const superAdminSelect = [
  {
    label: "---- Select Role ----",
    value: "",
  },
  {
    label: "Super Admin - Full Access",
    value: "SuperAdmin",
  },
  {
    label: "Sub Super Admin - Basic Access",
    value: "SubSuperAdmin",
  },
  {
    label: "Sub Admin - Viewonly Access",
    value: "SubAdmin",
  },
];

export const StudentRoleSelect = [
  {
    label: "---- Select Role ----",
    value: "",
  },
  {
    label: "Student",
    value: "Student",
  },
];

export const UniversityUserRolesSelect = [
  {
    label: "---- Select Role ----",
    value: "",
  },
  {
    label: "University Super Admin",
    value: "UniversitySuperAdmin",
  },
  {
    label: "University Sub Super Admin",
    value: "UniversitySubSuperAdmin",
  },
  {
    label: "University Sub Admin",
    value: "UniversitySubAdmin",
  },
];

export const superADminApplicationStatusSelect = [
  {
    label: "Under review by Team",
    value: "underReview",
  },
  {
    label: "Awaiting proof of payment of application fee",
    value: "applicationPaymentConformation",
  },
  {
    label: "Further documents required",
    value: "documentRequirement",
  },
  {
    label: "Application submitted to university",
    value: "submittedToUniveristy",
  },
  {
    label: "Application approved by university",
    value: "acceptedByUniversity",
  },
  {
    label: "Application rejected by university",
    value: "rejectedByUniversity",
  },
  {
    label: "Awaiting proof of payment for issuance of admission letter",
    value: "awaitingProofOfPayment",
  },
];

export const applicationStatusSelector = {
  underReview: "Under review by Team",
  applicationPaymentConformation:
    "Awaiting proof of payment of application fee",
  documentRequirement: "Further documents required",
  submittedToUniveristy: "Application submitted to university",
  acceptedByUniversity: "Application approved by university",
  rejectedByUniversity: "Application rejected by university",
  awaitingProofOfPayment:
    "Awaiting proof of payment for issuance of admission letter",
};

export const applicationStatusSortNamesForTable = {
  underReview: "Pending Review",
  applicationPaymentConformation:
    "Waiting for Application Fee Payment Confirmation",
  documentRequirement: "Documents Required",
  submittedToUniveristy: "Submitted to University",
  acceptedByUniversity: "Accepted",
  rejectedByUniversity: "Rejected",
  awaitingProofOfPayment: "Waiting for Proof of Payment",
};

export const experienceSelect = [
  { label: "---- Select Minimum Experience ----", value: "" },
  { label: "1 Year", value: "1 Year" },
  { label: "2 Year", value: "2 Year" },
  { label: "3 Year", value: "3 Year" },
  { label: "4 Year", value: "4 Year" },
  { label: "5 Year", value: "5 Year" },
  { label: "6 Year", value: "6 Year" },
  { label: "7 Year", value: "7 Year" },
  { label: "8 Year", value: "8 Year" },
  { label: "9 Year", value: "9 Year" },
  { label: "10 Year", value: "10 Year" },
  { label: "11 Year", value: "11 Year" },
  { label: "12 Year", value: "12 Year" },
  { label: "13 Year", value: "13 Year" },
  { label: "14 Year", value: "14 Year" },
  { label: "15 Year", value: "15 Year" },
  { label: "16 Year", value: "16 Year" },
  { label: "17 Year", value: "17 Year" },
  { label: "18 Year", value: "18 Year" },
  { label: "19 Year", value: "19 Year" },
  { label: "20 Year", value: "20 Year" },
  { label: "21 Year", value: "21 Year" },
  { label: "22 Year", value: "22 Year" },
  { label: "23 Year", value: "23 Year" },
  { label: "24 Year", value: "24 Year" },
  { label: "25 Year", value: "25 Year" },
];

export const englishExamtest = [
  { label: "---- Select English test ----", value: "" },
  { label: "IELTS", value: "IELTS" },
  { label: "TOEFL", value: "TOEFL" },
  { label: "PTE", value: "PTE" },
  {
    label: "DET (Duolingo English Test)",
    value: "DET (Duolingo English Test)",
  },
];

export const educationalRequirementSelect = [
  { label: "---- Select Education -----", value: "" },
  { label: "EQF Level 1 – < year 12", value: "1" },
  {
    label: "EQF Level 2 – Year 12/Secondary school leaving certificate ",
    value: "2",
  },
  { label: "EQF Level 3 – A levels/IB/AP/BAC/Equivalent", value: "3" },
  {
    label: "EQF Level 5 – Two Year associate degree/HND/Foundation degree",
    value: "5",
  },
  { label: "EQF Level 6 – Three/Four-year bachelor’s degree ", value: "6" },
  { label: "EQF Level 7 – Master’s Degree", value: "7" },
];
