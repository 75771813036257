import React, { useState } from "react";

import CustomVericalInputField from "../../../reusables/FromComponent/CustomVericalInputField";
import CustomVerticalSelectField from "../../../reusables/FromComponent/CustomVerticalSelectField";
import CustomButton from "../../../reusables/FromComponent/CustomButton";

import Notification, {
  findEmptyFields,
  handleStateChange,
} from "../../../../utils/helpers";
import {
  countries,
  newCountryList,
  universitySignInStep1DefaultStateObj,
} from "../../../../utils/constant";

const UniversitySignUpStep1 = ({ handleChangeTab, handleSaveFields }) => {
  const [step1Data, setStep1Data] = useState(
    universitySignInStep1DefaultStateObj
  );

  const handleStep1Submit = (data) => {
    if (findEmptyFields(data).length) {
      Notification.errorNotification(
        `Please Enter following Field:  ${findEmptyFields(data)[0]}`
      );
    } else {
      handleSaveFields(data);
      handleChangeTab("tab2");
    }
  };

  return (
    <div className="sign_in_step_1_form_holder">
      <form className="step-1_form">
        {/* <div className="field_holder">
          <input
            type="text"
            placeholder="University Name"
            className="input_field_style"
            name="universityName"
            onChange={(e) => handleStateChange(e, setStep1Data)}
          />
        </div> */}
        <CustomVericalInputField
          name="universityName"
          label="University Name"
          value={step1Data?.universityName}
          onChange={(e) => handleStateChange(e, setStep1Data)}
        />
        {/* <div className="field_holder">
          <input
            type="number"
            placeholder="University Registration Number"
            className="input_field_style"
            name="universityRegistrationNumber"
            onChange={(e) => handleStateChange(e, setStep1Data)}
          />
        </div> */}

        <CustomVericalInputField
          name="universityRegistrationNumber"
          label="University Registration Number"
          value={step1Data?.universityRegistrationNumber}
          onChange={(e) => handleStateChange(e, setStep1Data)}
        />

        {/* <div className="field_holder">
          <input
            type="number"
            placeholder="University Tax Number"
            className="input_field_style"
            name="universityTaxNumber"
            onChange={(e) => handleStateChange(e, setStep1Data)}
          />
        </div> */}

        <CustomVericalInputField
          name="universityTaxNumber"
          label="University Tax Number"
          type="number"
          value={step1Data?.universityTaxNumber}
          onChange={(e) => handleStateChange(e, setStep1Data)}
        />

        {/* <div className="field_holder">
          <input
            type="text"
            placeholder="Type Of Institutions"
            className="input_field_style"
            name="typeOfInstitution"
            onChange={(e) => handleStateChange(e, setStep1Data)}
          />
        </div> */}

        <CustomVericalInputField
          name="typeOfInstitution"
          label="Type Of Institutions"
          value={step1Data?.typeOfInstitution}
          onChange={(e) => handleStateChange(e, setStep1Data)}
        />
        {/* 
        <div className="field_holder">
          <select
            type="text"
            placeholder="Agency Address"
            className="input_field_style"
            name="universityCountry"
            onChange={(e) => handleStateChange(e, setStep1Data)}
          >
            <option>---- Select Country -----</option>
            {countries.map((country) => (
              <option>{country.name}</option>
            ))}
          </select>
        </div> */}

        <CustomVerticalSelectField
          name="universityCountry"
          label="University Country"
          value={step1Data?.universityCountry}
          onChange={(e) => handleStateChange(e, setStep1Data)}
          options={newCountryList}
        />

        <div className="next_button_holder" type="button">
          {/* <button
            type="button"
            className="next_button"
            onClick={() => handleStep1Submit(step1Data)}
          >
            Next
          </button> */}
          <CustomButton
            buttonLabel="Next"
            onClick={() => handleStep1Submit(step1Data)}
            fullwidth
          />
        </div>
      </form>
    </div>
  );
};

export default UniversitySignUpStep1;
