import React from "react";
import { useNavigate } from "react-router-dom";
import "./AbstractBlogBox.css";

import CustomButton from "../FromComponent/CustomButton";

import {
  formatString,
  removeSubstring,
  replaceLocalhostUrl,
} from "../../../utils/helpers";

const AbstractBlogBox = ({
  blogImage = "",
  title,
  description,
  link,
  type,
  buttonText = "View Details",
}) => {
  const navigate = useNavigate();
  return (
    <div className="blog_abstract_box">
      <img
        src={
          blogImage !== ""
            ? replaceLocalhostUrl(blogImage)
            : "/images/blog/blogImageDummy.jpg"
        }
        className="blog_abdtract_image_style"
        alt="blog_image"
      />
      <div className="blog_abdtract_content_holder">
        <p className="abstract_blog_title_style">{formatString(title, 40)}</p>

        <div className="description_holder">
          <div
            dangerouslySetInnerHTML={{
              __html: removeSubstring(description, "<p><br></p>"),
            }}
          ></div>
          <div className="abstract_gradient"></div>
        </div>
        <div className="abstract_box_blog_button_holder">
          <CustomButton
            buttonLabel={type == "blog" ? "Read more" : buttonText}
            onClick={() => navigate(link)}
          />
        </div>
      </div>
    </div>
  );
};

export default AbstractBlogBox;
