import React, { useState } from "react";
import Notification, {
  handleFileInputInState,
  handleStateChange,
  removeEmptyProperties,
  replaceLocalhostUrl,
} from "../../../../../utils/helpers";
import axios from "axios";
import { API_HEADERS } from "../../../../../utils/constant";

const OurStory = ({ ourStoryImage, ourStoryText }) => {
  const [ourStoryData, setourStoryData] = useState({
    ourStoryText: ourStoryText || "",
    ourStoryImage: "",
  });

  const handleUpdateOurStory = () => {
    const newData = removeEmptyProperties(ourStoryData);
    const OurStoryDataToSend = new FormData();

    Object.keys(newData).forEach((key) => {
      OurStoryDataToSend.append(key, newData[key]);
    });

    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/aboutUsInfo/updateAboutUsContent`,
        OurStoryDataToSend,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification(
          "About Us Content Updated Successfully"
        );
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  return (
    <div className="our_story_main">
      <p className="curious_heading">OUR STORY</p>
      <div className="story_holder">
        <div className="story_text_holder">
          <textarea
            className="strory_text_field"
            rows={15}
            value={ourStoryData.ourStoryText}
            name="ourStoryText"
            onChange={(e) => handleStateChange(e, setourStoryData)}
          />
        </div>
        <label className="story_image_holder" htmlFor="our_story_image_input">
          {ourStoryData.ourStoryImage !== "" ? (
            <img
              src={URL.createObjectURL(ourStoryData?.ourStoryImage)}
              className="image_style"
            />
          ) : ourStoryImage ? (
            <img
              src={replaceLocalhostUrl(ourStoryImage)}
              className="image_style"
            />
          ) : (
            <img src="/images/aboutUs/our-story.jpg" className="image_style" />
          )}
        </label>
        <input
          id="our_story_image_input"
          type="file"
          className="image_input_hiddden_style"
          name="ourStoryImage"
          onChange={(e) => handleFileInputInState(e, setourStoryData)}
        />
      </div>
      <div className="update_Button_holder">
        <button className="add_agent_btn_style" onClick={handleUpdateOurStory}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default OurStory;
