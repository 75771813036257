import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./SuperAdminAddCourse.css";
import {
  AiFillCheckSquare,
  AiOutlineMinus,
  AiOutlinePlus,
} from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";

import { useGetAllUniversities } from "../../../../hooks/useGetAllUniversities";

import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import CustomVericalInputField from "../../../reusables/FromComponent/CustomVericalInputField";
import CustomVerticalSelectField from "../../../reusables/FromComponent/CustomVerticalSelectField";

import Notification, {
  findEmptyFields,
  generateEducationRequirementScoreSelect,
  generateRandomString,
  handleFileInputInState,
  handleStateChange,
  removeElementFromArray,
} from "../../../../utils/helpers";
import {
  API_HEADERS,
  addCourseStateDefaultStateObject,
  courseLevelSelect,
  courseModeSelect,
  courseUploadField,
  educationalRequirementSelect,
  englishExamtest,
  experienceSelect,
  languages,
  newCountryList,
  newCourseSubjectListForStudent,
} from "../../../../utils/constant";

const SuperAdminAddCourse = () => {
  const [courseDetails, setCourseDetails] = useState(
    addCourseStateDefaultStateObject
  );

  const navigate = useNavigate();
  const [universities, isLoading, error] = useGetAllUniversities();

  const [showEducationInfo, setShowEducationInfo] = useState(false);
  const [showTestInfo, setShowTestInfo] = useState(false);
  const [showEducationRequirementInfo, setshowEducationRequirementInfo] =
    useState(false);

  const [educationalRequirement, setEducationalRequirement] = useState({
    educationIQFLevel: "",
    educationScore: "",
  });

  useEffect(() => {
    setCourseDetails((prev) => ({
      ...prev,
      educationalRequirement: educationalRequirement,
    }));
  }, [educationalRequirement]);

  const [workExperience, setworkExperience] = useState([
    {
      id: generateRandomString(5),
      work: "",
      experience: "",
    },
  ]);

  const [englishProfData, setEnglishProfData] = useState([
    {
      id: generateRandomString(5),
      englishTest: "",
      reading: "",
      writing: "",
      listning: "",
      speaking: "",
      total: "",
    },
  ]);

  const [documentRequirement, setDocumentRequirement] =
    useState(courseUploadField);

  const [openAddOtherDocument, setOpenAddOtherDocument] = useState(false);
  const [docName, setDocName] = useState("");

  const handleValueEntering = (id, work, experience) => {
    const updatedWorkInput = workExperience.map((item) => {
      if (item.id === id) {
        if (work !== undefined) return { ...item, work };
        else return { ...item, experience };
      }
      return item;
    });
    setworkExperience(updatedWorkInput);
  };

  const handleValueEnteringEnglishTest = (
    id,
    englishTest,
    reading,
    writing,
    listning,
    speaking,
    total
  ) => {
    const updatedTestInput = englishProfData.map((item) => {
      if (item.id === id) {
        if (englishTest !== undefined) return { ...item, englishTest };
        if (reading !== undefined) return { ...item, reading: reading };
        if (writing !== undefined) return { ...item, writing: writing };
        if (listning !== undefined) return { ...item, listning: listning };
        if (speaking !== undefined) return { ...item, speaking: speaking };
        if (total !== undefined) return { ...item, total: total };
        else return { ...item };
      }
      return item;
    });

    setEnglishProfData(updatedTestInput);
  };

  const handleDocumentCheking = (id, value) => {
    const updatedDocInput = documentRequirement.map((item) => {
      if (item.id === id) {
        return { ...item, required: value };
      }
      return item;
    });
    setDocumentRequirement(updatedDocInput);
  };

  const handleAddOtherDocument = (docName) => {
    if (docName !== "") {
      setDocumentRequirement([
        ...documentRequirement,
        {
          id: generateRandomString(5),
          documentName: docName,
          required: false,
          type: "userAdded",
        },
      ]);
    }
  };

  useEffect(() => {
    setCourseDetails((prev) => ({
      ...prev,
      courseWorkRequirement: workExperience,
    }));
  }, [workExperience]);

  useEffect(() => {
    setCourseDetails((prev) => ({
      ...prev,
      englishTestRequirement: englishProfData,
    }));
  }, [englishProfData]);

  useEffect(() => {
    setCourseDetails((prev) => ({
      ...prev,
      basicDoumentRequirement: documentRequirement,
    }));
  }, [documentRequirement]);

  const handleSubmit = () => {
    if (findEmptyFields(courseDetails).length > 0) {
      Notification.errorNotification(
        `Please fill ${findEmptyFields(courseDetails)[0]}`
      );
    } else {
      const courseFormData = new FormData();

      Object.keys(courseDetails).forEach((key) => {
        if (
          Array.isArray(courseDetails[key]) &&
          key !== "englishTestRequirement" &&
          key !== "courseWorkRequirement"
        ) {
          courseDetails[key].forEach((item) => {
            courseFormData.append(key, JSON.stringify(item));
          });
        } else if (
          Array.isArray(courseDetails[key]) &&
          (key === "englishTestRequirement" || key === "courseWorkRequirement")
        ) {
          courseFormData.append(key, JSON.stringify(courseDetails[key]));
        } else if (key === "educationalRequirement") {
          courseFormData.append(key, JSON.stringify(courseDetails[key]));
        } else {
          courseFormData.append(key, courseDetails[key]);
        }
      });

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/course/addCourse`,
          courseFormData,
          API_HEADERS()
        )
        .then(() => {
          Notification.successNotification("New course added Successfully");
          navigate("/superAdmin/course");
        })
        .catch((err) => {
          Notification.errorNotification(err.response.data.message);
        });
    }
  };

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"ADD COURSE"} />
        <div className="add_course_container">
          <div className="input_container">
            <div className="basic_details_section_container">
              <div className="course_image_and_detail_style">
                <div className="course_thumbnail_input_container">
                  <label
                    className="course_input_label_style_with_image"
                    htmlFor="coursethumbnail"
                  >
                    {courseDetails.coursethumbnail ? (
                      <img
                        src={URL.createObjectURL(courseDetails.coursethumbnail)}
                        className="course_thumnail_style"
                        alt="course_thumbnail"
                      />
                    ) : (
                      <img
                        src="/images/superAdmin/upload_placeholder.png"
                        className="course_upload_placeholder_img_style"
                        alt="course_thumbnail"
                      />
                    )}
                  </label>
                  <input
                    type="file"
                    id="coursethumbnail"
                    name="coursethumbnail"
                    className="document_input_field_actual_style"
                    onChange={(e) =>
                      handleFileInputInState(e, setCourseDetails)
                    }
                  />
                </div>
                <div className="basic_detail_holder container">
                  <div className="row">
                    <CustomVericalInputField
                      name="courseName"
                      label="Course Name"
                      value={courseDetails?.courseName}
                      onChange={(e) => handleStateChange(e, setCourseDetails)}
                      colClass="col-12 col-lg-6"
                    />

                    <div className="col-12 col-lg-6">
                      <div className="agency_field_holder ">
                        <label className="agency_label_style">
                          University Name
                        </label>
                        <select
                          className="agency_input_field_style"
                          name="courseUniversityId"
                          // Id will be stored in database with university reference
                          onChange={(e) =>
                            handleStateChange(e, setCourseDetails)
                          }
                        >
                          <option>---- Select University ----</option>
                          {universities &&
                            universities.map((university) => (
                              <option value={university._id}>
                                {university.universityName}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <CustomVericalInputField
                      name="courseDuration"
                      label="Course Duration (in Months)"
                      type="number"
                      value={courseDetails?.courseDuration}
                      onChange={(e) => handleStateChange(e, setCourseDetails)}
                      colClass="col-12 col-lg-6"
                    />

                    <CustomVerticalSelectField
                      name="courseSubjectType"
                      label="Course Discipline"
                      value={courseDetails?.courseSubjectType}
                      onChange={(e) => handleStateChange(e, setCourseDetails)}
                      options={newCourseSubjectListForStudent}
                      colClass="col-12 col-lg-6"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="input_container">
            <div className="container">
              <div className="row">
                <CustomVericalInputField
                  name="courseStartDate"
                  label="Course Start Date"
                  type="date"
                  value={courseDetails?.courseStartDate}
                  onChange={(e) => handleStateChange(e, setCourseDetails)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="courseApplicationStartDate"
                  label="Application Start Date"
                  type="date"
                  value={courseDetails?.courseApplicationStartDate}
                  onChange={(e) => handleStateChange(e, setCourseDetails)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="courseApplicationEndDate"
                  label="Application End Date"
                  type="date"
                  value={courseDetails?.courseApplicationEndDate}
                  onChange={(e) => handleStateChange(e, setCourseDetails)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="possibelCourseEndDate"
                  label="Possible Course End Date"
                  type="date"
                  value={courseDetails?.possibelCourseEndDate}
                  onChange={(e) => handleStateChange(e, setCourseDetails)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="courseLevel"
                  label="Course Level"
                  value={courseDetails?.courseLevel}
                  onChange={(e) => handleStateChange(e, setCourseDetails)}
                  options={courseLevelSelect}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="courseMaxSeat"
                  label="Course Max Seat"
                  type="number"
                  value={courseDetails?.courseMaxSeat}
                  onChange={(e) => handleStateChange(e, setCourseDetails)}
                  colClass="col-12 col-lg-4"
                />
              </div>
            </div>
          </div>
          <div className="input_container">
            <div className="container">
              <div className="row">
                <CustomVericalInputField
                  name="courseApplicationFee"
                  label="Application Fee"
                  type="number"
                  value={courseDetails?.courseApplicationFee}
                  onChange={(e) => handleStateChange(e, setCourseDetails)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="courseFee"
                  label="Course Fee"
                  type="number"
                  value={courseDetails?.courseFee}
                  onChange={(e) => handleStateChange(e, setCourseDetails)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="courseStudyMode"
                  label="Study Mode"
                  value={courseDetails?.courseStudyMode}
                  onChange={(e) => handleStateChange(e, setCourseDetails)}
                  options={courseModeSelect}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="courseLanguage"
                  label="Language"
                  value={courseDetails?.courseLanguage}
                  onChange={(e) => handleStateChange(e, setCourseDetails)}
                  options={languages}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="courseCountry"
                  label="Course Country"
                  value={courseDetails?.courseCountry}
                  onChange={(e) => handleStateChange(e, setCourseDetails)}
                  options={newCountryList}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="courseCity"
                  label="Course City"
                  value={courseDetails?.courseCity}
                  onChange={(e) => handleStateChange(e, setCourseDetails)}
                  colClass="col-12 col-lg-4"
                />

                <div className="col-12 col-lg-12">
                  <div className="course_label_field_holder_flex_style">
                    <label className="course_label_style">
                      Course Description
                    </label>
                    <textarea
                      type="text"
                      className="course_input_field_style_with_img_textArea"
                      name="courseDescription"
                      onChange={(e) => handleStateChange(e, setCourseDetails)}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-12">
                  <div className="course_label_field_holder_flex_style">
                    <label className="course_label_style">
                      Course Special Feature
                    </label>
                    <textarea
                      type="text"
                      className="course_input_field_style_with_img_textArea"
                      name="courseSpecialFeature"
                      onChange={(e) => handleStateChange(e, setCourseDetails)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="input_container">
            <div className="requirement_container_box">
              <div className="work_exp_container">
                <div className="label_and_add_btn_holder">
                  <label className="course_label_style_multiple">
                    Work Experience
                    <input
                      type="checkbox"
                      className="conformationCheckBox"
                      onChange={(e) => setShowEducationInfo(e.target.checked)}
                    />
                  </label>
                  <AiOutlinePlus
                    className="add_work_exp_icon_style"
                    onClick={() =>
                      setworkExperience([
                        ...workExperience,
                        {
                          id: generateRandomString(5),
                          work: "",
                          experience: "",
                        },
                      ])
                    }
                  />
                </div>
                {showEducationInfo &&
                  workExperience &&
                  workExperience.map((field) => (
                    <div className="work_option_holder">
                      <div className="container">
                        <div className="row">
                          <div className="col-8 col-lg-4">
                            <CustomVericalInputField
                              value={field.work}
                              placeholder={"Please enter work type"}
                              id={field.id}
                              onChange={(e) =>
                                handleValueEntering(
                                  field.id,
                                  e.target.value,
                                  ...[,]
                                )
                              }
                            />
                          </div>
                          <div className="col-12 col-lg-8">
                            <CustomVerticalSelectField
                              className="course_input_field_style_with_img"
                              value={field.experience}
                              placeholder={"Please enter minimum experience"}
                              id={field.id}
                              onChange={(e) =>
                                handleValueEntering(
                                  field.id,
                                  ...[,],
                                  e.target.value
                                )
                              }
                              options={experienceSelect}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="minus_btn_holder">
                        <AiOutlineMinus
                          className="minus_icon_style"
                          onClick={() => {
                            setworkExperience(
                              removeElementFromArray(workExperience, field.id)
                            );
                          }}
                        />
                      </div>
                    </div>
                  ))}
              </div>
              <div className="english_prof_container">
                <div className="label_and_add_btn_holder">
                  <label className="course_label_style_multiple">
                    English test Requirements
                    <input
                      type="checkbox"
                      className="conformationCheckBox"
                      onChange={(e) => setShowTestInfo(e.target.checked)}
                    />
                  </label>
                  <AiOutlinePlus
                    className="add_work_exp_icon_style"
                    onClick={() =>
                      setEnglishProfData([
                        ...englishProfData,
                        {
                          id: generateRandomString(5),
                          englishTest: "",
                          reading: "",
                          writing: "",
                          listning: "",
                          speaking: "",
                          total: "",
                        },
                      ])
                    }
                  />
                </div>
                {showTestInfo &&
                  englishProfData &&
                  englishProfData.map((field) => (
                    <div className="work_option_holder">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-lg-12">
                            <CustomVerticalSelectField
                              className="course_input_field_style_with_img"
                              value={field.englishTest}
                              placeholder={"Please enter service"}
                              id={field.id}
                              onChange={(e) =>
                                handleValueEnteringEnglishTest(
                                  field.id,
                                  e.target.value,
                                  ...[,]
                                )
                              }
                              options={englishExamtest}
                            />
                          </div>
                          <div
                            className="col-12 col-lg-12"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <CustomVericalInputField
                              className="course_input_field_style_with_img"
                              value={field.reading}
                              placeholder={"Reading "}
                              id={field.id}
                              onChange={(e) =>
                                handleValueEnteringEnglishTest(
                                  field.id,
                                  ...[,],
                                  e.target.value
                                )
                              }
                            />
                            <CustomVericalInputField
                              className="course_input_field_style_with_img"
                              value={field.writing}
                              placeholder={"Writing "}
                              id={field.id}
                              onChange={(e) =>
                                handleValueEnteringEnglishTest(
                                  field.id,
                                  ...[,],
                                  ...[,],
                                  e.target.value
                                )
                              }
                            />
                            <CustomVericalInputField
                              className="course_input_field_style_with_img"
                              value={field.listning}
                              placeholder={"Listening"}
                              id={field.id}
                              onChange={(e) =>
                                handleValueEnteringEnglishTest(
                                  field.id,
                                  ...[,],
                                  ...[,],
                                  ...[,],

                                  e.target.value
                                )
                              }
                            />
                            <CustomVericalInputField
                              className="course_input_field_style_with_img"
                              value={field.speaking}
                              placeholder={"Speaking"}
                              id={field.id}
                              onChange={(e) =>
                                handleValueEnteringEnglishTest(
                                  field.id,
                                  ...[,],
                                  ...[,],
                                  ...[,],
                                  ...[,],
                                  e.target.value
                                )
                              }
                            />
                            <CustomVericalInputField
                              className="course_input_field_style_with_img"
                              value={field.total}
                              placeholder={"Total"}
                              id={field.id}
                              onChange={(e) =>
                                handleValueEnteringEnglishTest(
                                  field.id,
                                  ...[,],
                                  ...[,],
                                  ...[,],
                                  ...[,],
                                  ...[,],
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="minus_btn_holder">
                        <AiOutlineMinus
                          className="minus_icon_style"
                          onClick={() => {
                            setEnglishProfData(
                              removeElementFromArray(englishProfData, field.id)
                            );
                          }}
                        />
                      </div>
                    </div>
                  ))}
              </div>

              <div className="english_prof_container">
                <div className="label_and_add_btn_holder">
                  <label className="course_label_style_multiple">
                    Educational Requirement
                    <input
                      type="checkbox"
                      className="conformationCheckBox"
                      onChange={(e) =>
                        setshowEducationRequirementInfo(e.target.checked)
                      }
                    />
                  </label>
                </div>
                {showEducationRequirementInfo && (
                  <div className="work_option_holder">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-lg-8">
                          <CustomVerticalSelectField
                            name="educationIQFLevel"
                            options={educationalRequirementSelect}
                            value={
                              courseDetails?.educationalRequirement
                                ?.educationIQFLevel
                            }
                            onChange={(e) =>
                              setEducationalRequirement((prev) => ({
                                ...prev,
                                educationIQFLevel: e.target.value,
                              }))
                            }
                          />
                        </div>
                        <div className="col-12 col-lg-4">
                          <CustomVerticalSelectField
                            className="course_input_field_style_with_img"
                            placeholder={"Please enter minimum experience"}
                            value={
                              courseDetails?.educationalRequirement
                                ?.educationScore
                            }
                            options={generateEducationRequirementScoreSelect(
                              100
                            )}
                            onChange={(e) =>
                              setEducationalRequirement((prev) => ({
                                ...prev,
                                educationScore: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="input_container">
            <div className="container">
              <div className="row">
                {documentRequirement.map((doc) => (
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="document_checker">
                      <AiFillCheckSquare
                        className={
                          doc.required
                            ? "document_checker_checked"
                            : "document_icon_style"
                        }
                        onClick={() =>
                          doc.required
                            ? handleDocumentCheking(doc.id, false)
                            : handleDocumentCheking(doc.id, true)
                        }
                      />
                      <p className="document_name_text_style">
                        {doc.documentName}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="add_new_doc_btn_holder">
              <button
                className="add_doc_btn_style"
                onClick={() => setOpenAddOtherDocument(!openAddOtherDocument)}
              >
                Add Document Requirement
              </button>
              {openAddOtherDocument && (
                <div className="other_doc_field_holder">
                  <div className="doc_field_solo_holder">
                    <input
                      className="course_input_field_style_with_img"
                      placeholder="Enter document name"
                      onChange={(e) => setDocName(e.target.value)}
                    />
                  </div>
                  <button
                    className="add_doc_btn_style"
                    onClick={() => handleAddOtherDocument(docName)}
                  >
                    Add
                  </button>
                  <RxCross2
                    className="other_doc_close_icon_style"
                    onClick={() => setOpenAddOtherDocument(false)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="submit_btn_holder">
            <button className="submit_btn_style" onClick={handleSubmit}>
              Add Course
            </button>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminAddCourse;
