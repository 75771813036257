import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { IoKeySharp } from "react-icons/io5";
import "./AgencyProfileView.css";

import { useGetAgencyById } from "../../../hooks/useGetAgencyById";

import { AuthContext } from "../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../reusables/PageTitle/PageTitle";
import Loader from "../../reusables/Loader/Loader";
import ErrorPage from "../../reusables/ErrorPage/ErrorPage";
import CustomVericalInputField from "../../reusables/FromComponent/CustomVericalInputField";
import CustomVerticalSelectField from "../../reusables/FromComponent/CustomVerticalSelectField";

import Notification, {
  generateRandomString,
  handleFileInputInState,
  handleStateChange,
  removeElementFromArray,
  removeEmptyProperties,
  removePropertiesFromArray,
} from "../../../utils/helpers";
import { API_HEADERS, newCountryList } from "../../../utils/constant";

const AgencyProfileView = () => {
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [agencyData, isLoading, error] = useGetAgencyById(
    user?.agencyRef ||
      JSON.parse(localStorage.getItem("currentLoginUser")).agencyRef
  );

  const [agencyDataToUpdate, setAgencydataToUpdate] = useState(null);

  const [services, setServices] = useState([
    {
      id: generateRandomString(5),
      service: "",
    },
  ]);

  const [sourceCountry, setSourceCountry] = useState([
    {
      id: generateRandomString(5),
      country: "",
    },
  ]);

  const handleServiceValueEntering = async (id, service) => {
    const updatedServicesInput = await services.map((item) => {
      if (item.id === id) {
        return { ...item, service };
      }
      return item;
    });
    setServices(updatedServicesInput);
  };

  const handleSourseCountryValueEntering = (id, country) => {
    const updatedSourceCountryInput = sourceCountry.map((item) => {
      if (item.id === id) {
        return { ...item, country };
      }
      return item;
    });
    setSourceCountry(updatedSourceCountryInput);
  };

  useEffect(() => {
    setAgencydataToUpdate({
      agencyName: agencyData?.agencyName || "",
      agencyRegistrationNumber: agencyData?.agencyRegistrationNumber || "",
      agencyTaxNumber: agencyData?.agencyTaxNumber || "",
      agencyCommission: agencyData?.agencyCommission || "",
      mainContactEmail: agencyData?.mainContactEmail || "",
      agencyMainPhoneNumber: agencyData?.agencyMainPhoneNumber || "",
      businessWebsite: agencyData?.businessWebsite || "",
      businessSocial: agencyData?.businessSocial || "",
      businessIncorporatedCertificate: "",
      agencyCountry: agencyData?.agencyCountry || "",
      agencyCity: agencyData?.agencyCity || "",
      agencyAddress: agencyData?.agencyAddress || "",
      postcode: agencyData?.postcode || "",
      services: agencyData?.services || "",
      sourceCountry: agencyData?.sourceCountry || "",
    });

    setServices(agencyData?.services);
    setSourceCountry(agencyData?.sourceCountry);
  }, [agencyData, user?.agencyRef]);

  useEffect(() => {
    setAgencydataToUpdate((prev) => ({
      ...prev,
      sourceCountry: sourceCountry,
    }));
  }, [sourceCountry]);

  useEffect(() => {
    setAgencydataToUpdate((prev) => ({
      ...prev,
      services: services,
    }));
  }, [services]);

  const handleUpdate = () => {
    const dataToUpdate = removeEmptyProperties({
      ...agencyDataToUpdate,
      sourceCountry: removePropertiesFromArray(
        agencyDataToUpdate.sourceCountry,
        ["_id"]
      ),
      services: removePropertiesFromArray(agencyDataToUpdate.services, ["_id"]),
      updatedBy: user?._id,
      agencyId: JSON.parse(localStorage.getItem("currentLoginUser")).agencyRef,
    });

    const agencyFormData = new FormData();

    Object.keys(dataToUpdate).forEach((key) => {
      if (Array.isArray(dataToUpdate[key])) {
        dataToUpdate[key].forEach((item) => {
          agencyFormData.append(key, JSON.stringify(item));
        });
      } else {
        agencyFormData.append(key, dataToUpdate[key]);
      }
    });

    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/agency/updateAgencyById`,
        agencyFormData,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Agency Updated Successfully");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"AGENCY PROFILE"} />
        <div className="agency_form">
          <div className="add_agent_btn_holder">
            <button
              className="change_password_btn_style"
              onClick={() => navigate(`/agency/agent/changePassword`)}
            >
              <IoKeySharp className="add_user_icon_style" />
              Change Password
            </button>
          </div>
          <div className="agency_input_container">
            <div className="container">
              <div className="row">
                <CustomVericalInputField
                  type="text"
                  colClass="col-12 col-lg-6"
                  label="R.Agency Name"
                  name="agencyName"
                  value={agencyDataToUpdate?.agencyName}
                  onChange={(e) => handleStateChange(e, setAgencydataToUpdate)}
                />

                <CustomVericalInputField
                  type="number"
                  colClass="col-12 col-lg-6"
                  label="R.Agency Registration No."
                  name="agencyRegistrationNumber"
                  value={agencyDataToUpdate?.agencyRegistrationNumber}
                  onChange={(e) => handleStateChange(e, setAgencydataToUpdate)}
                />

                <CustomVericalInputField
                  type="number"
                  colClass="col-12 col-lg-6"
                  label=" R.Agency Tax No."
                  name="agencyTaxNumber"
                  value={agencyDataToUpdate?.agencyTaxNumber}
                  onChange={(e) => handleStateChange(e, setAgencydataToUpdate)}
                />

                <CustomVericalInputField
                  type="number"
                  colClass="col-12 col-lg-6"
                  label="Agency Commission"
                  name="agencyCommission"
                  value={agencyDataToUpdate?.agencyCommission}
                  onChange={(e) => handleStateChange(e, setAgencydataToUpdate)}
                />
              </div>
            </div>
          </div>
          <div className="agency_input_container">
            <div className="container">
              <div className="row">
                <CustomVericalInputField
                  type="email"
                  colClass="col-12 col-lg-6"
                  label="Main Contact Email"
                  name="mainContactEmail"
                  value={agencyDataToUpdate?.mainContactEmail}
                  onChange={(e) => handleStateChange(e, setAgencydataToUpdate)}
                />

                <CustomVericalInputField
                  type="tel"
                  pattern="^[0-9]{10}$"
                  colClass="col-12 col-lg-6"
                  label="Main Contact Phone No."
                  name="agencyMainPhoneNumber"
                  value={agencyDataToUpdate?.agencyMainPhoneNumber}
                  onChange={(e) => handleStateChange(e, setAgencydataToUpdate)}
                />

                <CustomVericalInputField
                  type="text"
                  colClass="col-12 col-lg-6"
                  label="Business Website"
                  name="businessWebsite"
                  value={agencyDataToUpdate?.businessWebsite}
                  onChange={(e) => handleStateChange(e, setAgencydataToUpdate)}
                />

                <CustomVericalInputField
                  type="text"
                  colClass="col-12 col-lg-6"
                  label="Business Social"
                  name="businessSocial"
                  value={agencyDataToUpdate?.businessSocial}
                  onChange={(e) => handleStateChange(e, setAgencydataToUpdate)}
                />

                <div className="col-12 col-lg-6">
                  <div className="agency_field_holder">
                    <label className="agency_label_style">
                      Business Incorporated Certificate
                    </label>

                    <label
                      htmlFor="businessIncorporatedCertificate"
                      style={{ width: "90%" }}
                    >
                      <div className="cv_file_holder_div_style">
                        {agencyDataToUpdate &&
                        agencyDataToUpdate.businessIncorporatedCertificate !==
                          ""
                          ? `${agencyDataToUpdate.businessIncorporatedCertificate.name}`
                          : ""}
                      </div>
                    </label>
                    <input
                      type="file"
                      className="actual_file_uopload_input_field"
                      id="businessIncorporatedCertificate"
                      name="businessIncorporatedCertificate"
                      onChange={(e) =>
                        handleFileInputInState(e, setAgencydataToUpdate)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="agency_input_container">
            <div className="container">
              <div className="row">
                <CustomVerticalSelectField
                  label="Agency Country"
                  name="agencyCountry"
                  value={agencyDataToUpdate?.agencyCountry}
                  onChange={(e) => handleStateChange(e, setAgencydataToUpdate)}
                  colClass="col-12 col-lg-6"
                  options={newCountryList}
                />

                <CustomVericalInputField
                  type="text"
                  colClass="col-12 col-lg-6"
                  label="Agency City"
                  name="agencyCity"
                  value={agencyDataToUpdate?.agencyCity}
                  onChange={(e) => handleStateChange(e, setAgencydataToUpdate)}
                />

                <CustomVericalInputField
                  type="text"
                  colClass="col-12 col-lg-6"
                  label="Agency Address"
                  name="agencyAddress"
                  value={agencyDataToUpdate?.agencyAddress}
                  onChange={(e) => handleStateChange(e, setAgencydataToUpdate)}
                />

                <CustomVericalInputField
                  type="text"
                  colClass="col-12 col-lg-6"
                  label="Post Code"
                  name="postcode"
                  value={agencyDataToUpdate?.postcode}
                  onChange={(e) => handleStateChange(e, setAgencydataToUpdate)}
                />
              </div>
            </div>
          </div>
          <div className="agency_input_container">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="agency_field_holder">
                    <label className="agency_label_style">
                      Service you provide
                      <AiOutlinePlus
                        className="add_service_icon_style"
                        onClick={() =>
                          setServices([
                            ...services,
                            {
                              id: generateRandomString(5),
                              service: "",
                            },
                          ])
                        }
                      />
                    </label>
                    {services &&
                      services.map((service) => (
                        <div className="multiple_field_holder">
                          <input
                            type="text"
                            className="agency_input_field_style_multiple_input"
                            name="service"
                            value={service?.service}
                            onChange={(e) =>
                              handleServiceValueEntering(
                                service.id,
                                e.target.value
                              )
                            }
                          />
                          <AiOutlineMinus
                            className="minus_icon_style"
                            onClick={() => {
                              setServices(
                                removeElementFromArray(services, service.id)
                              );
                            }}
                          />
                        </div>
                      ))}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="agency_field_holder">
                    <label className="agency_label_style">
                      Main Source Country
                      <AiOutlinePlus
                        className="add_service_icon_style"
                        onClick={() =>
                          setSourceCountry([
                            ...sourceCountry,
                            {
                              id: generateRandomString(5),
                              country: "",
                            },
                          ])
                        }
                      />
                    </label>
                    {sourceCountry &&
                      sourceCountry.map((country) => (
                        <div className="multiple_field_holder">
                          <select
                            type="text"
                            className="agency_input_field_style_multiple_input"
                            name="sourceCountry"
                            value={country.country != "" ? country.country : ""}
                            onChange={(e) =>
                              handleSourseCountryValueEntering(
                                country.id,
                                e.target.value
                              )
                            }
                          >
                            <option>---Select Country ----</option>
                            {newCountryList.map((country) => (
                              <option>{country.label}</option>
                            ))}
                          </select>
                          <AiOutlineMinus
                            className="minus_icon_style"
                            onClick={() => {
                              setSourceCountry(
                                removeElementFromArray(
                                  sourceCountry,
                                  country.id
                                )
                              );
                            }}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="update_agency_profile_btn_holder">
            <button
              className="update_agency_profile_btn_style"
              onClick={handleUpdate}
            >
              Update
            </button>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default AgencyProfileView;
