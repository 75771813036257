import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MdDeleteOutline } from "react-icons/md";
import "./SuperAdminViewSubscriber.css";

import { useGetAllSubscribers } from "../../../../hooks/useGetAllSubscribers";

import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";
import Pagination from "../../../reusables/Pagination/Pagination";
import EmptyComponent from "../../../reusables/EmptryComponent/EmptyComponent";

import CustomButton from "../../../reusables/FromComponent/CustomButton";

import Notification, { exportToExcel } from "../../../../utils/helpers";
import { API_HEADERS } from "../../../../utils/constant";

const SuperAdminViewSubscriber = () => {
  const [fetchAgain, setFetchAgain] = useState(false);
  const [subscribers, isLoading, error] = useGetAllSubscribers(fetchAgain);
  const [paginatedData, setpaginatedData] = useState([]);

  const navigation = useNavigate();

  const handleDeleteSubscriber = (subscriberId) => {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/subscriber/deleteSubscriberById/${subscriberId}`,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Subscriber deleted successfully");
        setFetchAgain(!fetchAgain);
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"SUBSCRIBERS"} />
        <br />
        <br />
        <div className="subscriber_list_holder">
          <div className="new_email_btn_holder">
            <CustomButton
              buttonLabel="Export Detail in Excel"
              onClick={() => {
                exportToExcel(subscribers);
              }}
            />
            <CustomButton
              buttonLabel="Compose new Email"
              onClick={() => navigation("/superAdmin/composeEmail")}
            />
          </div>
          <br />
          {paginatedData?.length ? (
            paginatedData?.map((subscriber, index) => (
              <div className="subscriber_box">
                <p className="index_text">{index + 1}</p>
                <p className="email_text_style">{subscriber.email}</p>
                <p className="date_text_style">
                  {subscriber.createdAt
                    ? new Date(subscriber.createdAt).toLocaleDateString(
                        "en-US",
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        }
                      )
                    : "-"}
                </p>
                <MdDeleteOutline
                  className="sub_icon_style"
                  onClick={() => {
                    handleDeleteSubscriber(subscriber?._id);
                  }}
                />
              </div>
            ))
          ) : (
            <EmptyComponent
              title="No Subscriber found"
              subTitle="Platform haven't got any subscriber yet. When we have one will be listed here."
            />
          )}
          <br />
          <br />
          <Pagination setpaginatedData={setpaginatedData} data={subscribers} />
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminViewSubscriber;
