import React from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "./SuperAdminCourseDetailView.css";
import { BsCheckSquareFill, BsXSquareFill } from "react-icons/bs";

import { useGetCourseById } from "../../../../hooks/useGetCourseById";

import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import CustomVericalInputField from "../../../reusables/FromComponent/CustomVericalInputField";

import Notification, {
  getEducationLevelBylevelNumber,
  replaceLocalhostUrl,
} from "../../../../utils/helpers";
import { API_HEADERS } from "../../../../utils/constant";

const SuperAdminCourseDetailView = () => {
  const { courseId } = useParams();
  const [courseData, isLoading, error] = useGetCourseById(courseId);
  const navigate = useNavigate();

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;

  const handleDeleteCourse = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/course/deleteCourseById/${courseId}/${courseData?.courseUniversityId?._id}`,
        API_HEADERS()
      )
      .then((response) => {
        Notification.successNotification("Course Deleted Successfully");
        setTimeout(() => navigate("/superAdmin/course"), 2000);
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"COURSE VIEW"} />
        <div className="new_course_detail_container">
          <div className="update_course_btn_holder">
            <button
              className="delete_course_btn_style"
              onClick={handleDeleteCourse}
            >
              Delete
            </button>
            <button
              className="update_course_btn_style"
              onClick={() => navigate(`/superAdmin/course/update/${courseId}`)}
            >
              Update Course
            </button>
          </div>

          <div className="course_input_container">
            <div className="container">
              <div className="row">
                <CustomVericalInputField
                  name="courseName"
                  label="Course Name"
                  value={courseData?.courseName}
                  readOnly
                  colClass="col-12 col-md-6 col-lg-4"
                />

                <CustomVericalInputField
                  name="courseSubjectType"
                  label="Course Subject"
                  value={courseData?.courseSubjectType}
                  readOnly
                  colClass="col-12 col-md-6 col-lg-4"
                />

                <CustomVericalInputField
                  name="courseApplicationFee"
                  label="Application Fee"
                  value={courseData?.courseApplicationFee}
                  readOnly
                  colClass="col-12 col-md-6 col-lg-4"
                />

                <CustomVericalInputField
                  name="courseStudyMode"
                  label="Study Mode"
                  value={courseData?.courseStudyMode}
                  readOnly
                  colClass="col-12 col-md-6 col-lg-4"
                />

                <CustomVericalInputField
                  name="courseFee"
                  label="Course fee/year"
                  value={courseData?.courseFee}
                  readOnly
                  colClass="col-12 col-md-6 col-lg-4"
                />

                <CustomVericalInputField
                  name="courseUniversityName"
                  label="University Name"
                  value={courseData?.courseUniversityId?.universityName}
                  readOnly
                  colClass="col-12 col-md-6 col-lg-4"
                />

                <CustomVericalInputField
                  name="courseLanguage"
                  label="Study Language"
                  value={courseData?.courseLanguage}
                  readOnly
                  colClass="col-12 col-md-6 col-lg-4"
                />

                <CustomVericalInputField
                  name="courseLevel"
                  label="Course Level"
                  value={courseData?.courseLevel}
                  readOnly
                  colClass="col-12 col-md-6 col-lg-4"
                />

                <CustomVericalInputField
                  name="courseMaxSeat"
                  label="Available Seats"
                  type="number"
                  value={courseData?.courseMaxSeat}
                  readOnly
                  colClass="col-12 col-md-6 col-lg-4"
                />
              </div>
            </div>
          </div>
          <div className="course_desc_container">
            <PageTitle title={"COURSE OVERVIEW AND DESCRIPTION"} />

            <div className="course_description_holder">
              <p className="course_desc_text_style">
                {courseData?.courseDescription}
              </p>
            </div>

            <div>
              <div className="who_we_are_conatiner">
                <div className="who_we_are_content_holder">
                  <div className="img_border">
                    <div className="img_container">
                      <img
                        src={replaceLocalhostUrl(courseData?.coursethumbnail)}
                        className="who_we_are_img_style"
                        alt="who_we_are_image"
                      ></img>
                    </div>
                  </div>
                  <div className="desc_title_holder">
                    <PageTitle title={"SPECIAL FEATURES"} />
                    <div className="who_we_are_desc">
                      {courseData?.courseSpecialFeature}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="document_required_section">
              <p className="Document_heading">Admission Requirements</p>
              <p className="document_info_text_style">
                (all these options will appear as a dropdown and will determine
                what documents an applicant needs and also what courses an
                applicant is suitable for)
              </p>
              <div className="course_input_container">
                <div className="container">
                  <div className="row">
                    {courseData &&
                      courseData?.basicDoumentRequirement?.map((document) => (
                        <div className="col-12 col-lg-4 col-md-4 col-sm-6 single_document_check_holder">
                          {document["required"] !== "" &&
                          document["required"] !== "false" ? (
                            <BsCheckSquareFill className="document_icon_style_check" />
                          ) : (
                            <BsXSquareFill className="document_icon_style_uncheck" />
                          )}

                          <p className="document_heading_txt_style">
                            {document.documentName}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="work_english_requirement">
              <p className="Document_heading">
                Work experience and English proficiency Requirements
              </p>
              <p className="document_info_text_style">
                (All this data will directly shown in text to the applicant.)
              </p>
              <div
                className="course_input_container"
                style={{ textAlign: "left" }}
              >
                <div className="">
                  <div className="work_requirement">
                    <p className="requirement_heading">
                      Work Experience Requirement
                    </p>
                    {courseData &&
                      courseData?.courseWorkRequirement.map((requirement) => (
                        <div>
                          <p className="requirement_text_style">
                            {requirement?.work} : {requirement?.experience}
                          </p>
                        </div>
                      ))}
                  </div>
                  <div
                    className="english_prof_requiremen"
                    style={{ marginTop: "60px" }}
                  >
                    <p className="requirement_heading">
                      English Proficiency Requirement
                    </p>
                    <div className="row">
                      {courseData &&
                        courseData?.englishTestRequirement.map(
                          (requirement) => (
                            <div className="col-md-6 col-12">
                              <p className="requirement_text_style">
                                {requirement?.englishTest} (Total:{" "}
                                {requirement?.total})
                              </p>
                              <div className="english_test_score_container">
                                <p className="score_text_style requirement_text_style">
                                  Reading: {requirement?.reading}
                                </p>
                                <p className="score_text_style requirement_text_style">
                                  Writing: {requirement?.writing}
                                </p>
                                <p className="score_text_style requirement_text_style">
                                  Listening: {requirement?.listning}
                                </p>
                                <p className="score_text_style requirement_text_style">
                                  Speaking: {requirement?.speaking}
                                </p>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                  <div
                    className="work_requirement"
                    style={{ marginTop: "60px" }}
                  >
                    <p className="requirement_heading">
                      Educational Requirement
                    </p>
                    <p className="requirement_text_style">
                      Minimum Qualification:{" "}
                      {getEducationLevelBylevelNumber(
                        courseData?.educationalRequirement?.educationIQFLevel
                      )}
                    </p>
                    <p className="requirement_text_style">
                      Minimum Score:{" "}
                      {`${courseData?.educationalRequirement?.educationScore}%`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminCourseDetailView;
