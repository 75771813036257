import React, { useState } from "react";
import axios from "axios";

import Notification, {
  removeEmptyProperties,
  replaceLocalhostUrl,
} from "../../../../../utils/helpers";
import { API_HEADERS } from "../../../../../utils/constant";

const WhoWeAreBlock = ({ aboutUsCards }) => {
  const [whoWeAreContent, setWhoWeAreContent] = useState(aboutUsCards);

  const handleInputChange = (index, field, newValue) => {
    const updatedData = [...whoWeAreContent];
    updatedData[index][field] = newValue;
    setWhoWeAreContent(updatedData);
  };

  const handleUpdat = (value) => {
    const newData = removeEmptyProperties(value);
    const cardDataToSend = new FormData();

    Object.keys(newData).forEach((key) => {
      cardDataToSend.append(key, newData[key]);
    });

    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/aboutUsInfo/updateAboutUsCard`,
        cardDataToSend,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification(
          "About Us Content Updated Successfully"
        );
      })
      .catch((err) => {
        Notification.errorNotification(err?.response?.data?.message);
      });
  };
  return (
    <div className="who_we_are_main row gx-4 gy-4">
      <p className="curious_heading">ABOUT US</p>
      {whoWeAreContent?.map((content, index) => (
        <div className="col-12 col-lg-4 col-md-6">
          <div className="who_we_container">
            <div className="who_we_icon_holder">
              <label htmlFor={`team_member_image_input_${content.id}`}>
                {typeof content.iconImage === "string" ? (
                  <img
                    src={replaceLocalhostUrl(content.iconImage)}
                    className="icon_image_style"
                  />
                ) : (
                  <img
                    src={URL.createObjectURL(content.iconImage)}
                    className="icon_image_style"
                  />
                )}
              </label>
              <input
                id={`team_member_image_input_${content.id}`}
                type="file"
                className="image_input_hiddden_style"
                name="ourCultureImage"
                onChange={(e) =>
                  handleInputChange(index, "iconImage", e.target.files[0])
                }
              />
            </div>
            <div className="who_we_content_holder">
              <input
                type="text"
                value={content.heading}
                placeholder="Enter Heading"
                className="who_we_are_headings aboutus_form_input_style"
                onChange={(e) =>
                  handleInputChange(index, "heading", e.target.value)
                }
              />
              <textarea
                rows={5}
                value={content.description}
                placeholder="Enter Heading"
                className="who_we_are_description aboutus_form_input_style"
                onChange={(e) =>
                  handleInputChange(index, "description", e.target.value)
                }
              />
              <div className="d-flex justify-content-end">
                <button
                  className="update_team_member_btn mb-3 mt-1"
                  onClick={() => handleUpdat(content)}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default WhoWeAreBlock;
