import React, { useContext, useEffect, useState } from "react";
import "./Course.css";

import { useGetAllCourse } from "../../../hooks/useGetAllCourse";
import { useGetFavoriteCourseByStudentId } from "../../../hooks/useGetFavoriteCourseByStudentId";

import { AuthContext } from "../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../reusables/Navbar/UserNavbar/UserNavbar";
import UniversityCard from "../../reusables/UniversityCard/UniversityCard";
import CourseCard from "../../reusables/CourseCard/CourseCard";
import EmptyComponent from "../../reusables/EmptryComponent/EmptyComponent";
import SideManu from "./SideBar/SideManu";
import PageTitle from "../../reusables/PageTitle/PageTitle";
import Loader from "../../reusables/Loader/Loader";
import ErrorPage from "../../reusables/ErrorPage/ErrorPage";
import Pagination from "../../reusables/Pagination/Pagination";

import {
  courseFilter,
  getIdListFormArrayOfObject,
} from "../../../utils/helpers";

const Course = () => {
  const [course, isLoading, error] = useGetAllCourse();
  const [showFilter, setShowFilter] = useState(false);
  const { user } = useContext(AuthContext);
  const [filteredData, setFilteredData] = useState(course);
  const [paginatedData, setpaginatedData] = useState(filteredData);

  const FavoriteCourses = useGetFavoriteCourseByStudentId(
    user?.studentRef ||
      JSON.parse(localStorage.getItem("currentLoginUser"))?.studentRef
  );

  const [searchObjData, setSearchObjData] = useState(null);

  const clearSearch = () => {
    setFilteredData(course);
  };

  useEffect(() => {
    async function filterFunction() {
      if (searchObjData !== null)
        setFilteredData(await courseFilter(searchObjData, course));
    }

    filterFunction();
  }, [searchObjData]);

  useEffect(() => {
    setFilteredData(course);
  }, [course]);

  useEffect(() => {
    setpaginatedData(filteredData);
  }, [filteredData]);

  if (FavoriteCourses[1] || isLoading) return <Loader />;
  if (FavoriteCourses[2] || error) return <ErrorPage />;

  return (
    <>
      <UserNavbar>
        <PageTitle title={"COURSES"} />
        <br />
        <br />
        <div className="course_page_main_container">
          <div className="course_page_mobile_sidebar_container">
            <button
              onClick={() => setShowFilter(!showFilter)}
              className="course_page_show_filter_btn"
            >
              Filter Data
            </button>
            {showFilter && (
              <SideManu
                setSearchObjData={setSearchObjData}
                clearSearch={clearSearch}
              />
            )}
          </div>
          <div className="course_page_sidbe_container">
            <SideManu
              setSearchObjData={setSearchObjData}
              clearSearch={clearSearch}
            />
          </div>
          {paginatedData?.length ? (
            <div className="university_course_container">
              {paginatedData &&
                paginatedData.map((data, index) => (
                  <div className=" row" key={index}>
                    <div className="col-12 p-3 col-lg-7 m-auto m-md-0">
                      <UniversityCard university={data?.courseUniversityId} />
                    </div>
                    <div className="col-12 p-3 col-lg-5 m-auto m-md-0">
                      <CourseCard
                        courseData={data}
                        favoriteList={getIdListFormArrayOfObject(
                          FavoriteCourses[0]
                        )}
                      />
                    </div>
                  </div>
                ))}
              <Pagination
                setpaginatedData={setpaginatedData}
                data={filteredData}
              />
            </div>
          ) : (
            <EmptyComponent
              title={"No courses found"}
              subTitle={"Please try expanding your search"}
            />
          )}
        </div>
      </UserNavbar>
    </>
  );
};

export default Course;
