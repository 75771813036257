import { useEffect, useState } from "react";
import axios from "axios";
import { API_HEADERS } from "../utils/constant";

export const useGetApplicationByAgencyId = (agencyId) => {
  const [isLoading, setIsLoading] = useState(false);
  const [applications, setApplications] = useState(null);
  const [error, setError] = useState();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/agency/getApplicationFormAgency/${agencyId}`,
        API_HEADERS()
      )
      .then((response) => {
        setApplications(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, [agencyId]);

  return [applications, isLoading, error];
};
