import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./UniversityAddNewUser.css";

import { useGetUniversityById } from "../../../../hooks/useGetUniversityById";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import CustomInputField from "../../../reusables/FromComponent/CustomInputField";
import CustomSelectField from "../../../reusables/FromComponent/CustomSelectField";

import Notification, {
  findEmptyFields,
  handleStateChange,
} from "../../../../utils/helpers";
import {
  API_HEADERS,
  UniversityUserRolesSelect,
  addgeneralUserDefaultStateObject,
} from "../../../../utils/constant";

const UniversityAddNewUser = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [newAdminData, setNewAdmindata] = useState({
    ...addgeneralUserDefaultStateObject,
    addedBy:
      user?._id || JSON.parse(localStorage.getItem("currentLoginUser"))?._id,
  });

  const [universityData, isLoading, error] = useGetUniversityById(
    user?.universityRef ||
      JSON.parse(localStorage.getItem("currentLoginUser"))?.universityRef
  );

  const handleAddNewAdmin = () => {
    if (findEmptyFields(newAdminData).length > 0) {
      Notification.errorNotification(
        `Please add ${findEmptyFields(newAdminData)[0]}`
      );
    } else if (newAdminData.password !== newAdminData.confirmPassword) {
      Notification.errorNotification(
        "Please confirm your password correctly..!"
      );
    } else if (!universityData?.isVerified) {
      Notification.errorNotification("University not verified! Access Denied.");
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/university/addUserToUneversity`,
          {
            ...newAdminData,
            universityRef: JSON.parse(localStorage.getItem("currentLoginUser"))
              ?.universityRef,
          },
          API_HEADERS()
        )
        .then(() => {
          Notification.successNotification("New User added Successfully");
          navigate("/university/userList");
        })
        .catch((err) => {
          Notification.errorNotification(err.response.data.message);
        });
    }
  };

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;
  return (
    <div>
      <UserNavbar>
        <PageTitle title={"ADD UNIVERSITY USER"} />
        <div className="agent_view_form_holder">
          <CustomInputField
            name="username"
            value={newAdminData?.username}
            label={"User Name"}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />
          <CustomInputField
            type="email"
            value={newAdminData?.email}
            name={"email"}
            label={"Email"}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />
          <CustomInputField
            name="firstName"
            value={newAdminData?.firstName}
            label={"First Name"}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />
          <CustomInputField
            name="lastName"
            value={newAdminData?.lastName}
            label={"Last Name"}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />
          <CustomSelectField
            name="role"
            value={newAdminData?.role}
            label={"Role"}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
            options={UniversityUserRolesSelect}
          />
          <CustomInputField
            type="password"
            value={newAdminData?.password}
            name={"password"}
            label={"Password"}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />
          <CustomInputField
            type="password"
            value={newAdminData?.confirmPassword}
            name={"confirmPassword"}
            label={"Confirm Password"}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />
          <div className="agent_label_field_holder">
            <label className="university_label_style"> </label>
            <div className="university_user_action_btn_holder">
              <button
                className="agency_agent_btn_style"
                onClick={handleAddNewAdmin}
              >
                Add Agent
              </button>
            </div>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default UniversityAddNewUser;
