import React from "react";
import { BsFacebook, BsInstagram, BsLinkedin, BsYoutube } from "react-icons/bs";
import { Link } from "react-router-dom";

const SocialMediaBlock = () => {
  return (
    <div className="email_data_holder">
      <p className="email_reacg_out_heading">FOLLOW OUR JOURNEY</p>
      <p className="email_heading_style">Follow us in social media</p>
      <p className="email_desc_style">
        Stay informed with our exciting journey connecting people to the world
        of learning.
      </p>
      <div className="social_icon_holder">
        <a
          href="https://www.facebook.com/profile.php?id=61550971872712"
          target="_blank"
        >
          <BsFacebook className="contact_us_icons_style_solical" />
        </a>
        <BsYoutube className="contact_us_icons_style_solical margin_left_style" />
        <a
          href="https://instagram.com/zoomtocollege?igshid=OGQ5ZDc2ODk2ZA=="
          target="_blank"
        >
          <BsInstagram className="contact_us_icons_style_solical margin_left_style" />
        </a>
        <a
          href="https://www.linkedin.com/company/zoomtocollege/"
          target="_blank"
        >
          <BsLinkedin className="contact_us_icons_style_solical margin_left_style" />
        </a>
      </div>
    </div>
  );
};

export default SocialMediaBlock;
