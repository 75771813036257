import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BsCalendar } from "react-icons/bs";
import { TfiPencilAlt } from "react-icons/tfi";
import "./SuperAdminBlogPreview.css";

import { useGetBlogById } from "../../../../hooks/useGetBlogById";

import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import Notification, { replaceLocalhostUrl } from "../../../../utils/helpers";
import { API_HEADERS } from "../../../../utils/constant";

const SuperAdminBlogPreview = () => {
  const { blogId } = useParams();
  const [blogData, isLoading, error] = useGetBlogById(blogId);
  const navigate = useNavigate();

  const handleDeleteBlog = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/blog/deleteBlogById/${blogId}`,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Student Deleted Successfully");
        setTimeout(() => navigate("/superAdmin/blog"), 2000);
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  const handlePublishAndUnpublishBlog = (status) => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/blog/changePublishStatusByBlogId`,
        {
          blogId,
          status,
          ...(status === "published" ? { publishDate: new Date() } : null),
        },
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Blog Status changed");
        setTimeout(() => navigate("/superAdmin/blog"), 2000);
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;
  return (
    <div>
      <UserNavbar>
        <PageTitle title={"BLOG PREVIEW"} />
        <div className="main_blog_container">
          <div className="blogTitleHolder">
            <p className="blog_title_text_style">{blogData?.title}</p>
          </div>
          <div className="blog_info_holder">
            <div className="auther_info">
              <TfiPencilAlt className="author_icon_style" />
              <p>{blogData?.author?.username}</p>
            </div>
            <div className="auther_info margin_left_style">
              <BsCalendar className="author_icon_style" />
              <p>
                {blogData?.publishDate
                  ? new Date(blogData?.publishDate).toLocaleDateString(
                      "en-US",
                      {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      }
                    )
                  : "-"}
              </p>
            </div>
          </div>

          {blogData?.blogImage && (
            <div className="image_contaienr">
              <img
                src={replaceLocalhostUrl(blogData?.blogImage)}
                className="image_style"
                alt="blog_image"
              />
            </div>
          )}
          <div
            className="blog_content_holder"
            dangerouslySetInnerHTML={{ __html: blogData?.description }}
          />

          <div className="blog_action_btn_holder">
            <button
              className="agency_agent_btn_style mt-1"
              onClick={() => {
                blogData?.status === "PublishPending"
                  ? handlePublishAndUnpublishBlog("published")
                  : handlePublishAndUnpublishBlog("PublishPending");
              }}
            >
              {blogData?.status === "PublishPending"
                ? "Publish Blog"
                : "Unpublish Blog"}
            </button>
            <button
              className="agency_agent_btn_style margin_left_style mt-1"
              onClick={() => navigate(`/superAdmin/blog/update/${blogId}`)}
            >
              Update
            </button>
            <button
              className="agency_agent_btn_style margin_left_style mt-1"
              onClick={handleDeleteBlog}
            >
              delete
            </button>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminBlogPreview;
