import React, { useState } from "react";

import CustomVericalInputField from "../../../reusables/FromComponent/CustomVericalInputField";
import CustomVerticalSelectField from "../../../reusables/FromComponent/CustomVerticalSelectField";
import CustomButton from "../../../reusables/FromComponent/CustomButton";

import Notification, {
  findEmptyFields,
  handleStateChange,
} from "../../../../utils/helpers";
import {
  UniversityUserRolesSelect,
  agencySignUpStep4DefaultStateObj,
} from "../../../../utils/constant";

const UniversitySignUpStep2 = ({ handleRoleSubmit }) => {
  const [step4Data, setStep4Data] = useState(agencySignUpStep4DefaultStateObj);

  const handleSubmit = () => {
    if (findEmptyFields(step4Data).length) {
      Notification.errorNotification(
        `Please Enter following Field:  ${findEmptyFields(step4Data)[0]}`
      );
    } else {
      handleRoleSubmit(step4Data);
    }
  };

  return (
    <div className="sign_in_step_1_form_holder">
      <form className="step-1_form">
        {/* <div className="field_holder">
          <input
            type="text"
            placeholder="User First Name"
            className="input_field_style"
            name="firstName"
            onChange={(e) => handleStateChange(e, setStep4Data)}
          />
        </div> */}

        <CustomVericalInputField
          name="firstName"
          label="User First Name"
          value={step4Data?.firstName}
          onChange={(e) => handleStateChange(e, setStep4Data)}
        />
        {/* <div className="field_holder">
          <input
            type="text"
            placeholder="User Last Name"
            className="input_field_style"
            name="lastName"
            onChange={(e) => handleStateChange(e, setStep4Data)}
          />
        </div> */}

        <CustomVericalInputField
          name="lastName"
          label="User Last Name"
          value={step4Data?.lastName}
          onChange={(e) => handleStateChange(e, setStep4Data)}
        />

        {/* <div className="field_holder">
          <input
            type="text"
            placeholder="User Prefered Username"
            className="input_field_style"
            name="username"
            onChange={(e) => handleStateChange(e, setStep4Data)}
          />
        </div> */}

        <CustomVericalInputField
          name="username"
          label="Preferred Username"
          value={step4Data?.username}
          onChange={(e) => handleStateChange(e, setStep4Data)}
        />

        {/* <div className="field_holder">
          <select
            type="text"
            className="input_field_style"
            name="role"
            onChange={(e) => handleStateChange(e, setStep4Data)}
          >
            <option>---- Select User Type ----</option>
            <option value={"UniversitySuperAdmin"}>
              University Super Admin
            </option>
            <option value={"UniversitySubSuperAdmin"}>
              University Sub Super Admin
            </option>
            <option value={"UniversitySubAdmin"}>University Sub Admin</option>
          </select>
        </div> */}

        <CustomVerticalSelectField
          name="role"
          label="User Role"
          value={step4Data?.role}
          onChange={(e) => handleStateChange(e, setStep4Data)}
          options={UniversityUserRolesSelect}
        />
        {/* <div className="field_holder">
          <input
            type="email"
            placeholder="User Email"
            className="input_field_style"
            name="email"
            onChange={(e) => handleStateChange(e, setStep4Data)}
          />
        </div> */}

        <CustomVericalInputField
          name="email"
          label="User Email"
          type="email"
          value={step4Data?.email}
          onChange={(e) => handleStateChange(e, setStep4Data)}
        />

        {/* <div className="field_holder">
          <input
            type="password"
            placeholder="Enter Password"
            className="input_field_style"
            name="password"
            onChange={(e) => handleStateChange(e, setStep4Data)}
          />
        </div> */}

        <CustomVericalInputField
          name="password"
          label="Enter Password"
          type="password"
          value={step4Data?.password}
          onChange={(e) => handleStateChange(e, setStep4Data)}
        />

        {/* <div className="field_holder">
          <input
            type="password"
            placeholder="Confirm Password"
            className="input_field_style"
            name="confirmPassword"
            onChange={(e) => handleStateChange(e, setStep4Data)}
          />
        </div> */}

        <CustomVericalInputField
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          value={step4Data?.confirmPassword}
          onChange={(e) => handleStateChange(e, setStep4Data)}
        />

        {/* <div className="next_button_holder" type="button">
          <button type="button" className="next_button" onClick={handleSubmit}>
            Submit
          </button>
        </div> */}

        <CustomButton buttonLabel="Submit" onClick={handleSubmit} fullwidth />
      </form>
    </div>
  );
};

export default UniversitySignUpStep2;
