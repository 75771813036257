import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./AgencySignUp.css";

import Notification, {
  classNames,
  findEmptyFields,
} from "../../../utils/helpers";
import SignUpStep1 from "./SignupSteps/SignUpStep1";
import SignUpStep2 from "./SignupSteps/SignUpStep2";
import SignUpStep3 from "./SignupSteps/SignUpStep3";
import { AgencySignUpTabs } from "../../../utils/constant";
import MainNavbar from "../../reusables/Navbar/MainNavbar/MainNavbar";
import SignUpStep4 from "./SignupSteps/SignUpStep4";

const AgencySignUp = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("tab1");

  const [recruiterSigninData, setRecruiterSigninData] = useState({
    isVerified: false,
  });

  const updateRecruitersDataState = (values) => {
    const newFields = { ...recruiterSigninData, ...values };
    setRecruiterSigninData(newFields);
  };

  const handleChangeTab = async (data) => {
    setActiveTab(data);
  };

  const onSubmit = (data) => {
    if (findEmptyFields(data).length <= 0) {
      const recruiterSignUpData = { ...recruiterSigninData, ...data };
      const formData = new FormData();

      Object.keys(recruiterSignUpData).forEach((item) => {
        if (Array.isArray(recruiterSignUpData[item])) {
          formData.append(item, JSON.stringify(recruiterSignUpData[item]));
        } else {
          formData.append(item, recruiterSignUpData[item]);
        }
      });

      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/auth/signup`, formData)
        .then(() => {
          Notification.successNotification("Your agency added Successfully");
          navigate("/login");
        })
        .catch((err) => {
          Notification.errorNotification(err?.response?.data?.message);
        });
    } else {
      Notification.errorNotification("Please fill all the required field");
    }
  };

  return (
    <MainNavbar>
      <div className="signup_main_conatainer">
        <div className="block_title">
          <p className="title_text_style">RECRUITMENT AGENT SIGN UP</p>
          <div className="short_border"></div>
        </div>
        <div className="sign_up_tab_holder">
          {AgencySignUpTabs.map((tab) => (
            <div className="tab_btn_holder_cont">
              <button
                className={classNames(
                  tab.tab === activeTab && "selected_tab_btn-style",
                  "tab_btn_style"
                )}
                onClick={() => handleChangeTab(tab.tab)}
              >
                {tab.name}
              </button>
            </div>
          ))}
        </div>
        <div>
          {activeTab === "tab1" ? (
            <SignUpStep1
              handleChangeTab={handleChangeTab}
              handleSaveFields={updateRecruitersDataState}
            />
          ) : activeTab === "tab2" ? (
            <SignUpStep2
              handleChangeTab={handleChangeTab}
              handleSaveFields={updateRecruitersDataState}
            />
          ) : activeTab === "tab3" ? (
            <SignUpStep3
              handleChangeTab={handleChangeTab}
              handleSaveFields={updateRecruitersDataState}
            />
          ) : activeTab === "tab4" ? (
            <SignUpStep4 handleRoleSubmit={(data) => onSubmit(data)} />
          ) : null}
        </div>
      </div>
    </MainNavbar>
  );
};

export default AgencySignUp;
