import React from "react";
import "./PageTitle.css";

const PageTitle = ({ title }) => {
  return (
    <div className="title_holder">
      <p className="title_text_style">{title}</p>
      <div className="horizonral_green_line"></div>
    </div>
  );
};

export default PageTitle;
