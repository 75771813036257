import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TfiBrushAlt } from "react-icons/tfi";
import { DateRange } from "react-date-range";

import { useGetStudentForAgencyByAgencyRef } from "../../../../hooks/useGetStudentForAgencyByAgencyRef";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import TableComponent from "../../../reusables/TableComponent/TableComponent";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";
import EmptyComponent from "../../../reusables/EmptryComponent/EmptyComponent";

import { handleStateChange, searchData } from "../../../../utils/helpers";
import {
  countries,
  studentFieldToShow,
  studentListCols,
} from "../../../../utils/constant";

const AgencyStudentList = () => {
  const { user } = useContext(AuthContext);
  const [students, isLoading, error] = useGetStudentForAgencyByAgencyRef(
    user?.agencyRef ||
      JSON.parse(localStorage.getItem("currentLoginUser")).agencyRef
  );

  const [filterData, setFilterData] = useState(students);

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [filterContent, setFilterContent] = useState({
    startDate: "",
    endDate: "",
    firstName: "",
    lastName: "",
    countryOfResidence: "",
  });

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const clearSearch = () => {
    setFilterContent({
      startDate: "",
      endDate: "",
      firstName: "",
      lastName: "",
      countryOfResidence: "",
    });
    setFilterData(students);
  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (ranges) => {
    setFilterContent((prev) => ({
      ...prev,
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    }));
    onToggle();
  };

  useEffect(() => {
    setFilterData(searchData(filterContent, students));
  }, [filterContent]);

  useEffect(() => {
    setFilterData(students);
  }, [students]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"STUDENTS"} />

        <div className="student_list_container">
          <div className="action_button_holder">
            <div className="date_range_keeper">
              <input
                className="applciation_input_field_style date_show_input"
                value={
                  filterContent.endDate &&
                  filterContent.startDate &&
                  `${new Date(
                    filterContent.startDate
                  ).toLocaleDateString()} - ${new Date(
                    filterContent.endDate
                  ).toLocaleDateString()}`
                }
                placeholder="Select dates"
                onClick={onToggle}
              />
              {isOpen && (
                <div className="date_picker_holder">
                  <DateRange
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    showSelectionPreview={true}
                  />
                </div>
              )}
            </div>
            {/* <select
              className="applciation_input_field_style"
              name="countryOfResidence"
              onChange={(e) => handleStateChange(e, setFilterContent)}
            >
              {countries.map((country) => (
                <option>{country.name}</option>
              ))}
            </select> */}
            <input
              type="text"
              className="applciation_input_field_style"
              placeholder="Search By first name"
              name="firstName"
              onChange={(e) => handleStateChange(e, setFilterContent)}
              value={filterContent.firstName}
            />

            <input
              type="text"
              className="applciation_input_field_style"
              placeholder="Search by last name"
              name="lastName"
              onChange={(e) => handleStateChange(e, setFilterContent)}
              value={filterContent.lastName}
            />

            <button
              className="add_university_navigation_btn"
              onClick={clearSearch}
            >
              <TfiBrushAlt className="clear_icon_style" />
            </button>
            <button
              className="add_university_navigation_btn"
              onClick={() => navigate("/agency/student/addStudent")}
            >
              Add New Student
            </button>
          </div>

          <TableComponent
            tableColumns={studentListCols}
            data={filterData}
            fieldsToShowList={studentFieldToShow}
            navigateTo="/agency/student/view"
            emptyFor="agencyStudentList"
          />
        </div>
      </UserNavbar>
    </div>
  );
};

export default AgencyStudentList;
