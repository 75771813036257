import React, { useContext, useState } from "react";
import axios from "axios";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import ReactQuill from "react-quill";
import "./SuperAdminAddBlog.css";
import "react-quill/dist/quill.snow.css";
import "./TextEditor.css";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";

import Notification, {
  handleFileInputInState,
} from "../../../../utils/helpers";
import { API_HEADERS } from "../../../../utils/constant";

const SuperAdminAddBlog = () => {
  const { user } = useContext(AuthContext);

  const [blogInfo, setblogInfo] = useState({
    title: "",
    description: "",
    author:
      user?._id || JSON.parse(localStorage.getItem("currentLoginUser"))?._id,
    status: "",
  });

  const onChangeValue = (e) => {
    setblogInfo({
      ...blogInfo,
      [e.target.name]: e.target.value,
    });
  };

  const ondescription = (value) => {
    setblogInfo({ ...blogInfo, description: value });
  };

  const oninformation = (value) => {
    setblogInfo({ ...blogInfo, information: value });
  };

  const [isError, setError] = useState(null);

  const addDetails = async (event) => {
    const blogFormData = new FormData();

    const blogInfoNew = { ...blogInfo, status: "PublishPending" };

    Object.keys(blogInfoNew).forEach((key) => {
      blogFormData.append(key, blogInfoNew[key]);
    });
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/blog/addBlog`,
        blogFormData,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("New Blog added Successfully");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  const addDetailsAndPublish = () => {
    const blogFormData = new FormData();

    const blogInfoNew = {
      ...blogInfo,
      status: "published",
      publishDate: new Date(),
    };

    Object.keys(blogInfoNew).forEach((key) => {
      blogFormData.append(key, blogInfoNew[key]);
    });
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/blog/addBlog`,
        blogFormData,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("New Blog added Successfully");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  return (
    <div>
      <UserNavbar>
        <PageTitle title="BLOG" />
        <div className="recruitment_agent_form">
          <div className="container">
            <div className="row">
              <form className="update__forms">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="font-weight-bold">Title</label>
                    <input
                      type="text"
                      name="title"
                      value={blogInfo?.title}
                      onChange={onChangeValue}
                      className="form-control"
                      placeholder="Title"
                      required
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="font-weight-bold">Blog Image </label>
                    <label
                      className="uploaded_blog_img_label"
                      htmlFor="blogImage"
                    >
                      {blogInfo.blogImage ? (
                        <img
                          src={URL.createObjectURL(blogInfo.blogImage)}
                          className="uploaded_blog_img_style"
                          controls
                          alt="blog_image"
                        />
                      ) : (
                        <img
                          src={"/images/superAdmin/upload_placeholder.png"}
                          className="blog_img_style"
                          controls
                          alt="blog_image"
                        />
                      )}
                    </label>
                    <input
                      type="file"
                      className="actual_documetn_upload_input_field"
                      id="blogImage"
                      name="blogImage"
                      onChange={(e) => handleFileInputInState(e, setblogInfo)}
                    />
                  </div>

                  <div className="form-group col-md-12 editor">
                    <label className="font-weight-bold">
                      {" "}
                      Description <span className="required"> * </span>{" "}
                    </label>
                    <EditorToolbar toolbarId={"t1"} />
                    <ReactQuill
                      theme="snow"
                      value={blogInfo?.description}
                      onChange={ondescription}
                      placeholder={"Write something awesome..."}
                      modules={modules("t1")}
                      formats={formats}
                    />
                  </div>
                  <br />
                  <br />
                  {isError !== null && (
                    <div className="errors"> {isError} </div>
                  )}
                  <div className="form-group col-sm-12 text-right">
                    <button
                      type="button"
                      className="agency_agent_btn_style"
                      onClick={addDetails}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="agency_agent_btn_style margin_left_style"
                      onClick={addDetailsAndPublish}
                    >
                      Save and Publish
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminAddBlog;
