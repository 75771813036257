import React, { useState } from "react";
import { useGetSuperAdminByRole } from "../../../../hooks/useGetSuperAdminByRole";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";
import EmptyComponent from "../../../reusables/EmptryComponent/EmptyComponent";
import { MdDeleteOutline } from "react-icons/md";
import Pagination from "../../../reusables/Pagination/Pagination";
import MailEditor from "./MailEditor";
import { useEffect } from "react";
import { handleStateChange, searchData } from "../../../../utils/helpers";

const UniversityMail = () => {
  const [paginatedData, setpaginatedData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [filterData, setFilterData] = useState([]);

  const [filterContent, setFilterContent] = useState({
    searchStr: "",
  });

  const [superAdmin, isLoading, error] = useGetSuperAdminByRole(
    "UniversitySubSuperAdmin",
    "universityRef",
    "universityName"
  );

  useEffect(() => {
    if (filterContent.searchStr == "") setFilterData(superAdmin);
    else {
      setFilterData(
        superAdmin.filter((data) => {
          return (
            data.email
              .toLowerCase()
              .includes(filterContent.searchStr.toLowerCase()) ||
            data?.universityRef?.universityName
              .toLowerCase()
              .includes(filterContent?.searchStr.toLowerCase())
          );
        })
      );
    }
  }, [filterContent]);

  useEffect(() => {
    setFilterData(superAdmin);
  }, [superAdmin]);

  // Function to handle checkbox change
  const handleCheckboxChange = (subscriber) => {
    const updatedSelectedUsers = [...selectedUsers];
    const emailIndex = updatedSelectedUsers.findIndex(
      (email) => email === subscriber.email
    );

    if (emailIndex === -1) {
      updatedSelectedUsers.push(subscriber.email);
    } else {
      updatedSelectedUsers.splice(emailIndex, 1);
    }

    setSelectedUsers(updatedSelectedUsers);
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedUsers([]);
    } else {
      const allEmails = superAdmin.map((subscriber) => subscriber.email);
      setSelectedUsers(allEmails);
    }

    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (selectedUsers.length === superAdmin.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedUsers, superAdmin]);

  return (
    <div className="row mail_management_container">
      <div className="col-12 col-lg-6">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="select_all_check_box">
              <label>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                  className="me-3"
                />
                {selectedUsers.length === superAdmin.length
                  ? "Deselect All"
                  : "Select All"}
              </label>
              <input
                type="text"
                className="mail_search_input"
                placeholder="Search by email or institution"
                name="searchStr"
                onChange={(e) => handleStateChange(e, setFilterContent)}
                value={filterContent.searchStr}
              />
            </div>
            {paginatedData?.length ? (
              paginatedData?.map((subscriber, index) => (
                <div className="mail_user_list">
                  <input
                    type="checkbox"
                    checked={selectedUsers.includes(subscriber.email)}
                    onChange={() => handleCheckboxChange(subscriber)}
                  />
                  <p className="mail_user_list_index">{index + 1}</p>
                  <p className="email_text_style">{subscriber?.email}</p>
                  <p className="email_text_style">
                    {subscriber?.universityRef?.universityName}
                  </p>
                </div>
              ))
            ) : (
              <EmptyComponent
                title="No Subscriber found"
                subTitle="Platform haven't got any subscriber yet. When we have one will be listed here."
              />
            )}
            <Pagination setpaginatedData={setpaginatedData} data={filterData} />
          </>
        )}
      </div>
      <div className="col-12 col-lg-6">
        <MailEditor selectedUsers={selectedUsers} />
      </div>
    </div>
  );
};

export default UniversityMail;
