import React from "react";
import { replaceLocalhostUrl } from "../../../../utils/helpers";

const GoalStatement = ({ valuesAndGoalsArray, ownerName, wordFromOwner }) => {
  return (
    <div className="goal_state_main">
      <p className="curious_heading">OUR VALUES AND GOALS</p>
      <div className="values_goals_area">
        <div className="goal_values_area row">
          {valuesAndGoalsArray?.map((value) => (
            <div
              key={value.id}
              className="col-12 col-lg-6 col-md-6 col-sm-6 value_container"
            >
              <img
                src={replaceLocalhostUrl(value.iconImage)}
                width={50}
                height={50}
              />
              <p className="value_name_style">{value.name}</p>
              <p className="value_description_style">{value.description}</p>
            </div>
          ))}
        </div>
        <div className="goal_statement_area">
          <p className="curious_heading">WORD FROM OUR FOUNDER</p>
          <p className="word_from_owner_style">{wordFromOwner}</p>
          <p className="curious_heading">- {ownerName}</p>
        </div>
      </div>
    </div>
  );
};

export default GoalStatement;
