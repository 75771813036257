import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { useGetBlogById } from "../../../../hooks/useGetBlogById";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";
import EditorToolbar, {
  formats,
  modules,
} from "../SuperAdminAddBlog/EditorToolbar";

import Notification, {
  handleFileInputInState,
  removeEmptyProperties,
  replaceLocalhostUrl,
} from "../../../../utils/helpers";
import { API_HEADERS } from "../../../../utils/constant";

const SuperAdminUpdateBlog = () => {
  const { blogId } = useParams();
  const navigate = useNavigate();
  const [blogData, isLoading, error] = useGetBlogById(blogId);
  const { user } = useNavigate(AuthContext);
  const [blogInfo, setblogInfo] = useState({ blogImage: "" });

  const onChangeValue = (e) => {
    setblogInfo({
      ...blogInfo,
      [e.target.name]: e.target.value,
    });
  };

  const ondescription = (value) => {
    setblogInfo({ ...blogInfo, description: value });
  };

  const oninformation = (value) => {
    setblogInfo({ ...blogInfo, information: value });
  };

  const [isError, setError] = useState(null);

  const handleUpdateBlog = async (event) => {
    const blogFormData = new FormData();
    const newBlogInfo = removeEmptyProperties(blogInfo);

    Object.keys(newBlogInfo).forEach((key) => {
      blogFormData.append(key, newBlogInfo[key]);
    });
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/blog/updateBlogById`,
        blogFormData,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Blog Updated Successfuly");
        navigate(`/superAdmin/blog/view/${blogId}`);
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  useEffect(() => {
    setblogInfo({
      title: blogData?.title,
      description: blogData?.description || "",
      author:
        user?._id || JSON.parse(localStorage.getItem("currentLoginUser"))?._id,
      status: blogData?.status,
      blogImage: "",
      blogId: blogId,
    });
  }, [blogData, blogId]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"UPDATE BLOG"} />
        <div className="recruitment_agent_form">
          <div className="container">
            <div className="row">
              <form className="update__forms">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="font-weight-bold">Title</label>
                    <input
                      type="text"
                      name="title"
                      value={blogInfo?.title}
                      onChange={onChangeValue}
                      className="form-control"
                      placeholder="Title"
                      required
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="font-weight-bold">Blog Image </label>
                    <label
                      className="uploaded_blog_img_label"
                      htmlFor="blogImage"
                    >
                      {blogInfo?.blogImage !== "" ? (
                        <img
                          src={URL.createObjectURL(blogInfo?.blogImage)}
                          className="uploaded_blog_img_style"
                          controls
                          alt="blog_image"
                        />
                      ) : blogData?.blogImage ? (
                        <img
                          src={replaceLocalhostUrl(blogData?.blogImage)}
                          className="uploaded_blog_img_style"
                          controls
                          alt="blog_image"
                        />
                      ) : (
                        <img
                          src={"/images/superAdmin/upload_placeholder.png"}
                          className="blog_img_style"
                          controls
                          alt="blog_image"
                        />
                      )}
                    </label>
                    <input
                      type="file"
                      className="actual_documetn_upload_input_field"
                      id="blogImage"
                      name="blogImage"
                      onChange={(e) => handleFileInputInState(e, setblogInfo)}
                    />
                  </div>

                  <div className="form-group col-md-12 editor">
                    <label className="font-weight-bold">
                      {" "}
                      Description <span className="required"> * </span>{" "}
                    </label>
                    <EditorToolbar toolbarId={"t1"} />
                    <ReactQuill
                      theme="snow"
                      value={blogInfo?.description}
                      onChange={ondescription}
                      placeholder={"Write something awesome..."}
                      modules={modules("t1")}
                      formats={formats}
                    />
                  </div>
                  <br />
                  <br />
                  {isError !== null && (
                    <div className="errors"> {isError} </div>
                  )}
                  <div className="form-group col-sm-12 text-right">
                    <button
                      type="button"
                      className="agency_agent_btn_style"
                      onClick={handleUpdateBlog}
                    >
                      Update and Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminUpdateBlog;
