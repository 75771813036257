import React from "react";
import { useNavigate } from "react-router-dom";
import "./ErrorPage.css";

import UserNavbar from "../Navbar/UserNavbar/UserNavbar";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <UserNavbar>
        <div className="container" style={{ margin: "-50px 0px" }}>
          <div className="row">
            <div className="col-12 col-lg-6 align-self-center">
              <img
                src="/images/ErrorImage/falling_astronaut.jpg"
                alt="Error_page_astronaut_image"
              />
            </div>
            <div className="col-12 col-lg-6 align-self-center">
              <h1 className="Heading404">500</h1>
              <h2 className="sub_heading_404">UH OH! Something went wrong</h2>
              <p className="">
                We are trying to solve the problem. It might take few Minutes.
                Apologies.
              </p>
              <button
                className="btn_to_home"
                onClick={() => {
                  navigate("/");
                }}
              >
                HOME
              </button>
            </div>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default ErrorPage;
