import { toast } from "react-toastify";
import ExcelJS from "exceljs";
import { educationalRequirementSelect } from "./constant";

export const handleStateChange = (e, stateName) => {
  const { name, value } = e.target;
  stateName((prevState) => ({
    ...prevState,
    [name]: value,
  }));
};

export const handleFileInputInState = (e, stateName) => {
  const { name } = e.target;
  stateName((prevState) => ({
    ...prevState,
    [name]: e.target.files[0],
  }));
};

export const generateRandomString = (length) => {
  const chars =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    result += chars.charAt(randomIndex);
  }

  return result;
};

export const randomNumberString = (length) => {
  const characters = "1234567890";
  let randomString = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
};

export const removeElementFromArray = (array, element) => {
  if (array.length <= 1) {
    return array;
  }
  const newArr = array.filter((item) => {
    return item.id !== element;
  });

  return newArr;
};

export function removeEmptyProperties(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (
        obj[key] === "" ||
        obj[key] === null ||
        obj[key] === undefined ||
        obj[key].length === 0
      ) {
        delete obj[key];
      }
    }
  }
  return obj;
}

export function removePropertiesFromArray(arr, propertiesToRemove) {
  if (arr?.length <= 0) return;
  const updatedArray = [];

  for (let i = 0; i < arr.length; i++) {
    const obj = { ...arr[i] }; // Create a shallow copy of the object

    for (let j = 0; j < propertiesToRemove.length; j++) {
      const property = propertiesToRemove[j];
      if (obj.hasOwnProperty(property)) {
        delete obj[property];
      }
    }

    updatedArray.push(obj);
  }

  return updatedArray;
}

export const getStringListFromObjectList = (array) => {
  if (array.length < 0) {
    return [""];
  }
  var strlist = [];

  array.map((element) => {
    strlist.push(element.value);
  });

  return strlist;
};

export default class Notification {
  static warningNotification = (text) => {
    toast.warning(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  static errorNotification = (text) => {
    toast.error(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  static successNotification = (text) => {
    toast.success(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
}

export function findEmptyFields(obj) {
  const emptyFields = [];

  for (let key in obj) {
    if (
      key !== "businessAddress" &&
      key !== "businessSocial" &&
      key !== "businessWebsite" &&
      key !== "phoneNumber" &&
      key !== "additionalComment" &&
      key !== "visaPermitStatus" &&
      key !== "englishLanguageTest" &&
      key !== "languageTestScore" &&
      key !== "secondCitizenship" &&
      obj.hasOwnProperty(key) &&
      (obj[key] === "" || obj[key] === null || obj[key] === undefined)
    ) {
      emptyFields.push(key);
    }
  }

  return emptyFields;
}

export function removeEmptyStrings(arr) {
  return arr.filter((item) => item !== "");
}

export const handleApplicationFilter = (applicationdata, category) => {
  if (category === "all" || category === undefined) {
    return applicationdata;
  } else {
    return applicationdata.filter(
      (item) => item.applicationFormStatus === category
    );
  }
};

export function differentiateApplicationsByStatus(arr) {
  const open = [];
  const closed = [];
  const completed = [];

  arr.forEach((obj) => {
    const status = obj.applicationFormStatus;

    if (status === "open") {
      open.push(obj);
    } else if (status === "closed") {
      closed.push(obj);
    } else if (status === "completed") {
      completed.push(obj);
    }
  });

  return {
    totalApplicationCount: arr.length,
    openApplicationCount: open.length,
    closedApplicationCount: closed.length,
    completedApplicationCount: completed.length,
  };
}

// function to return  the keys which have values
const getKeys = (searchObj) => {
  const keys = Object.keys(searchObj).filter((key) => {
    return (
      searchObj[key] !== "" &&
      searchObj[key] !== undefined &&
      key !== "startDate" &&
      key !== "endDate"
    );
  });
  return keys;
};

// export const searchData = (searchObj, allData) => {
//   const keysToSearch = getKeys(searchObj);
//   if (keysToSearch <= 0) return allData;
//   const filtered = [];
//   for (let i = 0; i < allData.length; i += 1) {
//     let check = false;
//     const record = allData[i];
//     for (let j = 0; j < keysToSearch.length; j += 1) {
//       const key = keysToSearch[j];
//       if (
//         record[key]
//           ?.toString()
//           ?.toLowerCase()
//           ?.includes(searchObj[key].toString().toLowerCase())
//       ) {
//         check = true;
//       } else {
//         check = false;
//         break;
//       }
//     }
//     if (check) {
//       filtered.push(record);
//     }
//   }

//   if (filtered?.length) {
//     return filtered;
//   }
//   return [];
// };

export function searchData(searchObj, allData) {
  const keysToSearch = getKeys(searchObj);
  const startDate = searchObj.startDate;
  const endDate = searchObj.endDate;

  if (keysToSearch <= 0 && startDate === "" && endDate === "") return allData;

  const filtered = [];
  for (let i = 0; i < allData.length; i += 1) {
    let check = true;
    const record = allData[i];

    for (let j = 0; j < keysToSearch.length; j += 1) {
      const key = keysToSearch[j];

      if (
        !record[key]
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchObj[key].toString().toLowerCase())
      ) {
        check = false;
        break;
      }
    }

    if (check && filterByDateRange([record], startDate, endDate).length > 0) {
      filtered.push(record);
    }
  }

  if (filtered?.length) {
    return filtered;
  }
  return [];
}

// Filter the applications in date ranges
export function filterByDateRange(array, startDate, endDate) {
  if (startDate === "" || endDate === "") {
    return array;
  }

  return array.filter((item) => {
    const applicationDate = new Date(item.createdAt).getTime();
    return (
      applicationDate >= new Date(startDate).getTime() &&
      applicationDate <= new Date(endDate).getTime()
    );
  });
}

// Donload custom data from link with dynamic file name
export const downloadContent = (url) => {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = () => {
    if (xhr.status === 200) {
      const blob = new Blob([xhr.response]);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      const filename = url.substring(url.lastIndexOf("/") + 1);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  xhr.send();
};

export const afterLoginRoute = (userRole) => {
  switch (userRole) {
    case "SuperAdmin":
      return "/superAdmin/dashboard";

    case "SubSuperAdmin":
      return "/superAdmin/dashboard";

    case "SubAdmin":
      return "/superAdmin/dashboard";

    case "AgencySuperAdmin":
      return "/agency/dashboard";

    case "AgencySubSuperAdmin":
      return "/agency/dashboard";

    case "AgencySubAdmin":
      return "/agency/dashboard";

    case "UniversitySuperAdmin":
      return "/university/dashboard";

    case "UniversitySubSuperAdmin":
      return "/university/dashboard";

    case "UniversitySubAdmin":
      return "/university/dashboard";

    case "Student":
      return "/student/profile";

    default:
      return "/";
  }
};

export const manageUniversityAndCourseSelection = (
  universityId,
  courseId,
  allUniversities,
  allcourses
) => {
  if (
    (universityId === "" || universityId === "all") &&
    (courseId === "" || courseId === "all")
  ) {
    return {
      change: false,
      universities: allUniversities,
      courses: allcourses,
    };
  } else if (universityId !== "") {
    const courseArr = [];
    allcourses.map((course) => {
      if (course?.courseUniversityId._id === universityId) {
        courseArr.push(course);
      }
    });
    return {
      change: "course",
      universities: allUniversities,
      courses: courseArr,
    };
  } else if (courseId !== "" && universityId === "") {
    const universityArr = [];
    allUniversities.map((university) => {
      university.courseList.forEach((course) => {
        if (course._id === courseId) {
          universityArr.push(university);
        }
      });
    });

    return {
      change: "university",
      universities: universityArr,
      courses: allcourses,
    };
  }
};

// function convertToCamelCase(sentence) {
//   var words = sentence.toLowerCase().split(" ");

//   for (var i = 1; i < words.length; i++) {
//     words[i] = words[i][0].toUpperCase() + words[i].slice(1);
//   }

//   return words.join("");
// }

export const handleUserAddedDoumentRequirements = (documents) => {
  const organizedDocs = [];
  if (documents === undefined || Object.keys(documents).length < 0) {
    return [];
  } else {
    documents.map((doc) => {
      if (
        (doc.required === "true" || doc.required === true) &&
        doc.type === "userAdded"
      ) {
        const newDoc = {
          ...doc,
          id: "other",
        };
        organizedDocs.push(newDoc);
      } else if (
        (doc.required === "true" || doc.required === true) &&
        doc.type === "basic"
      ) {
        organizedDocs.push(doc);
      }
    });
  }
  return organizedDocs;
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function replaceLocalhostUrl(inputString) {
  if (inputString) {
    const uploadsIndex = inputString.indexOf("/uploads");
    const liveBackendUrl = process.env.REACT_APP_BACKEND_URL;
    if (uploadsIndex !== -1) {
      const filename = inputString.substring(uploadsIndex);

      return `${liveBackendUrl}${filename}`;
    }
    // const regex = /^http:\/\/localhost:\d+/;
    // const replacedString = inputString.replace(regex, liveBackendUrl);
  } else {
    return "";
  }
}

export const getIdListFormArrayOfObject = (data) =>
  data.map((item) => item._id);

export const removeSubstring = (originalString, substringToRemove) => {
  var regex = new RegExp(substringToRemove, "g");
  return originalString.replace(regex, "");
};

export const formatString = (str, length) => {
  if (str.length <= length) {
    return str; // Return the original string if it's already equal to or shorter than the desired length
  } else {
    return str.substr(0, length - 3) + "..."; // Extract the first (length - 3) characters and append three dots
  }
};

// export const searchData = (searchObj, allData) => {
//   const keysToSearch = getKeys(searchObj);
//   if (keysToSearch <= 0) return allData;
//   const filtered = [];
//   for (let i = 0; i < allData.length; i += 1) {
//     let check = false;
//     const record = allData[i];
//     for (let j = 0; j < keysToSearch.length; j += 1) {
//       const key = keysToSearch[j];
//       if (
//         record[key]
//           ?.toString()
//           ?.toLowerCase()
//           ?.includes(searchObj[key].toString().toLowerCase())
//       ) {
//         check = true;
//       } else {
//         check = false;
//         break;
//       }
//     }
//     if (check) {
//       filtered.push(record);
//     }
//   }

//   if (filtered?.length) {
//     return filtered;
//   }
//   return [];
// };

export const courseFilter = (searchObj, data) => {
  const filtered = [];
  if (data === undefined || data.length <= 0) return data;
  const keysToSearch = getKeys(searchObj);
  for (let i = 0; i < data.length; i += 1) {
    let check = false;
    const record = data[i];
    for (let j = 0; j < keysToSearch.length; j += 1) {
      const key = keysToSearch[j];
      if (
        key === "universityName"
          ? record["courseUniversityId"]["universityName"]
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchObj[key].toString().toLowerCase())
          : key === "courseDuration"
          ? parseFloat(record[key]) <= parseFloat(searchObj[key])
          : record[key]
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchObj[key].toString().toLowerCase())
      ) {
        check = true;
      } else {
        check = false;
        break;
      }
    }
    if (check) {
      filtered.push(record);
    }
  }
  if (filtered?.length) {
    return filtered;
  }

  return [];
};

export const sortApplicationByLastUpdate = (data) => {
  const pendingReview = [];
  const other = [];

  data.map((application) => {
    try {
      if (
        new Date(application?.updatedAt).getTime() >
        new Date(application?.superAdminLastView).getTime()
      ) {
        pendingReview.push({ ...application, showGreenDot: true });
      } else {
        other.push({ ...application, showGreenDot: false });
      }
    } catch (err) {
      other.push({ ...application, showGreenDot: false });
    }
  });

  return [...pendingReview, ...other];
};

function convertArray(array) {
  return array.map((item) => {
    const { englishTest, requiredScore } = item;
    return { [englishTest]: requiredScore };
  });
}

function calculatePercentageOfOne(array) {
  const count = array.filter((num) => num === 1).length;
  const percentage = (count / array.length) * 100;
  return percentage;
}

export const calculateTheMatchPersentage = async (
  studentData,
  courseData,
  suggest = false
) => {
  try {
    const metrixArr = [];
    const suggestions = {};

    const stduentNewData = {
      // langauge: studentData?.firstlanguage,
      decipline: studentData?.desiredCourseDiscipline,
      courseLevel: studentData?.desiredlevelOfStudy,
      educationalRequirement: {
        highestEducation: studentData?.highestEducation,
        averageGrade: studentData?.averageGrade,
      },
      courseCountry: studentData?.desiredCountry?.map((item) => {
        return item.value;
      }),
      studentCountry: studentData?.countryOfBirth,
      englishTestRequirement: studentData?.englishTestRequirement,
    };
    const courseNewData = {
      // langauge: courseData?.courseLanguage,
      decipline: courseData?.courseSubjectType,
      courseLevel: courseData?.courseLevel,
      educationalRequirement: {
        highestEducation: courseData?.educationalRequirement?.educationIQFLevel,
        averageGrade: courseData?.educationalRequirement?.educationScore,
      },
      courseCountry: [courseData?.courseCountry],
      englishTestRequirement: courseData?.englishTestRequirement,
      // englishtest: convertArray(courseData?.englishTestRequirement),
    };

    Object.keys(courseNewData)?.map(async (key) => {
      // if (key === "englishtest") {
      //   let check = false;
      //   courseNewData["englishtest"].map((test) => {
      //     Object.keys(test).map((testName) => {
      //       if (
      //         stduentNewData["englishtest"][testName] &&
      //         testName === Object.keys(stduentNewData["englishtest"])[0] &&
      //         test[testName] <= stduentNewData["englishtest"][testName]
      //       ) {
      //         check = true;
      //       }
      //     });
      //   });
      //   if (check) metrixArr.push(1);
      //   else metrixArr.push(0);
      // } else
      if (key === "educationalRequirement") {
        if (
          Number(stduentNewData[key]?.highestEducation) >=
          Number(courseNewData[key]?.highestEducation)
        ) {
          metrixArr.push(1);
          if (
            Number(stduentNewData[key]?.averageGrade) >=
            Number(courseNewData[key]?.averageGrade)
          ) {
            metrixArr.push(1);
          } else {
            metrixArr.push(0);
            suggestions.highestEducation = courseNewData[key]?.highestEducation;
            suggestions.averageGrade = courseNewData[key]?.averageGrade;
          }
        } else {
          metrixArr.push(0);
          suggestions.highestEducation = courseNewData[key]?.highestEducation;
          suggestions.averageGrade = courseNewData[key]?.averageGrade;
        }
      } else if (key === "courseCountry") {
        if (stduentNewData[key]?.includes(courseNewData[key][0])) {
          metrixArr.push(1);
          if (courseNewData[key][0] === stduentNewData?.studentCountry) {
            metrixArr.push(1);
          } else {
            {
              metrixArr.push(0);
              suggestions.courseCountry = courseNewData[key][0];
            }
          }
        } else {
          metrixArr.push(0);
          suggestions.courseCountry = courseNewData[key][0];
        }
      } else if (key == "englishTestRequirement") {
        let EnglishTestEligible = false;

        stduentNewData?.englishTestRequirement?.map(async (studentTest) => {
          courseNewData?.englishTestRequirement?.map((courseTest) => {
            if (
              studentTest.englishTest === courseTest.englishTest &&
              studentTest.total >= courseTest.total
            ) {
              EnglishTestEligible = true;
            }
          });
        });
        if (EnglishTestEligible) metrixArr.push(1);
        else {
          metrixArr.push(0);
          suggestions.englishTestRequirement =
            courseNewData.englishTestRequirement;
        }
      } else {
        if (courseNewData[key] === stduentNewData[key]) metrixArr.push(1);
        else {
          metrixArr.push(0);
          suggestions[key] = courseNewData[key];
        }
      }
    });

    return {
      suggestions,
      matchPersentage: calculatePercentageOfOne(metrixArr),
    };
  } catch (err) {
    return 0;
  }
};

export function filterApplicationsByStatus(applications, status) {
  const validStatuses = [
    "acceptedByUniversity",
    "rejectedByUniversity",
    "underReview",
  ];

  if (status === "all") {
    return applications;
  } else if (validStatuses.includes(status)) {
    return applications?.filter(
      (application) =>
        application.superAdminStatus.toLowerCase() === status.toLowerCase()
    );
  } else {
    return applications;
  }
}

export function filterApplicationsByStatusForUniversity(applications, status) {
  const validStatuses = ["open", "accepted", "rejected"];

  if (status === "all") {
    return applications;
  } else if (validStatuses.includes(status)) {
    return applications?.filter(
      (application) =>
        application.applicationFormStatus.toLowerCase() === status.toLowerCase()
    );
  } else {
    return applications;
  }
}

export function filterApplicationByStatusForAgencyAndStudents(
  applications,
  status
) {
  const validStatuses = [
    "acceptedByUniversity",
    "rejectedByUniversity",
    "underReview",
  ];

  const pendingStatus = [
    "underReview",
    "applicationPaymentConformation",
    "documentRequirement",
    "submittedToUniveristy",
    "awaitingProofOfPayment",
  ];

  if (status === "all") {
    return applications;
  } else if (status === "underReview") {
    return applications?.filter((application) =>
      pendingStatus.includes(application.superAdminStatus)
    );
  } else if (validStatuses.includes(status)) {
    return applications?.filter(
      (application) =>
        application.superAdminStatus.toLowerCase() === status.toLowerCase()
    );
  } else {
    return applications;
  }
}

export const formatDateString = (inputDate) => {
  const date = new Date(inputDate);

  const day = date.getUTCDate().toString().padStart(2, "0");
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${day} ${month} ${year}`;
};

export const exportToExcel = async (data) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Subscribers");

  worksheet.columns = [
    { header: "Subscriber Email", key: "email", width: 30 },
    { header: "Date On Subscribed", key: "createdAt", width: 30 },
  ];

  data.forEach((subscriber) => {
    worksheet.addRow({
      email: subscriber.email,
      createdAt: formatDateString(subscriber.createdAt),
    });
  });

  const excelBuffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = "subscribers.xlsx";
  a.click();

  URL.revokeObjectURL(url);
};

export const generateEducationRequirementScoreSelect = (maxPercentage) => {
  const scoreSelect = [{ label: "---- Select Score ----", value: "" }];

  for (let i = 1; i <= maxPercentage; i++) {
    scoreSelect.push({ label: `${i}%`, value: `${i}` });
  }

  return scoreSelect;
};

export const getEducationLevelBylevelNumber = (levelNo) => {
  let levelName = "";
  educationalRequirementSelect.map((requirement) => {
    if (requirement.value === levelNo) {
      levelName = requirement.label;
    }
  });

  return levelName;
};
