import React from "react";
import "./AgencyHomePage.css";

import MainNavbar from "../../reusables/Navbar/MainNavbar/MainNavbar";
import WhyJoinUs from "./AgencyHomeElement/WhyJoinUs";
import WhoWeAre from "./AgencyHomeElement/WhoWeAre";
import HowDoesItWork from "./AgencyHomeElement/HowDoesItWork";

import {
  agencyWhyJoinUs,
  howDoesItWorkAgency,
  whoWeAreAgency,
} from "../../../utils/constant";

const AgencyHomePage = () => {
  return (
    <div>
      <MainNavbar>
        <div className="agency_home_hape_content_holder">
          <WhyJoinUs whyJoinUs={agencyWhyJoinUs} />
          <WhoWeAre whoWeAre={whoWeAreAgency} />
          <HowDoesItWork
            howDoesItWork={howDoesItWorkAgency}
            userType={"recruiter"}
          />
        </div>
      </MainNavbar>
    </div>
  );
};

export default AgencyHomePage;
