import React, { useContext } from "react";

import { useGetCourseForUniverciryById } from "../../../../../hooks/useGetCourseForUniverciryById";

import { AuthContext } from "../../../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../../reusables/PageTitle/PageTitle";
import TableComponent from "../../../../reusables/TableComponent/TableComponent";
import Loader from "../../../../reusables/Loader/Loader";
import EmptyComponent from "../../../../reusables/EmptryComponent/EmptyComponent";
import ErrorPage from "../../../../reusables/ErrorPage/ErrorPage";

import {
  universityCourseListTableCols,
  universityCourseViewFileds,
} from "../../../../../utils/constant";

const UniversityCourseListView = () => {
  const { user } = useContext(AuthContext);

  const [courses, isLoading, error] = useGetCourseForUniverciryById(
    user?.universityRef ||
      JSON.parse(localStorage.getItem("currentLoginUser"))?.universityRef
  );

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;
  return (
    <div>
      <UserNavbar>
        <PageTitle title={"UNIVERSITY'S COURSES"} />
        <div className="agency_list_container">
          <TableComponent
            data={courses}
            tableColumns={universityCourseListTableCols}
            fieldsToShowList={universityCourseViewFileds}
            navigateTo="/university/course/view"
            emptyFor="universityCourseList"
          />
        </div>
      </UserNavbar>
    </div>
  );
};

export default UniversityCourseListView;
