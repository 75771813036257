import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./AgencyAgentProfileView.css";

import { useGetAdminById } from "../../../../hooks/useGetAdminById";
import { useGetAgencyById } from "../../../../hooks/useGetAgencyById";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import CustomInputField from "../../../reusables/FromComponent/CustomInputField";
import CustomSelectField from "../../../reusables/FromComponent/CustomSelectField";

import Notification, { handleStateChange } from "../../../../utils/helpers";
import {
  API_HEADERS,
  agencyRoleSelectOption,
} from "../../../../utils/constant";

const AgencyAgentProfileView = () => {
  const { user } = useContext(AuthContext);
  const { agentId } = useParams();
  const navigate = useNavigate();

  const [adminData, isLoading, error] = useGetAdminById(agentId);

  const agencyData = useGetAgencyById(
    user?.agencyRef ||
      JSON.parse(localStorage.getItem("currentLoginUser"))?.agencyRef
  );

  const [newAdminData, setNewAdmindata] = useState(null);

  const handleUpdateAdmin = () => {
    if (!agencyData[0].isVerified) {
      Notification.errorNotification("Agency not verified! Access denied.");
    } else {
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/auth/updateAdminById`,
          { ...newAdminData, adminId: agentId },
          API_HEADERS()
        )
        .then(() => {
          Notification.successNotification(
            "Agent Details Updated Successfully"
          );
          navigate("/agency/agentList");
        })
        .catch((err) => {
          Notification.errorNotification(err.response.data.message);
        });
    }
  };

  const handleDeleteAdmin = () => {
    if (!agencyData[0].isVerified) {
      Notification.errorNotification("Agency not verified! Access denied.");
    } else {
      axios
        .delete(
          `${process.env.REACT_APP_BACKEND_URL}/auth/deleteAdminById/${agentId}`,
          API_HEADERS()
        )
        .then(() => {
          Notification.successNotification("Agent Deleted Successfully");
          navigate("/agency/agentList");
        })
        .catch((err) => {
          Notification.errorNotification(err.response.data.message);
        });
    }
  };

  useEffect(() => {
    setNewAdmindata({
      username: adminData?.username || "",
      email: adminData?.email || "",
      firstName: adminData?.firstName || "",
      lastName: adminData?.lastName || "",
      role: adminData?.role || "",
    });
  }, [agentId, adminData]);

  if (isLoading || agencyData[1]) return <Loader />;
  if (error || agencyData[2]) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"View Profile"} />

        <div className="agent_view_form_holder">
          <CustomInputField
            type="text"
            name="username"
            value={newAdminData?.username || ""}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
            label="User Name"
          />

          <CustomInputField
            type="email"
            name="email"
            value={newAdminData?.email || ""}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
            label="Email"
          />

          <CustomInputField
            type="text"
            name="firstName"
            value={newAdminData?.firstName || ""}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
            label="First Name"
          />

          <CustomInputField
            type="text"
            name="lastName"
            value={newAdminData?.lastName || ""}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
            label="Last Name"
          />

          <CustomSelectField
            name="role"
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
            value={newAdminData?.role || ""}
            options={agencyRoleSelectOption}
            label="Role"
          />

          <div className="agent_label_field_holder">
            <label className="agency_agent_label_style"> </label>
            <div className="agency_agent_action_btn_holder">
              <button
                className="agency_agent_btn_style"
                onClick={handleDeleteAdmin}
              >
                Delete
              </button>
              <button
                className="agency_agent_btn_style"
                onClick={handleUpdateAdmin}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default AgencyAgentProfileView;
