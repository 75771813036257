import React, { useContext, useState } from "react";
import axios from "axios";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";

import CustomInputField from "../../../reusables/FromComponent/CustomInputField";

import Notification, { handleStateChange } from "../../../../utils/helpers";
import { API_HEADERS } from "../../../../utils/constant";

const StudentChangePassword = () => {
  const { user } = useContext(AuthContext);

  const [passwords, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChnagePassword = () => {
    if (passwords.newPassword !== passwords.confirmPassword) {
      Notification.errorNotification("re-Confirmed Password incorrect");
    } else {
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/auth/changeAdminPassword`,
          {
            ...passwords,
            userId: user?._id,
          },
          API_HEADERS()
        )
        .then(() => {
          Notification.successNotification(
            "Admin password changed Successfully"
          );
        })
        .catch((err) => {
          Notification.errorNotification(err.response.data.message);
        });
    }
  };
  return (
    <div>
      <UserNavbar>
        <PageTitle title={"CHANGE PROFILE PASSWORD"} />
        <div className="change_passwrod_form_holder">
          <CustomInputField
            name="oldPassword"
            label="Old Password"
            type="password"
            value={passwords?.oldPassword}
            onChange={(e) => handleStateChange(e, setPassword)}
          />

          <CustomInputField
            name="newPassword"
            label="New Password"
            type="password"
            value={passwords?.newPassword}
            onChange={(e) => handleStateChange(e, setPassword)}
          />

          <CustomInputField
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            value={passwords?.confirmPassword}
            onChange={(e) => handleStateChange(e, setPassword)}
          />

          <div className="password_label_field_holder">
            <label className="password_label_style"> </label>
            <div className="password_save_btn_holder">
              <button
                className="save_password_btn_style"
                onClick={handleChnagePassword}
              >
                Save New password
              </button>
            </div>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default StudentChangePassword;
