import React, { useContext, useState } from "react";
import "./AddSubSuperAdmin.css";
import axios from "axios";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";

import CustomInputField from "../../../reusables/FromComponent/CustomInputField";
import CustomSelectField from "../../../reusables/FromComponent/CustomSelectField";
import CustomButton from "../../../reusables/FromComponent/CustomButton";

import Notification, { handleStateChange } from "../../../../utils/helpers";
import { API_HEADERS, superAdminSelect } from "../../../../utils/constant";
import { useNavigate } from "react-router-dom";

const AddSubSuperAdmin = () => {
  const { user } = useContext(AuthContext);
  const [newAdminData, setNewAdmindata] = useState({
    addedBy:
      user?._id || JSON.parse(localStorage.getItem("currentLoginUser"))?._id,
  });

  const navigate = useNavigate();

  const handleAddNewAdmin = () => {
    if (newAdminData.password !== newAdminData.confirmPassword) {
      Notification.errorNotification(
        "Password not match. Please confirm correctly "
      );
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/signup`,
        newAdminData,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("New admin added Successfully");
        navigate("/superAdmin/profile");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"ADD NEW SUB-ADMIN"} />
        <div className="add_user_form_holder">
          <CustomInputField
            name="username"
            label="User Name"
            value={newAdminData?.username}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />

          <CustomInputField
            name="email"
            label="Email"
            type="email"
            value={newAdminData?.email}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />

          <CustomInputField
            name="firstName"
            label="First Name"
            value={newAdminData?.firstName}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />

          <CustomInputField
            name="lastName"
            label="Last Name"
            value={newAdminData?.lastName}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />

          <CustomSelectField
            name="role"
            label="Role"
            value={newAdminData?.role}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
            options={superAdminSelect}
          />

          <CustomInputField
            name="password"
            label="Password"
            type="password"
            value={newAdminData?.password}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />

          <CustomInputField
            name="confirmPassword"
            label="Confirm password"
            type="password"
            value={newAdminData?.confirmPassword}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />

          <div className="label_field_holder">
            <label className="new_admin_label_style"> </label>
            <div className="admin_save_btn_holder">
              <CustomButton
                buttonLabel="Save"
                onClick={handleAddNewAdmin}
                fullwidth
              />
            </div>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default AddSubSuperAdmin;
