import React from "react";
import { useGetAboutUsStatistics } from "../../../../hooks/useGetAboutUsStatistics";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";
import { aboutUsStatistics } from "../../../../utils/constant";

const Statistics = () => {
  const [statistics, isLoading, error] = useGetAboutUsStatistics();

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;

  return (
    <div className="row statistics_holder">
      <p className="curious_heading">OUR COMMUNITY</p>
      <br />
      <br />
      <br />
      {Object.keys(statistics)?.map((stat) => (
        <div className="col-12 col-lg-3 col-md-4 col-sm-6 col-xs-6">
          <div className="stat_container">
            {aboutUsStatistics[stat].icon}
            <p className="stat_number_style">{statistics[stat]}+</p>
            <p className="stat_label_style">{aboutUsStatistics[stat].label}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Statistics;
