import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateRange } from "react-date-range";
import { TfiBrushAlt } from "react-icons/tfi";
import "./SuperAdminAgencyList.css";

import { useGetAllAgency } from "../../../../hooks/useGetAllAgency";

import PageTitle from "../../../reusables/PageTitle/PageTitle";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import SuperAdminUnverifiedAgencyList from "./SuperAdminUnverifiedAgencyList";
import TableComponent from "../../../reusables/TableComponent/TableComponent";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import { handleStateChange, searchData } from "../../../../utils/helpers";
import {
  agencyFieldstoShow,
  agencyListTableCols,
  countries,
} from "../../../../utils/constant";

const SuperAdminAgencyList = () => {
  const [agencies, isLoading, error] = useGetAllAgency();

  const [filterData, setFilterData] = useState(agencies);

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [filterContent, setFilterContent] = useState({
    startDate: "",
    endDate: "",
    agencyName: "",
    agencyCountry: "",
  });

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const clearSearch = () => {
    setFilterContent({
      startDate: "",
      endDate: "",
      agencyName: "",
      agencyCountry: "",
    });
    setFilterData(agencies);
  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (ranges) => {
    setFilterContent((prev) => ({
      ...prev,
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    }));
    onToggle();
  };

  useEffect(() => {
    setFilterData(searchData(filterContent, agencies));
  }, [filterContent]);

  useEffect(() => {
    setFilterData(agencies);
  }, [agencies]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;
  return (
    <div>
      <UserNavbar>
        <PageTitle title={"VERIFIED AGENCIES"} />
        <div className="agency_list_container">
          <div className="action_button_holder">
            <div className="date_range_keeper">
              <input
                className="applciation_input_field_style date_show_input"
                value={
                  filterContent.endDate &&
                  filterContent.startDate &&
                  `${new Date(
                    filterContent.startDate
                  ).toLocaleDateString()} - ${new Date(
                    filterContent.endDate
                  ).toLocaleDateString()}`
                }
                placeholder="Select dates"
                onClick={onToggle}
              />
              {isOpen && (
                <div className="date_picker_holder">
                  <DateRange
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    showSelectionPreview={true}
                  />
                </div>
              )}
            </div>
            <select
              className="applciation_input_field_style"
              name="agencyCountry"
              onChange={(e) => handleStateChange(e, setFilterContent)}
            >
              {countries.map((country) => (
                <option>{country.name}</option>
              ))}
            </select>
            <input
              type="text"
              className="applciation_input_field_style"
              placeholder="Search Agencies"
              name="agencyName"
              onChange={(e) => handleStateChange(e, setFilterContent)}
              value={filterContent.agencyName}
            />

            <button
              className="add_university_navigation_btn"
              onClick={clearSearch}
            >
              <TfiBrushAlt className="clear_icon_style" />
            </button>
            <button
              className="add_university_navigation_btn"
              onClick={() => navigate("/superAdmin/agent/addAgent")}
            >
              Add New Agency
            </button>
          </div>

          <TableComponent
            data={filterData}
            tableColumns={agencyListTableCols}
            fieldsToShowList={agencyFieldstoShow}
            navigateTo="/superAdmin/agent/view"
            emptyFor="superAdminVerifiedAgencyList"
          />
        </div>

        <div className="unverified_agencies_holder">
          <SuperAdminUnverifiedAgencyList />
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminAgencyList;
