import React, { useEffect, useState } from "react";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import { useGetQualificationById } from "../../../../hooks/useGetQualificationById";

import PageTitle from "../../../reusables/PageTitle/PageTitle";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import CustomSelectField from "../../../reusables/FromComponent/CustomSelectField";
import CustomInputField from "../../../reusables/FromComponent/CustomInputField";

import Notification, {
  handleStateChange,
  removeEmptyProperties,
} from "../../../../utils/helpers";

import {
  API_HEADERS,
  newCountryList,
  newhighestEducationList,
} from "../../../../utils/constant";

const SuperAdminViewQualification = () => {
  const { qualificationId } = useParams();

  const navigate = useNavigate();

  const [qualificationData, isLoading, error] =
    useGetQualificationById(qualificationId);

  const [newQualification, setNewQualification] = useState({});

  const handleUpdateQualification = () => {
    const postingQualificationData = removeEmptyProperties({
      ...newQualification,
      qualificationId,
    });
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/suggestion/updateSuggestionById`,
        postingQualificationData,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Qualification Updated Successfully");
        navigate("/superAdmin/qualification");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  const handleDeleteQualification = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/suggestion/deleteSuggestionById/${qualificationId}`,
        API_HEADERS()
      )
      .then(() => {
        Notification.warningNotification("Qualification deleted Successfully");
        navigate("/superAdmin/qualification");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  useEffect(() => {
    setNewQualification((prev) => ({
      ...prev,
      studentCountry: qualificationData?.studentCountry || "",
      courseCountry: qualificationData?.courseCountry || "",
      educationLevel: qualificationData?.educationLevel || "",
      examination: qualificationData?.examination || "",
      gradingSystem: qualificationData?.gradingSystem || "",
      passingGrade: qualificationData?.passingGrade || "",
    }));
  }, [qualificationData]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;
  return (
    <div>
      <UserNavbar>
        <PageTitle title={"VIEW QUALIFICATION"} />
        <div className="add_user_form_holder">
          <CustomSelectField
            name="studentCountry"
            label="Student Country"
            value={newQualification?.studentCountry}
            onChange={(e) => handleStateChange(e, setNewQualification)}
            options={newCountryList}
          />

          <CustomSelectField
            name="courseCountry"
            label="Course Country"
            value={newQualification?.courseCountry}
            onChange={(e) => handleStateChange(e, setNewQualification)}
            options={newCountryList}
          />

          <CustomSelectField
            name="educationLevel"
            label="Education Level"
            value={newQualification?.educationLevel}
            onChange={(e) => handleStateChange(e, setNewQualification)}
            options={newhighestEducationList}
          />

          <CustomInputField
            name="examination"
            label="Examination"
            value={newQualification?.examination}
            onChange={(e) => handleStateChange(e, setNewQualification)}
          />

          <CustomInputField
            name="gradingSystem"
            label="Grading System"
            value={newQualification?.gradingSystem}
            onChange={(e) => handleStateChange(e, setNewQualification)}
          />

          <CustomInputField
            name="passingGrade"
            label="Passing Grade"
            value={newQualification?.passingGrade}
            onChange={(e) => handleStateChange(e, setNewQualification)}
          />

          <div className="admin_action_btn_container">
            <div className="admin_action_btn_holder">
              <button
                className="action_admin_btn_style"
                onClick={handleUpdateQualification}
              >
                Update
              </button>
              <button
                className="action_admin_btn_style"
                onClick={handleDeleteQualification}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminViewQualification;
