import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoKeySharp } from "react-icons/io5";

import { useGetUniversityById } from "../../../hooks/useGetUniversityById";

import { AuthContext } from "../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../reusables/PageTitle/PageTitle";
import Loader from "../../reusables/Loader/Loader";
import ErrorPage from "../../reusables/ErrorPage/ErrorPage";

import CustomVericalInputField from "../../reusables/FromComponent/CustomVericalInputField";
import CustomVerticalSelectField from "../../reusables/FromComponent/CustomVerticalSelectField";

import Notification, {
  handleFileInputInState,
  handleStateChange,
  removeEmptyProperties,
  replaceLocalhostUrl,
} from "../../../utils/helpers";
import {
  API_HEADERS,
  newCountryList,
  universityAddDefaultStateObj,
} from "../../../utils/constant";

const UniversityProfileView = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const universityId =
    user?.universityRef ||
    JSON.parse(localStorage.getItem("currentLoginUser")).universityRef;

  const [universityData, isLoading, error] = useGetUniversityById(universityId);

  const [newUniversityData, setNewuniversityData] = useState({
    ...universityAddDefaultStateObj,
  });

  useEffect(() => {
    setNewuniversityData({
      updatedBy:
        user?._id || JSON.parse(localStorage.getItem("currentLoginUser"))._id,
      universityName: universityData?.universityName || "",
      universityRegistrationNumber:
        universityData?.universityRegistrationNumber || "",
      universityTaxNumber: universityData?.universityTaxNumber || "",
      typeOfInstitution: universityData?.typeOfInstitution || "",
      universityCoverImage: "",
      subImage1: "",
      subImage2: "",
      subImage3: "",
      universityCountry: universityData?.universityCountry || "",
      universityCity: universityData?.universityCity || "",
      universityAddress: universityData?.universityAddress || "",
      universityPostalcode: universityData?.universityPostalcode || "",
      universityDescription: universityData?.universityDescription || "",
    });
  }, [universityId, universityData, user?._id]);

  const handleUpdateUniversity = () => {
    const dataToUpdate = removeEmptyProperties({
      ...newUniversityData,
      universityId,
      updatedBy:
        user?._id || JSON.parse(localStorage.getItem("currentLoginUser"))?._id,
    });
    const universityData = new FormData();

    Object.keys(dataToUpdate).forEach((key) => {
      if (["subImage1", "subImage2", "subImage3"].includes(key)) {
        universityData.append("subImages", dataToUpdate[key]);
      } else {
        universityData.append(key, dataToUpdate[key]);
      }
    });

    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/university/updateUniversityById`,
        universityData,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("University Updated Successfully");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;
  return (
    <div>
      <UserNavbar>
        <PageTitle title={"PROFILE"} />
        <div className="add_university_holder">
          <div className="add_agent_btn_holder">
            <button
              className="change_password_btn_style"
              onClick={() => navigate(`/university/user/changePassword`)}
            >
              <IoKeySharp className="add_user_icon_style" />
              Change Password
            </button>
          </div>
          <div className="input_container">
            <div className="bacsic_detail_holder">
              <div className="basic_university_detail_holder">
                <div className="university_label_field_holder">
                  <CustomVericalInputField
                    name="universityName"
                    label={"University Name"}
                    value={newUniversityData?.universityName}
                    onChange={(e) => handleStateChange(e, setNewuniversityData)}
                    placeholder={"University Name"}
                  />
                </div>

                <div className="university_label_field_holder">
                  <CustomVericalInputField
                    name="universityRegistrationNumber"
                    value={newUniversityData?.universityRegistrationNumber}
                    label={"Registration Number"}
                    onChange={(e) => handleStateChange(e, setNewuniversityData)}
                  />
                </div>
                <div className="university_label_field_holder">
                  <CustomVericalInputField
                    name={"universityTaxNumber"}
                    value={newUniversityData?.universityTaxNumber}
                    onChange={(e) => handleStateChange(e, setNewuniversityData)}
                    label={"Tax Number"}
                  />
                </div>
                <div className="university_label_field_holder">
                  <CustomVericalInputField
                    name="typeOfInstitution"
                    value={newUniversityData?.typeOfInstitution}
                    onChange={(e) => handleStateChange(e, setNewuniversityData)}
                    label={"Type of Institution"}
                  />
                </div>
              </div>
              <div className="university_image_container">
                <label
                  className="university_cover_image"
                  htmlFor="universityCoverImage"
                >
                  {newUniversityData?.universityCoverImage !== "" ? (
                    <img
                      src={URL.createObjectURL(
                        newUniversityData?.universityCoverImage
                      )}
                      className="cover_img_style"
                      alt="university_cover_image"
                    />
                  ) : universityData?.universityCoverImage ? (
                    <img
                      src={replaceLocalhostUrl(
                        universityData?.universityCoverImage
                      )}
                      className="cover_img_style"
                      alt="university_cover_image"
                    />
                  ) : (
                    <img
                      src="/images/superAdmin/upload_placeholder.png"
                      className="upload_placeholder_img_style"
                      alt="university_cover_image"
                    />
                  )}
                </label>
                <input
                  type="file"
                  className="actual_documetn_upload_input_field"
                  id="universityCoverImage"
                  name="universityCoverImage"
                  onChange={(e) =>
                    handleFileInputInState(e, setNewuniversityData)
                  }
                />

                <div className="sub_images_container">
                  <label className="sub_images_class" htmlFor="subImage1">
                    {newUniversityData?.subImage1 !== "" ? (
                      <img
                        src={URL.createObjectURL(newUniversityData?.subImage1)}
                        className="side_image_style"
                        alt="side_image"
                      />
                    ) : universityData?.subImages[0] ? (
                      <img
                        src={replaceLocalhostUrl(universityData?.subImages[0])}
                        className="side_image_style"
                        alt="side_image"
                      />
                    ) : (
                      <img
                        src="/images/superAdmin/upload_placeholder.png"
                        className="subimage_upload_placeholder_img_style"
                        alt="side_image"
                      />
                    )}
                  </label>
                  <input
                    type="file"
                    className="actual_documetn_upload_input_field"
                    id="subImage1"
                    name="subImage1"
                    onChange={(e) =>
                      handleFileInputInState(e, setNewuniversityData)
                    }
                  />
                  <label className="sub_images_class" htmlFor="subImage2">
                    {newUniversityData?.subImage2 !== "" ? (
                      <img
                        src={URL.createObjectURL(newUniversityData?.subImage2)}
                        className="side_image_style"
                        alt="side_image"
                      />
                    ) : universityData?.subImages[1] ? (
                      <img
                        src={replaceLocalhostUrl(universityData?.subImages[1])}
                        className="side_image_style"
                        alt="side_image"
                      />
                    ) : (
                      <img
                        src="/images/superAdmin/upload_placeholder.png"
                        className="subimage_upload_placeholder_img_style"
                        alt="side_image"
                      />
                    )}
                  </label>
                  <input
                    type="file"
                    className="actual_documetn_upload_input_field"
                    id="subImage2"
                    name="subImage2"
                    onChange={(e) =>
                      handleFileInputInState(e, setNewuniversityData)
                    }
                  />
                  <label className="sub_images_class" htmlFor="subImage3">
                    {newUniversityData?.subImage3 ? (
                      <img
                        src={URL.createObjectURL(newUniversityData?.subImage3)}
                        className="side_image_style"
                        alt="side_image"
                      />
                    ) : universityData?.subImages[2] ? (
                      <img
                        src={replaceLocalhostUrl(universityData?.subImages[2])}
                        className="side_image_style"
                        alt="side_image"
                      />
                    ) : (
                      <img
                        src="/images/superAdmin/upload_placeholder.png"
                        className="subimage_upload_placeholder_img_style"
                        alt="side_image"
                      />
                    )}
                  </label>
                  <input
                    type="file"
                    className="actual_documetn_upload_input_field"
                    id="subImage3"
                    name="subImage3"
                    onChange={(e) =>
                      handleFileInputInState(e, setNewuniversityData)
                    }
                  />
                </div>
                <p>Change All three images together</p>
              </div>
            </div>
            <div className="university_label_field_holder">
              <label className="university_label_style">About University</label>
              <textarea
                type="text"
                value={newUniversityData?.universityDescription}
                className="full_university_input_field_style"
                name="universityDescription"
                onChange={(e) => handleStateChange(e, setNewuniversityData)}
              />
            </div>
          </div>
          <div className="input_container">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="university_label_field_holder">
                    <CustomVerticalSelectField
                      label={"University Country"}
                      value={newUniversityData?.universityCountry}
                      onChange={(e) =>
                        handleStateChange(e, setNewuniversityData)
                      }
                      options={newCountryList}
                      name="universityCountry"
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="university_label_field_holder">
                    <CustomVericalInputField
                      name="universityCity"
                      label={"University City"}
                      value={newUniversityData?.universityCity}
                      onChange={(e) =>
                        handleStateChange(e, setNewuniversityData)
                      }
                      placeholder={"University City"}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="university_label_field_holder">
                    <CustomVericalInputField
                      name="universityAddress"
                      label={"University Address"}
                      value={newUniversityData?.universityAddress}
                      onChange={(e) =>
                        handleStateChange(e, setNewuniversityData)
                      }
                      placeholder={"University Address"}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="university_label_field_holder">
                    <CustomVericalInputField
                      name="universityPostalcode"
                      label={"Postal code"}
                      value={newUniversityData?.universityPostalcode}
                      onChange={(e) =>
                        handleStateChange(e, setNewuniversityData)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="submit_button_holder">
            <button
              className="add_new_univercity_btn"
              onClick={handleUpdateUniversity}
            >
              Update University
            </button>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default UniversityProfileView;
