import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import UserNavbar from "../../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../../reusables/PageTitle/PageTitle";

import CustomVerticalSelectField from "../../../../reusables/FromComponent/CustomVerticalSelectField";
import CustomVericalInputField from "../../../../reusables/FromComponent/CustomVericalInputField";
import CustomButton from "../../../../reusables/FromComponent/CustomButton";

import {
  API_HEADERS,
  countryCurrency,
  languages,
  newCountryList,
  postGraduationWorkPermit,
  workDuringStydentSelect,
} from "../../../../../utils/constant";
import Notification, {
  handleFileInputInState,
  handleStateChange,
} from "../../../../../utils/helpers";

const AddCountryForm = () => {
  const [countryData, setCountryData] = useState({});
  const navigate = useNavigate();

  const handleSaveCountry = () => {
    const countryDataFormData = new FormData();

    Object.keys(countryData).forEach((key) => {
      countryDataFormData.append(key, countryData[key]);
    });
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/homeInfo/addCountry`,
        countryDataFormData,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Country added successfully");
        navigate(`/superAdmin/homePageInfoForm`);
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };
  return (
    <div>
      <UserNavbar>
        <PageTitle title={"ADD COUNTRY"} />
        <div className="add_university_holder">
          <div className="input_container">
            <div className="bacsic_detail_holder">
              <div className="basic_university_detail_holder">
                <CustomVerticalSelectField
                  name="countryName"
                  label="Country Name"
                  value={countryData?.countryName}
                  onChange={(e) => handleStateChange(e, setCountryData)}
                  options={newCountryList}
                />

                <CustomVerticalSelectField
                  name="officialCurrency"
                  label="Official Currency"
                  value={countryData?.officialCurrency}
                  onChange={(e) => handleStateChange(e, setCountryData)}
                  options={countryCurrency}
                />

                <CustomVerticalSelectField
                  name="officialLanguage"
                  label="Official Language"
                  value={countryData?.officialLanguage}
                  onChange={(e) => handleStateChange(e, setCountryData)}
                  options={languages}
                />
              </div>
              <div className="university_image_container">
                <label
                  className="country_cover_image"
                  htmlFor="countryCoverImage"
                >
                  {countryData?.countryCoverImage ? (
                    <img
                      src={URL.createObjectURL(countryData?.countryCoverImage)}
                      className="country_cover_img_style"
                      alt="country_cover_image"
                    />
                  ) : (
                    <img
                      src="/images/superAdmin/upload_placeholder.png"
                      className="country_upload_placeholder_img_style"
                      alt="country_cover_image"
                    />
                  )}
                </label>
                <input
                  type="file"
                  className="actual_documetn_upload_input_field"
                  id="countryCoverImage"
                  name="countryCoverImage"
                  onChange={(e) => handleFileInputInState(e, setCountryData)}
                />
              </div>
            </div>
          </div>
          <div className="input_container">
            <CustomVericalInputField
              name="averageLivingCostPerYear"
              label="Average Cost of living per year"
              value={countryData?.averageLivingCostPerYear}
              onChange={(e) => handleStateChange(e, setCountryData)}
            />

            {/* <CustomVericalInputField
              name="workPermit"
              label="Post study work permit Y/N"
              value={countryData?.workPermit}
              onChange={(e) => handleStateChange(e, setCountryData)}
            /> */}

            <CustomVerticalSelectField
              name="workPermit"
              label="Post study work permit Y/N"
              value={countryData?.workPermit}
              onChange={(e) => handleStateChange(e, setCountryData)}
              options={postGraduationWorkPermit}
            />

            {/* <CustomVericalInputField
              name="workPermitDuringStudies"
              label="Work Permit During Studies Y/N"
              value={countryData?.workPermitDuringStudies}
              onChange={(e) => handleStateChange(e, setCountryData)}
            /> */}

            <CustomVerticalSelectField
              name="workPermitDuringStudies"
              label="Work Permit During Studies Y/N"
              value={countryData?.workPermitDuringStudies}
              onChange={(e) => handleStateChange(e, setCountryData)}
              options={workDuringStydentSelect}
            />

            <div className="university_label_field_holder">
              <label className="university_label_style">Details</label>
              <textarea
                type="text"
                className="university_input_field_style"
                name="details"
                onChange={(e) => handleStateChange(e, setCountryData)}
              />
            </div>

            <CustomVericalInputField
              name="referenceLink"
              label="Official Reference link"
              value={countryData?.referenceLink}
              onChange={(e) => handleStateChange(e, setCountryData)}
            />
          </div>
          <div className="submit_button_holder">
            <CustomButton
              buttonLabel="Save Country"
              onClick={handleSaveCountry}
            />
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default AddCountryForm;
