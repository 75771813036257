import React from "react";
import { replaceLocalhostUrl } from "../../../../utils/helpers";

const CuriousBlock = ({ heading, subText, imageURL }) => {
  return (
    <div className="curious_main_block">
      <p className="curious_heading">CURIOUS</p>
      <div className="curious_detail_container">
        <div className="curious_text_area">
          <p className="courious_main_text_style">{heading}</p>
          <p className="curious_sub_text_style">{subText}</p>
        </div>
        <div className="curious_image_area">
          {imageURL ? (
            <img
              src={replaceLocalhostUrl(imageURL)}
              className="curious_image_style"
            />
          ) : (
            <img
              src="/images/aboutUs/about-us-cover.jpg"
              className="curious_image_style"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CuriousBlock;
