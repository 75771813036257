import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Footer.css";

import { BsFacebook, BsInstagram, BsLinkedin, BsYoutube } from "react-icons/bs";

import { footer_item_list_with_title } from "../../../utils/constant";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="main_footer_container">
      <div className="footer_content_holder">
        <div className="footer__main_col_style">
          <img
            src="/images/navbar/NewLogo.svg"
            className="footer_logo_style"
            alt="logo"
          />
          <div className="footer_desc_holder">
            <p className="footer_desc_text_style">
              Your dream school, made easy
            </p>
          </div>
        </div>
        {footer_item_list_with_title.map((footerItem) => (
          <div className="footer_col_style" key={footerItem.id}>
            <p className="link_list_title_style">{footerItem.title}</p>
            <div className="link_content_container">
              {footerItem.links.map((link) => (
                <Link
                  to={link.link}
                  className="footer_link_text_style"
                  key={link.id}
                >
                  {link.name}
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="contact_content_holder">
        <p className="footer_terms_text_style">
          Zoomtocollege © 2023 -{" "}
          <span
            className="privacy_policy_style"
            onClick={() => navigate("/termsAndConditions")}
          >
            Terms and Conditions
          </span>{" "}
          -{" "}
          <span
            className="privacy_policy_style"
            onClick={() => navigate("/privacyPolicy")}
          >
            Privacy Policy
          </span>
        </p>
        <div className="icons_holder">
          <a
            href="https://www.facebook.com/profile.php?id=61550971872712"
            target="_blank"
          >
            <BsFacebook className="footer_icon_style" />
          </a>
          <a
            href="https://instagram.com/zoomtocollege?igshid=OGQ5ZDc2ODk2ZA=="
            target="_blank"
          >
            <BsInstagram className="footer_icon_style" />
          </a>
          <a
            href="https://www.linkedin.com/company/zoomtocollege/"
            target="_blank"
          >
            <BsLinkedin className="footer_icon_style" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
