import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router";

import { useGetAdminById } from "../../../../hooks/useGetAdminById";
import { useGetUniversityById } from "../../../../hooks/useGetUniversityById";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import CustomInputField from "../../../reusables/FromComponent/CustomInputField";
import CustomSelectField from "../../../reusables/FromComponent/CustomSelectField";

import Notification, { handleStateChange } from "../../../../utils/helpers";
import {
  API_HEADERS,
  UniversityUserRolesSelect,
} from "../../../../utils/constant";

const UniversityUserProfileView = () => {
  const { user } = useContext(AuthContext);
  const { userId } = useParams();
  const navigate = useNavigate();

  const [adminData, isLoading, error] = useGetAdminById(userId);

  const universityData = useGetUniversityById(
    user?.universityRef ||
      JSON.parse(localStorage.getItem("currentLoginUser"))?.universityRef
  );

  const [newAdminData, setNewAdmindata] = useState(null);

  const handleUpdateAdmin = () => {
    if (!universityData[0].isVerified) {
      Notification.errorNotification("University not verified! Access denied.");
    } else {
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/auth/updateAdminById`,
          { ...newAdminData, adminId: userId },
          API_HEADERS()
        )
        .then(() => {
          Notification.successNotification("User Details Updated Successfully");
          navigate("/university/userList");
        })
        .catch((err) => {
          Notification.errorNotification(err.response.data.message);
        });
    }
  };

  const handleDeleteAdmin = () => {
    if (!universityData[0].isVerified) {
      Notification.errorNotification("University not verified! Access denied.");
    } else {
      axios
        .delete(
          `${process.env.REACT_APP_BACKEND_URL}/auth/deleteAdminById/${userId}`,
          API_HEADERS()
        )
        .then(() => {
          Notification.successNotification("User Deleted Successfully");
          navigate("/university/userList");
        })
        .catch((err) => {
          Notification.errorNotification(err.response.data.message);
        });
    }
  };

  useEffect(() => {
    setNewAdmindata({
      username: adminData?.username || "",
      email: adminData?.email || "",
      firstName: adminData?.firstName || "",
      lastName: adminData?.lastName || "",
      role: adminData?.role || "",
    });
  }, [userId, adminData]);

  if (isLoading || universityData[1]) return <Loader />;
  if (error || universityData[2]) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"USER PROFILE"} />
        <div className="agent_view_form_holder">
          <CustomInputField
            name="username"
            value={newAdminData?.username}
            label={"User Name"}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />
          <CustomInputField
            type="email"
            name="email"
            label={"Email"}
            value={newAdminData?.email}
          />
          <CustomInputField
            name="firstName"
            label={"First Name"}
            value={newAdminData?.firstName}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />
          <CustomInputField
            name="lastName"
            label={"Last Name"}
            value={newAdminData?.lastName}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />
          <CustomSelectField
            name="role"
            label={"Role"}
            value={newAdminData?.role}
            options={UniversityUserRolesSelect}
            onChange={(e) => handleStateChange(e, setNewAdmindata)}
          />
          <div className="agent_label_field_holder">
            <label className="agency_agent_label_style"> </label>
            <div className="agency_agent_action_btn_holder">
              <button
                className="agency_agent_btn_style"
                onClick={handleDeleteAdmin}
              >
                Delete
              </button>
              <button
                className="agency_agent_btn_style"
                onClick={handleUpdateAdmin}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default UniversityUserProfileView;
