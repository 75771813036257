import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { MultiSelect } from "react-multi-select-component";
import "./SuperAdminAddStudent.css";

import { useGetAllCourse } from "../../../../hooks/useGetAllCourse";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";

import CustomVericalInputField from "../../../reusables/FromComponent/CustomVericalInputField";
import CustomVerticalSelectField from "../../../reusables/FromComponent/CustomVerticalSelectField";

import Notification, {
  findEmptyFields,
  generateEducationRequirementScoreSelect,
  generateRandomString,
  handleFileInputInState,
  handleStateChange,
  removeElementFromArray,
} from "../../../../utils/helpers";
import {
  API_HEADERS,
  StudentRoleSelect,
  addStudentStateInitialStateObject,
  courseLevelSelect,
  educationalRequirementSelect,
  englishExamtest,
  genderSelect,
  languages,
  newCountryList,
  newCourseSubjectListForStudent,
  newMaritalStatus,
  postGraduationWorkPermit,
  visaPermission,
  workDuringStydentSelect,
} from "../../../../utils/constant";

const SuperAdminAddStudent = () => {
  const [course, isLoading, error] = useGetAllCourse();
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  const [studentToAdd, setStudentToAdd] = useState({
    ...addStudentStateInitialStateObject,
    addedBy:
      user?._id || JSON.parse(localStorage.getItem("currentLoginUser"))._id,
  });

  const [showTestInfo, setShowTestInfo] = useState(false);
  const [englishProfData, setEnglishProfData] = useState([
    {
      id: generateRandomString(5),
      englishTest: "",
      reading: "",
      writing: "",
      listning: "",
      speaking: "",
      total: "",
    },
  ]);

  const handleValueEnteringEnglishTest = (
    id,
    englishTest,
    reading,
    writing,
    listning,
    speaking,
    total
  ) => {
    const updatedTestInput = englishProfData.map((item) => {
      if (item.id === id) {
        if (englishTest !== undefined) return { ...item, englishTest };
        if (reading !== undefined) return { ...item, reading: reading };
        if (writing !== undefined) return { ...item, writing: writing };
        if (listning !== undefined) return { ...item, listning: listning };
        if (speaking !== undefined) return { ...item, speaking: speaking };
        if (total !== undefined) return { ...item, total: total };
        else return { ...item };
      }
      return item;
    });

    setEnglishProfData(updatedTestInput);
  };

  useEffect(() => {
    setStudentToAdd((prev) => ({
      ...prev,
      englishTestRequirement: englishProfData,
    }));
  }, [englishProfData]);

  const handleAddStudent = () => {
    if (findEmptyFields(studentToAdd).length > 0) {
      Notification.errorNotification(
        `Please enter ${findEmptyFields(studentToAdd)[0]}`
      );
    } else {
      const studentData = new FormData();
      Object.keys(studentToAdd).forEach((key) => {
        if (
          Array.isArray(studentToAdd[key]) &&
          (key === "desiredCountry" || key === "englishTestRequirement")
        ) {
          studentData.append(key, JSON.stringify(studentToAdd[key]));
        } else {
          studentData.append(key, studentToAdd[key]);
        }
      });

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/auth/signup`,
          studentData,
          API_HEADERS()
        )
        .then(() => {
          Notification.successNotification("Student added Successfully");
          navigate("/superAdmin/student");
        })
        .catch((err) => {
          Notification.errorNotification(err.response.data.message);
        });
    }
  };

  const [showVisaResidenceField, setshowVisaResidenceField] = useState(true);

  useEffect(() => {
    const newCitizenshipArray = [
      studentToAdd?.citizenship,
      studentToAdd?.secondCitizenship,
    ];
    if (
      newCitizenshipArray.includes(studentToAdd?.countryOfResidence) &&
      studentToAdd?.citizenship &&
      studentToAdd?.secondCitizenship &&
      studentToAdd?.countryOfResidence
    )
      setshowVisaResidenceField(false);
    else setshowVisaResidenceField(true);
  }, [
    studentToAdd?.citizenship,
    studentToAdd?.secondCitizenship,
    studentToAdd?.countryOfResidence,
  ]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;
  return (
    <div>
      <UserNavbar>
        <PageTitle title={"ADD STUDENT"} />
        <div className="add_student_form">
          <div className="student_input_container">
            <div className="profile_photo_info_holder">
              <div className="student_profile_img_container">
                <label
                  htmlFor="studen_profile_image"
                  className="student_profile_img_label_style"
                >
                  {studentToAdd && studentToAdd.studentProfileImage !== "" ? (
                    <img
                      src={URL.createObjectURL(
                        studentToAdd.studentProfileImage
                      )}
                      className="user_uploaded_image_style"
                      alt="profile_picture"
                    />
                  ) : (
                    <img
                      src="/images/superAdmin/upload_placeholder.png"
                      className="upload_place_holder_style"
                      alt="profile_picture"
                    />
                  )}
                </label>
                <input
                  type="file"
                  className="actual_file_uopload_input_field"
                  id="studen_profile_image"
                  name="studentProfileImage"
                  onChange={(e) => handleFileInputInState(e, setStudentToAdd)}
                />
              </div>
              <div className="general_details_holder">
                <div className="container">
                  <div className="row">
                    <CustomVericalInputField
                      name="firstName"
                      label="Applicant First Name"
                      value={studentToAdd?.firstName}
                      onChange={(e) => handleStateChange(e, setStudentToAdd)}
                      colClass="col-12 col-lg-6"
                    />

                    <CustomVericalInputField
                      name="lastName"
                      label="Applicant Last Name"
                      value={studentToAdd?.lastName}
                      onChange={(e) => handleStateChange(e, setStudentToAdd)}
                      colClass="col-12 col-lg-6"
                    />

                    <CustomVericalInputField
                      name="email"
                      label="Email Address"
                      type="email"
                      value={studentToAdd?.email}
                      onChange={(e) => handleStateChange(e, setStudentToAdd)}
                      colClass="col-12 col-lg-6"
                    />

                    <CustomVericalInputField
                      name="username"
                      label="Username"
                      value={studentToAdd?.username}
                      onChange={(e) => handleStateChange(e, setStudentToAdd)}
                      colClass="col-12 col-lg-6"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="student_input_container">
            <div className="container">
              <div className="row">
                <CustomVerticalSelectField
                  name="role"
                  label="Role"
                  value={studentToAdd?.role}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  options={StudentRoleSelect}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="placeOfBirth"
                  label="Place of Birth"
                  value={studentToAdd?.placeOfBirth}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="countryOfBirth"
                  label="Country of Birth"
                  value={studentToAdd?.countryOfBirth}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  options={newCountryList}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  label="Citizenship"
                  name="citizenship"
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  value={studentToAdd?.citizenship}
                  options={newCountryList}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  label="Second Citizenship"
                  name="secondCitizenship"
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  value={studentToAdd?.secondCitizenship}
                  options={newCountryList}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  label="Country of Residence"
                  name="countryOfResidence"
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  value={studentToAdd?.countryOfResidence}
                  options={newCountryList}
                  colClass="col-12 col-lg-4"
                />

                {showVisaResidenceField && (
                  <CustomVerticalSelectField
                    name="visaPermitStatus"
                    label="Visa / Resident Permit Held"
                    value={studentToAdd?.visaPermitStatus}
                    onChange={(e) => handleStateChange(e, setStudentToAdd)}
                    options={visaPermission}
                    colClass="col-12 col-lg-4"
                  />
                )}

                <CustomVerticalSelectField
                  name="firstlanguage"
                  label="First Language"
                  value={studentToAdd?.firstlanguage}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  options={languages}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="maritalStatus"
                  label="Marital Status"
                  value={studentToAdd?.maritalStatus}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  options={newMaritalStatus}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="cityOfResidence"
                  label="City of Residence"
                  value={studentToAdd?.cityOfResidence}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="gender"
                  label="Gender"
                  value={studentToAdd?.gender}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  options={genderSelect}
                  colClass="col-12 col-lg-4"
                />

                <div className="col-12 col-lg-4">
                  <div className="student_label_field_holder_flex_style">
                    <label className="student_label_style">CV</label>
                    <label htmlFor="cv" style={{ width: "90%" }}>
                      <div className="cv_file_holder_div_style">
                        {studentToAdd && studentToAdd.cv !== ""
                          ? `${studentToAdd.cv.name}`
                          : ""}
                      </div>
                    </label>
                    <input
                      type="file"
                      className="actual_file_uopload_input_field"
                      id="cv"
                      name="cv"
                      onChange={(e) =>
                        handleFileInputInState(e, setStudentToAdd)
                      }
                    />
                  </div>
                </div>

                <CustomVericalInputField
                  name="address"
                  label="Address"
                  value={studentToAdd?.address}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="passportNumber"
                  label=" Passport Number"
                  value={studentToAdd?.passportNumber}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="dateOfBirth"
                  label="Date of Birth"
                  type="date"
                  value={studentToAdd?.dateOfBirth}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="postcode"
                  label="Postcode"
                  value={studentToAdd?.postcode}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="passportExpiry"
                  label="Passport Expiry"
                  type="month"
                  value={studentToAdd?.passportExpiry}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  colClass="col-12 col-lg-4"
                />

                <div className="col-12 col-lg-4">
                  <div className="student_label_field_holder_flex_style">
                    <label className="student_label_style">Passport Copy</label>
                    <label htmlFor="passportCopy" style={{ width: "90%" }}>
                      <div className="cv_file_holder_div_style">
                        {studentToAdd && studentToAdd.passportCopy !== ""
                          ? `${studentToAdd.passportCopy.name}`
                          : ""}
                      </div>
                    </label>
                    <input
                      type="file"
                      className="actual_file_uopload_input_field"
                      id="passportCopy"
                      name="passportCopy"
                      onChange={(e) =>
                        handleFileInputInState(e, setStudentToAdd)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="student_input_container">
            <div className="container">
              <div className="row">
                <CustomVerticalSelectField
                  name="highestEducation"
                  label="Highest Education Level Attained"
                  value={studentToAdd?.highestEducation}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  options={educationalRequirementSelect}
                  colClass="col-12 col-lg-4"
                  toolTip="We use this information to match you to the most suitable courses"
                />

                <CustomVericalInputField
                  name="studentAcademicInstitution"
                  label="Name of Academic Institution"
                  value={studentToAdd?.studentAcademicInstitution}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="languageOfInstruction"
                  label="Language of Instruction"
                  value={studentToAdd?.languageOfInstruction}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  options={languages}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="countryOfEducation"
                  label="Country of Education"
                  value={studentToAdd?.countryOfEducation}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  options={newCountryList}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="averageGrade"
                  label="Average grade (%)"
                  informativeLink={`/blog/view/650ceecf2cf8f95dc218238f`}
                  value={studentToAdd?.averageGrade}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  colClass="col-12 col-lg-4"
                  options={generateEducationRequirementScoreSelect(100)}
                />

                <CustomVericalInputField
                  name="dateAttendedFrom"
                  label="Date Attended From"
                  type="date"
                  value={studentToAdd?.dateAttendedFrom}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="dateAttendedUntil"
                  label="Date Attended Until"
                  type="date"
                  value={studentToAdd?.dateAttendedUntil}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  colClass="col-12 col-lg-4"
                />

                <CustomVericalInputField
                  name="nameOfDegree"
                  label="Name of Course"
                  value={studentToAdd?.nameOfDegree}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  colClass="col-12 col-lg-4"
                />

                <div className="col-12 col-lg-4">
                  <div className="student_label_field_holder_flex_style">
                    <label className="student_label_style">
                      Proof of Academic Qualification
                    </label>
                    <label
                      htmlFor="proofOfAcadamicQualification"
                      style={{ width: "90%" }}
                    >
                      <div className="cv_file_holder_div_style">
                        {studentToAdd &&
                        studentToAdd.proofOfAcadamicQualification !== ""
                          ? `${studentToAdd.proofOfAcadamicQualification.name}`
                          : ""}
                      </div>
                    </label>
                    <input
                      type="file"
                      className="actual_file_uopload_input_field"
                      id="proofOfAcadamicQualification"
                      name="proofOfAcadamicQualification"
                      onChange={(e) =>
                        handleFileInputInState(e, setStudentToAdd)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="student_input_container">
            <div className="english_prof_container">
              <div className="label_and_add_btn_holder">
                <label className="course_label_style_multiple">
                  Given English test
                  <input
                    type="checkbox"
                    className="conformationCheckBox"
                    onChange={(e) => setShowTestInfo(e.target.checked)}
                  />
                </label>
                <AiOutlinePlus
                  className="add_work_exp_icon_style"
                  onClick={() =>
                    setEnglishProfData([
                      ...englishProfData,
                      {
                        id: generateRandomString(5),
                        englishTest: "",
                        reading: "",
                        writing: "",
                        listning: "",
                        speaking: "",
                        total: "",
                      },
                    ])
                  }
                />
              </div>
              {showTestInfo &&
                englishProfData &&
                englishProfData.map((field) => (
                  <div className="work_option_holder">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-lg-12">
                          <CustomVerticalSelectField
                            className="course_input_field_style_with_img"
                            value={field.englishTest}
                            placeholder={"Please enter service"}
                            id={field.id}
                            onChange={(e) =>
                              handleValueEnteringEnglishTest(
                                field.id,
                                e.target.value,
                                ...[,]
                              )
                            }
                            options={englishExamtest}
                          />
                        </div>
                        <div
                          className="col-12 col-lg-12"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <CustomVericalInputField
                            className="course_input_field_style_with_img"
                            value={field.reading}
                            placeholder={"Reading "}
                            id={field.id}
                            onChange={(e) =>
                              handleValueEnteringEnglishTest(
                                field.id,
                                ...[,],
                                e.target.value
                              )
                            }
                          />
                          <CustomVericalInputField
                            className="course_input_field_style_with_img"
                            value={field.writing}
                            placeholder={"Writing "}
                            id={field.id}
                            onChange={(e) =>
                              handleValueEnteringEnglishTest(
                                field.id,
                                ...[,],
                                ...[,],
                                e.target.value
                              )
                            }
                          />
                          <CustomVericalInputField
                            className="course_input_field_style_with_img"
                            value={field.listning}
                            placeholder={"Listening"}
                            id={field.id}
                            onChange={(e) =>
                              handleValueEnteringEnglishTest(
                                field.id,
                                ...[,],
                                ...[,],
                                ...[,],

                                e.target.value
                              )
                            }
                          />
                          <CustomVericalInputField
                            className="course_input_field_style_with_img"
                            value={field.speaking}
                            placeholder={"Speaking"}
                            id={field.id}
                            onChange={(e) =>
                              handleValueEnteringEnglishTest(
                                field.id,
                                ...[,],
                                ...[,],
                                ...[,],
                                ...[,],
                                e.target.value
                              )
                            }
                          />
                          <CustomVericalInputField
                            className="course_input_field_style_with_img"
                            value={field.total}
                            placeholder={"Total"}
                            id={field.id}
                            onChange={(e) =>
                              handleValueEnteringEnglishTest(
                                field.id,
                                ...[,],
                                ...[,],
                                ...[,],
                                ...[,],
                                ...[,],
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="minus_btn_holder">
                      <AiOutlineMinus
                        className="minus_icon_style"
                        onClick={() => {
                          setEnglishProfData(
                            removeElementFromArray(englishProfData, field.id)
                          );
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="student_input_container">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <label className="agency_label_style">Desired Country</label>
                  <MultiSelect
                    options={newCountryList}
                    value={studentToAdd?.desiredCountry || []}
                    onChange={(values) => {
                      setStudentToAdd((prev) => ({
                        ...prev,
                        desiredCountry: [...values],
                      }));
                    }}
                    className="multipleInputStyle"
                    isCreatable={true}
                  />
                </div>

                <CustomVerticalSelectField
                  name="workDuringStudy"
                  label="Work During Your Studies"
                  value={studentToAdd?.workDuringStudy}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  options={workDuringStydentSelect}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="postGraduateWorkPermit"
                  label="Post-Graduation work Permit"
                  value={studentToAdd?.postGraduateWorkPermit}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  options={postGraduationWorkPermit}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="desiredlevelOfStudy"
                  label="Desired Level of Study"
                  value={studentToAdd?.desiredlevelOfStudy}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  options={courseLevelSelect}
                  colClass="col-12 col-lg-4"
                />

                <CustomVerticalSelectField
                  name="desiredCourseDiscipline"
                  label="Desired Course Discipline"
                  value={studentToAdd?.desiredCourseDiscipline}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  options={newCourseSubjectListForStudent}
                  colClass="col-12 col-lg-4"
                />

                <div className="col-12 col-lg-4">
                  <div className="student_label_field_holder_flex_style">
                    <label className="student_label_style">Course</label>
                    <select
                      className="student_input_field_style"
                      name="courseID"
                      onChange={(e) => handleStateChange(e, setStudentToAdd)}
                    >
                      <option>---- Select Option ----</option>
                      {course.map((item) => (
                        <option value={item?._id}>{item.courseName}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <CustomVericalInputField
                  name="preferedStartMonth"
                  label="Preferred Start Month"
                  type="month"
                  value={studentToAdd?.preferedStartMonth}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  colClass="col-12 col-lg-4"
                />
              </div>
            </div>
          </div>

          <div className="student_input_container">
            <div className="container">
              <div className="row">
                <CustomVericalInputField
                  name="password"
                  label="Password"
                  type="password"
                  value={studentToAdd?.password}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  value={studentToAdd?.confirmPassword}
                  onChange={(e) => handleStateChange(e, setStudentToAdd)}
                  colClass="col-12 col-lg-6"
                />
              </div>
            </div>
          </div>

          <div className="student_btn_holder">
            <button className="add_agent_btn_style" onClick={handleAddStudent}>
              Add Student
            </button>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminAddStudent;
