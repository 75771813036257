import React from "react";
import { agencyWhyJoinUsPoints } from "../../../../utils/constant";

const WhyJoinUs = ({ whyJoinUs }) => {
  return (
    <div className="why_join_us_container">
      <div className="block_title">
        <p className="title_text_style">WHY JOIN US</p>
        <div className="short_border"></div>
      </div>
      <div className="why_join_us_desc_holder">
        <p className="why_join_desc">{whyJoinUs}</p>
      </div>
      <div style={{ textAlign: "left" }}>
        <p className="why_join_desc">
          Here are just a few reasons why you should join us:
        </p>
        <ul>
          {agencyWhyJoinUsPoints.map((data) => (
            <li id={data.id}>{data.line}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default WhyJoinUs;
