import React from "react";
import { Modal } from "react-bootstrap";

import WhyApplyForm from "./FormComponents/WhyApplyForm";

const HomeInfoFormModal = ({ show, handleClose, formType, selectedData }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      {formType === "whyApply" && (
        <WhyApplyForm handleClose={handleClose} selectedData={selectedData} />
      )}
    </Modal>
  );
};

export default HomeInfoFormModal;
