import React from "react";
import { Route, Routes } from "react-router-dom";

import PageNotFound from "../reusables/PageNotFound/PageNotFound";

import UniversityHomePage from "../University/UniversityHomePage/UniversityHomePage";
import UniversitySignUp from "../University/UniversitySignUpController/UniversitySignUp";
import UniversityDashboard from "../University/UniversityDashboard/UniversityDashboard";
import UniversityPrivateRoute from "./PrivateRoutes/UniversityPrivateRoute";

import UniversityProfileView from "../University/UniversityProfileView/UniversityProfileView";

import UniversityAddNewUser from "../University/UniversityUserControl/UniversityAddNewUser/UniversityAddNewUser";
import UniversityUserPasswordChange from "../University/UniversityUserControl/UniversityUserPasswordChange/UniversityUserPasswordChange";
import UniversityUserList from "../University/UniversityUserControl/UniversityUserList/UniversityUserList";
import UniversityUserProfileView from "../University/UniversityUserControl/UniversityUserProfileView/UniversityUserProfileView";

import UniversityCourseListView from "../University/UniversityUserControl/UniversityCourseControl/UniversityCourseListView/UniversityCourseListView";
import UniversityCourseDetailView from "../University/UniversityUserControl/UniversityCourseControl/UniversityCourseDetailView/UniversityCourseDetailView";

import ApplicationDetailView from "../University/UniversityApplicationControl/ApplicationDetailView/ApplicationDetailView";
import AllApplicationForUniversityList from "../University/UniversityApplicationControl/AllApplicationForUniversityList/AllApplicationForUniversityList";

const UniversityRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/*" element={<PageNotFound />} />

        <Route path="/home" element={<UniversityHomePage />} />

        <Route path="/universityInquiry" element={<UniversitySignUp />} />

        <Route
          path="/dashboard"
          element={
            <UniversityPrivateRoute>
              <UniversityDashboard />
            </UniversityPrivateRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <UniversityPrivateRoute>
              <UniversityProfileView />
            </UniversityPrivateRoute>
          }
        />

        <Route
          path="user/changePassword"
          element={
            <UniversityPrivateRoute>
              <UniversityUserPasswordChange />
            </UniversityPrivateRoute>
          }
        />

        <Route
          path="user/addUser"
          element={
            <UniversityPrivateRoute>
              <UniversityAddNewUser />
            </UniversityPrivateRoute>
          }
        />

        <Route
          path="/userList"
          element={
            <UniversityPrivateRoute>
              <UniversityUserList />
            </UniversityPrivateRoute>
          }
        />

        <Route
          path="/user/view/:userId"
          element={
            <UniversityPrivateRoute>
              <UniversityUserProfileView />
            </UniversityPrivateRoute>
          }
        />

        <Route
          path="/courseList"
          element={
            <UniversityPrivateRoute>
              <UniversityCourseListView />
            </UniversityPrivateRoute>
          }
        />

        <Route
          path="/course/view/:courseId"
          element={
            <UniversityPrivateRoute>
              <UniversityCourseDetailView />
            </UniversityPrivateRoute>
          }
        />

        <Route
          path="/application/view/:applicationId"
          element={
            <UniversityPrivateRoute>
              <ApplicationDetailView />
            </UniversityPrivateRoute>
          }
        />

        <Route
          path="/application"
          element={
            <UniversityPrivateRoute>
              <AllApplicationForUniversityList />
            </UniversityPrivateRoute>
          }
        />
      </Routes>
    </div>
  );
};

export default UniversityRoutes;
