import React, { useState } from "react";
import { TfiUpload } from "react-icons/tfi";

import Notification, {
  findEmptyFields,
  handleFileInputInState,
  handleStateChange,
} from "../../../../utils/helpers";
import { agencySigninStep1DefaultStateObj } from "../../../../utils/constant";

const SignUpStep1 = ({ handleChangeTab, handleSaveFields }) => {
  const [step1Data, setStep1Data] = useState(agencySigninStep1DefaultStateObj);

  const handleStep1Submit = (data) => {
    if (findEmptyFields(data).length) {
      Notification.errorNotification(
        `Please Enter following Field:  ${findEmptyFields(data)[0]}`
      );
    } else {
      handleSaveFields(data);
      handleChangeTab("tab2");
    }
  };
  return (
    <div className="sign_in_step_1_form_holder">
      <form className="step-1_form">
        <div className="field_holder">
          <input
            type="text"
            placeholder="Agency Name"
            className="input_field_style"
            name="agencyName"
            onChange={(e) => handleStateChange(e, setStep1Data)}
          />
        </div>
        <div className="field_holder">
          <input
            type="text"
            placeholder="Agency Registration Number"
            className="input_field_style"
            name="agencyRegistrationNumber"
            onChange={(e) => handleStateChange(e, setStep1Data)}
          />
        </div>
        <div className="field_holder">
          <input
            type="text"
            placeholder="Agency tax Number"
            className="input_field_style"
            name="agencyTaxNumber"
            onChange={(e) => handleStateChange(e, setStep1Data)}
          />
        </div>
        {/* <div className="field_holder">
          <input
            type="number"
            placeholder="Agency Commission Criteria"
            className="input_field_style"
            name="agencyCommission"
            onChange={(e) => handleStateChange(e, setStep1Data)}
          />
        </div> */}
        <div className="field_holder">
          <input
            type="text"
            placeholder="Agency Address"
            className="input_field_style"
            name="agencyAddress"
            onChange={(e) => handleStateChange(e, setStep1Data)}
          />
        </div>
        <div className="field_holder">
          <input
            type="text"
            placeholder="Agency Main Phone Number"
            className="input_field_style"
            name="agencyMainPhoneNumber"
            onChange={(e) => handleStateChange(e, setStep1Data)}
          />
        </div>

        <div className="field_holder">
          <label
            className="input_field_style label_upload_style"
            htmlFor="cerificate"
          >
            <div className="input_text_holder business_cerificate_input_flex_style">
              <p className="input_text_style">
                Business Incorporation Certificate{" "}
              </p>
              <div>
                <p className="class_upload_btn" type="button">
                  <TfiUpload className="btn_icon_style" />
                  Upload
                </p>
              </div>
            </div>
            <input
              type="file"
              id="cerificate"
              placeholder="Business Name"
              className="upload_actual_input_field"
              name="businessIncorporatedCertificate"
              onChange={(e) => handleFileInputInState(e, setStep1Data)}
            />
          </label>
        </div>
        <div className="next_button_holder" type="button">
          <button
            type="button"
            className="next_button"
            onClick={() => handleStep1Submit(step1Data)}
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default SignUpStep1;
