import React, { useContext } from "react";

import { useGetUniversityStartisticsByID } from "../../../hooks/useGetUniversityStartisticsByID";

import { AuthContext } from "../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../reusables/PageTitle/PageTitle";
import StatisticBlock from "../../reusables/StatisticBlock/StatisticBlock";
import Loader from "../../reusables/Loader/Loader";
import ErrorPage from "../../reusables/ErrorPage/ErrorPage";

import { universityStatisticInfo } from "../../../utils/constant";

const UniversityDashboard = () => {
  const { user } = useContext(AuthContext);

  const [statistics, isLoading, error] = useGetUniversityStartisticsByID(
    user?.universityRef ||
      JSON.parse(localStorage.getItem("currentLoginUser"))?.universityRef
  );

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title="DASHBOARD" />
        <br />
        <br />
        <div className="container">
          <div className="row">
            {Object.keys(statistics).length &&
              Object.keys(statistics).map((statKey) => (
                <StatisticBlock
                  statTitle={universityStatisticInfo[statKey].statTitle}
                  description={universityStatisticInfo[statKey].description}
                  count={statistics[statKey]}
                  link={universityStatisticInfo[statKey].link}
                />
              ))}
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default UniversityDashboard;
