import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import "./GeneralLogin.css";

import { AuthContext } from "../../reusables/AuthProvider/AuthProvider";
import MainNavbar from "../../reusables/Navbar/MainNavbar/MainNavbar";
import PageTitle from "../../reusables/PageTitle/PageTitle";

import CustomButton from "../../reusables/FromComponent/CustomButton";
import CustomVericalInputField from "../../reusables/FromComponent/CustomVericalInputField";

import Notification, {
  afterLoginRoute,
  handleStateChange,
} from "../../../utils/helpers";

const GeneralLogin = () => {
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  const { setUserDetails } = useContext(AuthContext);

  const handleSubmit = async () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, userData)
      .then((response) => {
        localStorage.setItem("currentLoginUser", JSON.stringify(response.data));
        setUserDetails(response.data);
        navigate(afterLoginRoute(response.data.role));
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  return (
    <MainNavbar>
      <>
        <PageTitle title={"sign in to get started"} />
        <div className="login_form_holder">
          <form className="login_form_style">
            <CustomVericalInputField
              name="email"
              label="Enter Email"
              type="email"
              value={userData?.email}
              onChange={(e) => handleStateChange(e, setUserData)}
            />

            <CustomVericalInputField
              name="password"
              label="Enter Password"
              type="password"
              value={userData?.password}
              onChange={(e) => handleStateChange(e, setUserData)}
            />
            <div className="forget_password_holder">
              <Link to="/forgotPassword" className="signup_link">
                <span> Forgot password ?</span>
              </Link>
            </div>

            <div className="login_field_holder">
              <CustomButton
                buttonLabel="Login"
                onClick={handleSubmit}
                fullwidth
              />
            </div>
            <div className="forget_password_holder  my-4">
              <span className="forget_password_text">
                Don't have student account ?
              </span>

              <Link to="/student/signup" className="signup_link">
                <span> Signup here</span>
              </Link>
            </div>
          </form>
        </div>
      </>
    </MainNavbar>
  );
};

export default GeneralLogin;
