import React, { useState } from "react";
import Notification, {
  removeEmptyProperties,
  replaceLocalhostUrl,
} from "../../../../../utils/helpers";
import { API_HEADERS } from "../../../../../utils/constant";
import axios from "axios";

const GoalStatementUpdate = ({
  valuesAndGoalsArray,
  ownerNameData,
  wordFromOwnerData,
}) => {
  const [goalValuesForAboutUs, setGoalValuesForAboutUs] =
    useState(valuesAndGoalsArray);
  const [wordFromOwner, setWordFromOwner] = useState(wordFromOwnerData);
  const [ownerName, setOwnerName] = useState(ownerNameData);

  const handleInputChange = (index, field, newValue) => {
    const updatedData = [...goalValuesForAboutUs];
    updatedData[index][field] = newValue;
    setGoalValuesForAboutUs(updatedData);
  };

  const handleUpdat = (value) => {
    const newData = removeEmptyProperties(value);
    const cardDataToSend = new FormData();

    Object.keys(newData).forEach((key) => {
      cardDataToSend.append(key, newData[key]);
    });

    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/aboutUsInfo/updateValuesAndGoalsArray`,
        cardDataToSend,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification(
          "About Us Content Updated Successfully"
        );
      })
      .catch((err) => {
        Notification.errorNotification(err?.response?.data?.message);
      });
  };

  const UpdateOwnerWordText = () => {
    const formData = new FormData();
    formData.append("ownerName", ownerName);
    formData.append("wordFromOwner", wordFromOwner);

    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/aboutUsInfo/updateAboutUsContent`,
        formData,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification(
          "About Us Content Updated Successfully"
        );
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  return (
    <div className="goal_state_main">
      <p className="curious_heading">OUR VALUES AND GOALS</p>
      <div className="values_goals_area">
        <div className="goal_values_area row">
          {goalValuesForAboutUs?.map((content, index) => (
            <div
              key={content.id}
              className="col-12 col-lg-6 col-md-6 col-sm-6 value_container"
            >
              <label htmlFor={`team_member_image_input_${content.id}`}>
                {typeof content.iconImage === "string" ? (
                  <img
                    src={replaceLocalhostUrl(content.iconImage)}
                    width={50}
                    height={50}
                  />
                ) : (
                  <img
                    src={URL.createObjectURL(content.iconImage)}
                    width={50}
                    height={50}
                  />
                )}
              </label>
              <input
                id={`team_member_image_input_${content.id}`}
                type="file"
                className="image_input_hiddden_style"
                name="ourCultureImage"
                onChange={(e) =>
                  handleInputChange(index, "iconImage", e.target.files[0])
                }
              />
              <input
                type="text"
                value={content.name}
                placeholder="Enter Name"
                className="value_name_style aboutus_form_input_style text-center"
                onChange={(e) =>
                  handleInputChange(index, "name", e.target.value)
                }
              />
              <textarea
                rows={3}
                value={content.description}
                placeholder="Enter Heading"
                className="value_description_style aboutus_form_input_style text-center"
                onChange={(e) =>
                  handleInputChange(index, "description", e.target.value)
                }
              />

              <button
                className="update_goals_btn "
                onClick={() => handleUpdat(content)}
              >
                Update
              </button>
            </div>
          ))}
        </div>
        <div className="goal_statement_area">
          <p className="curious_heading">WORD FROM OWNER</p>
          <textarea
            rows={10}
            placeholder="Enter text"
            className="word_from_owner_style aboutus_form_input_style"
            onChange={(e) => setWordFromOwner(e.target.value)}
            value={wordFromOwner}
          />
          <input
            placeholder="Enter owner name"
            className="curious_heading aboutus_form_input_style"
            onChange={(e) => setOwnerName(e.target.value)}
            value={ownerName}
          />
          <div className="update_Button_holder">
            <button
              className="add_agent_btn_style mt-5"
              onClick={UpdateOwnerWordText}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalStatementUpdate;
