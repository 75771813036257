import React from "react";
import "./UniversityCard.css";
import { replaceLocalhostUrl } from "../../../utils/helpers";
import { VscVerifiedFilled } from "react-icons/vsc";

import CustomButton from "../FromComponent/CustomButton";

const UniversityCard = ({ university }) => {
  return (
    <div className="UniversityCard_container">
      <div className="university_card_basic_detail_box">
        <img
          src={replaceLocalhostUrl(university?.universityCoverImage)}
          className="university_cover_img"
          alt="university_cover_img"
        />
        <div className="university_card_basic_detail_sub_box">
          <p className="university_card_uni_name">
            {university?.universityName}
          </p>
          <p className="university_card_uni_city">
            {university?.universityCity} / {university?.universityCountry}
          </p>
          <div className="university_card_btn_container">
            <CustomButton buttonLabel={university?.typeOfInstitution} small />

            {/* <AiOutlineHeart /> */}
            {university?.isVerified && (
              <VscVerifiedFilled className="verified_icon_style" />
            )}
          </div>
        </div>
      </div>
      <div className="university_card_sub_image_box">
        <img
          src={replaceLocalhostUrl(university?.subImages[0])}
          className="university_card_sub_image_large"
          alt="subImages"
          // height={160}
        />
        <div className="university_card_sub_image_flex_box">
          <img
            src={replaceLocalhostUrl(university?.subImages[1])}
            className="university_card_sub_image"
            alt="subImages"
            // height={80}
          />
          <img
            src={replaceLocalhostUrl(university?.subImages[2])}
            className="university_card_sub_image"
            alt="subImages"
            // height={80}
          />
        </div>
      </div>
      <div>
        <p className="university_card_uni_desc">
          {university?.universityDescription?.length > 200
            ? `${university?.universityDescription.slice(0, 200)}...`
            : university?.universityDescription}
        </p>
      </div>
      <div className="university_card_view_btn_container"></div>
    </div>
  );
};

export default UniversityCard;
