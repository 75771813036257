import React, { useState } from "react";
import Notification, { handleStateChange } from "../../../../utils/helpers";
import axios from "axios";
import { API_HEADERS } from "../../../../utils/constant";

const MailEditor = ({ selectedUsers }) => {
  const [emailContent, setEmailContent] = useState({
    subject: "",
    content: "",
  });

  const handleSentEmailToAll = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/subscriber/sendMailToSelectedUser`,
        { ...emailContent, emailList: selectedUsers },
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Mail sent to all subscriber.");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };
  return (
    <div className="mx-4">
      <div className="university_label_field_holder">
        <label className="university_label_style">Email Subject</label>
        <input
          type="text"
          className="full_university_input_field_style"
          name="subject"
          onChange={(e) => handleStateChange(e, setEmailContent)}
        />
      </div>
      <div className="university_label_field_holder">
        <label className="university_label_style">Email Content</label>
        <textarea
          type="text"
          className="full_university_input_field_style"
          name="content"
          onChange={(e) => handleStateChange(e, setEmailContent)}
          rows={5}
        />
      </div>
      <div className="new_email_btn_holder">
        <button className="add_agent_btn_style" onClick={handleSentEmailToAll}>
          Send mail to all Subscribers
        </button>
      </div>
    </div>
  );
};

export default MailEditor;
