import React, { useContext, useEffect, useState } from "react";
import { DateRange } from "react-date-range";

import { useGetAllApplicationOfUniversityById } from "../../../../hooks/useGetAllApplicationOfUniversityById";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import TableComponent from "../../../reusables/TableComponent/TableComponent";
import Loader from "../../../reusables/Loader/Loader";
import EmptyComponent from "../../../reusables/EmptryComponent/EmptyComponent";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import {
  SuperAdminapplicationListFields,
  SuperAdminapplicationTableCols,
} from "../../../../utils/constant";
import {
  filterApplicationsByStatus,
  filterApplicationsByStatusForUniversity,
  handleStateChange,
  searchData,
} from "../../../../utils/helpers";
import { TfiBrushAlt } from "react-icons/tfi";
import { useLocation } from "react-router-dom";

const AllApplicationForUniversityList = () => {
  const { user } = useContext(AuthContext);

  const [applications, isLoading, error] = useGetAllApplicationOfUniversityById(
    user?.universityRef ||
      JSON.parse(localStorage.getItem("currentLoginUser"))?.universityRef
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigationFilter = queryParams.get("filter") || "all";

  const [filterData, setFilterData] = useState(
    filterApplicationsByStatusForUniversity(applications, navigationFilter)
  );

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [filterContent, setFilterContent] = useState({
    startDate: "",
    endDate: "",
    firstName: "",
    lastName: "",
  });

  const [isOpen, setIsOpen] = useState(false);

  const clearSearch = () => {
    setFilterContent({
      startDate: "",
      endDate: "",
      firstName: "",
      lastName: "",
    });
    setFilterData(
      filterApplicationsByStatusForUniversity(applications, navigationFilter)
    );
  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (ranges) => {
    setFilterContent((prev) => ({
      ...prev,
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    }));
    onToggle();
  };

  useEffect(() => {
    setFilterData(
      searchData(
        filterContent,
        filterApplicationsByStatusForUniversity(applications, navigationFilter)
      )
    );
  }, [filterContent]);

  useEffect(() => {
    if (applications !== null) {
      setFilterData(
        filterApplicationsByStatusForUniversity(applications, navigationFilter)
      );
    }
  }, [applications]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"ALL APPLICATIONS"} />
        <div className="agency_agent_list_holder">
          {applications?.length ? (
            <>
              <div className="action_button_holder">
                <div className="date_range_keeper">
                  <input
                    className="applciation_input_field_style date_show_input"
                    value={
                      filterContent.endDate &&
                      filterContent.startDate &&
                      `${new Date(
                        filterContent.startDate
                      ).toLocaleDateString()} - ${new Date(
                        filterContent.endDate
                      ).toLocaleDateString()}`
                    }
                    placeholder="Select dates"
                    onClick={onToggle}
                  />
                  {isOpen && (
                    <div className="date_picker_holder">
                      <DateRange
                        ranges={[selectionRange]}
                        onChange={handleSelect}
                        showSelectionPreview={true}
                      />
                    </div>
                  )}
                </div>
                <input
                  type="text"
                  className="applciation_input_field_style"
                  placeholder="Search by first name"
                  name="firstName"
                  onChange={(e) => handleStateChange(e, setFilterContent)}
                  value={filterContent.firstName}
                />

                <input
                  type="text"
                  className="applciation_input_field_style"
                  placeholder="Search by Lastname"
                  name="lastName"
                  onChange={(e) => handleStateChange(e, setFilterContent)}
                  value={filterContent.lastName}
                />
                <button
                  className="add_university_navigation_btn"
                  onClick={clearSearch}
                >
                  <TfiBrushAlt className="clear_icon_style" />
                </button>
              </div>
              <TableComponent
                tableColumns={SuperAdminapplicationTableCols}
                fieldsToShowList={SuperAdminapplicationListFields}
                data={filterData}
                navigateTo="/university/application/view"
                emptyFor="universityApplicationList"
              />
            </>
          ) : (
            <EmptyComponent
              title="No Application found"
              subTitle="Didn't receive any application when university have application will be listed here."
            />
          )}
        </div>
      </UserNavbar>
    </div>
  );
};

export default AllApplicationForUniversityList;
