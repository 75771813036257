import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./SuperAdminAddAgent.css";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";

import CustomVericalInputField from "../../../reusables/FromComponent/CustomVericalInputField";
import CustomVerticalSelectField from "../../../reusables/FromComponent/CustomVerticalSelectField";

import Notification, { handleStateChange } from "../../../../utils/helpers";
import {
  API_HEADERS,
  addAgentStateDefaultStateObject,
  agencyRoleSelectOption,
  newCountryList,
} from "../../../../utils/constant";

const SuperAdminAddAgent = () => {
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [agencyData, setAgencyData] = useState({
    ...addAgentStateDefaultStateObject,
    addedBy:
      user?._id || JSON.parse(localStorage.getItem("currentLoginUser"))?._id,
    isVerified: true,
  });

  const handleAddAgency = () => {
    if (agencyData.password !== agencyData.confirmPassword) {
      return Notification.errorNotification(
        "Please confirm password again. Not matched!"
      );
    }
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/signup`,
        agencyData,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("New agency added Successfully");
        navigate("/superAdmin/agent");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"ADD RECRUITMENT AGENT"} />
        <div className="recruitment_agent_form">
          <div className="recruitment_agent_input_container">
            <div className="container">
              <div className="row">
                <CustomVericalInputField
                  name="agencyName"
                  label="R.Agency Name"
                  value={agencyData?.agencyName}
                  onChange={(e) => handleStateChange(e, setAgencyData)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="agencyRegistrationNumber"
                  label="Registration Number"
                  value={agencyData?.agencyRegistrationNumber}
                  onChange={(e) => handleStateChange(e, setAgencyData)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="agencyTaxNumber"
                  label="Tax Number"
                  value={agencyData?.agencyTaxNumber}
                  onChange={(e) => handleStateChange(e, setAgencyData)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="agencyCommission"
                  label="Agreed Commission (%)"
                  type="number"
                  value={agencyData?.agencyCommission}
                  onChange={(e) => handleStateChange(e, setAgencyData)}
                  colClass="col-12 col-lg-6"
                />
              </div>
            </div>
          </div>
          <div className="recruitment_agent_input_container">
            <div className="container">
              <div className="row">
                <CustomVerticalSelectField
                  label="R.Agency Country"
                  name="agencyCountry"
                  value={agencyData?.agencyCountry}
                  onChange={(e) => handleStateChange(e, setAgencyData)}
                  options={newCountryList}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="agencyCity"
                  label="R.Agency City"
                  value={agencyData?.agencyCity}
                  onChange={(e) => handleStateChange(e, setAgencyData)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="agencyAddress"
                  label="R.Agency Address"
                  value={agencyData?.agencyAddress}
                  onChange={(e) => handleStateChange(e, setAgencyData)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="postcode"
                  label="Postcode"
                  value={agencyData?.postcode}
                  onChange={(e) => handleStateChange(e, setAgencyData)}
                  colClass="col-12 col-lg-6"
                />
              </div>
            </div>
          </div>

          <div className="recruitment_agent_input_container">
            <div className="container">
              <div className="row">
                <CustomVericalInputField
                  name="firstName"
                  label="First Name"
                  value={agencyData?.firstName}
                  onChange={(e) => handleStateChange(e, setAgencyData)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="lastName"
                  label="Last Name"
                  value={agencyData?.lastName}
                  onChange={(e) => handleStateChange(e, setAgencyData)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="username"
                  label="User Name"
                  value={agencyData?.username}
                  onChange={(e) => handleStateChange(e, setAgencyData)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVerticalSelectField
                  name="role"
                  label="User type"
                  value={agencyData?.role}
                  onChange={(e) => handleStateChange(e, setAgencyData)}
                  options={agencyRoleSelectOption}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="email"
                  label="User Email"
                  type="email"
                  value={agencyData?.email}
                  onChange={(e) => handleStateChange(e, setAgencyData)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="password"
                  label="Password"
                  type="password"
                  value={agencyData?.password}
                  onChange={(e) => handleStateChange(e, setAgencyData)}
                  colClass="col-12 col-lg-6"
                />

                <CustomVericalInputField
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  value={agencyData?.confirmPassword}
                  onChange={(e) => handleStateChange(e, setAgencyData)}
                  colClass="col-12 col-lg-6"
                />
              </div>
            </div>
          </div>

          <div className="add_agent_btn_holder">
            <button className="add_agent_btn_style" onClick={handleAddAgency}>
              Add Agency
            </button>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default SuperAdminAddAgent;
