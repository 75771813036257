import React from "react";

const CustomInputField = ({
  label,
  name,
  onChange,
  type = "text",
  value = "",
  defaultValue,
  ...rest
}) => {
  return (
    <div className="password_label_field_holder">
      <label className="password_label_style">{label}</label>
      <input
        type={type}
        className="password_input_field_style"
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
};

export default CustomInputField;
