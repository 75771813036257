import React, { useState } from "react";
import Notification, {
  handleFileInputInState,
  handleStateChange,
  removeEmptyProperties,
  replaceLocalhostUrl,
} from "../../../../../utils/helpers";
import axios from "axios";
import { API_HEADERS } from "../../../../../utils/constant";

const OurCulture = ({ ourCultureImage, ourCultureText }) => {
  const [ourCultureData, setOurCultureData] = useState({
    ourCultureText: ourCultureText || "",
    ourCultureImage: "",
  });

  const handleUpdateOurCulture = () => {
    const newData = removeEmptyProperties(ourCultureData);
    const ourCultureDataToSend = new FormData();

    Object.keys(newData).forEach((key) => {
      ourCultureDataToSend.append(key, newData[key]);
    });

    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/aboutUsInfo/updateAboutUsContent`,
        ourCultureDataToSend,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification(
          "About Us Content Updated Successfully"
        );
      })
      .catch((err) => {
        Notification.errorNotification(err?.response?.data?.message);
      });
  };

  return (
    <div className="our_story_main">
      <p className="curious_heading">OUR CULTURE</p>
      <div className="story_holder">
        <label className="story_image_holder" htmlFor="our_culture_image_input">
          {ourCultureData.ourCultureImage !== "" ? (
            <img
              src={URL.createObjectURL(ourCultureData?.ourCultureImage)}
              className="image_style"
            />
          ) : ourCultureImage ? (
            <img
              src={replaceLocalhostUrl(ourCultureImage)}
              className="image_style"
            />
          ) : (
            <img src="/images/aboutUs/our-story.jpg" className="image_style" />
          )}
        </label>
        <input
          id="our_culture_image_input"
          type="file"
          className="image_input_hiddden_style"
          name="ourCultureImage"
          onChange={(e) => handleFileInputInState(e, setOurCultureData)}
        />
        <div className="story_text_holder">
          <textarea
            className="strory_text_field"
            placeholder="Please enter our culture text"
            rows={18}
            value={ourCultureData.ourCultureText}
            name="ourCultureText"
            onChange={(e) => handleStateChange(e, setOurCultureData)}
          />
        </div>
      </div>
      <div className="update_Button_holder">
        <button
          className="add_agent_btn_style"
          onClick={handleUpdateOurCulture}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default OurCulture;
