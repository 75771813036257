import { useEffect, useState } from "react";
import axios from "axios";
import { API_HEADERS } from "../utils/constant";

export const useGetStudentById = (studentId) => {
  const [isLoading, setIsLoading] = useState(false);
  const [studentData, setStudentData] = useState(null);
  const [error, setError] = useState();

  useEffect(() => {
    if (studentId) {
      setIsLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/student/getStudentById/${studentId}`,
          API_HEADERS()
        )
        .then((response) => {
          setStudentData(response.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, [studentId]);

  return [studentData, isLoading, error];
};
