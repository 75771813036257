import React from "react";
import { replaceLocalhostUrl } from "../../../../utils/helpers";

const OurCulture = ({ ourCultureImage, ourCultureText }) => {
  return (
    <div className="our_culture_main">
      <p className="curious_heading">OUR CULTURE</p>

      <div className="our_culture_content">
        <div className="our_culture_image_holder">
          <img
            src={
              replaceLocalhostUrl(ourCultureImage) ||
              "/images/aboutUs/our-culture.jpg"
            }
            className="our_culture_image_style"
          />
        </div>
        <div className="our_culture_text_holder">
          <p className="our_culture_text">{ourCultureText}</p>
        </div>
      </div>
    </div>
  );
};

export default OurCulture;
