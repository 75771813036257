import React, { useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

import Notification, {
  generateRandomString,
  getStringListFromObjectList,
  removeElementFromArray,
  removeEmptyStrings,
} from "../../../../utils/helpers";
import { countries } from "../../../../utils/constant";

const SignUpStep3 = ({ handleChangeTab, handleSaveFields }) => {
  //   const [studentsPerYear, setstudentsPerYear] = useState("");
  const [serviceInput, setServiceInput] = useState([
    {
      id: generateRandomString(5),
      country: "",
    },
  ]);

  const handleNext = () => {
    const countryList = getStringListFromObjectList(serviceInput);
    if (
      removeEmptyStrings(countryList).length <= 0 ||
      removeEmptyStrings(countryList).length > 5
    ) {
      Notification.errorNotification(
        "Please enter at least 1 and max 5 country"
      );
    } else {
      //   handleSaveFields({ sourceCountries: countryList, studentsPerYear });
      handleSaveFields({ sourceCountry: serviceInput });
      handleChangeTab("tab4");
    }
  };

  const handleValueEntering = (id, country) => {
    const updatedServiceInput = serviceInput.map((item) => {
      if (item.id === id) {
        return { ...item, country };
      }
      return item;
    });
    setServiceInput(updatedServiceInput);
  };
  return (
    <div className="sign_in_step_3_form_holder">
      <div className="step_3_holder">
        <div className="data_container">
          <div className="content_style add_button_style">
            What are your main source countries? At least 1 but no more than 5
            <AiOutlinePlus
              className="icon_style"
              onClick={() =>
                setServiceInput([
                  ...serviceInput,
                  { id: generateRandomString(5), country: "" },
                ])
              }
            />
          </div>
        </div>
        <div className="option_holder_container">
          {serviceInput.map((field) => (
            <div className="option_holder">
              <div className="option_text_holder">
                <select
                  className="option_text_style"
                  value={field.country}
                  placeholder={"Enter country"}
                  id={field.id}
                  onChange={(e) =>
                    handleValueEntering(field.id, e.target.value)
                  }
                  defaultValue={countries[0].name}
                >
                  {countries.map((country) => (
                    <option>{country.name}</option>
                  ))}
                </select>
                <div className="minus_btn_holder">
                  <AiOutlineMinus
                    className="icon_style"
                    onClick={() => {
                      setServiceInput(
                        removeElementFromArray(serviceInput, field.id)
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="data_container_for_input_field">
          <input
            className="content_style_input_field"
            placeholder="Average total number of Students per year?"
            onChange={(e) => setstudentsPerYear(e.target.value)}
          />
        </div> */}
        <div className="next_button_holder" type="submit">
          <button className="next_button" type="button" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUpStep3;
