import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import _ from "lodash";
import "./Pagination.css";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";

export default function Pagination({ setpaginatedData, data }) {
  const [currentPage, setcurrentPage] = useState(1);
  const [pageSize, setpageSize] = useState(6);

  var pageCount = data.length > 0 ? Math.ceil(data?.length / pageSize) : 0;
  const pages = pageCount ? _.range(1, pageCount + 1) : 0;
  useEffect(() => {
    setpaginatedData(
      data.slice(
        (currentPage - 1) * pageSize,
        (currentPage - 1) * pageSize + pageSize
      )
    );
  }, [data, pageSize]);

  const paginate = (pageNo) => {
    setcurrentPage(pageNo);
    const statrPage = (pageNo - 1) * pageSize;
    setpaginatedData(data.slice(statrPage, statrPage + pageSize));
  };

  const handleNext = () => {
    if (currentPage < pageCount) {
      paginate(currentPage + 1);
    } else {
      paginate(1);
    }
  };
  const handleBack = () => {
    if (currentPage <= 1) {
      paginate(pageCount);
    } else {
      paginate(currentPage - 1);
    }
  };

  return (
    <div>
      <nav>
        {/* <ul className="pagination"> */}
        <div className="pagination-container">
          <div className="current-page-number-box">
            <FaChevronCircleLeft onClick={handleBack} />
            <p>
              {currentPage} of {pageCount}
            </p>
            <FaChevronCircleRight onClick={handleNext} />
          </div>
          <div className="page-number-box">
            <label className="page-number-input-label">Page number : </label>

            <Form.Select
              onChange={(e) => paginate(Number(e.target.value))}
              className="page-size-dropdown"
              value={currentPage}
            >
              {pages
                ? pages?.map((page, index) => {
                    return (
                      <option value={page} key={index}>
                        {page}
                      </option>
                    );
                  })
                : null}
            </Form.Select>
          </div>

          <div className="page-size-box">
            <label>Page Size : </label>
            <Form.Select
              onChange={(e) => setpageSize(Number(e.target.value))}
              className="page-size-dropdown"
            >
              <option value="6">6</option>
              <option value="12">12</option>
              <option value="18">18</option>
            </Form.Select>
          </div>
        </div>
      </nav>
    </div>
  );
}
