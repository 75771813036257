import React from "react";
import { useNavigate } from "react-router-dom";

import CustomButton from "../../../reusables/FromComponent/CustomButton";

const HowDoesItWork = ({ howDoesItWork, userType }) => {
  const navigate = useNavigate();

  return (
    <div className="how_does_it_work_container">
      <div className="block_title">
        <p className="title_text_style">HOW DOES IT WORK?</p>
        <div className="short_border"></div>
      </div>
      <div className="stepper_container">
        {howDoesItWork.map((item, index) => (
          <>
            <div
              className={
                (index + 1) % 2 === 0 ? "steps_style_reverse" : "steps_style "
              }
            >
              <div
                className={
                  (index + 1) % 2 === 0
                    ? "step_container_reverse"
                    : "step_container"
                }
              >
                {item.step}
              </div>
              <div className="circle_container">
                <div className="green_circle"></div>
              </div>
              <div className="description_container">{item.description}</div>
            </div>
            <div className="horizontal_line"></div>
          </>
        ))}
      </div>
      <div className="recruiter_home_page_btn_holder">
        <CustomButton
          buttonLabel={userType === "recruiter" ? "Join Us" : "Partner with us"}
          onClick={() =>
            navigate(
              userType === "recruiter"
                ? "/agency/signup"
                : "/university/universityInquiry"
            )
          }
        />
      </div>
    </div>
  );
};

export default HowDoesItWork;
