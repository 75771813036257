import React from "react";
import { Navigate } from "react-router-dom";

const UniversityPrivateRoute = ({ children }) => {
  const currentLoginUser = JSON.parse(localStorage.getItem("currentLoginUser"));
  const allowedRoles = [
    "UniversitySuperAdmin",
    "UniversitySubSuperAdmin",
    "UniversitySubAdmin",
  ];

  if (currentLoginUser && allowedRoles.includes(currentLoginUser.role)) {
    return <div>{children}</div>;
  } else {
    return <Navigate to={"/"} />;
  }
};

export default UniversityPrivateRoute;
