import React, { useContext } from "react";
import { useNavigate } from "react-router";

import { useGetUsersOfUniversityById } from "../../../../hooks/useGetUsersOfUniversityById";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import TableComponent from "../../../reusables/TableComponent/TableComponent";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import {
  agentListtableCols,
  agenttableFields,
} from "../../../../utils/constant";

const UniversityUserList = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [userList, isLoading, error] = useGetUsersOfUniversityById(
    user?.universityRef ||
      JSON.parse(localStorage.getItem("currentLoginUser"))?.universityRef
  );

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;
  return (
    <div>
      <UserNavbar>
        <PageTitle title={"USER LIST"} />
        <div className="agency_agent_list_holder">
          <div className="add_agent_btn_holder">
            <button
              className="add_agent_btn_style"
              onClick={() => navigate(`/university/user/addUser`)}
            >
              Add University User
            </button>
          </div>

          <TableComponent
            tableColumns={agentListtableCols}
            fieldsToShowList={agenttableFields}
            data={userList}
            navigateTo="/university/user/view"
            emptyFor="universityUserList"
          />
        </div>
      </UserNavbar>
    </div>
  );
};

export default UniversityUserList;
