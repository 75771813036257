import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import jsPDF from "jspdf";
import { toPng } from "html-to-image";
import axios from "axios";
import { BsCheckSquareFill, BsXSquareFill } from "react-icons/bs";
import {
  AiFillCamera,
  AiOutlineCheckCircle,
  AiOutlineFolderAdd,
} from "react-icons/ai";

import { useGetApplicationById } from "../../../../hooks/useGetApplicationById";

import { AuthContext } from "../../../reusables/AuthProvider/AuthProvider";
import UserNavbar from "../../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../../reusables/PageTitle/PageTitle";
import Loader from "../../../reusables/Loader/Loader";
import ErrorPage from "../../../reusables/ErrorPage/ErrorPage";

import CustomVericalInputField from "../../../reusables/FromComponent/CustomVericalInputField";
import CustomVerticalSelectField from "../../../reusables/FromComponent/CustomVerticalSelectField";

import Notification, {
  generateEducationRequirementScoreSelect,
  handleFileInputInState,
  handleStateChange,
  removeElementFromArray,
  removeEmptyProperties,
  replaceLocalhostUrl,
} from "../../../../utils/helpers";
import {
  API_HEADERS,
  applicationStatusSelector,
  genderSelect,
  languages,
  newCountryList,
  newhighestEducationList,
} from "../../../../utils/constant";

const StudentViewApplication = () => {
  const { applicationId } = useParams();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [application, isLoading, error] = useGetApplicationById(applicationId);

  const [applicationToUpdate, setApplicationToUpdate] = useState({});

  const targetRef = useRef();

  const downloadImageAsPdf = async () => {
    const divElement = document.getElementById("capture_area");

    toPng(divElement, { backgroundColor: "#ffffff" })
      .then((dataUrl) => {
        const pdf = new jsPDF();
        const img = new Image();

        img.onload = () => {
          const imgWidth = pdf.internal.pageSize.getWidth() - 30;
          const imgHeight = (img.height * imgWidth) / img.width - 40;

          const x = (pdf.internal.pageSize.getWidth() - imgWidth) / 2;
          const y = (pdf.internal.pageSize.getHeight() - imgHeight) / 2;

          pdf.addImage(img, "PNG", x, y, imgWidth, imgHeight);
          pdf.save(`${applicationId} - Application`);
        };

        img.src = dataUrl;
      })
      .catch((error) => {
        console.error("Error capturing div as PDF:", error);
      });
  };

  const handleApplicationUpdate = () => {
    const newdata = {
      applicationId,
      ...removeEmptyProperties(applicationToUpdate),
    };

    const applicationFormData = new FormData();

    Object.keys(newdata).forEach((key) => {
      if (key === "other" && newdata["other"].length > 0) {
        newdata.other.map((doc) => {
          applicationFormData.append("other", doc);
        });
      }
      applicationFormData.append(key, newdata[key]);
    });
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/application/updateApplicationById`,
        applicationFormData,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification("Application Updated Successfully");
        navigate("/student/application");
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  useEffect(() => {
    setApplicationToUpdate({
      userProfilePicture: "",
      academicInstitution: application?.academicInstitution || "",
      address: application?.address || "",
      averageGrade: application?.averageGrade || "",
      cityOfResidence: application?.cityOfResidence || "",
      countryOfBirth: application?.countryOfBirth || "",
      countryOfEducation: application?.countryOfEducation || "",
      countryOfResidence: application?.countryOfResidence || "",
      firstLanguage: application?.firstLanguage || "",
      firstName: application?.firstName || "",
      gender: application?.gender || "",
      highestEducationLevel: application?.highestEducationLevel || "",
      languageOfInstruction: application?.languageOfInstruction || "",
      lastName: application?.lastName || "",
      nationality: application?.nationality || "",
      placeOfBirth: application?.placeOfBirth || "",
      postcode: application?.postcode || "",
      secondCitizenship: application?.secondCitizenship || "",
      other: [],
    });
  }, [user, application]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;

  return (
    <div>
      <UserNavbar>
        <PageTitle title={"VIEW APPLICATION"} />
        <br />
        <div className="create_application_component_container" ref={targetRef}>
          {/* {application?.applicationFormStatus !== "open" ? (
            <div>
              {application?.applicationFormStatus === "accepted" ? (
                <p className="accepted_status_text">
                  Congratulations, <br />
                  University has made a decision to admit you on this course.
                  Please check your registered email for further payment related
                  steps.
                </p>
              ) : application?.applicationFormStatus === "rejected" ? (
                <p className="rejected_status_text">
                  Rejected :{" "}
                  <span className="comment_text_style">
                    {application?.comment}
                  </span>
                </p>
              ) : null}
            </div>
          ) : (
            <div>
              {application?.superAdminStatus === "PendingReview" ? (
                <p className="pending_status_text">
                  Please wait your application is in review queue.
                </p>
              ) : application?.superAdminStatus === "Approved" ? (
                <p className="accepted_status_text">
                  Application is approved by Super admin transferred to the
                  University. University decision will be shown here.
                </p>
              ) : (
                <p className="requirement_status_text">
                  Todo: {application?.superAdminStatus}
                </p>
              )}
            </div>
          )} */}

          {application?.superAdminStatus === "acceptedByUniversity" ? (
            <p className="accepted_status_text">
              Application approved by university
            </p>
          ) : application?.superAdminStatus === "rejectedByUniversity" ? (
            <p className="rejected_status_text">
              Application rejected by university
              <p className="comment_text_style">{application?.comment}</p>
            </p>
          ) : (
            <p className="pending_status_text">
              {applicationStatusSelector[application?.superAdminStatus]}
            </p>
          )}

          <div id="capture_area" style={{ width: "100%", height: "100%" }}>
            <div className="application_detail_component">
              <div className="profile_photo_holder">
                <label htmlFor="profile_photo_upload">
                  {applicationToUpdate &&
                  applicationToUpdate?.userProfilePicture ? (
                    <img
                      src={URL.createObjectURL(
                        applicationToUpdate?.userProfilePicture
                      )}
                      className="profile_image_style"
                      alt="applicant_image"
                    />
                  ) : application?.userProfilePicture ? (
                    <img
                      src={replaceLocalhostUrl(application?.userProfilePicture)}
                      className="profile_image_style"
                      alt="applicant_image"
                    />
                  ) : (
                    <img
                      src="/images/superAdmin/upload_placeholder.png"
                      className="profile_image_style"
                      alt="applicant_image"
                    />
                  )}
                  <AiFillCamera className="upload_profile_photo_btn" />
                </label>
                <input
                  name="userProfilePicture"
                  type="file"
                  className="upload_document_actual_field"
                  id="profile_photo_upload"
                  onChange={(e) =>
                    handleFileInputInState(e, setApplicationToUpdate)
                  }
                />
              </div>
              <div className="filed_container">
                <div className="field_set_1">
                  <CustomVericalInputField
                    label="Application First Name"
                    name="firstName"
                    value={applicationToUpdate?.firstName}
                    onChange={(e) =>
                      handleStateChange(e, setApplicationToUpdate)
                    }
                  />

                  <CustomVericalInputField
                    name="applicationNo"
                    label="Application No"
                    type="number"
                    value={application?.applicationNo}
                    readOnly
                  />
                </div>
                <div className="field_set_2">
                  <CustomVericalInputField
                    name="lastName"
                    label="Application Last Name"
                    value={applicationToUpdate?.lastName}
                    onChange={(e) =>
                      handleStateChange(e, setApplicationToUpdate)
                    }
                  />

                  <CustomVericalInputField
                    name="applicationFormStatus"
                    label="Application Status"
                    value={application?.applicationFormStatus}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="agency_detail_container_add_application row">
              <CustomVericalInputField
                name="universityId"
                label="University Name"
                value={application?.universityId?.universityName}
                colClass="col-12 col-md-4"
                readOnly
              />

              <CustomVericalInputField
                name="applicationDate"
                label="Application Date"
                type="date"
                value={application?.applicationDate}
                colClass="col-12 col-md-4"
                readOnly
              />

              <CustomVericalInputField
                name="courseId"
                label="Course Name"
                value={application?.courseId?.courseName}
                colClass="col-12 col-md-4"
                readOnly
              />

              <CustomVericalInputField
                name="courseLevel"
                label="Course Level"
                value={
                  application?.courseId
                    ? `${application.courseId.courseLevel
                        .charAt(0)
                        .toUpperCase()}${application.courseId.courseLevel.slice(
                        1
                      )}`
                    : null
                }
                colClass="col-12 col-md-4"
                readOnly
              />

              <CustomVericalInputField
                name="courseFee"
                label="Course Fee"
                type="number"
                value={application?.courseId?.courseFee}
                colClass="col-12 col-md-4"
                readOnly
              />

              <CustomVericalInputField
                name="placeOfBirth"
                label="Place of Birth"
                value={applicationToUpdate?.placeOfBirth}
                onChange={(e) => handleStateChange(e, setApplicationToUpdate)}
                colClass="col-12 col-md-4"
              />

              <CustomVerticalSelectField
                name="countryOfBirth"
                label="Country of Birth"
                value={applicationToUpdate?.countryOfBirth}
                onChange={(e) => handleStateChange(e, setApplicationToUpdate)}
                options={newCountryList}
                colClass="col-12 col-md-4"
              />

              {/* <CustomVericalInputField
                name="nationality"
                label="Nationality"
                value={applicationToUpdate?.nationality}
                onChange={(e) => handleStateChange(e, setApplicationToUpdate)}
                colClass="col-12 col-md-4"
              /> */}

              <CustomVerticalSelectField
                label="Nationality"
                name="nationality"
                onChange={(e) => handleStateChange(e, setApplicationToUpdate)}
                value={applicationToUpdate?.nationality}
                options={newCountryList}
                colClass="col-12 col-md-4"
              />

              {/* <CustomVericalInputField
                name="secondCitizenship"
                label="Second Citizenship"
                value={applicationToUpdate?.secondCitizenship}
                onChange={(e) => handleStateChange(e, setApplicationToUpdate)}
                colClass="col-12 col-md-4"
              /> */}

              <CustomVerticalSelectField
                label="Second Citizenship"
                name="secondCitizenship"
                onChange={(e) => handleStateChange(e, setApplicationToUpdate)}
                value={applicationToUpdate?.secondCitizenship}
                options={newCountryList}
                colClass="col-12 col-md-4"
              />

              <CustomVerticalSelectField
                name="countryOfResidence"
                label="Country of Residence"
                value={applicationToUpdate?.countryOfResidence}
                onChange={(e) => handleStateChange(e, setApplicationToUpdate)}
                options={newCountryList}
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="address"
                label="Address"
                value={applicationToUpdate?.address}
                onChange={(e) => handleStateChange(e, setApplicationToUpdate)}
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="postcode"
                label="Postcode"
                value={applicationToUpdate?.postcode}
                onChange={(e) => handleStateChange(e, setApplicationToUpdate)}
                colClass="col-12 col-md-4"
              />

              <CustomVericalInputField
                name="cityOfResidence"
                label="City of Residence"
                value={applicationToUpdate?.cityOfResidence}
                onChange={(e) => handleStateChange(e, setApplicationToUpdate)}
                colClass="col-12 col-md-4"
              />

              <CustomVerticalSelectField
                name="gender"
                label="Gender"
                value={applicationToUpdate?.gender}
                onChange={(e) => handleStateChange(e, setApplicationToUpdate)}
                options={genderSelect}
                colClass="col-12 col-md-4"
              />

              <CustomVerticalSelectField
                name="firstLanguage"
                label="First Language"
                value={applicationToUpdate?.firstLanguage}
                onChange={(e) => handleStateChange(e, setApplicationToUpdate)}
                options={languages}
                colClass="col-12 col-md-4"
              />
            </div>
            <div className="education_detail_container row">
              <CustomVerticalSelectField
                label="Highest Education Level"
                name="highestEducationLevel"
                value={applicationToUpdate?.highestEducationLevel}
                onChange={(e) => handleStateChange(e, setApplicationToUpdate)}
                options={newhighestEducationList}
                colClass="col-12 col-md-4"
                toolTip="We use this information to match you to the most suitable courses"
              />

              <CustomVericalInputField
                name="academicInstitution"
                label="Name of Academic Institution"
                value={applicationToUpdate?.academicInstitution}
                onChange={(e) => handleStateChange(e, setApplicationToUpdate)}
                colClass="col-12 col-md-4"
              />

              <CustomVerticalSelectField
                label="Language of Instruction"
                name="languageOfInstruction"
                value={applicationToUpdate?.languageOfInstruction}
                onChange={(e) => handleStateChange(e, setApplicationToUpdate)}
                options={languages}
                colClass="col-12 col-md-4"
              />

              <CustomVerticalSelectField
                label="Country of Education"
                name="countryOfEducation"
                value={applicationToUpdate?.countryOfEducation}
                onChange={(e) => handleStateChange(e, setApplicationToUpdate)}
                options={newCountryList}
                colClass="col-12 col-md-4"
              />

              {/* <CustomVericalInputField
                name="averageGrade"
                label="Average Grade"
                value={applicationToUpdate?.averageGrade}
                onChange={(e) => handleStateChange(e, setApplicationToUpdate)}
                colClass="col-12 col-md-4"
              /> */}

              <CustomVerticalSelectField
                name="averageGrade"
                label="Average grade (%)"
                informativeLink={`/blog/view/650ceecf2cf8f95dc218238f`}
                value={applicationToUpdate?.averageGrade}
                onChange={(e) => handleStateChange(e, setApplicationToUpdate)}
                colClass="col-12 col-md-4"
                options={generateEducationRequirementScoreSelect(100)}
              />
            </div>
            <div className="application_detail_component">
              <div className="row">
                {application?.courseId?.basicDoumentRequirement?.map(
                  (document) =>
                    document.type !== "userAdded" && (
                      <div className="col-12 col-lg-4 col-md-4 col-sm-6 single_document_check_holder">
                        {application[document.id] !== "" &&
                        application[document.id] !== undefined ? (
                          <BsCheckSquareFill className="document_icon_style_check" />
                        ) : (
                          <BsXSquareFill className="document_icon_style_uncheck" />
                        )}

                        <a
                          className="document_heading_txt_style"
                          href={replaceLocalhostUrl(application[document.id])}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {document?.documentName}
                        </a>
                      </div>
                    )
                )}
                {application?.other.map(
                  (docLink, index) =>
                    docLink !== "" && (
                      <div className="col-12 col-lg-4 col-md-4 col-sm-6 single_document_check_holder">
                        <BsCheckSquareFill className="document_icon_style_check" />
                        <a
                          href={replaceLocalhostUrl(docLink)}
                          target="_blank"
                          rel="noreferrer"
                          className="document_heading_txt_style"
                        >
                          Document - {index + 1}
                        </a>
                      </div>
                    )
                )}
              </div>
            </div>

            <div className="education_detail_container row remove_justify_center gy-4">
              {application &&
                application?.courseId?.basicDoumentRequirement?.map((doc) => (
                  <>
                    {doc?.required && doc?.required !== "false" && (
                      <div className="col-12 col-lg-4 col-md-6 col-sm-6">
                        <label htmlFor={doc.id}>
                          <div className="upload_doc_holder">
                            <div
                              className={
                                ((application[doc.id] ||
                                  applicationToUpdate[doc.id]) &&
                                  doc.id !== "other") ||
                                (application["other"]?.length > 0 &&
                                  doc.id === "other")
                                  ? `upload_other_docs_container_done`
                                  : `upload_other_docs_container`
                              }
                            >
                              {application[doc.id] ||
                              applicationToUpdate[doc.id] ? (
                                <AiOutlineCheckCircle className="check_btn_upload_style" />
                              ) : null}
                              <AiOutlineFolderAdd className="file_input_icon" />
                              <p className="doc_name_text_style">{`Upload ${doc.documentName} here`}</p>
                            </div>
                            <p className="">
                              {application[doc.id] ? (
                                <a
                                  href={application[doc.id]}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {"Open Uploaded Doc"}
                                </a>
                              ) : applicationToUpdate[doc.id] ? (
                                "New Doc Uploaded"
                              ) : null}
                            </p>
                          </div>
                        </label>
                        <input
                          name={doc.id}
                          type="file"
                          className="upload_document_actual_field"
                          id={doc.id}
                          onChange={(e) => {
                            if (doc.type === "userAdded") {
                              setApplicationToUpdate((prev) => ({
                                ...prev,
                                other: [
                                  ...removeElementFromArray(
                                    applicationToUpdate.other,
                                    ""
                                  ),
                                  e.target.files[0],
                                ],
                              }));
                            } else {
                              handleFileInputInState(e, setApplicationToUpdate);
                            }
                          }}
                        />
                      </div>
                    )}
                  </>
                ))}
            </div>
          </div>
          <div className="download_pdf_button_holder">
            <button
              className="download_pdf_btn_style"
              onClick={downloadImageAsPdf}
            >
              Download As PDF
            </button>
            <button
              className="download_pdf_btn_style margin_left_style"
              onClick={handleApplicationUpdate}
            >
              Update Application
            </button>
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default StudentViewApplication;
