import React from "react";

const CustomVericalInputField = ({
  label,
  name,
  defaultValue,
  onChange,
  value = "",
  colClass = "",
  type = "text",
  pattern = "",
  ...rest
}) => {
  return (
    <div className={colClass}>
      <div className="agency_field_holder">
        <label className="agency_label_style">{label}</label>
        <input
          type={type}
          className="agency_input_field_style"
          name={name}
          defaultValue={defaultValue}
          onChange={onChange}
          value={value}
          pattern={pattern}
          {...rest}
        />
      </div>
    </div>
  );
};

export default CustomVericalInputField;
