import React from "react";
import { Route, Routes } from "react-router-dom";
import SuperAdminPrivateRoute from "./PrivateRoutes/SuperAdminPrivateRoute";

import PageNotFound from "../reusables/PageNotFound/PageNotFound";

import SuperAdminDashboard from "../SuperAdmin/SuperAdminDashboard";

import SuperAdminProfile from "../SuperAdmin/SuperAdminProfile/SuperAdminProfile";
import SuperAdminProfileView from "../SuperAdmin/SuperAdminProfile/SuperAdminProfileView/SuperAdminProfileView";
import AddSubSuperAdmin from "../SuperAdmin/SuperAdminProfile/AddSubSuperAdmin/AddSubSuperAdmin";
import SuperAdminProfileChangePassword from "../SuperAdmin/SuperAdminProfile/SuperAdminProfileChangePassword/SuperAdminProfileChangePassword";

import SuperAdminAddUniversity from "../SuperAdmin/UniversityControl/SuperAdminAddUniversity/SuperAdminAddUniversity";
import SuperAdminUniversityList from "../SuperAdmin/UniversityControl/SuperAdminUniversityList/SuperAdminUniversityList";
import SuperAdminViewUniversity from "../SuperAdmin/UniversityControl/SuperAdminViewUniversity/SuperAdminViewUniversity";

import SuperAdminAddCourse from "../SuperAdmin/SuperAdminCourseControl/SuperAdminAddCourse/SuperAdminAddCourse";
import SuperAdminCourseList from "../SuperAdmin/SuperAdminCourseControl/SuperAdminCourseList/SuperAdminCourseList";
import SuperAdminCourseDetailView from "../SuperAdmin/SuperAdminCourseControl/SuperAdminCourseDetailView/SuperAdminCourseDetailView";
import SuperAdminUpdateCourse from "../SuperAdmin/SuperAdminCourseControl/SuperAdminUpdateCourse/SuperAdminUpdateCourse";

import SuperAdminAddAgent from "../SuperAdmin/SuperAdminAgentControl/SuperAdminAddAgent/SuperAdminAddAgent";
import SuperAdminAgencyList from "../SuperAdmin/SuperAdminAgentControl/SuperAdminAgencyList/SuperAdminAgencyList";
import SuperAdminAgentView from "../SuperAdmin/SuperAdminAgentControl/SuperAdminAgentView/SuperAdminAgentView";

import SuperAdminAddStudent from "../SuperAdmin/SuperAdminStudentControl/SuperAdminAddStudent/SuperAdminAddStudent";
import SuperAdminStudentList from "../SuperAdmin/SuperAdminStudentControl/SuperAdminStudentList/SuperAdminStudentList";
import SuperAdminStudentView from "../SuperAdmin/SuperAdminStudentControl/SuperAdminStudentView/SuperAdminStudentView";

import SuperAdminAddApplication from "../SuperAdmin/SuperAdminApplicationControl/SuperAdminAddApplication/SuperAdminAddApplication";
import { SuperAdminApplicationList } from "../SuperAdmin/SuperAdminApplicationControl/SuperAdminApplicationList/SuperAdminApplicationList";
import SuperAdminApplicationDetailView from "../SuperAdmin/SuperAdminApplicationControl/SuperAdminApplicationDetailView/SuperAdminApplicationDetailView";

import ContentUpdateController from "../SuperAdmin/ContentUpdateController/ContentUpdateController";

import AddCountryForm from "../SuperAdmin/HomePageInfoControl/CountryControl/AddCountryForm/AddCountryForm";
import UpdateCountryForm from "../SuperAdmin/HomePageInfoControl/CountryControl/UpdateCountryForm/UpdateCountryForm";

import SuperAdminAddBlog from "../SuperAdmin/BlogControl/SuperAdminAddBlog/SuperAdminAddBlog";
import SuperAdminBlogList from "../SuperAdmin/BlogControl/SuperAdminBlogList/SuperAdminBlogList";
import SuperAdminBlogPreview from "../SuperAdmin/BlogControl/SuperAdminBlogPreview/SuperAdminBlogPreview";
import SuperAdminUpdateBlog from "../SuperAdmin/BlogControl/SuperAdminUpdateBlog/SuperAdminUpdateBlog";

import SuperAdminViewSubscriber from "../SuperAdmin/SubscriberControl/SuperAdminViewSubscriber/SuperAdminViewSubscriber";
import ComposeEmail from "../SuperAdmin/SubscriberControl/ComposeEmail/ComposeEmail";

import SuperAdminQualificationList from "../SuperAdmin/SuperAdminQualificationControl/SuperAdminQualificationList/SuperAdminQualificationList";
import SuperAdminAddQualification from "../SuperAdmin/SuperAdminQualificationControl/SuperAdminAddQualification/SuperAdminAddQualification";
import SuperAdminViewQualification from "../SuperAdmin/SuperAdminQualificationControl/SuperAdminViewQualification/SuperAdminViewQualification";
import MailManagement from "../SuperAdmin/MailController/MailManagement";

const SuperAdminRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/*" element={<PageNotFound />} />
        <Route
          path="/dashboard"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminDashboard />
            </SuperAdminPrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminProfile />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/profile/view/:adminId"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminProfileView />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/profile/addSubAdmin"
          element={
            <SuperAdminPrivateRoute>
              <AddSubSuperAdmin />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/profile/changePassword"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminProfileChangePassword />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/university"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminUniversityList />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/university/addUniversity"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminAddUniversity />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/university/view/:universityId"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminViewUniversity />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/course"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminCourseList />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/course/addcourse"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminAddCourse />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/course/view/:courseId"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminCourseDetailView />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/course/update/:courseId"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminUpdateCourse />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/agent"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminAgencyList />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/agent/addAgent"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminAddAgent />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/agent/view/:agencyId"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminAgentView />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/student"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminStudentList />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/student/addStudent"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminAddStudent />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/student/view/:studentId"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminStudentView />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/application/addApplication"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminAddApplication />
            </SuperAdminPrivateRoute>
          }
        />
        <Route
          path="/application"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminApplicationList />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/application/view/:applicationId"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminApplicationDetailView />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/homePageInfoForm"
          element={
            <SuperAdminPrivateRoute>
              <ContentUpdateController />
            </SuperAdminPrivateRoute>
          }
        />
        <Route
          path="/addCountryForm"
          element={
            <SuperAdminPrivateRoute>
              <AddCountryForm />
            </SuperAdminPrivateRoute>
          }
        />
        <Route
          path="/updateCountry/:countryId"
          element={
            <SuperAdminPrivateRoute>
              <UpdateCountryForm />
            </SuperAdminPrivateRoute>
          }
        />
        <Route
          path="blog/addBlog"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminAddBlog />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/blog"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminBlogList />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/blog/view/:blogId"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminBlogPreview />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/blog/update/:blogId"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminUpdateBlog />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/subscriber"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminViewSubscriber />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/composeEmail"
          element={
            <SuperAdminPrivateRoute>
              <ComposeEmail />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/qualification"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminQualificationList />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/qualification/addQualification"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminAddQualification />
            </SuperAdminPrivateRoute>
          }
        />

        <Route
          path="/qualification/view/:qualificationId"
          element={
            <SuperAdminPrivateRoute>
              <SuperAdminViewQualification />
            </SuperAdminPrivateRoute>
          }
        />
        <Route
          path="/mailManagement"
          element={
            <SuperAdminPrivateRoute>
              <MailManagement />
            </SuperAdminPrivateRoute>
          }
        />
      </Routes>
    </div>
  );
};

export default SuperAdminRoutes;
