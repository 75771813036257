import React, { useState } from "react";
import axios from "axios";
import "./AboutUsSectionGeneral.css";

import Notification, {
  handleFileInputInState,
  handleStateChange,
  removeEmptyProperties,
  replaceLocalhostUrl,
} from "../../../../../utils/helpers";
import { API_HEADERS } from "../../../../../utils/constant";

const Curious = ({ heading, subText, imageURL }) => {
  const [curiousData, setCuriousData] = useState({
    curiousHeading: heading || "",
    curiousSubtext: subText || "",
    curiousImage: "",
  });

  const handleUpdateCurious = () => {
    const newData = removeEmptyProperties(curiousData);
    const CuriousDataToSend = new FormData();

    Object.keys(newData).forEach((key) => {
      CuriousDataToSend.append(key, newData[key]);
    });

    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/aboutUsInfo/updateAboutUsContent`,
        CuriousDataToSend,
        API_HEADERS()
      )
      .then(() => {
        Notification.successNotification(
          "About Us Content Updated Successfuly"
        );
      })
      .catch((err) => {
        Notification.errorNotification(err.response.data.message);
      });
  };

  return (
    <div className="curious_main_block">
      <p className="curious_heading">CURIOUS</p>
      <div className="curious_detail_container">
        <div className="curious_text_area">
          <textarea
            className="about_us_curious_heading_input"
            rows={4}
            value={curiousData?.curiousHeading}
            name="curiousHeading"
            onChange={(e) => handleStateChange(e, setCuriousData)}
          />
          <textarea
            className="curious_sub_text_input"
            value={curiousData?.curiousSubtext}
            rows={3}
            name="curiousSubtext"
            onChange={(e) => handleStateChange(e, setCuriousData)}
          />
        </div>
        <label htmlFor="curious_image_input" className="curious_image_area">
          {curiousData?.curiousImage !== "" ? (
            <img
              src={URL.createObjectURL(curiousData.curiousImage)}
              className="curious_image_style"
            />
          ) : imageURL ? (
            <img
              src={replaceLocalhostUrl(imageURL)}
              className="curious_image_style"
            />
          ) : (
            <img
              src="/images/aboutUs/about-us-cover.jpg"
              className="curious_image_style"
            />
          )}
        </label>
        <input
          id="curious_image_input"
          type="file"
          className="image_input_hiddden_style"
          name="curiousImage"
          onChange={(e) => handleFileInputInState(e, setCuriousData)}
        />
      </div>
      <div className="update_Button_holder">
        <button className="add_agent_btn_style" onClick={handleUpdateCurious}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default Curious;
