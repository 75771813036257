import React from "react";
import { Route, Routes } from "react-router-dom";

import PageNotFound from "../reusables/PageNotFound/PageNotFound";

import { StudentPrivateRoute } from "./PrivateRoutes/StudentPrivateRoute";
import { StudentViewProfile } from "../Student/StudentViewProfile/StudentViewProfile";
import StudentDashboard from "../Student/StudentDashboard/StudentDashboard";

import StudentChangePassword from "../Student/StudentViewProfile/StudentChangePassword/StudentChangePassword";

import StudentSignUp from "../Student/StudentSignUp.jsx/StudentSignUp";

import Course from "../Student/CourseView/Course";
import CourseDetailView from "../Student/CourseView/CourseDetailView/CourseDetailView";

import StudentAddApplication from "../Student/StudentApplicationControl/StudentAddApplication/StudentAddApplication";
import StudentApplicationList from "../Student/StudentApplicationControl/StudentApplicationList/StudentApplicationList";
import StudentViewApplication from "../Student/StudentApplicationControl/StudentViewApplication/StudentViewApplication";

export const StudentRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/*" element={<PageNotFound />} />
        <Route path="/signup" element={<StudentSignUp />} />

        <Route
          path="/profile"
          element={
            <StudentPrivateRoute>
              <StudentViewProfile />
            </StudentPrivateRoute>
          }
        />

        <Route
          path="/dashboard"
          element={
            <StudentPrivateRoute>
              <StudentDashboard />
            </StudentPrivateRoute>
          }
        />

        <Route
          path="/profile/changePassword"
          element={
            <StudentPrivateRoute>
              <StudentChangePassword />
            </StudentPrivateRoute>
          }
        />
        <Route
          path="/course"
          element={
            <StudentPrivateRoute>
              <Course />
            </StudentPrivateRoute>
          }
        />
        <Route
          path="/courseDetails/:courseId"
          element={
            // <StudentPrivateRoute>
            <CourseDetailView />
            // </StudentPrivateRoute>
          }
        />
        <Route
          path="/application/addApplication"
          element={
            <StudentPrivateRoute>
              <StudentAddApplication />
            </StudentPrivateRoute>
          }
        />
        <Route
          path="/application"
          element={
            <StudentPrivateRoute>
              <StudentApplicationList />
            </StudentPrivateRoute>
          }
        />

        <Route
          path="/application/view/:applicationId"
          element={
            <StudentPrivateRoute>
              <StudentViewApplication />
            </StudentPrivateRoute>
          }
        />
      </Routes>
    </div>
  );
};
