import React from "react";
import { Navigate } from "react-router-dom";

export const StudentPrivateRoute = ({ children }) => {
  if (
    JSON.parse(localStorage.getItem("currentLoginUser"))?.role === "Student"
  ) {
    return <div>{children}</div>;
  } else {
    return <Navigate to={"/"}></Navigate>;
  }
};
