import React from "react";
import { Badge, Modal } from "react-bootstrap";
import {
  getEducationLevelBylevelNumber,
  replaceLocalhostUrl,
} from "../../../utils/helpers";
import "./SuggestionModal.css";

import CustomButton from "../FromComponent/CustomButton";

const SuggestionModal = ({
  show,
  handleClose,
  suggestionData,
  courseImage,
  courseName,
  courseDecepline,
  match,
  suggestion,
}) => {
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered size="md">
        <div className="suggestion_holder">
          <p className="modal_heading_style">Suggestions to Improve Profile</p>
          <p className="matching_text_style">Your Profile match {match} %</p>
          <div
            className="img_desc_holder"
            style={{
              backgroundImage: `linear-gradient(to right top, rgb(0 0 0 / 65%), rgb(0 0 0 / 65%), rgb(0 0 0 / 65%), rgb(0 0 0 / 65%), rgb(0 0 0 / 65%)), url(${replaceLocalhostUrl(
                courseImage
              )}`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              position: "relative",
            }}
          >
            <div className="course_detail_holder">
              <p className="course_name_style">{courseName}</p>
              <p className="course_decepline_style">{courseDecepline}</p>
            </div>
          </div>

          {Object.keys(suggestion).length ? (
            <div className="Suggestion_content">
              {suggestion.highestEducation && (
                <div className="graduation_improvement">
                  <p className="graduation_impr_text">
                    Minimum Required Qualification
                  </p>
                  <p className="badge_text_style">
                    {getEducationLevelBylevelNumber(
                      suggestion.highestEducation
                    )}
                  </p>
                  <Badge bg="success">
                    <p className="badge_text_style">
                      {suggestion.averageGrade} %
                    </p>
                  </Badge>
                  <hr />
                </div>
              )}
              {suggestion?.englishTestRequirement && (
                <div className="graduation_improvement">
                  <p className="graduation_impr_text">
                    English Test Requirements
                  </p>
                  {suggestion?.englishTestRequirement.map((test, index) => (
                    <div className="mb-5" key={index}>
                      <div className="d-flex justify-content-between mb-2">
                        <p className="badge_text_style">{test.englishTest}</p>
                        <Badge bg="success">
                          <p className="badge_text_style">{test.total}</p>
                        </Badge>
                      </div>
                      <div className="d-flex justify-content-between mb-2 text-center">
                        <div>
                          <p className="badge_text_style">Speaking</p>
                          <Badge bg="secondary">
                            <p className="badge_text_style">{test.speaking}</p>
                          </Badge>
                        </div>
                        <div>
                          <p className="badge_text_style">Listening</p>
                          <Badge bg="secondary">
                            <p className="badge_text_style">{test.listning}</p>
                          </Badge>
                        </div>
                        <div>
                          <p className="badge_text_style">Reading</p>
                          <Badge bg="secondary">
                            <p className="badge_text_style">{test.reading}</p>
                          </Badge>
                        </div>
                        <div>
                          <p className="badge_text_style">Writing</p>
                          <Badge bg="secondary">
                            <p className="badge_text_style">{test.writing}</p>
                          </Badge>
                        </div>
                      </div>
                    </div>
                  ))}
                  <hr />
                </div>
              )}
              {suggestion?.decipline && (
                <div className="graduation_improvement">
                  <p className="graduation_impr_text">Course Discipline</p>
                  <p className="badge_text_style">
                    {suggestion.decipline}{" "}
                    <span>{`(Your course desired discipline isn't a matched to the selected course discipline)`}</span>
                  </p>
                  <hr />
                </div>
              )}
              {suggestion?.courseLevel && (
                <div className="graduation_improvement">
                  <p className="graduation_impr_text">Course Level</p>
                  <p className="badge_text_style">
                    {getEducationLevelBylevelNumber(suggestion.courseLevel)}
                  </p>
                  <hr />
                </div>
              )}
              {suggestion?.courseCountry && (
                <div className="graduation_improvement">
                  <p className="graduation_impr_text">Course Country</p>
                  <p className="badge_text_style">
                    {suggestion.courseCountry}{" "}
                    <span>{`(Your desired country isn't a matched to the location of study of the selected course)`}</span>
                  </p>
                  <hr />
                </div>
              )}
            </div>
          ) : (
            <>
              <p className="matching_text_style mt-2">
                Your profile is already up-to-date and does not require any
                further improvement.
              </p>
            </>
          )}
        </div>
        <Modal.Footer>
          <CustomButton buttonLabel="Close" onClick={handleClose} secondary />
          <CustomButton buttonLabel="Understood" onClick={handleClose} />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SuggestionModal;
