import React from "react";
import { Route, Routes } from "react-router-dom";

import PageNotFound from "../reusables/PageNotFound/PageNotFound";

import AgencyPrivateRoute from "./PrivateRoutes/AgencyPrivateRoute";

import AgencyDashboard from "../Agency/AgencyDashboard/AgencyDashboard";
import AgencyHomePage from "../Agency/AgencyHomePage/AgencyHomePage";
import AgencySignUp from "../Agency/AgencySignUpControl/AgencySignUp";

import AgencyProfileView from "../Agency/AgencyProfileView/AgencyProfileView";

import AgencyAgentList from "../Agency/AgencyAgentControl/AgencyAgentList/AgencyAgentList";
import AgencyAgentProfileView from "../Agency/AgencyAgentControl/AgencyAgentProfileView/AgencyAgentProfileView";
import AgencyAddNewAgent from "../Agency/AgencyAgentControl/AgencyAddNewAgent/AgencyAddNewAgent";
import AgencyAgentPasswordChange from "../Agency/AgencyAgentControl/AgencyAgentPasswordChange/AgencyAgentPasswordChange";

import AgencyApplicationList from "../Agency/ApplicationControl/AgencyApplicationList/AgencyApplicationList";
import AgencyAddApplication from "../Agency/ApplicationControl/AgencyAddApplication/AgencyAddApplication";
import AgencyViewApplication from "../Agency/ApplicationControl/AgencyViewApplication/AgencyViewApplication";

import AgencyStudentList from "../Agency/AgencyStudentControl/AgencyStudentList/AgencyStudentList";
import AgencyAddStudent from "../Agency/AgencyStudentControl/AgencyAddStudent/AgencyAddStudent";
import AgencyStudentView from "../Agency/AgencyStudentControl/AgencyStudentView/AgencyStudentView";

const AgencyRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/*" element={<PageNotFound />} />

        <Route path="/home" element={<AgencyHomePage />} />

        <Route path="/signup" element={<AgencySignUp />} />

        <Route
          path="/dashboard"
          element={
            <AgencyPrivateRoute>
              <AgencyDashboard />
            </AgencyPrivateRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <AgencyPrivateRoute>
              <AgencyProfileView />
            </AgencyPrivateRoute>
          }
        />

        <Route
          path="/agentList"
          element={
            <AgencyPrivateRoute>
              <AgencyAgentList />
            </AgencyPrivateRoute>
          }
        />

        <Route
          path="/agent/view/:agentId"
          element={
            <AgencyPrivateRoute>
              <AgencyAgentProfileView />
            </AgencyPrivateRoute>
          }
        />

        <Route
          path="/agent/addAgent"
          element={
            <AgencyPrivateRoute>
              <AgencyAddNewAgent />
            </AgencyPrivateRoute>
          }
        />

        <Route
          path="/agent/changePassword"
          element={
            <AgencyPrivateRoute>
              <AgencyAgentPasswordChange />
            </AgencyPrivateRoute>
          }
        />

        <Route
          path="/application"
          element={
            <AgencyPrivateRoute>
              <AgencyApplicationList />
            </AgencyPrivateRoute>
          }
        />

        <Route
          path="/application/addApplication"
          element={
            <AgencyPrivateRoute>
              <AgencyAddApplication />
            </AgencyPrivateRoute>
          }
        />

        <Route
          path="/application/view/:applicationId"
          element={
            <AgencyPrivateRoute>
              <AgencyViewApplication />
            </AgencyPrivateRoute>
          }
        />

        <Route
          path="/student"
          element={
            <AgencyPrivateRoute>
              <AgencyStudentList />
            </AgencyPrivateRoute>
          }
        />

        <Route
          path="/student/addStudent"
          element={
            <AgencyPrivateRoute>
              <AgencyAddStudent />
            </AgencyPrivateRoute>
          }
        />

        <Route
          path="/student/view/:studentId"
          element={
            <AgencyPrivateRoute>
              <AgencyStudentView />
            </AgencyPrivateRoute>
          }
        />
      </Routes>
    </div>
  );
};

export default AgencyRoutes;
