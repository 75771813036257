import { useEffect, useState } from "react";
import axios from "axios";
import { API_HEADERS } from "../utils/constant";

export const useGetCourseForUniverciryById = (universityId) => {
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/university/getCoursesWithUniversityId/${universityId}`,
        API_HEADERS()
      )
      .then((response) => {
        setCourses(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, [universityId]);

  return [courses, isLoading, error];
};
