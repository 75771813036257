import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CourseCard.css";
import { AiFillHeart } from "react-icons/ai";
import { LuCalendarDays } from "react-icons/lu";

import CustomButton from "../FromComponent/CustomButton";

import { replaceLocalhostUrl } from "../../../utils/helpers";

const CourseCard = ({ courseData, favoriteList }) => {
  const navigate = useNavigate();
  const [like, setFavourite] = useState(false);

  useEffect(() => {
    favoriteList.map((id) => {
      if (id === courseData?._id) {
        return setFavourite(true);
      } else {
        return null;
      }
    });
  }, [favoriteList, courseData]);

  return (
    <div
      className="course_card_main_container"
      onClick={() => navigate(`/student/courseDetails/${courseData._id}`)}
    >
      <div className="course_card_basic_details_box">
        <img
          src={replaceLocalhostUrl(courseData?.coursethumbnail)}
          className="course_card_cover_img"
          alt="course_thumbnail"
        />
        <div className="course_card_basic_detail_sub_box">
          <div>
            <p className="university_card_uni_name">
              {courseData?.courseUniversityId?.universityName}
            </p>
            <p className="university_card_uni_city">
              {courseData?.courseUniversityId?.universityCity} /{" "}
              {courseData?.courseUniversityId?.universityCountry}
            </p>
          </div>
          <AiFillHeart
            className={
              like
                ? "university_card_heart_icon_red"
                : "university_card_heart_icon_gray"
            }
          />
        </div>
      </div>
      <div className="course_card_crs_details_box">
        <p className="course_card_crs_name">{courseData?.courseName}</p>
        <p className="university_card_uni_city">
          {courseData?.courseSubjectType}
        </p>

        {/* {courseData?.courseDescription.length < 100 ? (
          <p className="course_card_crs_desc">
            {courseData?.courseDescription}
          </p>
        ) : (
          <p className="course_card_crs_desc">
            {courseData?.courseDescription.slice(0, 100)}...
          </p>
        )} */}
      </div>
      <div>
        <span>
          <LuCalendarDays />
        </span>
        <span className="course_card_dates">Application Timeline:</span>
        <p>
          {courseData?.courseApplicationStartDate} to{" "}
          {courseData?.courseApplicationEndDate}
        </p>
      </div>
      <div className="course_card_free_container">
        <div className="course_card_free_sub_container">
          <p>Tuition Fees</p>
          <p> € {courseData?.courseFee} / year</p>
        </div>
        <div className="course_card_free_sub_container">
          <p>Duration</p>
          <p>{courseData?.courseDuration} Months</p>
        </div>
      </div>
      <div className="course_card_apply_btn_container">
        <CustomButton buttonLabel="Apply" />
      </div>
    </div>
  );
};

export default CourseCard;
