import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { GiHamburgerMenu } from "react-icons/gi";
import "./MainNavbar.css";

import { useGetHomeInfo } from "../../../../hooks/useGetHomeInfo";

import { AuthContext } from "../../AuthProvider/AuthProvider";
import Footer from "../../Footer/Footer";
import Loader from "../../Loader/Loader";
import ErrorPage from "../../ErrorPage/ErrorPage";

import CustomButton from "../../FromComponent/CustomButton";

import { main_nav_link } from "../../../../utils/constant";
import { replaceLocalhostUrl } from "../../../../utils/helpers";

const MainNavbar = ({ children }) => {
  const { user, setUserDetails } = useContext(AuthContext);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLogOut = () => {
    localStorage.removeItem("currentLoginUser");
    setUserDetails(null);
    navigate("/");
  };

  const [homeInfo, isLoading, error] = useGetHomeInfo();

  if (isLoading) return <Loader />;
  if (error) return <ErrorPage />;

  return (
    <div>
      <div
        className="main_nav_bar_holder"
        style={{
          backgroundImage: `linear-gradient(to right top, #192f596e, #192f596e, #192f596e, #192f596e, #192f596e), url(${replaceLocalhostUrl(
            homeInfo?.websiteCover
          )})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="main_navbar">
          <img
            src="/images/navbar/NewLogo.svg"
            onClick={() => navigate("/")}
            className="user_navbar_logo_style"
            alt="logo"
          />
          <div className="main_nav_link_holder">
            {main_nav_link.map((link) => (
              <Link
                className="link_text_style"
                to={user?._id ? link.afterLoginLink : link.link}
                key={link?.id}
              >
                {link.name}
              </Link>
            ))}
          </div>
          <div className="action_btn_holder">
            {["SuperAdmin", "SubSuperAdmin", "SubAdmin"].includes(
              user?.role
            ) ? (
              <CustomButton
                buttonLabel=" Admin"
                onClick={() => navigate("/superAdmin/dashboard")}
              />
            ) : null}

            <CustomButton
              buttonLabel={user?.role ? "Logout" : "Login"}
              onClick={() => {
                user?.role ? handleLogOut() : navigate("/login");
              }}
            />
          </div>
          <div className="user_mobile_nav_manu">
            <GiHamburgerMenu
              className="user_mobile_nav_manu_icon"
              onClick={handleShow}
            />
          </div>
        </div>
      </div>
      <div className="children_container_holder">{children}</div>
      <Footer />
      <div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          className="user_navbar_drawer"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {main_nav_link.map((link) => (
              <div className="user_mobile_link_text_style_container">
                <Link
                  to={link.link}
                  key={link.id}
                  className="user_mobile_link_text_style"
                >
                  {link.name}
                </Link>
              </div>
            ))}
            {["SuperAdmin", "SubSuperAdmin", "SubAdmin"].includes(
              user?.role
            ) ? (
              // <button
              //   className="basic_btn_style"
              //   onClick={() => navigate("/superAdmin/dashboard")}
              // >
              //   Admin
              // </button>
              <CustomButton
                buttonLabel="Admin"
                onClick={() => navigate("/superAdmin/dashboard")}
              />
            ) : null}
            <br />
            <br />
            {/* <button
              className="basic_btn_style"
              onClick={() => {
                user?.role ? handleLogOut() : navigate("/login");
              }}
            >
              {user?.role ? "Logout" : "Login"}
            </button> */}

            <CustomButton
              buttonLabel={user?.role ? "Logout" : "Login"}
              onClick={() => {
                user?.role ? handleLogOut() : navigate("/login");
              }}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
};

export default MainNavbar;
