import React from "react";
import "./CustomButton.css";

const CustomButton = ({
  buttonLabel,
  onClick,
  fullwidth = false,
  small = false,
  secondary = false,
  icon,
  className = "",
  ...rest
}) => {
  return (
    <div>
      <button
        type="button"
        className={` ${
          fullwidth
            ? "custom_button long_button"
            : small
            ? "small_custom_button "
            : secondary
            ? "secondary_custom_button"
            : "custom_button"
        }  ${className}`}
        onClick={onClick}
        {...rest}
      >
        {icon && icon}
        {buttonLabel}
      </button>
    </div>
  );
};

export default CustomButton;
