import React from "react";
import { Navigate } from "react-router-dom";

const AgencyPrivateRoute = ({ children }) => {
  const currentUserRole = JSON.parse(
    localStorage.getItem("currentLoginUser")
  )?.role;

  const allowedRoles = [
    "AgencySuperAdmin",
    "AgencySubSuperAdmin",
    "AgencySubAdmin",
  ];

  return allowedRoles.includes(currentUserRole) ? (
    <div>{children}</div>
  ) : (
    <Navigate to={"/"} />
  );
};

export default AgencyPrivateRoute;
