import React from "react";
import "./ContactUsContainer.css";

import SocialMediaBlock from "./ContactUsContent/SocialMediaBlock";
import EmailInfoBlock from "./ContactUsContent/EmailInfoBlock";
import UserNavbar from "../../reusables/Navbar/UserNavbar/UserNavbar";
import PageTitle from "../../reusables/PageTitle/PageTitle";

const ContactUsContainer = () => {
  return (
    <div>
      <UserNavbar>
        <PageTitle title={"Contact Us"} />
        <div className="about_us_container_style">
          <div className="email_container_style">
            <EmailInfoBlock />
          </div>
          <div className="solical_media_style">
            <SocialMediaBlock />
          </div>
        </div>
      </UserNavbar>
    </div>
  );
};

export default ContactUsContainer;
