import React, { useRef, useState } from "react";
import "./VideoPlayer.css";
import { replaceLocalhostUrl } from "../../../utils/helpers";

const VideoPlayer = ({ videoSource, classname, text }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };
  return (
    <div className="video_container">
      <video
        ref={videoRef}
        src={
          videoSource
            ? replaceLocalhostUrl(videoSource)
            : "/images/superAdmin/upload_placeholder.png"
        }
        className={`${classname} ${!isPlaying ? "play_video_bkg" : ""}`}
        onClick={togglePlayPause}
      />
      <button
        className={`play-pause-button ${isPlaying ? "playing" : "paused"}`}
        onClick={togglePlayPause}
      ></button>
      {!isPlaying && text ? (
        <div className="pause_video_title">
          <h1>{text.title}</h1>
          <p>{text.desc}</p>
        </div>
      ) : null}
    </div>
  );
};

export default VideoPlayer;
